import { Box, Grid, IconButton, Pagination, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Bookmark } from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import { UnknownText } from 'src/utils/constants/config';

// Utilities
import CompareCompany from 'src/asset/icons/compare-company.svg';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  position: 'relative',
}));

const DUMP_DATA = [
  {
    id: '1',
    is_marked: true,
    company_1: {
      name: 'Ledner - Gutmann',
      country: 'CA',
    },
    company_2: {
      name: 'Block, King and Ritchie',
      country: 'CN',
    },
  },
  {
    id: '2',
    is_marked: true,
    company_1: {
      name: 'Ledner - Gutmann',
      country: 'CA',
    },
    company_2: {
      name: 'Block, King and Ritchie',
      country: 'CN',
    },
  },
  {
    id: '3',
    is_marked: true,
    company_1: {
      name: 'Ledner - Gutmann',
      country: 'CA',
    },
    company_2: {
      name: 'Block, King and Ritchie',
      country: 'CN',
    },
  },
  {
    id: '4',
    is_marked: true,
    company_1: {
      name: 'Ledner - Gutmann',
      country: 'CA',
    },
    company_2: {
      name: 'Block, King and Ritchie',
      country: 'CN',
    },
  },
  {
    id: '5',
    is_marked: true,
    company_1: {
      name: 'Ledner - Gutmann',
      country: 'CA',
    },
    company_2: {
      name: 'Block, King and Ritchie',
      country: 'CN',
    },
  },
];

const ListCompareCompany = () => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {DUMP_DATA.map((data, index) => {
          return (
            <Grid xs={12} md={6} lg={6} xl={4} key={`company-bookmark-${data.id}-${index}`} item>
              <Profile dataCompare={data} />
            </Grid>
          );
        })}
      </Grid>
      <Stack mt={2} alignItems="center">
        <Pagination count={10} color="primary" shape="rounded" />
      </Stack>
    </React.Fragment>
  );
};

export default ListCompareCompany;

const Profile = ({
  dataCompare,
}: {
  dataCompare: {
    id: string;
    is_marked: boolean;
    company_1: {
      name: string;
      country: string;
    };
    company_2: {
      name: string;
      country: string;
    };
  };
}) => {
  const theme = useTheme();

  return (
    <StyledBox
      sx={{
        borderColor: theme.palette.divider,
      }}
      display={'flex'}
      flexDirection="column"
      gap={1}
      padding={1.5}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          border: '1px solid' + theme.palette.divider,
        }}
      >
        <IconFeather
          muiProps={{ fontSize: 'small' }}
          // icon={<Bookmark />}
          icon={<Bookmark fill={dataCompare.is_marked ? theme.palette.primary.main : 'transparent'} />}
        />
      </IconButton>
      <Typography color="text.secondary" variant="subtitle2">
        Corporates Compare
      </Typography>
      <Stack direction="column" pl={4} position="relative">
        <Stack alignItems={'center'} spacing={1} direction="row">
          <FlagCountry width={20} code={dataCompare.company_1.country} />
          <Typography color={theme.palette.secondary.main} variant="subtitle1">
            {dataCompare.company_1.name || UnknownText}
          </Typography>
        </Stack>
        <Stack alignItems={'center'} spacing={1} direction="row" mt={2}>
          <FlagCountry width={20} code={dataCompare.company_2.country} />
          <Typography color={theme.palette.secondary.main} variant="subtitle1">
            {dataCompare.company_2.name || UnknownText}
          </Typography>
        </Stack>
        <Box
          position="absolute"
          mt={0}
          top="50%"
          left={theme.spacing(-0.5)}
          zIndex={100}
          bgcolor={theme.palette.background.default}
          display="flex"
          sx={{
            transform: 'translateY(-50%)',
          }}
        >
          <Typography color="text.secondary" variant="caption">
            vs
          </Typography>
        </Box>
        <Box
          component={'img'}
          height={40}
          width={28}
          position="absolute"
          mt={0}
          top="50%"
          left={0}
          sx={{
            transform: 'translateY(-50%)',
          }}
          src={CompareCompany}
          // border={`1px solid ${theme.palette.divider}`}
          // borderRadius={theme.shape.borderRadius / 4}
        />
      </Stack>
    </StyledBox>
  );
};
