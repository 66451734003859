import { ChangeEvent, useState } from 'react';
import { Container, Stack, Typography, styled, Box, Pagination } from '@mui/material';
import queryString from 'query-string';
import numeral from 'numeral';
import { get } from 'lodash';

// Components
import ListPeople from 'src/features/PeopleProfiles/ListPeople';
import PeopleProfileDetail from 'src/features/PeopleProfiles/PeopleProfileDetail';
import FilterPeopleProfile from 'src/features/PeopleProfiles/Filter';

// Utilities
import { useQueryParams } from 'src/hooks/useQueryParam';
import { useQueryListPeople } from 'src/services/queries';
import { useSearchParams } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(7),
  marginTop: 8,
}));

const PeopleProfiles = () => {
  const query = useQueryParams();
  const [selected, setSelected] = useState(undefined as undefined | IPeopleProfile);
  const [, setSearchParams] = useSearchParams();
  const { data, isFetching } = useQueryListPeople(queryString.stringify(query));
  const { data: listPeople = [] } = data;
  return (
    <StyledContainer maxWidth="xl">
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            People Profile Search Result
          </Typography>
          <Typography variant="body2" gutterBottom>
            We found{' '}
            <Typography variant="subtitle2" component="span">
              {numeral(get(data, 'total_records', 0) || 0).format('0,0')} profiles matching
            </Typography>{' '}
            your search conditions
          </Typography>
        </Stack>
        <Box my={2}>
          <FilterPeopleProfile />
        </Box>
        <Stack direction="row" spacing={3}>
          <Stack flex={3}>
            <ListPeople
              listPeople={listPeople}
              isFetching={isFetching}
              selected={selected}
              onSelect={(people) => {
                setSelected(people);
              }}
            />
            {listPeople.length > 0 && (
              <Stack mt={2} alignItems="center">
                <Pagination
                  page={Number(query.offset) + 1}
                  count={data.total_pages}
                  onChange={function (_: ChangeEvent<unknown>, value: number): void {
                    setSearchParams((searchParams) => {
                      searchParams.set('offset', String(value - 1));
                      return searchParams;
                    });
                  }}
                  color="primary"
                  shape="rounded"
                  size="small"
                />
              </Stack>
            )}
          </Stack>
          <Stack flex={1}>{selected && <PeopleProfileDetail id={selected?.id || ''} />}</Stack>
        </Stack>
      </Stack>
    </StyledContainer>
  );
};

export default PeopleProfiles;
