import { SvgIcon, SvgIconProps } from '@mui/material';

const TwitterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 20 18" fill="none">
        <g clipPath="url(#clip0_245_25024)">
          <path
            d="M15.447 0H18.3603L11.9637 7.28333L19.437 17.1633H13.5723L8.98067 11.1593L3.724 17.1633H0.810664L7.58733 9.37333L0.430664 0H6.441L10.5893 5.48467L15.447 0ZM14.4273 15.4533H16.0423L5.59233 1.64667H3.857L14.4273 15.4533Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_245_25024">
            <rect width="19" height="17.1633" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default TwitterIcon;
