import React from 'react';
import { Helmet } from 'react-helmet';
import GeneralDisclaimer from 'src/features/GeneralDisclaimer';

const GeneralDisclaimerPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>General Disclaimer</title>
      </Helmet>
      <GeneralDisclaimer />
    </React.Fragment>
  );
};

export default GeneralDisclaimerPage;
