import { styled } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

const StyledLink = styled(Link)<IProps>(({ color }) => ({
  textDecoration: 'none',
  color: color ?? 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
}));

interface IProps extends LinkProps {
  color?: string;
}

const RouterLinkNoUnderline = (props: IProps) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};

export default RouterLinkNoUnderline;
