import { Box, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highmaps';
import React, { useEffect } from 'react';
import {
  getWorldMapBillOfLandingOption,
  getWorldMapCustomerOption,
  IWorldMapProps,
  WorldMapOptions,
} from 'src/components/HighChart/WorldMapChart/config';

interface IProps {
  height: number;
  type: WorldMapOptions;
  data: Array<number | [string, number | null] | null | Highcharts.SeriesMapDataOptions>;
  dataPoint?: Array<number | [number, number | null] | null | Highcharts.SeriesMappointDataOptions>;
  tooltipFormatter?: Highcharts.TooltipFormatterCallbackFunction;
}

const WorldMapChart = ({ height, type, data, dataPoint = [], tooltipFormatter }: IProps) => {
  const [mapData, setMapData] = React.useState(null);
  const theme = useTheme();

  useEffect(() => {
    fetch('https://code.highcharts.com/mapdata/custom/world.topo.json')
      .then((response) => response.json())
      .then((data) => setMapData(data))
      .catch(() => setMapData(null));
  }, []);

  if (!mapData) {
    return <Box height={height}>Loading map data...</Box>;
  }

  const getOption: () => Highcharts.Options = () => {
    const optionProps = {
      mapData: mapData,
      height: height,
      theme: theme,
      data: data,
      dataPoint: dataPoint,
      tooltipFormatter: tooltipFormatter,
    } as IWorldMapProps;
    switch (type) {
      case WorldMapOptions.company_bill_of_landing:
        return getWorldMapBillOfLandingOption({ ...optionProps });
      case WorldMapOptions.customer:
        return getWorldMapCustomerOption({ ...optionProps });
      default:
        return {};
    }
  };

  return <HighchartsReact highcharts={Highcharts} constructorType={'mapChart'} options={getOption()} />;
};

export default WorldMapChart;
