import { DatePicker, DatePickerProps } from '@icttm-dev/x-date-pickers-pro';
import { Calendar } from 'react-feather';
import IconFeather from 'src/components/common/Icon';

const ICon = () => <IconFeather muiProps={{ fontSize: 'small' }} icon={<Calendar />} />;

const DatePickerWithDefaultIcon = (props: DatePickerProps<any>) => {
  return (
    <DatePicker
      slots={{
        openPickerIcon: ICon,
      }}
      {...props}
    />
  );
};

export default DatePickerWithDefaultIcon;
