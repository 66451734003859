import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ReactNode, useMemo } from 'react';
import { Search } from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import PaperSelect from 'src/components/common/PaperSelect';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import { COUNTRIES } from 'src/utils/constants/countries';
import { sortObjectsArrayByKey } from 'src/utils/helpers/arrayHelpers';
import { getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';

interface IProps {
  id: string;
  required?: boolean;
  tooltipText?: string;
  label?: ReactNode;
}

const FormItemCountry = ({ id, tooltipText = '', label, required = false }: IProps) => {
  const formik = useFormikContext<ExpectedAny>();

  const country = useMemo(() => {
    return getCountryByCountryCode(formik.values[id]);
  }, [formik.values[id]]);

  return (
    <Autocomplete
      value={country}
      onChange={(_, value) => {
        formik.setFieldValue(id, value?.code);
      }}
      onBlur={formik.handleBlur}
      id={id}
      fullWidth
      size="small"
      autoComplete
      options={sortObjectsArrayByKey(COUNTRIES, 'label')}
      popupIcon={<IconFeather muiProps={{ fontSize: 'small' }} icon={<Search />} />}
      PaperComponent={PaperSelect}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box gap={1} display={'flex'} component="li" {...props}>
          <FlagCountry width={24} code={option.code} />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            ...params.InputLabelProps,
          }}
          placeholder="Typing..."
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: country.code ? <FlagCountry width={24} code={country.code} /> : null,
          }}
          label={label ? label : <span>Your Country {tooltipText && <TooltipFormLabel title={tooltipText} />}</span>}
        />
      )}
    />
  );
};

export default FormItemCountry;
