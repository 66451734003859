import MainLayout from 'src/layouts';
import { Helmet } from 'react-helmet';
import TradeData from 'src/features/TradeData';

const TradeDataPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Trade Data</title>
      </Helmet>
      <TradeData />
    </MainLayout>
  );
};

export default TradeDataPage;
