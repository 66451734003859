import {
  Checkbox,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import * as React from 'react';

// Component
import { isEmpty, isFunction, isNumber } from 'lodash';
import TableHeader from './TableHeader';
import TableToolbar from './TableToolbar';

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
}));

const HEADER_HEIGHT = 57;

export default function CustomTable<T>({
  selectable,
  data,
  configColumn,
  dataKey,
  size = 'medium',
  maxRow = 5,
  handleSort,
  handleFilter,
  page = 0,
  pageSize = 10,
  onChangePage,
  onChangePageSize,
  onRowClick,
  loading = false,
  isPagination = true,
  totalRecords,
}: {
  id?: string;
  selectable?: boolean;
  data: T[];
  configColumn: ConfigCell<T>[];
  dataKey: keyof T;
  size?: 'small' | 'medium';
  maxRow?: number;
  handleSort?: ({ sortBy, sort }: { sortBy: string; sort: OrderType }) => void;
  handleFilter?: () => void;
  page?: number;
  pageSize?: number;
  onChangePage?: (e: number) => void;
  onChangePageSize?: (e: number) => void;
  onRowClick?: (data: T) => void;
  loading?: boolean;
  isPagination?: boolean;
  totalRecords?: number;
}) {
  const theme = useTheme();
  const [maxHeight, setMaxHeight] = React.useState(0 as number | undefined);
  const [selected, setSelected] = React.useState<readonly T[]>([]);
  const [order, setOrder] = React.useState<OrderType>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');

  const refBody = React.useRef<ExpectedAny>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (isFunction(onChangePage)) {
      onChangePage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isFunction(onChangePageSize)) {
      onChangePageSize(parseInt(event.target.value, 10));
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleSort?.({ sortBy: property, sort: isAsc ? 'desc' : 'asc' });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: T) => {
    if (!selectable) return;
    const selectedIndex = selected.findIndex((item) => item[dataKey] === id[dataKey]);
    let newSelected: readonly T[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleRowClick = (event: React.MouseEvent<unknown>, data: T) => {
    handleClick(event, data);
    onRowClick?.(data);
  };

  const isSelected = (id: ExpectedAny) => selected.findIndex((item) => item[dataKey] === id[dataKey]) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * pageSize - data.length) : 0;

  React.useEffect(() => {
    if (isEmpty(data)) {
      setMaxHeight(undefined);
      return;
    }
    let headerHeight = HEADER_HEIGHT;
    const header = document.getElementById('custom-table_header');
    if (header) {
      headerHeight = header.offsetHeight + 1;
    }
    if (isNumber(refBody?.current?.clientHeight) && data.length > 0) {
      const heightPerRow = refBody?.current?.clientHeight / data.length;
      setMaxHeight(heightPerRow * maxRow + headerHeight);
    } else {
      setMaxHeight(headerHeight);
    }
  });

  const loadingSkeleton = new Array(5).fill(0).map((key: number) => (
    <TableRow key={key}>
      {configColumn.map((config) => (
        <StyledBodyCell key={config.id}>
          <Skeleton />
        </StyledBodyCell>
      ))}
    </TableRow>
  ));

  const renderEmptyData = () => {
    if (!loading && isEmpty(data)) {
      return emptyData;
    }
    return null;
  };

  const emptyData = new Array(1).fill(0).map((key: number) => (
    <TableRow key={key}>
      {configColumn.map((config) => (
        <StyledBodyCell key={config.id}>
          <Typography color={theme.palette.text.disabled}>--</Typography>
        </StyledBodyCell>
      ))}
    </TableRow>
  ));

  return (
    <Paper sx={{ width: '100%' }}>
      {handleFilter && <TableToolbar numSelected={selected.length} />}
      <TableContainer sx={{ maxHeight: maxHeight }} component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& .MuiTableRow-root.MuiTableRow-hover:hover': {
              backgroundColor: theme.palette.background.paper1,
            },
            width: '100%',
            tableLayout: 'fixed',
          }}
          stickyHeader
          aria-label="simple table"
          size={size}
        >
          <TableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            handleSelectAllClick={handleSelectAllClick}
            handleRequestSort={handleRequestSort}
            rowCount={data.length}
            configColumn={configColumn}
            selectable={selectable}
          />

          <TableBody ref={refBody}>
            {loading && loadingSkeleton}

            {renderEmptyData()}

            {!loading &&
              data.map((row, indexRow) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${indexRow}`;
                return (
                  <TableRow
                    key={`column-row-${indexRow}`}
                    hover
                    onClick={(event) => handleRowClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {selectable && (
                      <StyledBodyCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </StyledBodyCell>
                    )}
                    {configColumn.map((config, i) => (
                      <StyledBodyCell
                        key={config.id + indexRow + i}
                        align={config.align || 'left'}
                        padding={config.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === config.id ? order : false}
                      >
                        {config.cell(row)}
                      </StyledBodyCell>
                    ))}
                  </TableRow>
                );
              })}
            {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (size === 'small' ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      {isPagination && (
        <TablePagination
          disabled={loading}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRecords ? totalRecords : 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
