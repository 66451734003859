// import SearchIcon from '@mui/icons-material/Search';
// import TuneIcon from '@mui/icons-material/Tune';
import {
  Avatar,
  // Badge,
  Box,
  // Button,
  Container,
  IconButton,
  // InputAdornment,
  Menu,
  MenuItem,
  Stack,
  // TextField,
  // textFieldClasses,
  Toolbar,
  toolbarClasses,
  Typography,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import React from 'react';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
// import { BellIcon, ChatBubbleIcon } from 'src/components/Icons';

// Utilities
import { useNavigate } from 'react-router-dom';
import { queryClient } from 'src/configs/queryClient';
import useAuth from 'src/hooks/useAuth';
import { ME_KEY } from 'src/services/queries/useQueryAuth';
import { DRAWER_WIDTH_CLOSE, EmptyText, HEADER_HEIGHT } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

// const SearchInput = styled(TextField)(({ theme }) => ({
//   '& .MuiOutlinedInput-root': {
//     borderWidth: 1,
//     borderRadius: 100,
//     '& .MuiOutlinedInput-input': {
//       borderRadius: 100,
//       padding: '8px 16px',
//     },
//     fieldset: {
//       borderColor: theme.palette.divider,
//       borderRadius: 100,
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: theme.palette.divider,
//     },
//     '&:hover fieldset': {
//       borderColor: theme.palette.divider,
//     },
//   },
//   ['&.' + textFieldClasses.root]: {
//     marginLeft: 0,
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//   },
// }));

// const DividerIcon = styled(Box)(({ theme }) => ({
//   height: theme.spacing(3),
//   width: 1,
//   backgroundColor: theme.palette.divider,
// }));

const StyledAppbar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
  height: HEADER_HEIGHT,
  paddingLeft: DRAWER_WIDTH_CLOSE,
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { logoutFromContext } = useAuth();
  const { handleOpenSearch } = useFormSearch((state) => state);
  const userInfoFromCache = queryClient.getQueryData([ME_KEY]) as IResponse<IUser>;
  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledAppbar position="fixed" color="inherit">
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            ['&.' + toolbarClasses.root]: {
              padding: 0,
            },
          }}
        >
          {/* <Stack direction="row" justifyContent="center" alignItems="center">
            <SearchInput
              id="search-header"
              placeholder="Search…"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button disabled variant="text" color="inherit" startIcon={<TuneIcon />} sx={{ textTransform: 'none' }}>
              Advanced
            </Button>
          </Stack> */}
          <Stack direction="row" alignItems="center" justifyContent="start" spacing={2} sx={{ flexGrow: 1 }}>
            <ButtonWithLoading
              onClick={() => navigate(AuthRouteKeys.TradeData)}
              // onClick={() => handleOpenSearch(FormType.SearchTradeData)}
              sx={{ textTransform: 'none' }}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                Trade Data
              </Typography>
            </ButtonWithLoading>
            <ButtonWithLoading
              onClick={() => {
                handleOpenSearch(FormType.SearchCompany);
              }}
              sx={{ textTransform: 'none' }}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                Companies
              </Typography>
            </ButtonWithLoading>
            <ButtonWithLoading
              onClick={() => {
                handleOpenSearch(FormType.SearchPeople);
              }}
              sx={{ textTransform: 'none' }}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                People
              </Typography>
            </ButtonWithLoading>
            {/* <ButtonWithLoading
              onClick={() => {
                handleOpenSearch(FormType.CompareCompany);
              }}
              sx={{ textTransform: 'none' }}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                Compare
              </Typography>
            </ButtonWithLoading> */}
            <ButtonWithLoading sx={{ textTransform: 'none' }} onClick={() => navigate(AuthRouteKeys.TariffsAndRules)}>
              <Typography variant="subtitle1" fontWeight={600}>
                Tariffs and Rules
              </Typography>
            </ButtonWithLoading>
          </Stack>

          <Stack direction="row" justifyContent="center" alignItems="center">
            {/* <IconButton size="large" aria-label="show 17 new notifications" color="inherit" disabled>
              <Badge badgeContent={5} color="default">
                <ChatBubbleIcon />
              </Badge>
            </IconButton>
            <DividerIcon />
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit" disabled>
              <Badge badgeContent={2} color="default">
                <BellIcon />
              </Badge>
            </IconButton>
            <DividerIcon /> */}
            <Box mx={2}>
              <Typography variant="subtitle2">{userInfoFromCache.data?.username}</Typography>
              <Typography variant="caption">{userInfoFromCache.data?.email}</Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userInfoFromCache.data?.alt} src={userInfoFromCache.data?.avatar_url} sizes="48">
                  {(userInfoFromCache?.data?.first_name || EmptyText).charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-header"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={logoutFromContext}>
                  <Typography textAlign="center">Sign Out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
        </Toolbar>
      </Container>
    </StyledAppbar>
  );
}
