import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Step, StepLabel, Stepper, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserType } from 'src/utils/constants/enum';
import StepFeature from 'src/features/Onboarding/Steps/StepFeature';
import { useQueryUserInfo } from 'src/services/queries/useQueryAuth';
import { AuthRouteKeys } from 'src/utils/constants/routes';

//Components
import { useOnboardingStore } from 'src/zustand/useOnboardingStore';
import { STEPS_LABEL } from './config';
import StepGetStarted from './Steps/StepGetStarted';
import StepInformation from './Steps/StepInformation';
import StepInformationCompany from './Steps/StepInformationCompany';
import StepInformationOther from './Steps/StepInformationOther';
import StepTypeAccount from './Steps/StepTypeAccount';
import { AccountType } from 'src/utils/constants/enum';

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { data } = useQueryUserInfo();

  if (data.data.type === UserType.User) {
    navigate({ pathname: AuthRouteKeys.Home });
  }

  const { accountType } = useOnboardingStore((state) => state);

  const theme = useTheme();

  const renderFormInformation = (propsF: IFormOnboardingProps) => {
    switch (accountType) {
      case AccountType.PrivateBussiness:
        return <StepInformationCompany {...propsF} />;
      case AccountType.GovermentAgency:
        return <StepInformation {...propsF} />;
      case AccountType.Other:
        return <StepInformationOther {...propsF} />;
      default:
        return null;
    }
  };

  const onClickContinue = () => {
    if (step < STEPS_LABEL.length) {
      setStep(step + 1);
    }
  };

  const renderForm = () => {
    const propsF = {
      onClickContinue: onClickContinue,
      onClickBack: () => setStep(step - 1),
    } as IFormOnboardingProps;

    switch (step) {
      case 1:
        return <StepGetStarted {...propsF} />;
      case 2:
        return <StepTypeAccount {...propsF} />;
      case 3:
        return renderFormInformation(propsF);
      case 4:
        return <StepFeature {...propsF} />;
      default:
        return null;
    }
  };

  const renderLatestStep =
    step === STEPS_LABEL.length ? (
      <Step last active={step === STEPS_LABEL.length} key={STEPS_LABEL.length + 1}>
        <StepLabel
          sx={{
            '& .MuiStepLabel-label': {
              color: theme.palette.success.main,
            },
          }}
          StepIconComponent={() => <CheckCircleIcon color="success" />}
        >
          Dashboard
        </StepLabel>
      </Step>
    ) : (
      <Step last active={step === STEPS_LABEL.length} key={STEPS_LABEL.length + 1}>
        <StepLabel>Dashboard</StepLabel>
      </Step>
    );

  return (
    <Box
      sx={{
        height: '100vh',
        background: theme.palette.background.paper1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        padding={4}
        sx={{
          width: 680,
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius / 2,
        }}
      >
        {renderForm()}
      </Box>

      <Box sx={{ width: 800, mt: 6 }}>
        <Stepper nonLinear>
          {STEPS_LABEL.map((st: string, index: number) => (
            <Step disabled active={index < step} key={index}>
              <StepLabel>{st}</StepLabel>
            </Step>
          ))}
          {renderLatestStep}
        </Stepper>
      </Box>
    </Box>
  );
};

export default Onboarding;
