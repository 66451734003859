import React, { createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/utils/constants/config';
import { AuthRouteKeys, NoAuthRouteKeys } from 'src/utils/constants/routes';
import { useQueryUserInfo } from 'src/services/queries';
import { ERROR_CODE, SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { UserType } from 'src/utils/constants/enum';

type AuthContextProps = {
  logoutFromContext: () => void;
};

const initialState = {
  logoutFromContext: () => null,
};

// ==============================|| AUTH CONTEXT & PROVIDER ||============================== //
const AuthContext = createContext<AuthContextProps>(initialState);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { data, isFetching } = useQueryUserInfo();
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  useEffect(() => {
    if (!isFetching && data.status_code !== SUCCESS_CODE.GET && !refreshToken) {
      navigate(NoAuthRouteKeys.Login);
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      return;
    }
    // User not  found
    if (!isFetching && data.status_code === ERROR_CODE.NOT_FOUND) {
      navigate(NoAuthRouteKeys.Login);
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      return;
    }
    //check user set up onboarding or not
    if (!isFetching && data.status_code === SUCCESS_CODE.GET && data.data?.type !== UserType.User) {
      navigate({ pathname: AuthRouteKeys.Onboarding });
      return;
    }
  }, [JSON.stringify(data)]);

  const logoutFromContext = () => {
    try {
      navigate(NoAuthRouteKeys.Login);
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    } catch (error) {
      console.error('Error initiating logoutFromContext:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        logoutFromContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
