import { Container, Grid, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbWithBtnBack from 'src/components/BreadcrumbWithBtnBack';

// Components
import TabComponent from 'src/components/Tab/TabComponent';
import PeopleDetailCompanyRelated from 'src/features/PeopleDetail/CompanyRelated';
import PeopleDetailOverview from 'src/features/PeopleDetail/Overview';
import PeopleDetailPeopleRelated from 'src/features/PeopleDetail/PeopleRelated';
import PeopleDetailUserInfoPanel from 'src/features/PeopleDetail/UserInfoPanel';

// Utilities
import { PEOPLE_DETAIL_TABS, PEOPLE_DETAIL_TAB_KEYS } from 'src/features/PeopleDetail/config';
import { useQueryPeopleDetail } from 'src/services/queries';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { convertToStartCase } from 'src/utils/helpers/stringHelpers';
import analytics from 'src/analytics';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(7),
}));

const PeopleDetail = () => {
  const [selectedTab, setSelectedTab] = useState(PEOPLE_DETAIL_TAB_KEYS.overview);
  const { id } = useParams();
  const { data, isFetching } = useQueryPeopleDetail(id || '');
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate(AuthRouteKeys.Home);
    }
  }, []);

  const renderTab = () => {
    switch (selectedTab) {
      case PEOPLE_DETAIL_TAB_KEYS.overview:
        return <PeopleDetailOverview dataPeople={data?.data} isFetching={isFetching} />;
      case PEOPLE_DETAIL_TAB_KEYS.company_related:
        return <PeopleDetailCompanyRelated />;
      case PEOPLE_DETAIL_TAB_KEYS.people_related:
        return <PeopleDetailPeopleRelated id={id} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if(!data?.data?.full_name) return;
    analytics.logEvent('page_view', {
      'page_title': `People Detail - ${data?.data?.full_name}`,
      'page_location': window.location.href,
      'page_path': window.location.pathname
    })
  }, [data?.data?.full_name]);

  return (
    <StyledContainer maxWidth={'xl'}>
      <Grid spacing={3} container>
        <Grid xs={12} item>
          <BreadcrumbWithBtnBack
            listItem={[
              {
                text: 'People',
              },
              {
                text: convertToStartCase(data?.data?.country_name || ''),
              },
              {
                text: convertToStartCase(data?.data?.full_name || ''),
              },
            ]}
            updatedAt={data?.data?.updated_at || ''}
          />
        </Grid>

        <Grid xs={12} item>
          <PeopleDetailUserInfoPanel dataPeople={data?.data} isFetching={isFetching} />
        </Grid>

        <Grid xs={12} item>
          <TabComponent
            selected={selectedTab}
            handleChange={(_, e: string) => {
              setSelectedTab(e as PEOPLE_DETAIL_TAB_KEYS);
            }}
            tabs={PEOPLE_DETAIL_TABS}
          />
        </Grid>

        {renderTab()}
      </Grid>
    </StyledContainer>
  );
};

export default PeopleDetail;
