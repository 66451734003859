import { AccountType } from 'src/utils/constants/enum';
import { create } from 'zustand';

export interface Information {
  //government
  department?: string;
  website_address?: string;
  type_of_agency_working_for?: string;
  role?: string;
  work_email?: string;
  confirm_work_email?: boolean;
  //company
  company_name?: string;
  //other
  industry?: string;
  type_organization?: string;
}
export interface IFeature {
  //government
  identify_counterfeit_goods?: boolean;
  investigate_market_trends?: boolean;
  map_a_supply_chain?: boolean;
  source_new_suppliers?: boolean;
  predicting_future_demand?: boolean;
  understand_taxes_and_tariffs_of_products?: boolean;
  explore_supply_chain_disruptions?: boolean;
  ensure_compliance_with_trade_transparency_regulations?: boolean;
  negative_media_monitoring?: boolean;
  obtain_company_contacts?: boolean;
  //company
  KYC_AML?: boolean;
  find_new_customers?: boolean;
  understand_tariffs_and_taxes_for_my_product?: boolean;
  mapping_my_supply_chain?: boolean;
}

export type IAccountType = AccountType;
export interface IOnboardingState {
  address?: string;
  avatar?: string;
  accountType?: IAccountType;
  informations?: Information;
  features?: IFeature;
}

export interface IOnboardingAction {
  dispatch: (state: IOnboardingState) => void;
}

export const useOnboardingStore = create<IOnboardingState & IOnboardingAction>((set) => ({
  address: '',
  avatar: undefined,
  accountType: AccountType.PrivateBussiness,
  informations: undefined,
  features: undefined,
  dispatch: (state: IOnboardingState) => set((prevState) => ({ ...prevState, ...state })),
}));
