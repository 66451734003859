import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { Bookmark, Globe, Mail, MapPin, Phone } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import IconFeather from 'src/components/common/Icon';
import LogoCompany from 'src/components/common/LogoCompany';
import { useQueryQuickViewCompanyProfile } from 'src/services/queries/useQueryCompany';
import { FORMAT_DATE_TIME, UnknownText } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { convertToStartCase } from 'src/utils/helpers/stringHelpers';

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  height: 48,
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  textAlign: 'center',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

interface IProps {
  selected?: ICompanyProfile;
}

const CompanyProfileDetail = ({ selected }: IProps) => {
  const {
    data: { data },
    isFetching,
  } = useQueryQuickViewCompanyProfile(selected?.id || '');
  const navigate = useNavigate();

  const theme = useTheme();

  const navigateToDetail = () => {
    if (selected?.id) {
      navigate(AuthRouteKeys.CompanyDetail.replace(':id?', selected?.id));
    }
  };

  return (
    <Card>
      <StyledCardHeader
        title={
          <Typography color="white" variant="subtitle1">
            Quick View Panel
          </Typography>
        }
      />
      {isFetching ? (
        <SkeletonContent />
      ) : (
        <StyledCardContent>
          <Box>
            <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} mb={1}>
              <LogoCompany logoURL={data.logo_url || ''} width={40} height={40} />
              <Tooltip title="Bookmarks Coming Soon!">
                <IconButton sx={{ border: '1px solid' + theme.palette.divider }} size="medium">
                  <IconFeather
                    muiProps={{ fontSize: 'small' }}
                    icon={<Bookmark fill={data.is_marked ? theme.palette.primary.main : 'transparent'} />}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography color={theme.palette.primary.main} variant="subtitle1">
              {(data?.name || '').toUpperCase() || UnknownText}
            </Typography>
          </Box>

          <Stack spacing={1}>
            {data?.full_address && (
              <InformationItem
                theme={theme}
                icon={<MapPin />}
                label={(data?.full_address || UnknownText).toUpperCase()}
              />
            )}
            {!isEmpty(data?.phone_numbers) && (
              <InformationItem theme={theme} icon={<Phone />} label={getArrayDefault(data?.phone_numbers).join(', ')} />
            )}
            {!isEmpty(data?.emails) && (
              <InformationItem theme={theme} icon={<Mail />} label={getArrayDefault(data?.emails).join(', ')} />
            )}
            {!isEmpty(data?.websites) && (
              <InformationItem theme={theme} icon={<Globe />} label={getArrayDefault(data?.websites).join(', ')} />
            )}
          </Stack>

          <Divider />

          <Stack spacing={1}>
            <Typography gap={1} display={'flex'} variant="subtitle2">
              Company Number:{' '}
              <Typography color={theme.palette.text.secondary} variant="body2">
                {(data?.company_code || '').toUpperCase() || UnknownText}
              </Typography>
            </Typography>

            <Typography gap={1} display={'flex'} variant="subtitle2">
              Incorporation Date:{' '}
              <Typography color={theme.palette.text.secondary} variant="body2">
                {data?.date_incorporated
                  ? formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, data?.date_incorporated)
                  : UnknownText}
              </Typography>
            </Typography>

            <Box>
              <Typography display={'inline'} variant="subtitle2">
                Company Type:
              </Typography>
              <Typography ml={1} display={'inline'} color={theme.palette.text.secondary} variant="body2">
                {convertToStartCase(data?.company_type || '') || UnknownText}
              </Typography>
            </Box>

            <Typography gap={1} display={'flex'} variant="subtitle2">
              Jurisdiction:{' '}
              <Typography color={theme.palette.text.secondary} variant="body2">
                {convertToStartCase(data?.jurisdiction || '') || UnknownText}
              </Typography>
            </Typography>
          </Stack>

          <Divider />

          {!isEmpty(data.hs_codes) && (
            <Stack gap={1} flexWrap={'wrap'} alignItems={'center'} direction={'row'}>
              <Typography variant="subtitle2">HS Code</Typography>
              {getArrayDefault(data.hs_codes).map((code: string) => (
                <Chip key={code} size="small" color="default" label={code} />
              ))}
            </Stack>
          )}

          {!isEmpty(data.products) && (
            <Stack gap={1} flexWrap={'wrap'} alignItems={'center'} direction={'row'}>
              <Typography variant="subtitle2">Products</Typography>
              {getArrayDefault(data.products).map((code: string) => (
                <Chip key={code} size="small" color="default" label={code} />
              ))}
            </Stack>
          )}

          <ButtonWithLoading
            size="large"
            variant="contained"
            isRounded
            disabled={isFetching}
            onClick={navigateToDetail}
          >
            Profile Detail <ArrowForward sx={{ ml: 1 }} fontSize="small" />
          </ButtonWithLoading>
        </StyledCardContent>
      )}
    </Card>
  );
};

export default CompanyProfileDetail;

const InformationItem = ({ theme, icon, label }: { theme: Theme; icon: ReactNode; label: string }) => {
  return (
    <Stack gap={1} direction={'row'} alignItems="center">
      <IconFeather
        muiProps={{
          sx: {
            color: theme.palette.text.secondary,
            fontSize: 12,
          },
        }}
        icon={icon}
      />
      <Typography color={theme.palette.text.secondary} variant="caption">
        {label}
      </Typography>
    </Stack>
  );
};

const SkeletonContent = () => {
  return (
    <StyledCardContent>
      <Box>
        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} mb={1}>
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={24} />
        </Stack>
        <Skeleton animation="wave" variant="rectangular" width={60} />
      </Box>
      <Stack spacing={1}>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Skeleton animation="wave" variant="rectangular" width={120} />
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
      </Stack>
      <Divider />
      <Stack gap={1} flexWrap={'wrap'} alignItems={'center'} direction={'row'}>
        <Skeleton animation="wave" variant="rectangular" width={60} />
        <Skeleton animation="wave" variant="rectangular" width={120} />
      </Stack>
      <Stack gap={1} flexWrap={'wrap'} alignItems={'center'} direction={'row'}>
        <Skeleton animation="wave" variant="rectangular" width={60} />
        <Skeleton animation="wave" variant="rectangular" width={120} />
      </Stack>
      <Skeleton animation="wave" height={56} />
    </StyledCardContent>
  );
};
