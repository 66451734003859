import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { capitalize, find } from 'lodash';
import { useMemo } from 'react';

// Components
import PaperSelect from 'src/components/common/PaperSelect';

// Utilities
import { useQueryIndustry } from 'src/services/queries/useQueryCompany';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

interface IProps {
  label?: string;
  id: string;
  countryCode: string[];
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const FormItemIndustryClassification = ({ label, id, countryCode, required, error, disabled }: IProps) => {
  const { values, handleBlur, setFieldValue } = useFormikContext<ExpectedAny>();
  const { data, isFetching } = useQueryIndustry(countryCode);
  const { data: listIndustry = [] } = data;

  const getIndustryByCode = (industryCode: string) => {
    if (!industryCode) {
      return null;
    }
    const product = find(listIndustry, (o) => o.industry_code === industryCode);

    if (!product) {
      setFieldValue(id, '');
      return null;
    }

    return product;
  };

  const industry = useMemo(() => {
    return getIndustryByCode(values[id]);
  }, [values[id], data.data]);

  return (
    <Autocomplete
      id={id}
      value={industry}
      loading={isFetching}
      onBlur={handleBlur}
      onChange={(_, value) => {
        setFieldValue(id, value?.industry_code);
      }}
      autoComplete
      disabled={disabled}
      fullWidth
      size="small"
      autoHighlight
      options={getArrayDefault(listIndustry)}
      PaperComponent={PaperSelect}
      getOptionLabel={(option) => capitalize(option?.industry_desc || '')}
      renderInput={(params) => (
        <TextField {...params} error={error} required={required} label={<span>{label ?? 'Industry'}</span>} />
      )}
    />
  );
};

export default FormItemIndustryClassification;
