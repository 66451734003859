import { styled, Typography, Table, TableBody, TableCell, TableRow, Stack, Skeleton } from '@mui/material';
import { find, get, isString } from 'lodash';
import { useMemo } from 'react';
import { queryClient } from 'src/configs/queryClient';

// Components
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import { EmptyText } from 'src/utils/constants/config';
import FlagCountry from 'src/components/common/FlagCountry';

// Utilities
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { QUERY_KEY_PRODUCT_NATIONAL_CODE_TARIFFS, QUERY_KEY_COUNTRY_TARIFFS } from 'src/services/queries';
import { useQueryParams } from 'src/hooks/useQueryParam';

const StyledTable = styled(Table)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper2,
}));

const Overview = ({ dataNtmMeasures, isFetching }: { dataNtmMeasures?: INtmMeasures[]; isFetching: boolean }) => {
  const { partner, reporter, hs_code, product } = useQueryParams();
  const listProductNationalFromCache = queryClient.getQueryData([
    QUERY_KEY_PRODUCT_NATIONAL_CODE_TARIFFS,
    reporter,
    hs_code,
  ]) as IResponse<INtmMeasures>;
  const listCountriesTariff = queryClient.getQueryData([QUERY_KEY_COUNTRY_TARIFFS]) as IResponse<ICountryTariffs[]>;

  const productNationalCode = useMemo(() => {
    if (getArrayDefault(listProductNationalFromCache?.data).length > 0) {
      return getArrayDefault(listProductNationalFromCache?.data).find(
        (productNation) => productNation.Code === product,
      );
    }
  }, [listProductNationalFromCache?.data]);

  const getCountryByCountryCode = (countryCode: string) => {
    if (!countryCode) {
      return {
        Id: '',
        Code: '',
        Name: '',
        ISO2: '',
        ISO3: '',
        ValidFrom: '',
        ValidUntil: '',
      };
    }
    const country = find(listCountriesTariff.data, (o) => o.Code === countryCode);

    if (!country) {
      return {
        Id: '',
        Code: '',
        Name: '',
        ISO2: '',
        ISO3: '',
        ValidFrom: '',
        ValidUntil: '',
      };
    }

    return country;
  };

  const countryReporter = useMemo(() => {
    return getCountryByCountryCode(reporter && isString(reporter) ? reporter : '');
  }, [reporter, listCountriesTariff.data]);

  const countryPartner = useMemo(() => {
    return getCountryByCountryCode(partner && isString(partner) ? partner : '');
  }, [partner, listCountriesTariff.data]);

  if (isFetching) {
    return <SkeletonContent />;
  }

  return (
    <StyledWhiteBox spacing={2}>
      <Typography variant="h6">Regulatory Requirements</Typography>
      <StyledTable aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                HS Code
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                {hs_code}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                Product Name
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                {product} - {productNationalCode?.Name}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                Exporting Country
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                <FlagCountry code={countryPartner.ISO2} width={16} /> {countryPartner.Name}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                Destination Country
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                <FlagCountry code={countryReporter.ISO2} width={16} /> {countryReporter.Name}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                HS revision
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                {get(dataNtmMeasures, '[0].HsRevision', EmptyText)}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                NTM Classification
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                {get(dataNtmMeasures, '[0].NtmClassification', EmptyText)}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                NTM year
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                {get(dataNtmMeasures, '[0].NtmYear', EmptyText)}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">
              <Typography variant="subtitle2" color="text.secondary">
                Source
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2" color="text.secondary">
                <div dangerouslySetInnerHTML={{ __html: get(dataNtmMeasures, '[0].DataSource', EmptyText) || '' }} />
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </StyledTable>
    </StyledWhiteBox>
  );
};

export default Overview;

const SkeletonContent = () => {
  return (
    <StyledWhiteBox spacing={2}>
      <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
      <Stack>
        <Skeleton animation="wave" variant="rectangular" height={400} />
      </Stack>
    </StyledWhiteBox>
  );
};
