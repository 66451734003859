import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { debounce, find } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Search } from 'react-feather';

// Components
import IconFeather from 'src/components/common/Icon';
import PaperSelect from 'src/components/common/PaperSelect';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';

// Utilities
import { useQueryHsCodeTariffs } from 'src/services/queries';
import { LEVEL_PRODUCT } from 'src/utils/constants/config';

interface IProps {
  id: string;
  countryCode: string;
  defaultKeyword?: string;
  required?: boolean;
  tooltipText?: string;
  label?: ReactNode;
  disabled?: boolean;
}

const FormItemHsCode = ({
  id,
  countryCode,
  defaultKeyword = '',
  tooltipText = '',
  label,
  required = false,
  disabled = false,
}: IProps) => {
  const formik = useFormikContext<ExpectedAny>();
  const [keyword, setKeyword] = useState(defaultKeyword);
  const { data, isFetching } = useQueryHsCodeTariffs({
    countryCode: countryCode,
    level: LEVEL_PRODUCT.HS_CODE,
    keyword: keyword,
  });

  const handleChangeHsCode = useCallback(
    debounce((e) => {
      setKeyword(e.target.value);
    }, 500),
    [],
  );

  const getProductByCode = (productCode: string) => {
    if (!productCode) {
      return null;
    }
    const product = find(data.data, (o) => o.Code === productCode);

    if (!product) {
      return null;
    }

    return product;
  };

  const product = useMemo(() => {
    return getProductByCode(formik.values[id]);
  }, [formik.values[id], data.data]);

  return (
    <Autocomplete
      value={product}
      onChange={(_, value) => {
        formik.setFieldValue(id, value?.Code);
      }}
      loading={isFetching}
      onBlur={formik.handleBlur}
      id={id}
      fullWidth
      size="small"
      autoComplete
      options={data.data}
      disabled={disabled}
      popupIcon={<IconFeather muiProps={{ fontSize: 'small' }} icon={<Search />} />}
      PaperComponent={PaperSelect}
      autoHighlight
      getOptionLabel={(option) => (option ? `${option.Code} - ${option.Name}` : '')}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          InputLabelProps={{
            ...params.InputLabelProps,
          }}
          placeholder="Typing..."
          onChange={handleChangeHsCode}
          label={
            label ? label : <span>Product / HS Code {tooltipText && <TooltipFormLabel title={tooltipText} />}</span>
          }
        />
      )}
    />
  );
};

export default FormItemHsCode;
