import { alpha, Theme } from '@mui/material';

const styleOverrideCheckbox = (theme: Theme) => ({
  "&.MuiCheckbox-root": {
    color: theme.palette.divider,
  },
  '&.MuiCheckbox-colorPrimary': {
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    }
  },
  '&.MuiCheckbox-colorSecondary': {
    "&.Mui-checked": {
      color: theme.palette.secondary.main,
    }
  },
  '&.MuiCheckbox-colorError': {
    "&.Mui-checked": {
      color: theme.palette.error.main,
    }
  },
  '&.MuiCheckbox-colorWarning': {
    "&.Mui-checked": {
      color: theme.palette.warning.main,
    }
  },
  '&.MuiCheckbox-colorInfo': {
    "&.Mui-checked": {
      color: theme.palette.info.main,
    }
  },
  '&.MuiCheckbox-colorSuccess': {
    "&.Mui-checked": {
      color: theme.palette.success.main,
    }
  },
  '&.MuiCheckbox-colorInherit': {
    "&.Mui-checked": {
      color: alpha(theme.palette.action.active, 0.5),
    }
  },
});

export default styleOverrideCheckbox;
