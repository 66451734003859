import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { Calendar, FileText } from 'react-feather';

// Components
import IconFeather from 'src/components/common/Icon';
import TextWithTooltip from 'src/components/common/TextWithTooltip';
import Table from 'src/components/Table';
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import FtaDetail from 'src/features/TariffsAndRules/FtaDetail';
import PopupRegimeCountry from 'src/features/TariffsAndRules/PopupRegimeCountry';

//Utilities
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

const CustomsTariff = ({ dataCustomDuties, isFetching }: { dataCustomDuties?: ICustomDuties; isFetching: boolean }) => {
  const [ftaId, setFtaId] = useState<number | null>(null);
  const [tariffItem, setTariffItem] = useState<{
    tariffRegime: string;
    agId: number;
  } | null>(null);

  const configColumnTariff: ConfigCell<IDutyItem>[] = [
    {
      id: 'TariffRegime',
      disablePadding: true,
      label: 'Tariff Regime',
      cell: (row: IDutyItem) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation();
              setTariffItem({
                tariffRegime: row.TariffRegime,
                agId: row.AgreementID,
              });
            }}
          >
            <IconFeather icon={<FileText />} muiProps={{ color: 'secondary', fontSize: 'small' }} />
            <Typography variant="body2" color="secondary">
              {row.TariffRegime}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'applied_tariff',
      disablePadding: true,
      label: 'Applied Tariff',
      cell: (row: IDutyItem) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.TariffReported}
          </Typography>
        );
      },
    },
    {
      id: 'ave',
      disablePadding: true,
      label: 'AVE',
      cell: (row: IDutyItem) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.TariffAve}
          </Typography>
        );
      },
    },
    {
      id: 'note',
      disablePadding: true,
      label: 'Note',
      cell: (row: IDutyItem) => {
        return (
          <Typography
            variant="body2"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setFtaId(row.FtaId);
            }}
          >
            {row.ShowDetailLink ? row.FtaRooDetailLinkLabel : ''}
          </Typography>
        );
      },
    },
  ];

  if (isFetching) {
    return <SkeletonContent />;
  }

  return (
    <StyledWhiteBox spacing={2}>
      <Typography variant="h6">
        Customs Tariffs
        <TextWithTooltip title="Customs tariffs are charges levied by the importing country on foreign goods crossing its border." />
      </Typography>
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <IconFeather icon={<Calendar />} />
        <Typography variant="body1" color="text.secondary">
          TARIFF YEAR {dataCustomDuties?.Year} ({get(dataCustomDuties, 'CustomDuty[0].Revision', EmptyText)})
        </Typography>
      </Box>
      <Table
        data={getArrayDefault(dataCustomDuties?.CustomDuty)}
        configColumn={configColumnTariff}
        dataKey="FtaId"
        isPagination={false}
      />
      {tariffItem && (
        <PopupRegimeCountry
          tariffYear={dataCustomDuties?.Year || EmptyText}
          tariffItem={tariffItem}
          open={true}
          handleClose={() => setTariffItem(null)}
        />
      )}
      {ftaId && <FtaDetail ftaId={ftaId} />}
    </StyledWhiteBox>
  );
};

export default CustomsTariff;

const SkeletonContent = () => {
  return (
    <StyledWhiteBox spacing={2}>
      <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
      <Stack>
        <Skeleton animation="wave" variant="rectangular" height={200} />
      </Stack>
    </StyledWhiteBox>
  );
};
