import { Box, Stack, styled } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import update from 'immutability-helper';

// Components
import UploadListImages from 'src/components/common/UploadListImages';

const StyledIconDelete = styled(CancelOutlinedIcon)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-1),
  right: theme.spacing(-1),
  backgroundColor: '#fff',
  cursor: 'pointer',
  borderRadius: 1000,
}));

const PrimaryAddress = ({
  listImage,
  handleChooseImages,
}: {
  listImage: Array<TypeImage>;
  handleChooseImages: (images: Array<TypeImage>) => void;
}) => {
  const removeImage = (index: number) => {
    const newState = update(listImage, { $splice: [[index, 1]] });
    handleChooseImages(newState);
  };

  return (
    <Stack spacing={2} flex={1}>
      <UploadListImages listImage={listImage} handleChooseImages={handleChooseImages} />
      <Stack direction="row" spacing={4} useFlexGap flexWrap="wrap">
        {listImage.map((file, index) => (
          <Box key={file.name} position="relative">
            <StyledIconDelete fontSize="small" color="primary" onClick={() => removeImage(index)} />
            <img
              srcSet={file.url}
              src={file.url}
              width={64}
              height={64}
              alt={file.name}
              loading="lazy"
              style={{ objectFit: 'cover' }}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default PrimaryAddress;
