import React from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

// Components
import GeneralInformation from 'src/features/CompanyDetail/OverView/GeneralInformation';
import IndustryClassifications from 'src/features/CompanyDetail/OverView/IndustryClassifications';
import GlobalRanking from 'src/features/CompanyDetail/OverView/GlobalRanking';
import OtherNames from 'src/features/CompanyDetail/OverView/OtherNames';
import RelatedCorporate from 'src/features/CompanyDetail/OverView/RelatedCorporate';
import DataSources from 'src/features/CompanyDetail/OverView/DataSources';
import SkeletonOverview from 'src/features/CompanyDetail/OverView/SkeletonOverview';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { useQueryCompanyDetail } from 'src/services/queries';

// Utilities

const OverViewTab = () => {
  const { id } = useParams();
  const { data, isFetching } = useQueryCompanyDetail(id || '');
  const dataCompany = data.data;

  return (
    <React.Fragment>
      {isFetching ? (
        <SkeletonOverview />
      ) : (
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            <StyledWhiteBox flex={1}>
              <GeneralInformation dataCompany={dataCompany} />
            </StyledWhiteBox>
            <StyledWhiteBox flex={1}>
              <IndustryClassifications dataCompany={dataCompany} />
            </StyledWhiteBox>
          </Stack>
          <OtherNames dataCompany={dataCompany} />
          <RelatedCorporate />
          <StyledWhiteBox>
            <DataSources dataCompany={dataCompany} />
          </StyledWhiteBox>
          <GlobalRanking dataCompany={dataCompany} />
        </Stack>
      )}
    </React.Fragment>
  );
};

export default OverViewTab;
