import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { get, isArray, isString, omit } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// Components
import FormItemCountryMulti from 'src/components/Form/FormItemCountryMulti';
import FormItemIndustryClassification from 'src/components/Form/FormItemIndustryClassification';
import ModalComponent from 'src/components/Modal/ModalComponent';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import FormItemSuggestAddress from 'src/features/PeopleDetail/Common/FormItemSuggestAddress';
import FormItemSuggestCompany from 'src/features/PeopleDetail/Common/FormItemSuggestCompany';
import FormItemSuggestName from 'src/features/PeopleDetail/Common/FormItemSuggestName';

// Utilities
import { SANCTION_STATUS, SanctionsStatus } from 'src/features/PeopleDetail/config';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { LIMIT_GET_LIST_PEOPLE } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { handleQueryParamFromObject } from 'src/utils/helpers/queryParam';

interface IProps {
  open: boolean;
  handleClose: () => void;
}
const FormSearchPeople: React.FC<IProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const params = useQueryParams();

  const getDefaultValue = (key: string) => {
    return get(params, key, '') as string;
  };

  const getDefaultArray = (key: string) => {
    const value = params[key];
    if (isArray(value)) {
      return value as ExpectedAny[];
    }
    if (isString(value)) {
      return [value] as string[];
    }
    return [];
  };
  const formik = useFormik<TypePeopleSearch>({
    enableReinitialize: true,
    initialValues: {
      limit: LIMIT_GET_LIST_PEOPLE,
      offset: 0,
      name: getDefaultValue('name'),
      sanction_status: getDefaultValue('sanction_status'),
      country_code: getDefaultArray('country_code'),
      company_name: getDefaultValue('company_name'),
      company_address: getDefaultValue('company_address'),
      industry: getDefaultValue('industry'),
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255),
      sanction_status: Yup.string().max(255),
      country_code: Yup.array().min(1, 'Please choose at least 1 country'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      handleClose();
      setSubmitting(false);
      values.country_code = getListCountryCode();
      if (values.sanction_status === SanctionsStatus.All) {
        values = omit(values, 'sanction_status');
      }
      const params = handleQueryParamFromObject(values);
      navigate({ pathname: AuthRouteKeys.PeopleProfiles, search: params });
    },
  });

  const getListCountryCode = () => {
    return (formik.values?.country_code || []).map((country) =>
      typeof country === 'string' ? country : get(country, 'code', ''),
    );
  };

  return (
    <ModalComponent padding={4} handleClose={handleClose} open={open}>
      <FormikForm formik={formik}>
        <Box width={540}>
          <Typography textAlign={'center'} variant="h5">
            People Search
          </Typography>
          <Box sx={{ mt: 1 }} />
          <Typography textAlign="center" color="text.secondary" variant="body2">
            Please enter your search criteria below. The more fields you complete, the more targeted your results will
            be.
          </Typography>
          <Box sx={{ mt: 4 }} />
          <Stack direction={'column'} spacing={3}>
            <FormItemSuggestName id="name" freeSolo={true} countryCode={getListCountryCode()} />
            <FormItemCountryMulti id="country_code" required />
            {/* <FormItemCompanyName id="company_name" freeSolo={true} /> */}
            <FormItemSuggestCompany id="company_name" freeSolo={true} countryCode={getListCountryCode()} />
            <FormItemSuggestAddress id="company_address" freeSolo={true} countryCode={getListCountryCode()} />
            {/* <FormItemAddress id="company_address" size="small" countryCode={getListCountryCode()} /> */}
            <FormItemIndustryClassification id="industry" countryCode={getListCountryCode()} />

            <TextField
              type="select"
              id="sanction_status"
              name="sanction_status"
              value={formik.values.sanction_status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              select
              fullWidth
              label="Sanctions Status"
            >
              {SANCTION_STATUS.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <ButtonWithLoading sx={{ height: 53 }} size="large" variant="contained" isRounded type="submit">
              Search Now
            </ButtonWithLoading>
          </Stack>
        </Box>
      </FormikForm>
    </ModalComponent>
  );
};

export default FormSearchPeople;
