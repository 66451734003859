import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { debounce, get, isString } from 'lodash';
import React, { ReactNode } from 'react';
import iconSearch from 'src/asset/icons/icon-search.svg';

// Components
import PaperSelect from 'src/components/common/PaperSelect';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import FlagCountry from 'src/components/common/FlagCountry';

// Utilities
import { EmptyText, LIMIT_GET_LIST_AUTO_COMPLETE } from 'src/utils/constants/config';
import { useQueryCompanyNames } from 'src/services/queries/useQueryCompany';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

interface IProps {
  id: string;
  fieldGetValue?: 'name' | 'id';
  tooltipText?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  freeSolo?: boolean;
  label?: ReactNode;
  jurisdiction?: string[] | IJurisdictions[];
}

const FormItemCompanyName = ({
  disabled,
  tooltipText = '',
  id,
  fieldGetValue = 'name',
  required,
  freeSolo,
  label,
  error,
  jurisdiction,
}: IProps) => {
  const { values, handleBlur, setFieldValue } = useFormikContext<TypeDTOSettingInformation>();

  const [query, setQuery] = React.useState('');
  const [selected, setSelected] = React.useState<ICompanyName | null>(null);

  const { data, isFetching } = useQueryCompanyNames(
    {
      limit: LIMIT_GET_LIST_AUTO_COMPLETE,
      offset: '0',
      company_name: query,
    },
    (jurisdiction || []).map((item: string | IJurisdictions) =>
      typeof item === 'string' ? item : get(item, 'jurisdiction', ''),
    ),
  );

  const onChange = (e: string) => {
    if (freeSolo) {
      setFieldValue(id, e);
    }
    setQuery(e);
  };

  const debounceOnchange = React.useCallback(debounce(onChange, 500), []);

  return (
    <Autocomplete
      id={id}
      defaultValue={{
        id: '',
        name: get(values, id, ''),
        address: '',
        country: '',
      }}
      loading={isFetching}
      onBlur={handleBlur}
      onChange={(_, value) => {
        if (isString(value)) {
          setFieldValue(id, value);
        } else {
          setFieldValue(id, value?.[fieldGetValue]);
          setSelected(value);
        }
      }}
      freeSolo={freeSolo}
      autoComplete
      filterOptions={(option) => option}
      disabled={disabled}
      fullWidth
      size="small"
      autoHighlight
      // options={get(values, id, '') ? getArrayDefault(data.data) : []}
      options={get(values, id, '') || query ? getArrayDefault(data.data) : []}
      PaperComponent={PaperSelect}
      getOptionLabel={(option) => {
        if (isString(option)) {
          return option;
        } else {
          return option?.name ?? EmptyText;
        }
      }}
      renderOption={(props, option) => {
        return (
          <Box component="li" display="flex" flexDirection="row" {...props} key={option.id}>
            <Stack direction="row" alignItems="flex-start" spacing={1}>
              <Box>
                <FlagCountry width={20} code={option.country ?? ''} />
              </Box>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="body2"> {option?.name ?? EmptyText}</Typography>
                <Typography color="text.secondary" variant="caption2">
                  {option?.full_address ?? EmptyText}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        );
      }}
      popupIcon={<Box component={'img'} src={iconSearch} />}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: freeSolo ? <Box component={'img'} src={iconSearch} /> : <></>,
            startAdornment: selected ? <FlagCountry width={20} code={selected?.country ?? ''} /> : null,
          }}
          onChange={(e) => debounceOnchange(e.target.value)}
          required={required}
          label={
            <span>
              {label ?? 'Company Name'} {tooltipText && <TooltipFormLabel title={tooltipText} />}
            </span>
          }
        />
      )}
    />
  );
};

export default FormItemCompanyName;
