import { ACCEPT_TYPE_IMAGE } from "src/utils/constants/config";

export const checkIsValidType = (file: File) => {
  return ACCEPT_TYPE_IMAGE.includes(file.type);
};

export const convertBytesToSize = (bytes: number, toType: "kb" | 'mb') => {
  let base = 1024
  if (toType === "kb") base = base
  if (toType === "mb") base = Math.pow(base, 2)
  return (bytes / base).toFixed(2)
}