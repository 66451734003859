import { Helmet } from 'react-helmet';
import MainLayout from 'src/layouts';
import PeopleProfiles from 'src/features/PeopleProfiles';

const PeopleProfilesPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Search People</title>
      </Helmet>
      <PeopleProfiles />
    </MainLayout>
  );
};

export default PeopleProfilesPage;
