import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from 'src/layouts/index';
import Setting from 'src/features/Setting';

const SettingPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Setting />
    </MainLayout>
  );
};

export default SettingPage;
