import { create } from 'zustand';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';

export type OldData = {
  full_name?: string;
  title?: string;
  email?: string;
  phone_number?: string;
};
export interface ICompanyContribute {
  open: boolean;
  type: TypeContribute | null;
  oldData: OldData;
}

export interface IFormSearchAction {
  handleCloseContribute: () => void;
  handleOpenContribute: (type: TypeContribute, oldData?: OldData) => void;
}

export const useCompanyContribute = create<ICompanyContribute & IFormSearchAction>((set) => {
  return {
    open: false,
    type: null,
    oldData: {
      full_name: '',
      title: '',
      email: '',
      phone_number: '',
    },
    handleCloseContribute: () => set(() => ({ open: false, type: null })),
    handleOpenContribute: (type: TypeContribute, oldData?: OldData) => set(() => ({ open: true, type, oldData })),
  };
});
