import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { alpha, Box, Rating, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import AvatarUser from 'src/components/common/AvatarUser';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import WhiteBoxContent from 'src/components/common/WhiteBoxContent';
import GoogleIcon from 'src/components/Icons/Google';
import TrustpilotIcon from 'src/components/Icons/Trustpilot';

// Utilities
import { FORMAT_DATE_TIME } from 'src/utils/constants/config';
import { CompanyReviewType } from 'src/utils/constants/enum';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

// const StyledWhiteBox = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderRadius: theme.shape.borderRadius * 2,
//   background: theme.palette.background.default,
//   border: `1px solid ${theme.palette.divider}`,
//   boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
// }));

const ReviewItem = ({ item }: { item: ICompanyReview }) => {
  const renderIcon = (item: ICompanyReview) => {
    switch (item.review_type) {
      case CompanyReviewType.Google:
        return <GoogleIcon fontSize="medium" />;
      case CompanyReviewType.Trustpilot:
        return <TrustpilotIcon fontSize="medium" />;
      default:
        return null;
    }
  };

  const { spacing, palette } = useTheme();

  return (
    <WhiteBoxContent padding={2} border={'1px solid'} borderColor={'divider'} position={'relative'} rowGap={2}>
      <Stack direction="row" spacing={1}>
        <AvatarUser
          alt={item?.reviewer_name}
          src={item.reviewer_avatar}
          sx={{ backgroundColor: alpha(palette.primary.main, 0.07) }}
        />
        <Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="subtitle1">{renderTextOrDefault(item.reviewer_name)}</Typography>
            <Typography variant="body2" color="text.secondary">
              <AccessTimeIcon color="inherit" sx={{ verticalAlign: 'middle', width: 12, mr: 1 }} />
              {formatUTCTime(FORMAT_DATE_TIME.TIME, item?.created_at)}
            </Typography>
            <Typography flex={1} variant="body2" color="text.secondary">
              <CalendarTodayOutlinedIcon color="inherit" sx={{ verticalAlign: 'middle', width: 12, mr: 1 }} />
              {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, item?.created_at)}
            </Typography>
            <Box position={'absolute'} right={spacing(2)} top={spacing(2)}>
              {renderIcon(item)}
            </Box>
          </Stack>
          <Rating name="text-feedback" value={item?.rating_value || 0} readOnly size="small" precision={0.1} />
        </Stack>
      </Stack>

      <Typography mt={2} variant="body1" color="text.secondary" align="left">
        {item.review_text}
      </Typography>
    </WhiteBoxContent>
  );
};

export default ReviewItem;

export const ReviewItemLoading = () => {
  return (
    <WhiteBoxContent padding={2} border={'1px solid'} borderColor={'divider'} rowGap={2}>
      <Stack mb={2} direction="row" spacing={1}>
        <Skeleton height={48} width={48} variant="circular" />

        <Stack direction={'column'}>
          <LoadingSkeleton width={500} count={2} />
        </Stack>
      </Stack>

      <LoadingSkeleton count={1} />
    </WhiteBoxContent>
  );
};
