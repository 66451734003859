import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { every, isArray } from 'lodash';
import { Search } from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import PaperSelect from 'src/components/common/PaperSelect';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import { COUNTRIES } from 'src/utils/constants/countries';
import { sortObjectsArrayByKey } from 'src/utils/helpers/arrayHelpers';
import { getFormItemError, renderFormItemError } from 'src/utils/helpers/renderFormItemError';
import { getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';

interface IProps {
  id: string;
  tooltipText?: string;
  required?: boolean;
  label?: ReactNode;
}

const FormItemCountryMulti = ({ id, tooltipText = '', required = false, label }: IProps) => {
  const formik = useFormikContext<ExpectedAny>();

  const getValue = () => {
    // if value is a list of countryCode => get list country from list COUNTRIES
    if (isArray(formik.values[id]) && every(formik.values[id], (country) => typeof country === 'string')) {
      return formik.values[id].map((countryCode: string) => {
        return getCountryByCountryCode(countryCode);
      });
    }
    return formik.values[id];
  };

  return (
    <Autocomplete
      value={getValue()}
      onChange={(_, value) => {
        formik.setFieldValue(id, value);
      }}
      onBlur={formik.handleBlur}
      id={id}
      fullWidth
      multiple={true}
      size="small"
      autoComplete
      options={sortObjectsArrayByKey(COUNTRIES, 'label')}
      popupIcon={<IconFeather muiProps={{ fontSize: 'small' }} icon={<Search />} />}
      PaperComponent={PaperSelect}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderTags={(value: Array<TypeCountry>, getTagProps) => {
        return value.map((option: TypeCountry, index: number) => {
          const tagProps = getTagProps({ index });
          return (
            <Chip
              variant="filled"
              label={option.label}
              icon={
                <FlagCountry
                  width={16}
                  code={option.code ?? ''}
                  className={tagProps.className}
                  iconProps={{
                    sx: {
                      marginLeft: '5px',
                      marginRight: '-6px',
                    },
                  }}
                />
              }
              {...tagProps}
              key={`jurisdiction-${index}`}
            />
          );
        });
      }}
      renderOption={(props, option) => (
        <Box gap={1} display={'flex'} component="li" {...props}>
          <FlagCountry width={24} code={option.code} />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          name={id}
          placeholder="Typing..."
          InputLabelProps={{ required: required }}
          label={label ? label : <span>Country {tooltipText && <TooltipFormLabel title={tooltipText} />}</span>}
          error={Boolean(getFormItemError(id, formik))}
          helperText={renderFormItemError(getFormItemError(id, formik))}
        />
      )}
    />
  );
};

export default FormItemCountryMulti;
