import { InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Search } from 'react-feather';
import * as Yup from 'yup';

// Components
import FormikForm from 'src/components/common/FormikForm';
import IconFeather from 'src/components/common/Icon';
import ListCompareCompany from 'src/features/Bookmark/CompareCompany/ListCompareCompany';
import { CONTACT_FILTER, StyledWhiteBox } from 'src/features/Bookmark/config';

const CompareCompanyTab = () => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country_code: '',
      contact: CONTACT_FILTER[0].value || '',
    },
    validationSchema: Yup.object().shape({
      country_code: Yup.string().max(255),
      contact: Yup.string().max(255),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });
  return (
    <Stack gap={3} direction="column">
      <StyledWhiteBox>
        <Typography variant="h6">Compare Company Bookmark</Typography>
        <FormikForm formik={formik}>
          <Stack direction="row" spacing={2} mt={2} mb={3}>
            <TextField
              sx={{ flex: 1 }}
              placeholder="Search Company Bookmark"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconFeather icon={<Search />} muiProps={{ color: 'disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <ListCompareCompany />
        </FormikForm>
      </StyledWhiteBox>
    </Stack>
  );
};

export default CompareCompanyTab;
