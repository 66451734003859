import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { isFunction } from 'lodash';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import iconSearch from 'src/asset/icons/icon-search.svg';
import { COUNTRIES } from 'src/utils/constants/countries';
import PaperSelect from 'src/components/common/PaperSelect';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';

interface IProps {
  id: string;
  disablePortal: boolean;
  callback?: () => void;
  autoFocus?: boolean;
}

const FormItemPhoneNumber = ({ id, disablePortal = false, callback, autoFocus }: IProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Autocomplete
      id={id}
      onChange={(_, value) => {
        setFieldValue(id, '+' + value?.phone);
        isFunction(callback) && callback();
      }}
      fullWidth
      size="small"
      autoComplete
      disablePortal={disablePortal}
      options={COUNTRIES}
      popupIcon={<Box component={'img'} src={iconSearch} />}
      PaperComponent={PaperSelect}
      autoHighlight
      open
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box gap={1} display={'flex'} component="li" {...props}>
          <FlagCountry width={24} code={option.code} />
          (+{option.phone}) {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          placeholder="Typing..."
          InputProps={{
            ...params.InputProps,
          }}
          label={
            <span>
              Your Phone Code <TooltipFormLabel title="" />
            </span>
          }
        />
      )}
    />
  );
};

export default FormItemPhoneNumber;
