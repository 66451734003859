import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useMemo, useState } from 'react';
import { ArrowDown, ChevronDown } from 'react-feather';
import * as Yup from 'yup';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FlagCountry from 'src/components/common/FlagCountry';
import FormikForm from 'src/components/common/FormikForm';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import ModalComponent from 'src/components/Modal/ModalComponent';
import PopupSelectPhoneCode from 'src/features/Setting/PopupSelectPhoneCode';

// Utilities
import GuardContributeImg from 'src/asset/images/guard-contribute.png';
import IconFeather from 'src/components/common/Icon';
import {
  IPropsDataReportWrong,
  RELATIONSHIP_DATA,
  TypeContribute,
  TYPE_CONTRIBUTE,
} from 'src/features/CompanyDetail/Common/config';
import { useMutateContributeCompany } from 'src/services/mutate';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { formatPhoneNumberBeforeSent, getCountryByPhoneCode, getCurrentUrl } from 'src/utils/helpers/stringHelpers';
import { validateEmail } from 'src/utils/helpers/validate';
import { OldData } from 'src/zustand/useCompanyContribute';

interface IProps {
  companyData: ICompanyDetail;
  open: boolean;
  oldData: OldData;
  handleClose: () => void;
}

const StyledHeaderAlert = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  padding: '8px 32px',
}));

const StyledNewData = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px dashed ${theme.palette.primary.main}`,
  position: 'relative',
  display: 'flex',
  padding: theme.spacing(2),
  flex: theme.spacing(1),
}));

const StyledOldData = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  display: 'flex',
  padding: theme.spacing(2),
  flex: theme.spacing(1),
}));

const FormReportWrong: React.FC<IProps> = ({ oldData, companyData, open, handleClose }) => {
  const theme = useTheme();
  const { mutateAsync } = useMutateContributeCompany();
  const [visiblePhoneCode, setVisiblePhoneCode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const formik = useFormik<IPropsDataReportWrong>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      type_contribute: TypeContribute.ReportWrongOtherContact,
      relation_to_data: '',
      note: '',
      is_confirm: false,
      old_data_full_name: oldData?.full_name || '',
      old_data_title: oldData?.title || '',
      old_data_email: oldData?.email || '',
      old_data_phone_number: oldData?.phone_number || '',
      new_data_full_name: '',
      new_data_title: '',
      new_data_email: '',
      new_data_phone_number: '',
      phone_code: '',
    },
    validationSchema: Yup.object().shape({
      type_contribute: Yup.string().max(255).required('Email is required'),
      relation_to_data: Yup.string().max(255).required('Relationship Data is required'),
      note: Yup.string().max(255),
      is_confirm: Yup.bool().oneOf([true], 'Please checked this checkbox'),
      old_data_full_name: Yup.string(),
      old_data_title: Yup.string(),
      old_data_email: Yup.string(),
      old_data_phone_number: Yup.string(),
      new_data_full_name: Yup.string().required('Full name is required'),
      new_data_title: Yup.string().required('Title is required'),
      new_data_email: Yup.string()
        .required('Email is required')
        .test('email', 'Email is invalid', (text?: string) => validateEmail(text || '')),
      new_data_phone_number: Yup.string()
        .required('Phone is required')
        .test('phone_code', 'Phone number is invalid', (text?: string, context?: ExpectedAny) => {
          const code = context?.parent?.phone_code;
          const phoneNumber = code + (text || '');
          if (!text) {
            return true;
          }
          return isValidPhoneNumber(phoneNumber || '');
        }),
      phone_code: Yup.string().required('Phone code is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const params: TypeDTOReportWrong = {
        company_id: companyData.id,
        note: values.note,
        type_of_contribution: values.type_contribute,
        relation_to_data: values.relation_to_data,
        old_data_full_name: values.old_data_full_name,
        old_data_title: values.old_data_title,
        old_data_email: values.old_data_email,
        old_data_phone_number: values.old_data_phone_number,
        new_data_full_name: values.new_data_full_name,
        new_data_title: values.new_data_title,
        new_data_email: values.new_data_email,
        new_data_phone_number: formatPhoneNumberBeforeSent(values.phone_code, values.new_data_phone_number),
      };
      const res = await mutateAsync(params);
      if (res.status_code === SUCCESS_CODE.CREATE) {
        handleClose();
      }
      setSubmitting(false);
    },
  });

  const countryCode = useMemo(() => {
    const country = getCountryByPhoneCode(formik.values.phone_code);
    return country.code;
  }, [formik.values.phone_code]);

  return (
    <ModalComponent
      padding={4}
      pt={10}
      handleClose={handleClose}
      open={open}
      closeOnBackdropClick={false}
      disabledCloseIcon={formik.isSubmitting}
      showCloseIcon={true}
      headerAlert={
        <StyledHeaderAlert id="form-contribute-header-alert" direction="row" alignItems="center">
          <Box src={GuardContributeImg} component="img" width={24} mr={1} />
          <Typography variant="caption"> Your personal information will remain </Typography>
          <Button variant="text" color="success">
            100% confidential
          </Button>
        </StyledHeaderAlert>
      }
    >
      <FormikForm formik={formik}>
        <Box width={540}>
          <Typography textAlign={'center'} variant="h5">
            Report Wrong Data
          </Typography>
          <Typography textAlign={'center'} color={theme.palette.text.secondary} variant="body2">
            Please fill in the information in the form, we will confirm via your email.
          </Typography>
          <Box mt={4} />
          <Stack direction={'column'} spacing={2.5}>
            <TextField
              type="select"
              id="type_contribute"
              name="type_contribute"
              value={formik.values.type_contribute}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              select
              fullWidth
              disabled
              label="Type Contribute"
              error={Boolean(formik.touched.type_contribute && formik.errors.type_contribute)}
              helperText={Boolean(formik.touched.type_contribute) && formik.errors.type_contribute}
            >
              {TYPE_CONTRIBUTE.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControl>
              <Autocomplete
                clearIcon={false}
                disabled
                options={[]}
                freeSolo
                multiple
                renderTags={(value) => value.map((option, index) => <Chip key={option + index} label={option} />)}
                value={[`${getCurrentUrl()}`]}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    size="small"
                    fullWidth
                    label={
                      <span>
                        Profile Contribute (URL) <TooltipFormLabel title="Profile Contribute" />
                      </span>
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                clearIcon={false}
                disabled
                options={[]}
                freeSolo
                multiple
                renderTags={(value) => value.map((option, index) => <Chip key={option + index} label={option} />)}
                value={[companyData?.name || '']}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    disabled
                    size="small"
                    fullWidth
                    label={
                      <span>
                        Company <TooltipFormLabel title="Company" />
                      </span>
                    }
                  />
                )}
              />
            </FormControl>
            <StyledOldData>
              <Stack spacing={2} flex={1}>
                <Typography variant="subtitle1">
                  Old Data{' '}
                  <Typography variant="body2" component="span" color="text.secondary">
                    This is information you think is wrong
                  </Typography>
                </Typography>
                <TextField
                  id="old_data_full_name"
                  name="old_data_full_name"
                  value={formik.values.old_data_full_name}
                  size="small"
                  disabled
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={Boolean(formik.touched.old_data_full_name && formik.errors.old_data_full_name)}
                  helperText={Boolean(formik.touched.old_data_full_name) && formik.errors.old_data_full_name}
                  label={<span>Full Name</span>}
                />
                <TextField
                  id="old_data_title"
                  name="old_data_title"
                  value={formik.values.old_data_title}
                  size="small"
                  disabled
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={Boolean(formik.touched.old_data_title && formik.errors.old_data_title)}
                  helperText={Boolean(formik.touched.old_data_title) && formik.errors.old_data_title}
                  label={<span>Title</span>}
                />
                <TextField
                  id="old_data_email"
                  name="old_data_email"
                  value={formik.values.old_data_email}
                  size="small"
                  disabled
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={Boolean(formik.touched.old_data_email && formik.errors.old_data_email)}
                  helperText={Boolean(formik.touched.old_data_email) && formik.errors.old_data_email}
                  label={<span>Email Address</span>}
                />
                <TextField
                  id="old_data_phone_number"
                  name="old_data_phone_number"
                  value={formik.values.old_data_phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.touched.old_data_phone_number && formik.errors.old_data_phone_number)}
                  helperText={Boolean(formik.touched.old_data_phone_number) && formik.errors.old_data_phone_number}
                  size="small"
                  disabled
                  fullWidth
                  label="Phone Number"
                />
              </Stack>
            </StyledOldData>
            <Stack alignItems="center">
              <IconFeather icon={<ArrowDown />} muiProps={{ color: 'disabled' }} />
            </Stack>
            <StyledNewData>
              <Stack spacing={2} flex={1}>
                <Typography variant="subtitle1">
                  New Data{' '}
                  <Typography variant="body2" component="span" color="text.secondary">
                    Please provide information that you believe is more accurate
                  </Typography>
                </Typography>
                <TextField
                  required
                  id="new_data_full_name"
                  name="new_data_full_name"
                  value={formik.values.new_data_full_name}
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={Boolean(formik.touched.new_data_full_name && formik.errors.new_data_full_name)}
                  helperText={Boolean(formik.touched.new_data_full_name) && formik.errors.new_data_full_name}
                  label={<span>Full Name</span>}
                />
                <TextField
                  required
                  id="new_data_title"
                  name="new_data_title"
                  value={formik.values.new_data_title}
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={Boolean(formik.touched.new_data_title && formik.errors.new_data_title)}
                  helperText={Boolean(formik.touched.new_data_title) && formik.errors.new_data_title}
                  label={<span>Title</span>}
                />
                <TextField
                  required
                  id="new_data_email"
                  name="new_data_email"
                  value={formik.values.new_data_email}
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={Boolean(formik.touched.new_data_email && formik.errors.new_data_email)}
                  helperText={Boolean(formik.touched.new_data_email) && formik.errors.new_data_email}
                  label={<span>Email Address</span>}
                />
                <TextField
                  id="new_data_phone_number"
                  name="new_data_phone_number"
                  value={formik.values.new_data_phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          background: theme.palette.grey[100],
                          height: 'fit-content',
                          padding: '4px 8px',
                          borderRadius: theme.shape.borderRadius / 4,
                        }}
                        onClick={(e) => {
                          setVisiblePhoneCode(true);
                          setAnchorEl(e.currentTarget);
                        }}
                        component={Box}
                        position="start"
                      >
                        <FlagCountry width={16} code={countryCode} />
                        <Typography sx={{ ml: 1 }} color={theme.palette.text.secondary} variant="body2">
                          {formik.values.phone_code}
                        </Typography>
                        <IconFeather muiProps={{ fontSize: 'small', sx: { ml: 1 } }} icon={<ChevronDown />} />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(formik.touched.new_data_phone_number && formik.errors.new_data_phone_number)}
                  helperText={Boolean(formik.touched.new_data_phone_number) && formik.errors.new_data_phone_number}
                  size="small"
                  fullWidth
                  label="Phone Number"
                />
              </Stack>
            </StyledNewData>
            <TextField
              type="select"
              id="relation_to_data"
              name="relation_to_data"
              value={formik.values.relation_to_data}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              select
              fullWidth
              required
              placeholder="Select option"
              label={
                <span>
                  Relationship to Data
                  <TooltipFormLabel title="Relationship to Data" />
                </span>
              }
              error={Boolean(formik.touched.relation_to_data && formik.errors.relation_to_data)}
              helperText={Boolean(formik.touched.relation_to_data) && formik.errors.relation_to_data}
            >
              {RELATIONSHIP_DATA.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="text"
              id="note"
              name="note"
              value={formik.values.note}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              fullWidth
              placeholder="Please describe detail hereSelect option"
              label={<span>Your Note To Change Data</span>}
              multiline
              rows={4}
              error={Boolean(formik.touched.note && formik.errors.note)}
              helperText={Boolean(formik.touched.note) && formik.errors.note}
            />
            <Box>
              <FormControlLabel
                id="is_confirm"
                name="is_confirm"
                onChange={(e, value) => {
                  formik.setFieldValue('is_confirm', value);
                }}
                value={formik.values.is_confirm}
                control={<Checkbox checked={formik.values.is_confirm} />}
                label={
                  <Typography color={theme.palette.text.secondary} variant="body1">
                    I certify that I am authorized to prove the above information
                  </Typography>
                }
              />
              {formik.touched.is_confirm && formik.errors.is_confirm && (
                <FormHelperText error>{formik.errors.is_confirm}</FormHelperText>
              )}
            </Box>
            <ButtonWithLoading
              fullWidth
              size="large"
              variant="contained"
              isRounded
              type="submit"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Submit
            </ButtonWithLoading>
          </Stack>
        </Box>
        <PopupSelectPhoneCode
          anchorEl={anchorEl}
          id="phone_code"
          open={visiblePhoneCode}
          handleClose={() => setVisiblePhoneCode(false)}
          disablePortal={true}
        />
      </FormikForm>
    </ModalComponent>
  );
};
export default FormReportWrong;
