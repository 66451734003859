import { Box, useTheme } from '@mui/material';
import ExpandableBox from 'src/components/common/ExpandableBox';
import WorldMapChart from 'src/components/HighChart/WorldMapChart';
import { getDataLabelOptionBillOfLanding, WorldMapOptions } from 'src/components/HighChart/WorldMapChart/config';

const MapAddress = () => {
  const theme = useTheme();

  return (
    <ExpandableBox title="Mapped Addresses" openDefault={false} flex={1}>
      <Box padding={3} display="flex" flexDirection="column" bgcolor={theme.palette.background.default}>
        <WorldMapChart
          data={[
            ['us', 10],
            ['tw', 20],
          ]}
          dataPoint={[
            {
              lon: 121.59,
              lat: 25.1,
              name: 'tw',
              dataLabels: getDataLabelOptionBillOfLanding({
                code: 'tw',
                companyName: 'HWA HSIA GLASS CO LTD',
                address:
                  'No. 49, Puu Chien Road, Hsinchu, Taiwan No. 49, Puu Chien Road, Hsinchu, Taiwan No. 49, Puu Chien Road, Hsinchu, TaiwanNo. 49, Puu Chien Road, Hsinchu, No. 49, Puu Chien Road, Hsinchu, Taiwan No. 49, Puu Chien Road, Hsinchu, Taiwan No. 49, Puu Chien Road, Hsinchu, TaiwanNo. 49, Puu Chien Road, Hsinchu, ',
                companyType: 'Buyer',
              }),
            },
            {
              lon: -118.24,
              lat: 34.05,
              dataLabels: getDataLabelOptionBillOfLanding({
                code: 'us',
                companyName: 'VERITIV .Ltd',
                address:
                  'No. 49, Puu Chien Road, Hsinchu, Taiwan No. 49, Puu Chien Road, Hsinchu, TaiwanNo. 49, Puu Chien Road, Hsinchu, TaiwanNo. 49, Puu Chien Road, Hsinchu, Taiwan No. 49, Puu Chien Road, Hsinchu, TaiwanNo. 49, Puu Chien Road, Hsinchu, Taiwan',
                companyType: 'Supplier',
              }),
            },
          ]}
          height={600}
          type={WorldMapOptions.company_bill_of_landing}
        />
      </Box>
    </ExpandableBox>
  );
};

export default MapAddress;
