import React from 'react';
// import { TransgenderOutlined } from '@mui/icons-material';
import { Avatar, Box, Grid, IconButton, Pagination, Stack, styled, Typography, useTheme } from '@mui/material';
import { Bookmark, Mail } from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import { UserIcon } from 'src/components/Icons';

// Utilities
import AvatarUser from 'src/components/common/AvatarUser';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: 1,
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 2,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const DUMP_DATA = [
  {
    id: '1',
    name: 'Nicolae Sobari',
    title: 'Head of Product Development',
    company_name: 'Business Class',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'CN',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '??/??/1986',
    is_marked: true,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    title: 'Senior Developer	',
    company_name: 'Tech Innovations',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'CH',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '10/08/1990',
    is_marked: true,
  },
  {
    id: '3',
    name: 'Nicolae Sobari',
    title: 'Head of Product Development',
    company_name: 'Business Class',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'EG',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '??/??/1986',
    is_marked: true,
  },
  {
    id: '4',
    name: 'Nicholas Sobar',
    title: 'Senior Developer	',
    company_name: 'Tech Innovations',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'EG',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '10/08/1990',
    is_marked: true,
  },
  {
    id: '5',
    name: 'Nicolae Sobari',
    title: 'Head of Product Development',
    company_name: 'Business Class',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'GR',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '??/??/1986',
    is_marked: true,
  },
  {
    id: '6',
    name: 'Nicholas Sobar',
    title: 'Senior Developer	',
    company_name: 'Tech Innovations',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'HM',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '10/08/1990',
    is_marked: true,
  },
  {
    id: '7',
    name: 'Nicholas Sobar',
    title: 'Senior Developer	',
    company_name: 'Tech Innovations',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'IS',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '10/08/1990',
    is_marked: true,
  },
  {
    id: '8',
    name: 'Nicolae Sobari',
    title: 'Head of Product Development',
    company_name: 'Business Class',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'CA',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '??/??/1986',
    is_marked: true,
  },
  {
    id: '9',
    name: 'Nicholas Sobar',
    title: 'Senior Developer	',
    company_name: 'Tech Innovations',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'CA',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '10/08/1990',
    is_marked: true,
  },
  {
    id: '10',
    name: 'Nicolae Sobari',
    title: 'Head of Product Development',
    company_name: 'Business Class',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'TW',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '??/??/1986',
    is_marked: true,
  },
  {
    id: '11',
    name: 'Nicholas Sobar',
    title: 'Senior Developer	',
    company_name: 'Tech Innovations',
    company_image:
      'https://plus.unsplash.com/premium_photo-1669075651892-ad3b64f2145c?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    country_code: 'CA',
    jurisdiction: 'Chișinău, Moldova',
    gender: 'Male',
    birthday: '10/08/1990',
    is_marked: true,
  },
];

const ListPeople = () => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {DUMP_DATA.map((people, index) => {
          return (
            <Grid xs={12} md={4} lg={3} xl={2} key={`company-bookmark-${people.id}-${index}`} item>
              <Profile people={people} />
            </Grid>
          );
        })}
      </Grid>
      <Stack mt={2} alignItems="center">
        <Pagination count={10} color="primary" shape="rounded" />
      </Stack>
    </React.Fragment>
  );
};

export default ListPeople;

const Profile = ({ people }: { people: IPeopleProfile }) => {
  const theme = useTheme();

  return (
    <StyledBox
      sx={{
        borderColor: theme.palette.divider,
      }}
      display={'flex'}
      flexDirection="column"
      gap={1}
      padding={1.5}
    >
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Box display="flex" flexDirection="row" justifyContent="center" position="relative">
            <AvatarUser
              alt={people.full_name}
              variant="rounded"
              src=""
              sx={{ width: 32, height: 32, border: '0.5px solid #A195FD', backgroundColor: '#F4F7FE' }}
            />
            <IconButton
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                height: 'fit-content',
                position: 'absolute',
                right: 0,
              }}
              size="small"
            >
              <IconFeather
                muiProps={{ fontSize: 'small' }}
                icon={<Bookmark fill={people.is_marked ? theme.palette.primary.main : 'transparent'} />}
              />
            </IconButton>
          </Box>
          <Typography variant="subtitle1" align="center">
            {people.full_name}
          </Typography>
          <Typography variant="caption2" color="text.secondary" align="center">
            {people.position}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
            <Avatar
              alt={people.company_name}
              src={people?.image_url || ''}
              sizes="100"
              variant="rounded"
              sx={{ width: 20, height: 20, border: '2px solid #fff' }}
            />
            <Typography variant="caption2" color="text.secondary">
              {people.company_name}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
            <FlagCountry width={20} code={people.country_code || ''} />
            <Typography variant="caption2" color="text.secondary">
              {people.jurisdiction}
            </Typography>
          </Box>
          {/* <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
            <TransgenderOutlined fontSize="small" color="disabled" />
            <Typography variant="caption2" color="text.secondary">
              {people.gender}
            </Typography>
          </Box> */}
          <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
            <UserIcon fontSize="small" color="disabled" />
            <Typography variant="caption2" color="text.secondary">
              {people.birthday}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
            <IconFeather
              muiProps={{
                fontSize: 'small',
                color: 'disabled',
              }}
              icon={<Mail />}
            />
            <Typography variant="caption2" color="text.secondary">
              No Contact
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </StyledBox>
  );
};
