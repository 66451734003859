import { useMutation } from '@tanstack/react-query';
import { UtilsService } from 'src/services/api';

export const useMutateUploadAvatarUser = () => {
  const { isPending, isSuccess, data, mutateAsync } = useMutation({
    mutationFn: (file?: File) => UtilsService.handleUploadAvatarUser(file),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutateAsync,
  };
};

export const useMutateDeleteAvatar = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: () => UtilsService.handleDeleteAvatar(),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateUploadContributeImages = () => {
  const { isPending, isSuccess, data, mutateAsync } = useMutation({
    mutationFn: (files?: Array<File>) => UtilsService.handleUploadContributeImages(files),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutateAsync,
  };
};

export const useMutateCheckImageContributeNudity = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (requestId: string) => UtilsService.checkImageContributeNudity(requestId),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateGetImageContributeUrl = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (requestId: string) => UtilsService.getImageContributeUrl(requestId),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateCheckImageNudity = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (requestId: string) => UtilsService.checkImageNudity(requestId),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateGetImageUrl = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (requestId: string) => UtilsService.getImageUrl(requestId),
  });
  return {
    isPending,
    mutateAsync,
  };
};
