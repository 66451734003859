import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { find } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { Search } from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import PaperSelect from 'src/components/common/PaperSelect';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import { useQueryCountriesTariffs } from 'src/services/queries';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

interface IProps {
  id: string;
  required?: boolean;
  tooltipText?: string;
  label?: ReactNode;
}

const FormItemCountryTariffs = ({ id, tooltipText = '', label, required = false }: IProps) => {
  const formik = useFormikContext<ExpectedAny>();
  const { data, isFetching } = useQueryCountriesTariffs();

  const getCountryByCountryCode = (countryCode: string) => {
    if (!countryCode) {
      return {
        Id: '',
        Code: '',
        Name: '',
        ISO2: '',
        ISO3: '',
        ValidFrom: '',
        ValidUntil: '',
      };
    }
    const country = find(data.data, (o) => o.Code === countryCode);

    if (!country) {
      return {
        Id: '',
        Code: '',
        Name: '',
        ISO2: '',
        ISO3: '',
        ValidFrom: '',
        ValidUntil: '',
      };
    }

    return country;
  };

  const country = useMemo(() => {
    return getCountryByCountryCode(formik.values[id]);
  }, [formik.values[id], data.data]);

  return (
    <Autocomplete
      value={country}
      onChange={(_, value) => {
        formik.setFieldValue(id, value?.Code);
      }}
      onBlur={formik.handleBlur}
      loading={isFetching}
      id={id}
      fullWidth
      size="small"
      autoComplete
      options={getArrayDefault(data.data)}
      popupIcon={<IconFeather muiProps={{ fontSize: 'small' }} icon={<Search />} />}
      PaperComponent={PaperSelect}
      autoHighlight
      getOptionLabel={(option) => option.Name}
      renderOption={(props, option) => (
        <Box gap={1} display={'flex'} component="li" {...props}>
          <FlagCountry width={24} code={option.ISO2} />
          {option.Name} ({option.ISO2})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            ...params.InputLabelProps,
          }}
          placeholder="Typing..."
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: country.Code ? <FlagCountry width={24} code={country.ISO2} /> : null,
          }}
          label={label ? label : <span>Your Country {tooltipText && <TooltipFormLabel title={tooltipText} />}</span>}
        />
      )}
    />
  );
};

export default FormItemCountryTariffs;
