import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import update from 'immutability-helper';

// Components
import { MAX_IMAGES_UPLOAD_CONTRIBUTE } from 'src/utils/constants/config';

const UploadListImages = ({
  listImage,
  handleChooseImages,
}: {
  listImage: Array<TypeImage>;
  handleChooseImages: (images: Array<TypeImage>) => void;
}) => {
  const theme = useTheme();

  const handleChooseImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = Array.from(e?.target?.files || []).map((file) => ({
      file: file,
      url: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      type: file.type,
    }));
    handleChooseImages(
      update(listImage, {
        $push: newState,
      }),
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={1}
      flexGrow={1}
      height={150}
      sx={{
        background: theme.palette.neutroState100.primary,
        borderRadius: theme.shape.borderRadius / 2,
      }}
    >
      <UploadFileOutlinedIcon color="action" />
      <Stack alignItems={'center'} direction={'row'}>
        <Button
          sx={{ textTransform: 'none' }}
          component="label"
          role={undefined}
          disabled={listImage.length >= MAX_IMAGES_UPLOAD_CONTRIBUTE}
        >
          Click to upload
          <input
            onChange={handleChooseImage}
            hidden
            accept="image/gif, image/jpeg, image/png, image/svg,"
            multiple
            type="file"
          />
        </Button>
        <Typography variant="body1">or drag and drop</Typography>
      </Stack>
      <Typography color={theme.palette.text.secondary} variant="body2">
        PNG, JPG (maximum 10 photos, 1MB/photo)
      </Typography>
    </Box>
  );
};

export default UploadListImages;
