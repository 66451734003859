import { entries, isArray, isEmpty } from 'lodash';

export const handleQueryParams = (key: string, params?: string[]) => {
  if (!params || isEmpty(params)) {
    return '';
  }
  let res = '';
  params.forEach((param, index) => {
    res += `${key}=${param}`;
    if (index < params.length - 1) {
      res += '&';
    }
  });
  return res;
};

export const handleQueryParamFromObject = (ob: Record<string, ExpectedAny>) => {
  const params = entries(ob)
    .map(([key, value]) => {
      if (isArray(value)) {
        const q = value.map((val: string | ExpectedAny) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        });
        return q.join('&');
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
  return params
}