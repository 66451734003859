import { Box, Container, styled, useTheme, Typography, Stack, Divider, Chip, Skeleton } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { isArray, isEmpty, isString, pickBy } from 'lodash';

// Components
import FormikForm from 'src/components/common/FormikForm';
import FormItemChooseCompany from 'src/features/CompareCompany/Common/FormItemChooseCompany';
import { TypeCompareCompany } from 'src/features/CompareCompany/Common/config';
import LogoCompany from 'src/components/common/LogoCompany';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { useQueryCompareCompanies } from 'src/services/queries';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { EmptyText, FORMAT_DATE_TIME } from 'src/utils/constants/config';
import FlagCountry from 'src/components/common/FlagCountry';
import { checkCountryHaveState, getCompanyAgeText, getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { useTranslation } from 'react-i18next';
import { AuthRouteKeys } from 'src/utils/constants/routes';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(7),
}));

const CompareCompany = () => {
  const theme = useTheme();
  const [, setSearchParam] = useSearchParams();
  const { companyIds } = useQueryParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (companyIds && isString(companyIds)) {
      navigate(AuthRouteKeys.CompanyDetail.replace(':id', companyIds));
    }
  }, [companyIds]);

  const GetCompareCompaniesQuery = useQueryCompareCompanies(companyIds || []);
  const dataCompareCompanies = getArrayDefault(GetCompareCompaniesQuery.data?.data);
  const { t } = useTranslation();
  const formik = useFormik<TypeCompareCompany>({
    enableReinitialize: true,
    initialValues: isArray(companyIds)
      ? companyIds.filter(Boolean).reduce((result: ExpectedAny, id: string, index: number) => {
          result[`company_${index}`] = id;
          return result;
        }, {})
      : {},
    validationSchema: Yup.object().shape({}),

    onSubmit: async (values, { setSubmitting }) => {
      const companyIds = pickBy(values, (_, key) => {
        return key.startsWith('company_');
      });
      setSearchParam({
        companyIds: Object.values(companyIds),
      });
      setSubmitting(false);
    },
  });

  const dataCompareCompany = useMemo(() => {
    return [
      {
        label: t('compare_companies.field.label.company_name'),
        values: dataCompareCompanies.map((company) => company?.name || EmptyText),
      },
      {
        label: t('compare_companies.field.label.company_number'),
        values: dataCompareCompanies.map((company) => company?.company_code || EmptyText),
      },
      {
        label: t('compare_companies.field.label.company_type'),
        values: dataCompareCompanies.map((company) => company?.company_type || EmptyText),
      },
      {
        label: t('compare_companies.field.label.jurisdiction'),
        values: dataCompareCompanies.map((company, index) => {
          const isCountryHaveState = checkCountryHaveState(company.country || '');
          const country = getCountryByCountryCode(company?.country || '');
          return (
            <Stack key={index} spacing={1} direction="row" alignItems="center">
              <FlagCountry code={company?.country || ''} width={16} />
              <Typography variant="body2" color="text.secondary">
                {isCountryHaveState
                  ? `${country && `${country.label} - `} ${company.jurisdiction}`
                  : `${country && country.label}`}
              </Typography>
            </Stack>
          );
        }),
      },
      {
        label: t('compare_companies.field.label.lei'),
        values: dataCompareCompanies.map((company) => company?.lei || EmptyText),
      },
      {
        label: t('compare_companies.field.label.date_incorporated'),
        values: dataCompareCompanies.map((company) =>
          company?.date_incorporated
            ? formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, company?.date_incorporated || '')
            : EmptyText,
        ),
      },
      {
        label: t('compare_companies.field.label.company_age'),
        values: dataCompareCompanies.map((company) => getCompanyAgeText(company?.company_age)),
      },
      {
        label: t('compare_companies.field.label.registered_address'),
        values: dataCompareCompanies.map((company) => (company?.registered_address || EmptyText).toUpperCase()),
      },
      {
        label: t('compare_companies.field.label.phone'),
        values: dataCompareCompanies.map((company) =>
          !isEmpty(company?.phone_numbers) ? getArrayDefault(company?.phone_numbers).join(', ') : EmptyText,
        ),
      },
      {
        label: t('compare_companies.field.label.fax'),
        values: dataCompareCompanies.map((company) =>
          !isEmpty(company?.fax_numbers) ? getArrayDefault(company?.fax_numbers).join(', ') : EmptyText,
        ),
      },
      {
        label: t('compare_companies.field.label.email'),
        values: dataCompareCompanies.map((company) =>
          !isEmpty(company?.emails) ? getArrayDefault(company?.emails).join(', ') : EmptyText,
        ),
      },
      {
        label: t('compare_companies.field.label.date_struck_off'),
        values: dataCompareCompanies.map((company) =>
          formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, company?.date_struck_off || ''),
        ),
      },
      {
        label: t('compare_companies.field.label.alerts'),
        values: dataCompareCompanies.map((company, index) =>
          company?.is_sanctioned ? (
            <Chip key={index} label="Sanctioned" color="warning" size="small" />
          ) : (
            <Chip key={index} label="Not Found" color="default" size="small" />
          ),
        ),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.buyer_global_rank')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.buyer_country_rank')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.seller_global_rank')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.seller_country_rank')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.number_of_foreign_customers_12_months')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.number_of_foreign_customers_all_time')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.number_of_foreign_suppliers_12_months')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
      {
        label: (
          <Typography variant={'body2'} color={'text.disabled'}>
            {t('compare_companies.field.label.number_of_foreign_suppliers_all_time')}
          </Typography>
        ),
        values: dataCompareCompanies.map((_, index) => (
          <Typography key={index} variant="body2" color="text.disabled">
            {EmptyText}
          </Typography>
        )),
      },
    ];
  }, [dataCompareCompanies]);

  return (
    <StyledContainer maxWidth={'xl'}>
      <Box
        padding={3}
        sx={{
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h4">{t('compare_companies.header.title')}</Typography>
          <Typography variant="body1" color="text.secondary">
            {t('compare_companies.header.description')}
          </Typography>
          {GetCompareCompaniesQuery.isFetching ? (
            <SkeletonContent />
          ) : (
            <Box display={dataCompareCompanies.length > 1 ? 'block' : 'none'}>
              <FormikForm formik={formik}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  divider={<Divider orientation="vertical" flexItem />}
                  sx={{
                    background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
                    boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                  }}
                >
                  <Box display="flex" flexBasis={'25%'} />
                  {dataCompareCompanies.map((company, index) => {
                    return (
                      <Box
                        flexBasis={`${(100 - 25) / dataCompareCompanies.length}%`}
                        key={company.name}
                        display="flex"
                        p={4}
                        flexDirection="column"
                        gap={3}
                        justifyContent={'space-between'}
                      >
                        <Box>
                          <LogoCompany logoURL={company?.logo_url || ''} width={64} height={64} />
                          <Typography marginTop={2} variant="h6" color="common.white">
                            {company?.name || EmptyText}
                          </Typography>
                        </Box>
                        <Box>
                          <FormItemChooseCompany
                            defaultValue={company}
                            disabledList={dataCompareCompanies.map((company) => company.id as string)}
                            placeholder={t('compare_companies.search_form.title')}
                            id={`autocomplete_company_compare_${index}`}
                            sx={{
                              '& .MuiAutocomplete-input': {
                                color: theme.palette.common.white,
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: theme.palette.common.white,
                              },
                              '& .MuiOutlinedInput-root': {
                                borderColor: `${theme.palette.common.white} !important`,
                                '&.Mui-focused fieldset': {
                                  borderColor: theme.palette.common.white,
                                  borderWidth: 1,
                                },
                                '&:hover fieldset': {
                                  borderColor: `${theme.palette.common.white} !important`,
                                },
                              },
                              '& .MuiInput-underline:before': {
                                color: theme.palette.common.white,
                              },
                              '& .MuiInputBase-input::placeholder': {
                                color: theme.palette.common.white,
                              },
                            }}
                            onChange={(value) => {
                              if (!value) return;
                              formik.setFieldValue(`company_${index}`, value);
                              formik.handleSubmit?.();
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </FormikForm>
              {dataCompareCompany.map((data, index) => (
                <Stack key={`data-compare-company-${index}`} direction="row">
                  <Box
                    flexBasis={`25%`}
                    display="flex"
                    py={2}
                    px={4}
                    borderBottom={`1px dashed ${theme.palette.divider}`}
                    borderRight={`1px dashed ${theme.palette.divider}`}
                  >
                    {typeof data.label === 'string' ? (
                      <Typography variant="subtitle2">{data.label}</Typography>
                    ) : (
                      data.label
                    )}
                  </Box>
                  {data.values.map((value, index) => {
                    const isLastElement = index === data.values.length - 1;
                    return (
                      <Box
                        flexBasis={`${(100 - 25) / dataCompareCompanies.length}%`}
                        display="flex"
                        py={2}
                        px={4}
                        key={index}
                        borderRight={!isLastElement ? `1px dashed ${theme.palette.divider}` : 'none'}
                        borderBottom={`1px dashed ${theme.palette.divider}`}
                      >
                        {typeof value === 'string' ? (
                          <Typography variant="body2" color="text.secondary">
                            {value}
                          </Typography>
                        ) : (
                          value
                        )}
                      </Box>
                    );
                  })}
                </Stack>
              ))}
            </Box>
          )}
        </Stack>
      </Box>
    </StyledContainer>
  );
};

export default CompareCompany;

const SkeletonContent = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rectangular" animation="wave" height={240} />
      <Skeleton animation="wave" variant="rectangular" height={24} />
      <Skeleton animation="wave" variant="rectangular" height={24} />
      <Skeleton animation="wave" variant="rectangular" height={24} />
      <Skeleton animation="wave" variant="rectangular" height={24} />
    </Stack>
  );
};
