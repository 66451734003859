import { AddOutlined, Clear } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import updateHelper from 'immutability-helper';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { every, filter, isArray, slice } from 'lodash';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import {
  AlternativeAddress,
  ClaimCompany,
  ContactInformation,
  EmailProfile,
  OtherNames,
  PrimaryAddress,
  RegisteredAddress,
  RelatedCorporate,
  SocialMedia,
  WebsiteProfile,
} from 'src/components/FormContributeCompany';
import ModalComponent from 'src/components/Modal/ModalComponent';
import {
  IPropsDataContribute,
  RELATIONSHIP_DATA,
  TYPE_CONTRIBUTE,
  TYPE_CONTRIBUTE_ARRAY,
  TypeContribute,
} from 'src/features/CompanyDetail/Common/config';

// Utilities
import { useTranslation } from 'react-i18next';
import GuardContributeImg from 'src/asset/images/guard-contribute.png';
import { useNotification } from 'src/hooks/useNotification';
import { useMutateContributeCompany } from 'src/services/mutate';
import {
  useMutateCheckImageContributeNudity,
  useMutateGetImageContributeUrl,
  useMutateUploadContributeImages,
} from 'src/services/mutate/useMutateUtil';
import {
  ACCEPT_TYPE_IMAGE,
  MAX_IMAGES_UPLOAD_CONTRIBUTE,
  MAX_SIZE_IMAGE_UPLOAD,
  MAX_TIMES_RETRY_CHECK_IMAGES_CONTRIBUTE,
} from 'src/utils/constants/config';
import { TypeSocialProfile } from 'src/utils/constants/enum';
import { ERROR_CODE, SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatPhoneNumberBeforeSent, getCurrentUrl } from 'src/utils/helpers/stringHelpers';
import { validateProfileURL, validateURL } from 'src/utils/helpers/validate';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';
import useSnackbarStore from 'src/zustand/useSnackbarStore';

interface IProps {
  companyData: ICompanyDetail;
  open: boolean;
  type: TypeContribute | null;
  handleClose: () => void;
}

const StyledBoxData = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px dashed ${theme.palette.primary.main}`,
  position: 'relative',
  display: 'flex',
  padding: theme.spacing(2),
  flex: theme.spacing(1),
}));

const StyledIconDelete = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-1),
  right: theme.spacing(-1),
  padding: theme.spacing(0.25),
  backgroundColor: theme.palette.action.active,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledHeaderAlert = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  padding: '8px 32px',
}));

const FormContribute: React.FC<IProps> = ({ companyData, open, type, handleClose }) => {
  const theme = useTheme();
  const [countData, setCountData] = useState(1);
  const [listImage, setListImage] = useState<Array<TypeImage>>([]);
  const { mutateAsync } = useMutateContributeCompany();
  const { handleOpenSearch } = useFormSearch((state) => state);
  const uploadContributeImageMutation = useMutateUploadContributeImages();
  const checkImageNudityMutation = useMutateCheckImageContributeNudity();
  const getImageUrlMutation = useMutateGetImageContributeUrl();
  const { dispatch } = useSnackbarStore();
  const { notification } = useNotification();
  const { t } = useTranslation();

  const formik = useFormik<IPropsDataContribute>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      type_contribute: type || TYPE_CONTRIBUTE[0].value,
      relation_to_data: '',
      note: '',
      is_confirm: false,
      contribution_email_company_profile: [],
      contribution_website_company_profile: [],
      contribution_other_names_company_profile: [],
      contribution_social_media_company_profile: [],
      contribution_related_corporates: [],
      contribution_contact_company_profile: [],
      contribution_registered_address_company_profile: [],
      contribution_alternative_address_company_profile: [],
      contribution_primary_address_company_profile: [],
      contribution_claim_your_company_profile: {
        company_email: '',
      },
    },
    validationSchema: Yup.object().shape({
      type_contribute: Yup.string().max(255).required('Email is required'),
      relation_to_data: Yup.string().max(255).required('Relationship Data is required'),
      note: Yup.string().max(255),
      is_confirm: Yup.bool().oneOf([true], 'Please checked this checkbox'),
      contribution_email_company_profile: Yup.array().of(
        Yup.object().shape({
          email: Yup.string().required('Email is required').email('Email is invalid'),
        }),
      ),
      contribution_claim_your_company_profile: Yup.object().shape({
        company_email: Yup.string()
          .email('Email is invalid')
          .test('type_contribute', 'Email is required', (text?: string) => {
            const typeContribute = formik?.values?.type_contribute;
            if (typeContribute === TypeContribute.ClaimCompanyProfile && !text) {
              return false;
            }
            return true;
          }),
      }),
      contribution_website_company_profile: Yup.array().of(
        Yup.object().shape({
          website: Yup.string()
            .required('Website is required')
            .test('website_address', 'Does not match website syntax', (text?: string) => validateURL(text || '')),
        }),
      ),
      contribution_other_names_company_profile: Yup.array().of(
        Yup.object().shape({
          other_name: Yup.string().required('Other Names required'),
        }),
      ),
      contribution_social_media_company_profile: Yup.array().of(
        Yup.object().shape({
          social_url: Yup.string()
            .required('Other Names is required')
            .test(
              'social_url',
              'Incorrect Social Link syntax',
              (text?: string) =>
                validateProfileURL(text || '', TypeSocialProfile.Twitter) ||
                validateProfileURL(text || '', TypeSocialProfile.Facebook) ||
                validateProfileURL(text || '', TypeSocialProfile.Instagram) ||
                validateProfileURL(text || '', TypeSocialProfile.Linkedin) ||
                validateProfileURL(text || '', TypeSocialProfile.Youtube),
            ),
        }),
      ),
      contribution_related_corporates: Yup.array().of(
        Yup.object().shape({
          people_name: Yup.string().required('Name is required'),
          people_role: Yup.string().required('Role required'),
        }),
      ),
      contribution_contact_company_profile: Yup.array().of(
        Yup.object().shape({
          full_name: Yup.string().required('Name is required'),
          title: Yup.string().required('Title is required'),
          email: Yup.string().required('Email is required').email('Email is invalid'),
          phone_code: Yup.string().required('Phone code is required'),
          phone_number: Yup.string().test(
            'phone_code',
            'Phone number is invalid',
            (text?: string, context?: ExpectedAny) => {
              const code = context?.parent?.phone_code;
              const phoneNumber = code + (text || '');
              if (!text) {
                return true;
              }
              return isValidPhoneNumber(phoneNumber || '');
            },
          ),
        }),
      ),
      contribution_registered_address_company_profile: Yup.array().of(Yup.string()),
      contribution_alternative_address_company_profile: Yup.array().of(Yup.string()),
      contribution_primary_address_company_profile: Yup.array().of(Yup.string()),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const params: TypeDTOContributeCompany = {
        company_id: companyData.id,
        type_of_contribution: values.type_contribute,
        relation_to_data: values.relation_to_data,
        note: values.note,
        ...(await formatParamsBeforeSent()),
      };
      const res: IResponse<ExpectedAny> = await mutateAsync(params);
      if (res.status_code === SUCCESS_CODE.CREATE) {
        handleClose();
      }
      setSubmitting(false);
      notification(
        res,
        {
          title: t('form_contribute.notification.success.title'),
          description: t('form_contribute.notification.success.description'),
        },
        {
          title: t('form_contribute.notification.failed.title'),
          description: t('form_contribute.notification.failed.description'),
        },
      );
    },
  });

  useEffect(() => {
    setCountData(1);
    setListImage([]);
    formik.setValues({
      ...formik.initialValues,
      type_contribute: formik.values.type_contribute,
      relation_to_data: formik.values.relation_to_data,
      note: formik.values.note,
      is_confirm: formik.values.is_confirm,
    });
    formik.setErrors({});
    formik.setTouched({});
  }, [formik.values.type_contribute]);

  const removeData = (indexRemove: number) => {
    setCountData(countData - 1);
    switch (formik.values.type_contribute) {
      case TypeContribute.Email:
        removeValidateContribute('contribution_email_company_profile', indexRemove);
        break;
      case TypeContribute.WebsiteCompanyProfile:
        removeValidateContribute('contribution_website_company_profile', indexRemove);
        break;
      case TypeContribute.OtherNames:
        removeValidateContribute('contribution_other_names_company_profile', indexRemove);
        break;
      case TypeContribute.SocialMedia:
        removeValidateContribute('contribution_social_media_company_profile', indexRemove);
        break;
      case TypeContribute.RelatedCorporate:
        removeValidateContribute('contribution_related_corporates', indexRemove);
        break;
      case TypeContribute.ContactInformation:
        removeValidateContribute('contribution_contact_company_profile', indexRemove);
        break;
      default:
        break;
    }
  };

  const removeValidateContribute = (type: keyof IPropsDataContribute, indexRemove: number) => {
    formik.setFieldValue(type, updateHelper(formik.values[type], { $splice: [[indexRemove, 1]] }));
    if (isArray(formik.touched[type])) {
      formik.setTouched({
        ...formik.touched,
        [type]: updateHelper(formik.touched[type], {
          $splice: [[indexRemove, 1]],
        }),
      });
    }
    if (isArray(formik.errors[type])) {
      formik.setErrors({
        ...formik.errors,
        [type]: updateHelper(formik.errors[type], {
          $splice: [[indexRemove, 1]],
        }),
      });
    }
  };

  const handleAlertWarning = () => {
    dispatch({
      open: true,
      title: t('form_contribute.notification.upload_image.warning.title'),
      description: t('form_contribute.notification.upload_image.warning.description'),
      severity: 'error',
      color: 'error',
    });
  };

  const checkSize = (images: Array<TypeImage>) => {
    return every(images, (image: TypeImage) => {
      return (image?.size || 0) <= MAX_SIZE_IMAGE_UPLOAD;
    });
  };

  const checkIsValidType = (images: Array<TypeImage>) => {
    return every(images, (image: TypeImage) => {
      return ACCEPT_TYPE_IMAGE.includes(image.type || '');
    });
  };

  const handleChooseImages = (images: Array<TypeImage>) => {
    let listImagesShadow = images;
    const isValidSize = checkSize(images);
    const isValidType = checkIsValidType(images);
    if (!isValidSize || !isValidType) {
      handleAlertWarning();
      listImagesShadow = filter(listImagesShadow, (image: TypeImage) => {
        return (image?.size || 0) <= MAX_SIZE_IMAGE_UPLOAD;
      });
    }
    if (listImagesShadow.length > MAX_IMAGES_UPLOAD_CONTRIBUTE) {
      dispatch({
        open: true,
        title: "Can not upload's photos",
        description: `Only choose maximum ${MAX_IMAGES_UPLOAD_CONTRIBUTE} images`,
        variant: 'standard',
        color: 'error',
      });
      listImagesShadow = slice(listImagesShadow, 0, MAX_IMAGES_UPLOAD_CONTRIBUTE);
    }
    setListImage(listImagesShadow);
  };

  const handleUploadImage = async (images: Array<TypeImage>) => {
    const listFiles = images.filter((image) => image.file !== undefined).map((image) => image.file);
    const resUploadImage = await uploadContributeImageMutation.mutateAsync(listFiles);

    if (resUploadImage.status_code !== SUCCESS_CODE.CREATE) {
      return [];
    }
    const requestIdUploadImage = resUploadImage.request_id;
    const listRequestIdCheckNudity = await getRequestIdCheckNudity(requestIdUploadImage);
    if (listRequestIdCheckNudity.length === 0) {
      return [];
    } else {
      const listPromises = listRequestIdCheckNudity.map((requestId) => getImageURL(requestId));
      const listImagesContribute = await Promise.all(listPromises);
      return listImagesContribute.filter((image) => !!image);
    }
  };

  const getRequestIdCheckNudity = async (requestIdUploadImage?: string) => {
    if (!requestIdUploadImage) return [];
    for (let attempt = 1; attempt <= MAX_TIMES_RETRY_CHECK_IMAGES_CONTRIBUTE; attempt++) {
      const responseCheckImageNudity = await checkImageNudityMutation.mutateAsync(requestIdUploadImage);
      const arrayDataCheckNudity = getArrayDefault(responseCheckImageNudity.data);
      if (
        responseCheckImageNudity.status_code === SUCCESS_CODE.GET &&
        arrayDataCheckNudity.length > 0 &&
        arrayDataCheckNudity.every((res) => res.status_code === SUCCESS_CODE.CREATE)
      ) {
        return arrayDataCheckNudity.map((dataCheck) => ({ request_id: dataCheck.request_id, alt: dataCheck.alt }));
      } else if (
        responseCheckImageNudity.status_code === SUCCESS_CODE.GET &&
        arrayDataCheckNudity.length > 0 &&
        arrayDataCheckNudity.some((res) => res.status_code === ERROR_CODE.FORBIDDEN)
      ) {
        handleOpenSearch(FormType.WarningSensitiveImage);
        return arrayDataCheckNudity
          .filter((dataCheck) => dataCheck.status_code === SUCCESS_CODE.CREATE)
          .map((dataCheck) => ({ request_id: dataCheck.request_id, alt: dataCheck.alt }));
      }

      await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 3 seconds before retrying
    }
    return [];
  };

  const getImageURL = async (requestIdCheckNudity: { request_id: string; alt: string }) => {
    for (let attempt = 1; attempt <= MAX_TIMES_RETRY_CHECK_IMAGES_CONTRIBUTE; attempt++) {
      const responseImageUrl = await getImageUrlMutation.mutateAsync(requestIdCheckNudity.request_id);

      if (responseImageUrl.status_code === SUCCESS_CODE.GET) {
        return { url: responseImageUrl.public_link || '', alt: requestIdCheckNudity.alt };
      }

      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds before retrying
    }
    return null;
  };

  const formatParamsBeforeSent = async () => {
    const formattedValues: Omit<
      TypeDTOContributeCompany,
      'company_id' | 'type_of_contribution' | 'relation_to_data' | 'note'
    > = {
      contribution_claim_your_company_profile: null,
      contribution_email_company_profile: null,
      contribution_website_company_profile: null,
      contribution_other_names_company_profile: null,
      contribution_social_media_company_profile: null,
      contribution_related_corporates: null,
      contribution_contact_company_profile: null,
      contribution_registered_address_company_profile: null,
      contribution_alternative_address_company_profile: null,
      contribution_primary_address_company_profile: null,
    };
    let listImagesContribute: Array<{
      url: string;
      alt: string;
    } | null> = [];
    if (listImage.length > 0) {
      listImagesContribute = await handleUploadImage(listImage);
    }
    switch (formik.values.type_contribute) {
      case TypeContribute.ClaimCompanyProfile: {
        formattedValues.contribution_claim_your_company_profile = {
          ...formik.values.contribution_claim_your_company_profile,
        };
        break;
      }
      case TypeContribute.Email:
        formattedValues.contribution_email_company_profile = formik.values.contribution_email_company_profile;
        break;
      case TypeContribute.WebsiteCompanyProfile:
        formattedValues.contribution_website_company_profile = formik.values.contribution_website_company_profile;
        break;
      case TypeContribute.OtherNames:
        formattedValues.contribution_other_names_company_profile =
          formik.values.contribution_other_names_company_profile;
        break;
      case TypeContribute.SocialMedia:
        formattedValues.contribution_social_media_company_profile =
          formik.values.contribution_social_media_company_profile;
        break;
      case TypeContribute.RelatedCorporate:
        formattedValues.contribution_related_corporates = formik.values.contribution_related_corporates;
        break;
      case TypeContribute.ContactInformation:
        formattedValues.contribution_contact_company_profile = formik.values.contribution_contact_company_profile.map(
          (contributeData) => {
            return {
              full_name: contributeData.full_name,
              title: contributeData.title,
              email: contributeData.email,
              phone_number: formatPhoneNumberBeforeSent(contributeData.phone_code, contributeData.phone_number),
            };
          },
        );
        break;
      case TypeContribute.RegisteredAddressCompany:
        formattedValues.contribution_registered_address_company_profile = listImagesContribute;
        break;
      case TypeContribute.PrimaryAddressCompany:
        formattedValues.contribution_primary_address_company_profile = listImagesContribute;
        break;
      case TypeContribute.AlternativeAddressCompany:
        formattedValues.contribution_alternative_address_company_profile = listImagesContribute;
        break;
      default:
        break;
    }
    return formattedValues;
  };

  return (
    <ModalComponent
      padding={4}
      pt={10}
      handleClose={handleClose}
      open={open}
      closeOnBackdropClick={false}
      disabledCloseIcon={formik.isSubmitting}
      showCloseIcon={true}
      headerAlert={
        <StyledHeaderAlert id="form-contribute-header-alert" direction="row" alignItems="center">
          <Box src={GuardContributeImg} component="img" width={24} mr={1} />
          <Typography variant="caption">Your personal information will remain</Typography>
          <Button variant="text" color="success">
            100% CONFIDENTIAL
          </Button>
        </StyledHeaderAlert>
      }
    >
      <FormikForm formik={formik}>
        <Box width={540}>
          <Typography textAlign={'center'} variant="h5">
            Contribute Data
          </Typography>
          <Typography textAlign={'center'} color={theme.palette.text.secondary} variant="body2">
            Please fill in the information in the form, we will confirm via your email.
          </Typography>

          <Stack direction={'column'} spacing={2.5} mt={4}>
            <TextField
              type="select"
              id="type_contribute"
              name="type_contribute"
              value={formik.values.type_contribute}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              select
              fullWidth
              label="Type Contribute"
              error={Boolean(formik.touched.type_contribute && formik.errors.type_contribute)}
              helperText={Boolean(formik.touched.type_contribute) && formik.errors.type_contribute}
            >
              {/* Cannot select option which has special form, ex: Review, Report wrong */}
              {TYPE_CONTRIBUTE.filter((type) => !type.disabled).map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControl>
              <Autocomplete
                clearIcon={false}
                disabled
                options={[]}
                freeSolo
                multiple
                renderTags={(value) => value.map((option, index) => <Chip key={option + index} label={option} />)}
                value={[`${getCurrentUrl()}`]}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    size="small"
                    fullWidth
                    label={
                      <span>
                        Profile Contribute (URL){' '}
                        <TooltipFormLabel title="This is the Profile (URL) you will contribute to" />
                      </span>
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                clearIcon={false}
                disabled
                options={[]}
                freeSolo
                multiple
                renderTags={(value) => value.map((option, index) => <Chip key={option + index} label={option} />)}
                value={[companyData?.name || '']}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    disabled
                    size="small"
                    fullWidth
                    label={
                      <span>
                        Company <TooltipFormLabel title="This is the company profile you are viewing" />
                      </span>
                    }
                  />
                )}
              />
            </FormControl>
            {Array(countData)
              .fill(null)
              .map((_, count) => {
                return (
                  <StyledBoxData key={count}>
                    {count > 0 && (
                      <StyledIconDelete size="small" onClick={() => removeData(count)}>
                        <Clear fontSize="small" />
                      </StyledIconDelete>
                    )}
                    {formik.values.type_contribute === TypeContribute.ClaimCompanyProfile && <ClaimCompany />}
                    {formik.values.type_contribute === TypeContribute.Email && <EmailProfile indexData={count} />}
                    {formik.values.type_contribute === TypeContribute.WebsiteCompanyProfile && (
                      <WebsiteProfile indexData={count} />
                    )}
                    {formik.values.type_contribute === TypeContribute.OtherNames && <OtherNames indexData={count} />}
                    {formik.values.type_contribute === TypeContribute.RelatedCorporate && (
                      <RelatedCorporate indexData={count} />
                    )}
                    {formik.values.type_contribute === TypeContribute.ContactInformation && (
                      <ContactInformation indexData={count} />
                    )}
                    {formik.values.type_contribute === TypeContribute.SocialMedia && <SocialMedia indexData={count} />}
                    {formik.values.type_contribute === TypeContribute.RegisteredAddressCompany && (
                      <RegisteredAddress listImage={listImage} handleChooseImages={handleChooseImages} />
                    )}
                    {formik.values.type_contribute === TypeContribute.PrimaryAddressCompany && (
                      <PrimaryAddress listImage={listImage} handleChooseImages={handleChooseImages} />
                    )}
                    {formik.values.type_contribute === TypeContribute.AlternativeAddressCompany && (
                      <AlternativeAddress listImage={listImage} handleChooseImages={handleChooseImages} />
                    )}
                  </StyledBoxData>
                );
              })}
            {TYPE_CONTRIBUTE_ARRAY.includes(formik.values.type_contribute) && (
              <Box textAlign="center">
                <Button
                  onClick={() => setCountData(countData + 1)}
                  variant="outlined"
                  color="secondary"
                  startIcon={<AddOutlined />}
                >
                  Add more
                </Button>
              </Box>
            )}

            <TextField
              type="select"
              id="relation_to_data"
              name="relation_to_data"
              value={formik.values.relation_to_data}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              select
              fullWidth
              required
              placeholder="Select option"
              label={
                <span>
                  Relationship to Data
                  <TooltipFormLabel title="Relationship to Data" />
                </span>
              }
              error={Boolean(formik.touched.relation_to_data && formik.errors.relation_to_data)}
              helperText={Boolean(formik.touched.relation_to_data) && formik.errors.relation_to_data}
            >
              {RELATIONSHIP_DATA.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="text"
              id="note"
              name="note"
              value={formik.values.note}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              fullWidth
              placeholder="Please describe detail hereSelect option"
              label={<span>Your Note To Change Data</span>}
              multiline
              rows={4}
              error={Boolean(formik.touched.note && formik.errors.note)}
              helperText={Boolean(formik.touched.note) && formik.errors.note}
            />
            <Box>
              <FormControlLabel
                id="is_confirm"
                name="is_confirm"
                onChange={(e, value) => {
                  formik.setFieldValue('is_confirm', value);
                }}
                value={formik.values.is_confirm}
                control={<Checkbox checked={formik.values.is_confirm} />}
                label={
                  <Typography color={theme.palette.text.secondary} variant="body1">
                    I certify that I am authorized to prove the above information
                  </Typography>
                }
              />
              {formik.touched.is_confirm && formik.errors.is_confirm && (
                <FormHelperText error>{formik.errors.is_confirm}</FormHelperText>
              )}
            </Box>
            <ButtonWithLoading
              fullWidth
              size="large"
              variant="contained"
              isRounded
              type="submit"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Submit
            </ButtonWithLoading>
          </Stack>
        </Box>
      </FormikForm>
    </ModalComponent>
  );
};
export default FormContribute;
