import { Box, Stack, Typography, useTheme, styled } from '@mui/material';
import { LINK_SUPPORT, PRODUCT_ROADMAP } from 'src/utils/constants/config';
import { ICON_SUPPORT_NO_DATA_COMPANY } from 'src/utils/constants/imagesUrl';

// Utilities

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  margin: '0px 3px',
  textDecoration: 'underline',
}));

const NoDataWebDetail = ({ action }: { action: () => void }) => {
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={2} margin={3}>
      <Typography variant="h4">ADAMftd Search Results Notification</Typography>
      <Typography variant="body1" color="text.secondary">
        We are actively retrieving the most up-to-date information for your search. This process may take a moment. If
        the page remains empty, please try refreshing it after a few moments. If the information you're looking for is
        still not available,{' '}
        <Typography component="span" color="secondary" onClick={() => action?.()} sx={{ textDecoration: 'underline' }}>
          consider contributing
        </Typography>{' '}
        data to enhance our database.
      </Typography>
      <Stack
        direction="column"
        gap={1}
        padding={2}
        alignItems="flex-start"
        position="relative"
        bgcolor={theme.palette.success.light}
        borderRadius={theme.shape.borderRadius / 2}
      >
        <Typography variant="h6">Need assistance with an issue? </Typography>
        <Typography variant="body1">
          Visit our{' '}
          <Link href={LINK_SUPPORT} target="_blank">
            support
          </Link>{' '}
          platform. You might also like to check out our{' '}
          <Link href={PRODUCT_ROADMAP} target="_blank">
            development roadmap
          </Link>
          .
        </Typography>
        <Box
          component="img"
          height="100px"
          src={ICON_SUPPORT_NO_DATA_COMPANY}
          sx={{ position: 'absolute', top: theme.spacing(-3), right: theme.spacing(2) }}
        />
      </Stack>
    </Stack>
  );
};
export default NoDataWebDetail;
