import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

// Components
import { isEmpty } from 'lodash';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import DataTable from 'src/features/CompanyDetail/Common/DataTable';
import { EmptyText, FORMAT_DATE_TIME } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { getCompanyAgeText } from 'src/utils/helpers/stringHelpers';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';

export default function GeneralInformation({ dataCompany }: { dataCompany: ICompanyDetail }) {
  const { handleOpenContribute } = useCompanyContribute((state) => state);

  const listData = [
    dataCompany?.company_type
      ? {
          key: 'company_type',
          title: 'Company Type',
          value: (dataCompany?.company_type || '').toUpperCase() || EmptyText,
        }
      : null,
    dataCompany?.agent_name
      ? {
          key: 'agent_name',
          title: 'Agent Name',
          value: dataCompany?.agent_name || EmptyText,
        }
      : null,
    dataCompany?.jurisdiction
      ? {
          key: 'jurisdiction',
          title: 'Jurisdiction',
          value: dataCompany?.jurisdiction || EmptyText,
        }
      : null,
    dataCompany?.lei
      ? {
          key: 'lei',
          title: 'LEI',
          value: dataCompany?.lei || EmptyText,
        }
      : null,
    dataCompany?.date_incorporated
      ? {
          key: 'date_incorporated',
          title: 'Date Incorporated',
          value: formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, dataCompany.date_incorporated || ''),
        }
      : null,
    dataCompany?.paid_up_capital
      ? {
          key: 'paid_up_capital',
          title: 'Paid Up Capital',
          value: dataCompany?.paid_up_capital ?? EmptyText,
        }
      : null,
    dataCompany?.authorised_capital
      ? {
          key: 'authorized_capital',
          title: 'Authorized Capital',
          value: dataCompany?.authorised_capital ?? EmptyText,
        }
      : null,
    dataCompany?.date_struck_off
      ? {
          key: 'date_struck_off',
          title: 'Date Struck Off',
          value: formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, dataCompany.date_struck_off || ''),
        }
      : null,
    dataCompany?.company_age
      ? {
          key: 'company_age',
          title: 'Company Age',
          value: getCompanyAgeText(dataCompany?.company_age),
        }
      : null,
    dataCompany?.number_of_employees
      ? {
          key: 'number_employees',
          title: 'Number of Employees',
          value: dataCompany?.number_of_employees || EmptyText,
        }
      : null,
    dataCompany?.registered_address
      ? {
          key: 'registered_address',
          title: 'Registered Address',
          value: (dataCompany?.registered_address || '').toUpperCase() || EmptyText,
        }
      : null,
    !isEmpty(dataCompany?.phone_numbers)
      ? {
          key: 'phone',
          title: 'Phone',
          value: !isEmpty(dataCompany?.phone_numbers)
            ? getArrayDefault(dataCompany?.phone_numbers).join(', ')
            : EmptyText,
        }
      : null,
    !isEmpty(dataCompany?.fax_numbers)
      ? {
          key: 'fax',
          title: 'Fax',
          value: !isEmpty(dataCompany?.fax_numbers) ? getArrayDefault(dataCompany?.fax_numbers).join(', ') : EmptyText,
        }
      : null,
    !isEmpty(dataCompany?.emails)
      ? {
          key: 'email',
          title: 'Email',
          value: (
            <Stack direction="row" spacing={1} alignItems="center">
              <>{!isEmpty(dataCompany?.emails) ? getArrayDefault(dataCompany?.emails).join(', ') : EmptyText}</>
              <Tooltip title="Contribute data">
                <IconButton size="small" onClick={() => handleOpenContribute(TypeContribute.Email)}>
                  <AddCircleOutlineOutlined color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        }
      : null,
    !isEmpty(dataCompany?.websites)
      ? {
          key: 'website',
          title: 'Website',
          value: (
            <Stack direction="row" spacing={1} alignItems="center">
              <>{!isEmpty(dataCompany?.websites) ? getArrayDefault(dataCompany?.websites).join(', ') : EmptyText}</>
              <Tooltip title="Contribute data">
                <IconButton size="small" onClick={() => handleOpenContribute(TypeContribute.WebsiteCompanyProfile)}>
                  <AddCircleOutlineOutlined color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        }
      : null,
  ];
  return (
    <React.Fragment>
      <Typography variant="h5" align="left">
        General Information
      </Typography>
      <DataTable listData={listData} />
    </React.Fragment>
  );
}
