import { InputAdornment, Pagination, Stack, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { Search } from 'react-feather';

// Components
import FormikForm from 'src/components/common/FormikForm';
import IconFeather from 'src/components/common/Icon';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { CompanyDetail } from 'src/model/company';
import { TRADEMARK_JSON } from 'src/utils/constants/trademark';
import { getTrademarkIds } from 'src/utils/helpers/trademark';
import FormItemCountry from 'src/components/Form/FormItemCountry';
import ListCompanyTrademark from './ListCompanyTrademark';
import { useState } from 'react';

interface IProps {
  company: CompanyDetail;
}
const DUMP_DATA = [
  {
    id: '1',
    name: 'intelliAd',
    owner: 'diva-e Next GmbH (Germany)',
    ipr: 'National Trademark Registration',
    registration_number: '302009035256',
    logo_url: null,
    country: 'DE',
    is_marked: true,
    date_incorporated: '03-08-2009T00:00:00Z',
    classes: ['42', '9', '35', '38'],
    status: 'Active',
    status_code: 0,
  },
  {
    id: '2',
    name: 'IntelliAd',
    // owner:
    //   'INTEGRATED INSURANCE SOLUTIONS LIMITED, a body corporate and politic, duly incorporated according to law (Canada)',
    owner: 'INTEGRATED INSURANCE SOLUTIONS LIMITED,...',
    ipr: 'National Trademark Registration',
    registration_number: '1320244',
    logo_url: null,
    country: 'CA',
    is_marked: true,
    date_incorporated: '08-04-2024T00:00:00Z',
    classes: ['35', '42'],
    status: 'Ended',
    status_code: 3,
  },
  {
    id: '3',
    name: 'intelliAd',
    owner: 'diva-e Next GmbH (Germany)',
    ipr: 'diva-e Next GmbH (Germany)',
    registration_number: '302019024997',
    logo_url: null,
    country: 'DE',
    is_marked: true,
    date_incorporated: '16-12-2019T00:00:00Z',
    classes: ['42', '9', '38'],
    status: 'Registered',
    status_code: 0,
  },
];

const TradeMark = ({ company }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selected, setSelected] = useState(undefined as undefined | ICompanyTrademark);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country_code: '',
    },
    validationSchema: Yup.object().shape({
      country_code: Yup.string().max(255),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });
  const ids = getTrademarkIds();
  const location = get(TRADEMARK_JSON, company.data.country || '');

  const params = {
    _id: ids[0],
    boolean: 'AND',
    bricks: [
      {
        _id: ids[1],
        key: 'applicant',
        strategy: 'Simple',
        value: company.data.name,
      },
      {
        _id: ids[2],
        key: 'applicant_cc',
        strategy: 'all_of',
        value: [location],
      },
    ],
  };

  const timestamp = new Date().getTime();
  const encodedParams = encodeURIComponent(JSON.stringify(params));

  return (
    <StyledWhiteBox>
      <Typography textAlign={'left'} mb={2} variant="h5">
        Company Trademark
      </Typography>
      <FormikForm formik={formik}>
        <Stack spacing={2} direction="row" mb={3}>
          <Box display="flex" flex={1} maxWidth="70%">
            <TextField
              placeholder="Search trademark"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconFeather icon={<Search />} muiProps={{ color: 'disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box display="flex" flex={1} maxWidth="30%">
            <FormItemCountry id="country_code" label="Filter By Country" />
          </Box>
        </Stack>
      </FormikForm>
      <ListCompanyTrademark
        listCompany={DUMP_DATA}
        onSelect={(company) => {
          setSelected(company);
        }}
      />
      <Stack mt={2} alignItems="center">
        <Pagination
          page={1}
          count={10}
          // onChange={function (_: ChangeEvent<unknown>, value: number): void {
          //   setSearchParams((searchParams) => {
          //     searchParams.set('offset', String(value - 1));
          //     return searchParams;
          //   });
          // }}
          color="primary"
          shape="rounded"
          size="small"
        />
      </Stack>
      <Box overflow={'hidden'}>
        <Box mt={-55}>
          <iframe
            frameBorder={0}
            height={1000}
            width={'100%'}
            src={`https://branddb.wipo.int/en/advancedsearch/results?sort=score%20desc&strategy=concept&rows=30&asStructure=${encodedParams}&_=${timestamp}&fg=_void`}
          />
        </Box>
      </Box>
    </StyledWhiteBox>
  );
};

export default TradeMark;
