import { get } from 'lodash';
import { Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';

// Components
import { IPropsDataContribute } from 'src/features/CompanyDetail/Common/config';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';

const ClaimCompany = () => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<IPropsDataContribute>();

  return (
    <Stack spacing={2} flex={1}>
      <TextField
        required
        id={`contribution_claim_your_company_profile.company_email`}
        name={`contribution_claim_your_company_profile.company_email`}
        value={get(values.contribution_claim_your_company_profile, 'company_email', '')}
        size="small"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        error={Boolean(
          touched.contribution_claim_your_company_profile?.company_email &&
            errors.contribution_claim_your_company_profile?.company_email,
        )}
        helperText={
          Boolean(touched.contribution_claim_your_company_profile?.company_email) &&
          errors.contribution_claim_your_company_profile?.company_email
        }
        label={
          <span>
            Company Email <TooltipFormLabel title="Company Email" />
          </span>
        }
      />
    </Stack>
  );
};

export default ClaimCompany;
