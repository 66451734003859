import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Box, Chip, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Briefcase } from 'react-feather';

// Components';
import IconFeather from 'src/components/common/Icon';
import TextWithTooltip from 'src/components/common/TextWithTooltip';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { getColorPercentMatch } from 'src/utils/helpers/stringHelpers';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';

// Utilities

export default function OtherNames({ dataCompany }: { dataCompany: ICompanyDetail }) {
  const { handleOpenContribute } = useCompanyContribute((state) => state);

  const listTraceDocument = useMemo(() => {
    return getArrayDefault(dataCompany?.other_names).find((name) => Object.keys(name || {}).includes('trade_document'));
  }, [dataCompany?.other_names]);

  const listCompanyKnownAs = useMemo(() => {
    return getArrayDefault(dataCompany?.other_names).find((name) =>
      Object.keys(name || {}).includes('company_known_as'),
    );
  }, [dataCompany?.other_names]);

  if (isEmpty(listCompanyKnownAs) && isEmpty(listTraceDocument)) {
    return <></>;
  }

  return (
    <StyledWhiteBox>
      <Stack spacing={1}>
        <Typography variant="h5" align="left">
          Other Names
          <TextWithTooltip title="Other Names" />
        </Typography>
        <Stack direction={'row'} spacing={3} divider={<Divider orientation="vertical" flexItem />}>
          <Box flex={1}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body2" component={'p'} align="left">
                This company also appears in trade documents under the following names with a
              </Typography>
            </Stack>
            {getArrayDefault(listTraceDocument?.trade_document).map((company, index) => (
              <Stack key={`company-trace-document-${index}`} spacing={2} direction="row" alignItems="center" mb={1}>
                <IconFeather icon={<Briefcase />} muiProps={{ fontSize: 'small' }} />
                <Typography variant="body2" align="left">
                  {company?.name ?? EmptyText}
                </Typography>
                <Chip
                  label={`${company?.match_rate ?? 0}% data match`}
                  color={getColorPercentMatch(company?.match_rate ?? 0)}
                />
              </Stack>
            ))}
            <Stack spacing={2} direction="row" alignItems="center" mb={1}>
              <IconFeather icon={<Briefcase />} muiProps={{ fontSize: 'small' }} />
              <Tooltip title="Contribute data">
                <IconButton size="small" onClick={() => handleOpenContribute(TypeContribute.OtherNames)}>
                  <AddCircleOutlineOutlined color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
          <Box flex={1}>
            <Typography variant="subtitle2" component={'p'} align="left">
              This company may also be known as the below (AI match)
            </Typography>
            {getArrayDefault(listCompanyKnownAs?.company_known_as).map((company, index) => (
              <Stack key={`company-know-${index}`} spacing={2} direction="row" alignItems="center" mb={1}>
                <IconFeather icon={<Briefcase />} muiProps={{ fontSize: 'small' }} />
                <Typography variant="body2" align="left">
                  {company}
                </Typography>
              </Stack>
            ))}
            <Stack spacing={2} direction="row" alignItems="center" mb={1}>
              <IconFeather icon={<Briefcase />} muiProps={{ fontSize: 'small' }} />
              <Tooltip title="Contribute data">
                <IconButton size="small" onClick={() => handleOpenContribute(TypeContribute.OtherNames)}>
                  <AddCircleOutlineOutlined color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </StyledWhiteBox>
  );
}
