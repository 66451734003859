import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { ReactNode, useMemo } from 'react';
import PaperSelect from 'src/components/common/PaperSelect';
import { CONSTANT_SETTING_TYPES } from 'src/services/api/config';
import { useQueryConstantSetting } from 'src/services/queries/useQueryUtilService';
import {uniqBy} from 'lodash'

interface IProps {
  disabled?: boolean;
  required?: boolean;
  id: string;
  label: ReactNode;
  type: CONSTANT_SETTING_TYPES;
  error?: boolean;
}

const FormItemConstantSetting = ({ disabled, id, label, required, type, error }: IProps) => {
  const { data, isFetching } = useQueryConstantSetting({ type: type });

  const { setFieldValue, values, handleBlur } = useFormikContext();

  const processedList = useMemo(() => {
    return uniqBy(data.data, 'key')
  }, [data.data])

  return (
    <Autocomplete
      onBlur={handleBlur}
      value={{
        key: get(values, id, ''),
        id: 0,
        value: get(values, id, ''),
        type: '',
      }}
      onChange={(_, value) => {
        setFieldValue(id, value?.value);
      }}
      loading={isFetching}
      autoComplete
      disabled={disabled}
      id={id}
      fullWidth
      size="small"
      options={processedList}
      PaperComponent={PaperSelect}
      getOptionLabel={(o) => o.key}
      getOptionKey={(o) => o.value}
      renderInput={(params) => <TextField {...params} error={error} required={required} label={label} />}
    />
  );
};

export default FormItemConstantSetting;
