import { useMemo } from 'react';
import { TextField, Stack, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { get, isArray } from 'lodash';

// Components
import { IPropsDataContribute } from 'src/features/CompanyDetail/Common/config';

const OtherNames = ({ indexData }: { indexData: number }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<IPropsDataContribute>();
  const errorsObject = useMemo(() => {
    if (isArray(errors.contribution_other_names_company_profile)) {
      return { other_name: get(errors.contribution_other_names_company_profile?.[indexData], 'other_name', '') };
    }

    return {
      other_name: '',
    };
  }, [errors, touched]);

  return (
    <Stack spacing={0.5} flex={1}>
      <TextField
        required
        id={`contribution_other_names_company_profile[${indexData}].other_name`}
        name={`contribution_other_names_company_profile[${indexData}].other_name`}
        value={get(values.contribution_other_names_company_profile, `[${indexData}].other_name`, '')}
        size="small"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        label={<span>Company Other Name Contribute</span>}
        error={Boolean(
          touched.contribution_other_names_company_profile?.[indexData]?.other_name && errorsObject?.other_name,
        )}
      />
      {touched.contribution_other_names_company_profile?.[indexData]?.other_name && (
        <FormHelperText error>{errorsObject?.other_name}</FormHelperText>
      )}
    </Stack>
  );
};

export default OtherNames;
