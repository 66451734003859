import { CountryTariffs, ProductTariffs, NtmMeasures, CustomDuties, TradeRemedy, Fta, RooFta } from 'src/model/tariffs';
import { getRequest } from 'src/services/api/http';
import { URL_ENDPOINT } from 'src/utils/constants/service';

function getCountryTariffs() {
  const url = URL_ENDPOINT.company.tariffs + 'countries';
  return getRequest(url, {}, new CountryTariffs()) as Promise<CountryTariffs>;
}

function searchHsCodeTariffs({ countryCode, level, keyword }: { countryCode: string; level: number; keyword: string }) {
  const url = URL_ENDPOINT.company.tariffs + 'products-by-keyword';
  return getRequest(url, { countryCode, level, keyword }, new ProductTariffs()) as Promise<ProductTariffs>;
}

function searchNationalCodeTariffs({
  countryCode,
  level,
  hsCode,
}: {
  countryCode: string;
  level: number;
  hsCode: string;
}) {
  const url = URL_ENDPOINT.company.tariffs + 'ntlc-products';
  return getRequest(url, { countryCode, level, code: hsCode }, new ProductTariffs()) as Promise<ProductTariffs>;
}

function getNtmMeasures({ reporter, partner, product }: { reporter: string; partner: string; product: string }) {
  const url = URL_ENDPOINT.company.tariffs + 'ntm-measures';
  return getRequest(url, { reporter, partner, product }, new NtmMeasures()) as Promise<NtmMeasures>;
}

function getCustomDuties({ reporter, partner, product }: { reporter: string; partner: string; product: string }) {
  const url = URL_ENDPOINT.company.tariffs + 'custom-duties';
  return getRequest(url, { reporter, partner, product }, new CustomDuties()) as Promise<CustomDuties>;
}

function getTradeRemedy({ reporter, partner, product }: { reporter: string; partner: string; product: string }) {
  const url = URL_ENDPOINT.company.tariffs + 'trade-remedy';
  return getRequest(url, { reporter, partner, product }, new TradeRemedy()) as Promise<TradeRemedy>;
}

function getRegimeCountryTariffs({ reporter, agId }: { reporter: string; agId: number }) {
  const url = URL_ENDPOINT.company.tariffs + 'tariff-regime-country-list';
  return getRequest(url, { reporter, agId, year: 'latest' }, new CountryTariffs()) as Promise<CountryTariffs>;
}

function getFtaTariffs({
  reporter,
  partner,
  product,
  ftaId,
}: {
  reporter: string;
  partner: string;
  product: string;
  ftaId: number;
}) {
  const url = URL_ENDPOINT.company.tariffs + 'fta';
  return getRequest(url, { reporter, partner, product, ftaId }, new Fta()) as Promise<Fta>;
}

function getRooFtaTariffs({ reporter, partner, ftaId }: { reporter: string; partner: string; ftaId: number }) {
  const url = URL_ENDPOINT.company.tariffs + 'roo-by-fta';
  return getRequest(url, { reporter, partner, ftaId }, new RooFta()) as Promise<RooFta>;
}

const TariffsService = {
  getCountryTariffs,
  searchHsCodeTariffs,
  searchNationalCodeTariffs,
  getNtmMeasures,
  getCustomDuties,
  getTradeRemedy,
  getRegimeCountryTariffs,
  getFtaTariffs,
  getRooFtaTariffs,
};

export default TariffsService;
