/* eslint-disable no-unused-vars */
export enum ErrorRouteKeys {
  Root = '/error',
}

export enum AuthRouteKeys {
  Home = '/',
  Onboarding = '/onboarding',
  Bookmark = '/bookmark',
  AccountProfile = '/account/profile',
  NewsFeed = '/community/news_feed',
  MyCompany = '/community/my_company',
  MyGroups = '/community/my_groups',
  MyContacts = '/community/my_contacts',
  CompanyProfiles = '/company/profiles',
  CompanyDetail = '/company/profile/:id?',
  PeopleProfiles = '/people/profiles',
  PeopleDetail = '/people/profile/:id?',
  CompareCompany = '/compare_company',
  TariffsAndRules = '/tariff_and_rule',
  Setting = '/setting',
  TradeData = '/trade_data',
  BillOfLadingDetail = '/company/bill_of_lading/:id?',
  TradeDataDetail = '/trade_data/trade_data_detail/:id?',
}

export enum NoAuthRouteKeys {
  Login = '/login',
  SignUp = '/signup',
  ForgotPassword = '/forgot_password',
  TwoStepVerification = '/two_step_verification',
  ResetPassword = '/reset_password',
  TermAndCondition = '/term_conditions',
  GeneralDisclaimer = 'genera_disclaimer',
}
