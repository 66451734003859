import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps, Theme, Tooltip } from '@mui/material';

const TooltipFormLabel = ({
  title,
  placement,
  sx = {},
  color,
}: {
  title: string;
  sx?: SxProps<Theme>;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  color?: 'action' | 'disabled' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}) => {
  return (
    <Tooltip arrow placement={placement || 'top'} title={title} sx={{ ...sx }}>
      <InfoOutlinedIcon color={color ?? 'action'} sx={{ fontSize: 16 }} />
    </Tooltip>
  );
};

export default TooltipFormLabel;
