import { DesktopMacRounded } from '@mui/icons-material';
import { Chip, Grid, MenuItem, Popover, Stack, Typography, useTheme } from '@mui/material';
import { capitalize } from 'lodash';
import { useId, useState } from 'react';
import { LogOut } from 'react-feather';
import { useTranslation } from 'react-i18next';
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import Table from 'src/components/Table';
import PopoverAction from 'src/components/Table/PopoverAction';
import { TypeTabSetting } from 'src/features/Setting/config';
import { useNotification } from 'src/hooks/useNotification';
import { useMutateLogoutUserDevice } from 'src/services/mutate/useMutateAuth';
import { useQueryDeviceManger } from 'src/services/queries/useQueryAuth';
import { FORMAT_DATE_TIME, LIMIT_GET_LIST } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { formatUTCTime } from 'src/utils/helpers/dayjs';

const DeviceManager = () => {
  const theme = useTheme();
  const id = useId();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { mutateAsync } = useMutateLogoutUserDevice();
  const { notification } = useNotification();
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: LIMIT_GET_LIST,
    offset: 0,
  });

  const { isFetching, data, refetch } = useQueryDeviceManger(params);

  const deviceMangerColumn: ConfigCell<IDeviceManger>[] = [
    {
      disablePadding: false,
      id: 'device',
      label: 'Devices Type',
      cell: (data: IDeviceManger) => {
        return (
          <Stack spacing={1}>
            <Typography display={'flex'} gap={1} color={theme.palette.text.secondary} variant="body2">
              <DesktopMacRounded sx={{ fontSize: '20px' }} /> {data.device_type} - {data.device_name}
            </Typography>
            <Stack direction={'row'} gap={1}>
              <FlagCountry width={16} code={data.location} />
              <Typography color={'text.secondary'} variant="caption">
                {data.location}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      disablePadding: false,
      id: 'status',
      label: 'Status',
      cell: (data: IDeviceManger) => {
        return (
          <Chip
            label={capitalize(data.is_online)}
            size="small"
            color={data.is_online === 'online' ? 'success' : 'default'}
          />
        );
      },
    },
    {
      disablePadding: false,
      id: 'ip_address',
      label: 'IP Address',
      cell: (data: IDeviceManger) => {
        return (
          <Typography color={theme.palette.text.secondary} variant="body2">
            {data.ip_address}
          </Typography>
        );
      },
    },
    {
      disablePadding: false,
      id: 'last_seen_time',
      label: 'Date & Time',
      cell: (data: IDeviceManger) => {
        return (
          <Stack direction={'row'} gap={1}>
            <Typography color={theme.palette.text.secondary} variant="body2">
              {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, data.last_seen_time)}
            </Typography>
            <Typography color={theme.palette.text.secondary} variant="body2">
              {formatUTCTime(FORMAT_DATE_TIME.H_MM_A, data.last_seen_time)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      disablePadding: false,
      id: 'action',
      label: 'Action',
      cell: (data: IDeviceManger) => {
        return (
          <PopoverAction
            primaryTypographyProps={{ color: 'suggestion' }}
            id="asdf"
            listAction={[
              {
                icon: <IconFeather muiProps={{ fontSize: 'small', color: 'inherit' }} icon={<LogOut />} />,
                text: 'Log out',
                action: () => onLogout(data),
              },
            ]}
          />
        );
      },
    },
  ];

  const onLogout = async (record: IDeviceManger) => {
    const res = await mutateAsync(record.id.toString());
    if (res.status_code === SUCCESS_CODE.GET) {
      refetch();
    }
    notification(
      res,
      {
        title: t('setting.device_manager.notification.logout.success.title'),
        description: t('setting.device_manager.notification.logout.success.description'),
      },
      {
        title: t('setting.device_manager.notification.logout.error.title'),
        description: t('setting.device_manager.notification.logout.error.description'),
      },
    );
  };

  const renderPopupAction = (
    <Popover
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
        }}
        dense
        sx={{ py: 2, px: 3, display: 'flex', gap: 1, color: theme.palette.text.secondary }}
      >
        <IconFeather muiProps={{ fontSize: 'small' }} icon={<LogOut />} />
        Log out
      </MenuItem>
    </Popover>
  );

  return (
    <Grid container gap={2}>
      {renderPopupAction}

      <Grid xs={12} item>
        <Typography variant="subtitle1">Where you’re logged in</Typography>
        <Typography variant="caption" color={'text.secondary'}>
          We detected that you logged in via the devices listed below. If any of these are not you, please disconnect
          and change your password immediately.
        </Typography>
      </Grid>

      <Grid xs={12} item>
        <Table
          loading={isFetching}
          onChangePage={(e) => {
            setParams({ ...params, ...{ offset: e } });
          }}
          onChangePageSize={(e) => setParams({ ...params, ...{ limit: e } })}
          pageSize={data.page_size}
          page={data.page}
          data={data.data}
          totalRecords={data?.total_records || 0}
          configColumn={deviceMangerColumn}
          dataKey={'id'}
        />
      </Grid>

      <Grid xs={12} item>
        <Typography justifyContent={'center'} display={'flex'} gap={0.5} variant="body2">
          Did you find a stranger logged in? Please
          <Typography color={'secondary.main'} variant="body2">
            <RouterLinkNoUnderline to={{ pathname: AuthRouteKeys.Setting, search: 'tab=' + TypeTabSetting.password }}>
              change your password
            </RouterLinkNoUnderline>
          </Typography>
          to stay protected
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeviceManager;
