export class ConstantSettings implements IResponse<IConstantSetting[]> {
  message?: string | undefined;
  status_code: number;
  warning?: string;
  detail?: string;
  errors?: ExpectedAny;
  data: IConstantSetting[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}
export class ImageUpload implements IResponse<IImageUpload> {
  message?: string | undefined;
  status_code: number;
  data: IImageUpload;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {
      request_id: '',
    };
  }
}

export class ImageContributeUpload implements IResponse<IImageContributeUpload[]> {
  message?: string | undefined;
  status_code: number;
  data: IImageContributeUpload[];
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = [];
  }
}
