import StarIcon from '@mui/icons-material/Star';
import { Box, Rating, Stack, styled, Typography, useTheme } from '@mui/material';
import { find } from 'lodash';

// Components

// Utilities
// import RatingYelp from 'src/asset/icons/rating-yelp.svg';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import TrustpilotStarIcon from 'src/components/Icons/TrustpilotStar';
import { useQueryCompanySocialReview } from 'src/services/queries/useQueryCompany';
import { CompanyReviewType } from 'src/utils/constants/enum';
import {
  LOGO_GOOGLE_URL,
  LOGO_TRUSTPILOT_URL,
  // LOGO_YELP_URL
} from 'src/utils/constants/imagesUrl';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

const StyleBox = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.common.white,
  border: '1px solid #D9D9F9',
}));

interface IProps {
  id?: string;
}

const SocialReview = ({ id }: IProps) => {
  const { data, isFetching } = useQueryCompanySocialReview({ id: id });

  const googleReview = find(data.data, (o: CompanySocialReview) => o.review_type === CompanyReviewType.Google);
  const trustpilotReview = find(data.data, (o: CompanySocialReview) => o.review_type === CompanyReviewType.Trustpilot);

  const theme = useTheme();

  if (isFetching) {
    return (
      <Stack direction="row" spacing={2}>
        <StyleBox direction="row" flex={1}>
          <LoadingSkeleton width={'100%'} count={1} height={80} />
        </StyleBox>
        <StyleBox direction="row" flex={1}>
          <LoadingSkeleton width={'100%'} count={1} height={80} />
        </StyleBox>
        <StyleBox direction="row" flex={1}>
          <LoadingSkeleton width={'100%'} count={1} height={80} />
        </StyleBox>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <StyleBox direction="row" flex={1}>
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
          p={1}
          sx={{
            bgcolor: '#4285F4',
            borderBottomLeftRadius: theme.shape.borderRadius * 2,
            borderTopLeftRadius: theme.shape.borderRadius * 2,
          }}
        >
          <Typography variant="h4" color="common.white">
            {renderTextOrDefault(googleReview?.rating_value)}
          </Typography>
        </Box>
        <Box display="flex" flex={1} p={1} flexDirection="column" alignItems="center" borderRadius={8}>
          <Box src={LOGO_GOOGLE_URL} component="img" width={100} mr={1} />
          <Typography variant="body2" color="text.secondary">
            average rating
          </Typography>
          <Rating
            name="text-feedback"
            value={googleReview?.rating_value}
            readOnly
            size="small"
            precision={0.1}
            emptyIcon={<StarIcon fontSize="inherit" />}
          />
        </Box>
      </StyleBox>
      <StyleBox alignItems={'center'} direction="row" flex={1}>
        <Box display="flex" flex={1} justifyContent="center" alignItems="center" p={1} borderRadius={8}>
          <Box src={LOGO_TRUSTPILOT_URL} component="img" width={200} mr={1} />
        </Box>
        <Box display="flex" flex={1} p={1} flexDirection="column" alignItems="center" borderRadius={8}>
          {/* <Typography variant="subtitle1">Excellent</Typography> */}
          <Stack direction="row" spacing={0.25}>
            <Rating
              name="text-feedback"
              value={trustpilotReview?.rating_value}
              readOnly
              size="small"
              precision={0.1}
              emptyIcon={<StarIcon fontSize="inherit" />}
              icon={<TrustpilotStarIcon sx={{ fontSize: 'inherit' }} />}
            />
          </Stack>
          <Typography variant="body2" color="text.secondary">
            Based on {trustpilotReview?.total_reviews ?? 0} reviews
          </Typography>
        </Box>
      </StyleBox>
      {/* <StyleBox direction="row" flex={1}>
        <Box display="flex" flex={1} justifyContent="center" alignItems="center" p={1} borderRadius={8}>
          <Box src={LOGO_YELP_URL} component="img" width={150} mr={1} />
        </Box>
        <Box
          display="flex"
          flex={1}
          p={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderRadius={8}
        >
          <Stack direction="row" spacing={0.25}>
            <Box src={RatingYelp} component="img" width={12} />
            <Box src={RatingYelp} component="img" width={12} />
            <Box src={RatingYelp} component="img" width={12} />
            <Box src={RatingYelp} component="img" width={12} />
            <Box src={RatingYelp} component="img" width={12} />
          </Stack>
          <Typography variant="body2" color="text.secondary">
            Based on 200 reviews
          </Typography>
        </Box>
      </StyleBox> */}
    </Stack>
  );
};

export default SocialReview;
