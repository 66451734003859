import { SvgIcon, SvgIconProps } from '@mui/material';

const WechatIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.50493 3.12582C4.08869 3.0756 1.25 5.52485 1.25 8.41347C1.25 10.0809 2.0538 11.5274 3.60975 12.5894C3.60975 12.5894 3.03949 14.4255 3.0476 14.4623C3.05571 14.4992 3.17982 14.5716 3.22302 14.5479C3.26621 14.5243 5.28442 13.35 5.28442 13.35C6.73079 13.8344 7.61309 13.7152 7.76369 13.6949C7.77414 13.6935 7.78106 13.6925 7.78441 13.6923C7.63005 13.2514 7.5289 12.5647 7.64408 11.9175C8.24705 8.53054 11.8013 7.47697 13.7493 7.65696C13.2114 5.21091 10.8749 3.17533 7.50493 3.12582ZM5.13043 5.90582C4.68228 5.90582 4.31906 6.2603 4.31906 6.69762C4.31906 7.13494 4.68228 7.48941 5.13043 7.48941C5.57858 7.48941 5.94189 7.13494 5.94189 6.69762C5.94189 6.2603 5.57858 5.90582 5.13043 5.90582ZM10.0426 5.90582C9.59459 5.90582 9.23128 6.2603 9.23128 6.69762C9.23128 7.13494 9.59459 7.48941 10.0426 7.48941C10.4908 7.48941 10.8541 7.13494 10.8541 6.69762C10.8541 6.2603 10.4909 5.90582 10.0426 5.90582Z"
          fill="#31CE6F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.75 12.4329C18.75 9.95822 16.2971 8.06273 13.4515 8.06273C10.4385 8.06273 8.03537 10.0011 8.03537 12.4756C8.03537 14.9546 10.3211 17.0069 13.455 17.0237C14.0857 17.0272 15.0252 16.8689 15.6587 16.6107C15.6587 16.6107 17.1731 17.5066 17.2579 17.5C17.3427 17.4934 17.3861 17.4279 17.3895 17.3717C17.3929 17.3155 16.907 15.9171 16.907 15.9171C18.2074 14.9236 18.75 13.8288 18.75 12.4329ZM11.4684 10.116C11.0719 10.116 10.7505 10.4301 10.7505 10.8178C10.7505 11.2052 11.0719 11.5194 11.4684 11.5194C11.8649 11.5194 12.1862 11.2053 12.1862 10.8178C12.1862 10.4301 11.8649 10.116 11.4684 10.116ZM15.3071 10.116C14.9106 10.116 14.5892 10.4301 14.5892 10.8178C14.5892 11.2052 14.9106 11.5194 15.3071 11.5194C15.7036 11.5194 16.025 11.2053 16.025 10.8178C16.025 10.4301 15.7036 10.116 15.3071 10.116Z"
          fill="#31CE6F"
        />
      </svg>
    </SvgIcon>
  );
};

export default WechatIcon;
