import React from 'react';
import { Helmet } from 'react-helmet';
import SignUp from 'src/features/SignUp';

const SignUpPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <SignUp />
    </React.Fragment>
  );
};

export default SignUpPage;
