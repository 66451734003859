import { SimplePaletteColorOptions } from '@mui/material';
import { alpha, createTheme } from '@mui/material/styles';
import colorSystem from 'src/scss/_colors-system.module.scss';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    suggestion: true;
    commonBlackStatesMain: true;
  }
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    tooltipFill: SimplePaletteColorOptions;
    primaryStateHover: SimplePaletteColorOptions;
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

const Palette = () =>
  createTheme({
    palette: {
      common: {
        black: colorSystem.commonBlack,
        white: colorSystem.commonWhite,
      },
      primary: {
        light: colorSystem.ftdPrimary200,
        main: colorSystem.ftdPrimary500,
        dark: colorSystem.ftdPrimary600,
        contrastText: colorSystem.commonWhite,
      },
      secondary: {
        light: colorSystem.ftdSecondary200,
        main: colorSystem.ftdSecondary500,
        dark: colorSystem.ftdSecondary600,
        contrastText: colorSystem.commonWhite,
      },
      error: {
        light: colorSystem.red100,
        main: colorSystem.red600,
        dark: colorSystem.red700,
        contrastText: colorSystem.commonWhite,
      },
      warning: {
        light: colorSystem.orange100,
        main: colorSystem.orange500,
        dark: colorSystem.orange600,
        contrastText: colorSystem.commonWhite,
      },
      info: {
        light: colorSystem.blue100,
        main: colorSystem.blue500,
        dark: colorSystem.blue600,
        contrastText: colorSystem.commonWhite,
      },
      success: {
        light: colorSystem.green100,
        main: colorSystem.green500,
        dark: colorSystem.green600,
        contrastText: colorSystem.commonWhite,
      },
      inheritWhite: {
        main: colorSystem.commonWhite,
      },
      grey: {
        50: colorSystem.grey50,
        100: colorSystem.grey100,
        200: colorSystem.grey200,
        300: colorSystem.grey300,
        400: colorSystem.grey400,
        500: colorSystem.grey500,
        600: colorSystem.grey600,
        700: colorSystem.grey700,
        800: colorSystem.grey800,
        900: colorSystem.grey900,
      },
      text: {
        primary: colorSystem.blue950,
        secondary: colorSystem.grey500,
        disabled: colorSystem.neutroState300,
      },
      background: {
        paper: colorSystem.commonWhite,
        default: colorSystem.commonWhite,
        paper1: colorSystem.backgroundPaper1,
        paper2: colorSystem.backgroundPaper2,
      },
      action: {
        active: alpha(colorSystem.neutroState700, 0.56),
        hover: alpha(colorSystem.neutroState700, 0.15),
        disabled: alpha(colorSystem.neutroState700, 0.38),
        disabledBackground: alpha(colorSystem.neutroState700, 0.12),
        selected: alpha(colorSystem.neutroState700, 0.08),
      },
      divider: colorSystem.neutroState300,
      rating: {
        main: colorSystem.yellow400,
      },
      tabsIndicator: {
        primary: colorSystem.ftdPrimary500,
        secondary: colorSystem.ftdSecondary500,
      },
      avatar: {
        primary: colorSystem.commonWhite,
        secondary: colorSystem.neutroState500,
      },
      neutroState100: {
        primary: colorSystem.neutroState100,
      },
      suggestion: createColor(colorSystem.ftdPrimary300),
      commonBlackStatesMain: createColor(colorSystem.blue1000),
      mapChart: createColor(colorSystem.indigo700),
      tooltipFill: createColor(colorSystem.blue1000),
      primaryStateHover: createColor(alpha(colorSystem.ftdPrimary500, 0.07))
    },
  });

export default Palette;
