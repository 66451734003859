import { Skeleton, SkeletonProps } from '@mui/material';

interface IProps extends SkeletonProps {
  count: number;
}

const LoadingSkeleton = ({ count, ...others }: IProps) => {
  return (
    <>
      {new Array(count).fill(0).map((_, i) => (
        <Skeleton width={'inherit'} {...others} key={`LoadingSkeleton-${i}`} />
      ))}
    </>
  );
};

export default LoadingSkeleton;
