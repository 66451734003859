import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { useMemo } from 'react';
import iconSearch from 'src/asset/icons/icon-search.svg';
import PaperSelect from 'src/components/common/PaperSelect';
import { useQueryCompanyTypes } from 'src/services/queries/useQueryCompany';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { convertToStartCase } from 'src/utils/helpers/stringHelpers';

interface IProp {
  id: string;
  jurisdiction: string[] | IJurisdictions[];
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  value: string;
}

export const ALL_TYPE_VALUE = 'All type';

const FormItemCompanyType = ({ id, jurisdiction, disabled, value, error, required }: IProp) => {
  const { data, isFetching } = useQueryCompanyTypes(
    jurisdiction.map((item: string | IJurisdictions) =>
      typeof item === 'string' ? item : get(item, 'jurisdiction', ''),
    ),
  );
  const { setFieldValue, handleBlur } = useFormikContext<TypeCompanySearch>();

  const listOptions = useMemo(() => {
    if (jurisdiction.length > 0 && getArrayDefault(data?.data).length > 0) {
      return [ALL_TYPE_VALUE].concat(data.data.sort()).map((type) => convertToStartCase(type));
    }
    return [ALL_TYPE_VALUE];
  }, [jurisdiction, data]);

  return (
    <Autocomplete
      onBlur={handleBlur}
      value={value}
      onChange={(_, value) => {
        setFieldValue(id, value || '');
      }}
      loading={isFetching}
      autoComplete
      disabled={disabled}
      id={id}
      fullWidth
      size="small"
      options={listOptions}
      PaperComponent={PaperSelect}
      popupIcon={<Box component={'img'} src={iconSearch} />}
      renderInput={(params) => (
        <TextField {...params} required={required} error={error} label={<span>Company Type</span>} />
      )}
    />
  );
};

export default FormItemCompanyType;
