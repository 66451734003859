import { Box, Container, styled } from '@mui/material';
import { useState } from 'react';

// Components
import BreadcrumbWithBtnBack from 'src/components/BreadcrumbWithBtnBack';
import TabComponent from 'src/components/Tab/TabComponent';
import ComingSoon from 'src/features/CompanyDetail/Common/ComingSoon';
import TradeDataInformation from 'src/features/TradeData/Common/Information';
// import PortOfOriginTab from 'src/features/TradeData/PortOfOrigin';
// import PortDeliveryTab from 'src/features/TradeData/PortDelivery';
// import TradeDataTab from 'src/features/TradeData/TradeData';
// import MarketAnalytics from 'src/features/TradeData/MarketAnalytics';
// import SupplierBuyerCountry from 'src/features/TradeData/SupplierCountry';

// Utilities
import SupplierPlaceHolder from 'src/asset/images/company/Supplier.png';
import MarketAnalyticsPlaceHolder from 'src/asset/images/trade-data/MarketAnalytics.png';
import SupplierCountryPlaceHolder from 'src/asset/images/trade-data/SupplierCountry.png';
import TradeDataPlaceHolder from 'src/asset/images/trade-data/TradeData.png';
import BuyerCountryPlaceHolder from 'src/asset/images/trade-data/BuyerCountry.png';
import PortOriginPlaceHolder from 'src/asset/images/trade-data/PortOrigin.png';
import { TABS_TRADE_DATA, TypeTabTradeData } from 'src/features/TradeData/config';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(7),
  marginTop: theme.spacing(1),
  textAlign: 'center',
}));

const TradeData = () => {
  const [selectedTab, setSelectedTab] = useState<string>(TypeTabTradeData.TradeData);

  const renderForm = () => {
    // switch (selectedTab) {
    //   case TypeTabTradeData.TradeData:
    //     return <TradeDataTab />;
    //   case TypeTabTradeData.MarketAnalytics:
    //     return <MarketAnalytics />;
    //   case TypeTabTradeData.MarketForecasts:
    //     return <ComingSoon image={MarketAnalyticsPlaceHolder} />;
    //   case TypeTabTradeData.RouteMarket:
    //     return <ComingSoon image={SupplierCountryPlaceHolder} />;
    //   case TypeTabTradeData.SupplierCountry:
    //     return <SupplierBuyerCountry selectedTab={selectedTab} />;
    //   case TypeTabTradeData.BuyerCountry:
    //     return <SupplierBuyerCountry selectedTab={selectedTab} />;
    //   case TypeTabTradeData.PortOrigin:
    //     return <PortOfOriginTab />;
    //   case TypeTabTradeData.PortDelivery:
    //     return <PortDeliveryTab />;
    //   case TypeTabTradeData.Suppliers:
    //     return <ComingSoon image={SupplierPlaceHolder} />;
    //   default:
    //     return <ComingSoon image={TradeDataPlaceHolder} />;
    // }
    switch (selectedTab) {
      case TypeTabTradeData.TradeData:
        return <ComingSoon image={TradeDataPlaceHolder} />;
      case TypeTabTradeData.MarketAnalytics:
        return <ComingSoon image={MarketAnalyticsPlaceHolder} />;
      case TypeTabTradeData.MarketForecasts:
        return <ComingSoon image={MarketAnalyticsPlaceHolder} />;
      case TypeTabTradeData.RouteMarket:
        return <ComingSoon image={SupplierCountryPlaceHolder} />;
      case TypeTabTradeData.SupplierCountry:
        return <ComingSoon image={SupplierCountryPlaceHolder} />;
      case TypeTabTradeData.BuyerCountry:
        return <ComingSoon image={BuyerCountryPlaceHolder} />;
      case TypeTabTradeData.PortOrigin:
        return <ComingSoon image={PortOriginPlaceHolder} />;
      case TypeTabTradeData.PortDelivery:
        return <ComingSoon image={PortOriginPlaceHolder} />;
      case TypeTabTradeData.Suppliers:
        return <ComingSoon image={SupplierPlaceHolder} />;
      default:
        return <ComingSoon image={TradeDataPlaceHolder} />;
    }
  };

  return (
    <StyledContainer maxWidth="xl">
      <BreadcrumbWithBtnBack
        listItem={[
          {
            text: 'Trade Data',
          },
          {
            text: '080300',
          },
          {
            text: '01082021-01082024',
          },
        ]}
        updatedAt="03-08-2009T00:00:00Z"
      />
      <TradeDataInformation />
      <TabComponent
        tabs={TABS_TRADE_DATA}
        selected={selectedTab}
        handleChange={(e, value) => {
          setSelectedTab(value);
        }}
      />
      <Box mt={3.75} />
      {renderForm()}
    </StyledContainer>
  );
};

export default TradeData;
