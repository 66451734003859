import { Box, Divider, FormControl, Stack, Switch, Theme, Typography, useTheme } from '@mui/material';
import { FormikContextType, useFormik } from 'formik';
import FormikForm from 'src/components/common/FormikForm';
import { useMutateUserNoti } from 'src/services/mutate/useMutateSetting';
import { useQueryUserNoti } from 'src/services/queries';

const Notification = () => {
  const theme = useTheme();
  const { mutateAsync } = useMutateUserNoti();
  const { data } = useQueryUserNoti();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email_news_and_updates: data?.data?.email_news_and_updates,
      email_tips_and_tutorials: data?.data?.email_tips_and_tutorials,
      email_comments: data?.data?.email_comments,
      email_group_messages: data?.data?.email_group_messages,
      email_credit_usage: data?.data?.email_credit_usage,
      email_security_alerts: data?.data?.email_security_alerts,
      email_reminders: data?.data?.email_reminders,
      push_notification_news_and_updates: data?.data?.push_notification_news_and_updates,
      push_notification_tips_and_tutorials: data?.data?.push_notification_tips_and_tutorials,
      push_notification_comments: data?.data?.push_notification_comments,
      push_notification_group_messages: data?.data?.push_notification_group_messages,
      push_notification_credit_usage: data?.data?.push_notification_credit_usage,
      push_notification_security_alerts: data?.data?.push_notification_security_alerts,
      push_notification_reminders: data?.data?.push_notification_reminders,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const handleUpdateSetting = async (id: string, value: boolean) => {
    const values = formik.values;
    const dto = {
      ...values,
      ...{ [id]: value },
    } as TypeDTOSettingNotification;
    await mutateAsync(dto);
  };

  return (
    <FormikForm formik={formik}>
      <Stack spacing={1.875} direction={'row'}>
        <Box width={350}>
          <Typography variant="subtitle1">Email Notifications</Typography>
          <Typography color={theme.palette.text.secondary} variant="caption">
            This will be displayed on your profile
          </Typography>
        </Box>
        <Box display={'flex'} gap={3} flexDirection={'column'} flex={1}>
          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'News and Updates'}
            subtitle={' Updates on product enhancements and new features'}
            id={'email_news_and_updates'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Tips and Tutorials'}
            subtitle={'Guidance on maximizing your use of ADAMftd'}
            id={'email_tips_and_tutorials'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Comments'}
            subtitle={'Messages from members of your group'}
            id={'email_comments'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Group Messages'}
            subtitle={'Messages from members of your group'}
            id={'email_group_messages'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Credit Usage'}
            subtitle={'Receive alerts when your credit is low'}
            id={'email_credit_usage'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Security Alerts'}
            subtitle={'Receive notifications for logins from unfamiliar locations'}
            id={'email_security_alerts'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Reminders'}
            subtitle={'Alerts to keep you informed about missed updates'}
            id={'email_reminders'}
            formik={formik}
          />
        </Box>
      </Stack>

      <Divider sx={{ my: '30px' }} />

      <Stack spacing={1.875} direction={'row'}>
        <Box width={350}>
          <Typography variant="subtitle1">Push Notifications</Typography>
          <Typography color={theme.palette.text.secondary} variant="caption">
            Get push notifications in-app to find out what’s going on when you’re online
          </Typography>
        </Box>
        <Box display={'flex'} gap={3} flexDirection={'column'} flex={1}>
          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'News and Updates'}
            subtitle={' Updates on product enhancements and new features'}
            id={'push_notification_news_and_updates'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Tips and Tutorials'}
            subtitle={'Guidance on maximizing your use of ADAMftd'}
            id={'push_notification_tips_and_tutorials'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Comments'}
            subtitle={'Messages from members of your group'}
            id={'push_notification_comments'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Group Messages'}
            subtitle={'Messages from members of your group'}
            id={'push_notification_group_messages'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Credit Usage'}
            subtitle={'Receive alerts when your credit is low'}
            id={'push_notification_credit_usage'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Security Alerts'}
            subtitle={'Receive notifications for logins from unfamiliar locations'}
            id={'push_notification_security_alerts'}
            formik={formik}
          />

          <NotiItem
            handleUpdateSetting={handleUpdateSetting}
            theme={theme}
            title={'Reminders'}
            subtitle={'Alerts to keep you informed about missed updates'}
            id={'push_notification_reminders'}
            formik={formik}
          />
        </Box>
      </Stack>
    </FormikForm>
  );
};

export default Notification;

export const NotiItem = ({
  theme,
  title,
  subtitle,
  id,
  formik,
  handleUpdateSetting,
}: {
  theme: Theme;
  title: string;
  subtitle: string;
  id: string;
  formik: FormikContextType<ExpectedAny>;
  handleUpdateSetting: (id: string, value: boolean) => void;
}) => {
  return (
    <Stack gap={1} direction={'row'}>
      <FormControl>
        <Switch
          checked={formik.values[id]}
          onChange={(e) => {
            formik.handleChange(e);
            handleUpdateSetting(id, e.target.checked);
          }}
          id={id}
        />
      </FormControl>
      <Box>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography color={theme.palette.text.secondary} variant="caption">
          {subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};
