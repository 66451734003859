import { Helmet } from 'react-helmet';
import CompanyDetail from 'src/features/CompanyDetail';
import CompanyLayout from 'src/pages/CompanyDetail/CompanyLayout';

const CompanyDetailPage = () => {
  return (
    <CompanyLayout>
      <Helmet>
        <title>Company Detail</title>
      </Helmet>
      <CompanyDetail />
    </CompanyLayout>
  );
};

export default CompanyDetailPage;
