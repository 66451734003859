import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';
const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
}));
const OverviewBox = ({ children }: { children: ReactNode }) => {
  return <StyledBox>{children}</StyledBox>;
};

export default OverviewBox;
