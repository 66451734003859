import { Box, Typography } from '@mui/material';
import Lottie from 'react-lottie';

// Components

// Utilities
import { useState } from 'react';
import AnalyticsIcon from 'src/asset/lottie/analytics.json';

const ComingSoon = ({ image }: { image: ExpectedAny }) => {
  const [isStopped, setIsStopped] = useState(true);

  return (
    <Box position="relative" onMouseEnter={() => setIsStopped(false)} onMouseLeave={() => setIsStopped(true)}>
      <Box src={image} component="img" width="100%" position="relative" />
      <Box
        position="absolute"
        bgcolor="rgba(255, 255, 255, 0.80)"
        left={0}
        right={0}
        top={0}
        bottom={0}
        sx={{
          backdropFilter: 'blur(2px)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '100px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Typography variant="h2" align="center">
          Coming Soon
        </Typography>
        <Typography variant="h6" color="text.secondary" align="center">
          This feature is currently under development. Please check back soon!
        </Typography>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: AnalyticsIcon,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          isStopped={isStopped}
          height={600}
          width={540}
        />
      </Box>
    </Box>
  );
};
export default ComingSoon;
