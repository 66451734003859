import { OpenInNew } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { isEmpty, startCase, truncate } from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import Table from 'src/components/Table';
import PopupNegativeStory from 'src/features/CompanyDetail/NegativeMedia/PopupNegativeStory';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { useQueryCompanyNegativeMedia, useQueryCompanyExactNegativeMedia } from 'src/services/queries';
import { FORMAT_DATE_TIME, LIMIT_GET_LIST } from 'src/utils/constants/config';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { formatUrlNonProtocol, renderTextOrDefault } from 'src/utils/helpers/stringHelpers';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import NoDataNegative from 'src/features/CompanyDetail/NegativeMedia/NoDataNegative';

// Utilities

const NegativeMedia = ({ company }: { company: ICompanyDetail }) => {
  const { id } = useParams();
  const theme = useTheme();
  const [paramsExact, setParamsExact] = useState({
    limit: LIMIT_GET_LIST,
    offset: 0,
  });
  const [paramsSimilar, setParamsSimilar] = useState({
    limit: LIMIT_GET_LIST,
    offset: 0,
  });

  const { isFetching: isFetchingExact, data: dataExact } = useQueryCompanyExactNegativeMedia({
    id: id,
    params: paramsExact,
  });
  const { isFetching: isFetchingSimilar, data: dataSimilar } = useQueryCompanyNegativeMedia({
    id: id,
    params: paramsSimilar,
  });
  const [openNegativeStory, setOpenNegativeStory] = useState<{ open: boolean; story: INegativeMedia | null }>({
    open: false,
    story: null,
  });
  const listNegativeExact = getArrayDefault(dataExact?.data);
  const listNegativeSimilar = getArrayDefault(dataSimilar?.data);

  const handleOpenNegativeStory = (story: INegativeMedia) => {
    setOpenNegativeStory({ open: true, story: story });
  };

  const handleCloseNegativeStory = () => {
    setOpenNegativeStory({ open: false, story: null });
  };

  const configColumn: ConfigCell<INegativeMedia>[] = [
    {
      id: 'title',
      disablePadding: true,
      label: 'Title',
      cell: (row: INegativeMedia) => {
        return (
          <Typography variant="body2" onClick={() => handleOpenNegativeStory(row)}>
            {renderTextOrDefault(row.title)}
          </Typography>
        );
      },
    },
    {
      id: 'outlet',
      disablePadding: false,
      label: 'Outlet',
      width: 150,
      cell: (row: INegativeMedia) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(startCase(row.site))}
          </Typography>
        );
      },
    },
    {
      id: 'date',
      disablePadding: false,
      width: 200,
      label: 'Date Retrieved',
      cell: (row: INegativeMedia) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, row.extracted_at)}
          </Typography>
        );
      },
    },
    {
      id: 'url',
      disablePadding: false,
      label: 'Source Link (URL)',
      cell: (row: INegativeMedia) => {
        return (
          <RouterLinkNoUnderline to={formatUrlNonProtocol(row.url || '')} target="_blank">
            <Typography ml={1} variant="body2" color="secondary.main">
              {truncate(`${renderTextOrDefault(row.url)}`, {
                length: 50,
              })}
            </Typography>
            <OpenInNew fontSize="small" color="secondary" />
          </RouterLinkNoUnderline>
        );
      },
    },
  ];

  if (isFetchingExact || isFetchingSimilar) {
    return (
      <StyledWhiteBox>
        <LoadingSkeleton count={5} height={50} />
      </StyledWhiteBox>
    );
  }

  if (isEmpty(listNegativeExact) && isEmpty(listNegativeSimilar)) {
    return (
      <Stack direction="column" alignItems="center">
        <StyledWhiteBox display="flex" flexDirection="column" width="70%" border={`1px solid ${theme.palette.divider}`}>
          <NoDataNegative />
        </StyledWhiteBox>
      </Stack>
    );
  }

  return (
    <React.Fragment>
      <Stack spacing={3}>
        {!isEmpty(listNegativeExact) && (
          <StyledWhiteBox>
            <Stack spacing={2}>
              <Typography variant="h5" align="left">
                Negative Media
              </Typography>
              {/* <Box height={600}>
              <ReactFlowComponent initialEdges={initialEdges} initialNodes={initialNodes} />
            </Box> */}
              <Table
                maxRow={5}
                onChangePage={(e) => setParamsExact({ ...paramsExact, ...{ offset: e } })}
                onChangePageSize={(e) => setParamsExact({ ...paramsExact, ...{ offset: 0, limit: e } })}
                page={paramsExact.offset}
                pageSize={paramsExact.limit}
                loading={isFetchingExact}
                data={listNegativeExact}
                totalRecords={dataExact?.total_pages ? dataExact?.total_pages * paramsExact.limit : 0}
                configColumn={configColumn}
                dataKey="id"
              />
            </Stack>
          </StyledWhiteBox>
        )}
        {!isEmpty(listNegativeSimilar) && (
          <StyledWhiteBox>
            <Stack spacing={2}>
              <Typography variant="h5" align="left">
                Similarly Named Entities
              </Typography>
              <Typography variant="body1" align="left" color="text.secondary">
                Below are articles with keywords similar to the company name “
                <Typography component="span" variant="body1" color="secondary">
                  {renderTextOrDefault(company?.name || '')}
                </Typography>
                ”. We provide more for your reference.
              </Typography>
              <Table
                maxRow={5}
                onChangePage={(e) => setParamsSimilar({ ...paramsSimilar, ...{ offset: e } })}
                onChangePageSize={(e) => setParamsSimilar({ ...paramsSimilar, ...{ offset: 0, limit: e } })}
                page={paramsSimilar.offset}
                pageSize={paramsSimilar.limit}
                loading={isFetchingSimilar}
                data={listNegativeSimilar}
                totalRecords={dataSimilar?.total_pages ? dataSimilar?.total_pages * paramsSimilar.limit : 0}
                configColumn={configColumn}
                dataKey="id"
              />
            </Stack>
          </StyledWhiteBox>
        )}
      </Stack>
      <PopupNegativeStory
        open={openNegativeStory.open}
        story={openNegativeStory.story}
        handleClose={handleCloseNegativeStory}
      />
    </React.Fragment>
  );
};

export default NegativeMedia;
