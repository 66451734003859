import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import { debounce, isString } from 'lodash';
import { useCallback } from 'react';
import {
  //  Printer,
  Search,
  Sliders,
} from 'react-feather';
import { useSearchParams } from 'react-router-dom';
import IconFeather from 'src/components/common/Icon';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';

const FilterPeopleProfile = () => {
  const query = useQueryParams();
  const { handleOpenSearch } = useFormSearch((state) => state);
  const [, setSearchParams] = useSearchParams();

  const onChangeSearch = useCallback(
    debounce(async (e) => {
      setSearchParams((searchParams) => {
        searchParams.set('name', String(e.target.value));
        searchParams.set('offset', '0');
        return searchParams;
      });
    }, 500),
    [query],
  );

  return (
    <Stack direction="row" spacing={3}>
      <Stack flex={3}>
        <TextField
          sx={{ flex: 1 }}
          placeholder="People Name"
          size="small"
          fullWidth
          defaultValue={query?.name && isString(query?.name) ? query?.name : ''}
          onChange={onChangeSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconFeather icon={<Search />} muiProps={{ color: 'disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack flex={1} justifyContent="flex-start">
        <Button
          onClick={() => {
            handleOpenSearch(FormType.SearchPeople);
          }}
          color="primary"
          startIcon={<IconFeather muiProps={{ fontSize: 'small' }} icon={<Sliders />} />}
          variant="text"
          sx={{ width: 'fit-content' }}
        >
          Advanced Filter
        </Button>
      </Stack>
    </Stack>
  );
};

export default FilterPeopleProfile;
