// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {Analytics as AnalyticsType} from 'firebase/analytics'
import { getAnalytics, logEvent as gaLogEvent, setAnalyticsCollectionEnabled } from "firebase/analytics";

class Analytics {
  app: any;
  analytics: AnalyticsType;
  constructor() {
    this.analytics = {} as AnalyticsType;
  }
  logEvent(eventName: string, eventPayload?: { [key: string]: any }){
    gaLogEvent(this.analytics, eventName, eventPayload);
  }
  initialize(){
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const config = {
      apiKey: process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY,
      projectId: process.env.REACT_APP_GOOGLE_ANALYTICS_PROJECT_ID,
      messagingSenderId: process.env.REACT_APP_GOOGLE_ANALYTICS_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_GOOGLE_ANALYTICS_APP_ID,
      measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    };

    this.app = initializeApp(config);

    this.analytics = getAnalytics(this.app);
  }
}

const analytics = new Analytics()

export default analytics;
