import { SvgIcon, SvgIconProps } from '@mui/material';

const GoogleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 33 32" fill="none">
        <path
          d="M30.5014 16.3109C30.5014 15.1598 30.4061 14.3198 30.1998 13.4487H16.7871V18.6442H24.6601C24.5014 19.9354 23.6442 21.8798 21.7394 23.1864L21.7127 23.3604L25.9536 26.58L26.2474 26.6087C28.9458 24.1665 30.5014 20.5731 30.5014 16.3109Z"
          fill="#4285F4"
        />
        <path
          d="M16.7863 30C20.6434 30 23.8814 28.7555 26.2466 26.6089L21.7386 23.1865C20.5323 24.011 18.9132 24.5865 16.7863 24.5865C13.0086 24.5865 9.80225 22.1444 8.65929 18.7688L8.49176 18.7827L4.08208 22.1272L4.02441 22.2843C6.37359 26.8577 11.199 30 16.7863 30Z"
          fill="#34A853"
        />
        <path
          d="M8.66013 18.7689C8.35855 17.8978 8.18401 16.9644 8.18401 16C8.18401 15.0355 8.35855 14.1022 8.64426 13.2311L8.63627 13.0455L4.17132 9.64736L4.02524 9.71546C3.05703 11.6133 2.50146 13.7444 2.50146 16C2.50146 18.2555 3.05703 20.3866 4.02524 22.2844L8.66013 18.7689Z"
          fill="#FBBC05"
        />
        <path
          d="M16.7864 7.4133C19.4689 7.4133 21.2784 8.54885 22.3102 9.4978L26.3419 5.64C23.8658 3.38445 20.6435 2 16.7864 2C11.199 2 6.3736 5.1422 4.02441 9.71549L8.64345 13.2311C9.80229 9.85555 13.0086 7.4133 16.7864 7.4133Z"
          fill="#EB4335"
        />
      </svg>
    </SvgIcon>
  );
};

export default GoogleIcon;
