import {
  PeopleDetail,
  PeopleInformationSuggestion,
  PeopleProfileQuickView,
  PeopleProfiles,
  PeopleRelatedCompany,
  PeopleRelatedPeoples,
} from 'src/model/people';
import { getRequest } from 'src/services/api/http';
import { URL_ENDPOINT } from 'src/utils/constants/service';
import { handleQueryParams } from 'src/utils/helpers/queryParam';
import queryString from 'query-string';

function getListPeople(query: string) {
  const url = URL_ENDPOINT.people.people + 'list-people?' + query;
  return getRequest(url, {}, new PeopleProfiles()) as Promise<PeopleProfiles>;
}

function getQuickViewPeopleProfile(id: string) {
  const url = URL_ENDPOINT.people.people + `quick-view/${id}`;
  return getRequest(url, {}, new PeopleProfileQuickView()) as Promise<PeopleProfileQuickView>;
}

function getPeopleDetail(id: string) {
  const url = URL_ENDPOINT.people.people + 'overview/' + id;
  return getRequest(url, {}, new PeopleDetail()) as Promise<PeopleDetail>;
}

function getPeopleRelatedCompany(id: string, params: Record<string, number>) {
  const url = URL_ENDPOINT.people.people + 'related-company/' + id;
  return getRequest(url, params, new PeopleRelatedCompany()) as Promise<PeopleRelatedCompany>;
}

function getPeopleRelatedPeople(id: string, params: Record<string, number>) {
  const url = URL_ENDPOINT.people.people + 'related-people/' + id;
  return getRequest(url, params, new PeopleRelatedPeoples()) as Promise<PeopleRelatedPeoples>;
}

function getPeopleInformationSuggestion(params: Record<string, string>, countryCode: string[]) {
  const queryCountry = handleQueryParams('country_code', countryCode);
  const url = `${URL_ENDPOINT.people.people}suggestions?${queryString.stringify(params)}&${queryCountry}`;
  return getRequest(url, {}, new PeopleInformationSuggestion()) as Promise<PeopleInformationSuggestion>;
}

const PeopleService = {
  getListPeople,
  getQuickViewPeopleProfile,
  getPeopleDetail,
  getPeopleRelatedCompany,
  getPeopleRelatedPeople,
  getPeopleInformationSuggestion,
};

export default PeopleService;
