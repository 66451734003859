import { Box, Typography, useTheme } from '@mui/material';
import numeral from 'numeral';

// Components
import ExpandableBox from 'src/components/common/ExpandableBox';
import TableHorizontal from 'src/components/TableHorizontal';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

// Utilities

const PackingInformation = ({ tradeDataDetail }: { tradeDataDetail: IBillOfLandingDetail }) => {
  const theme = useTheme();

  const configColumn = [
    {
      label: 'Quantity',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.quantity)}
        </Typography>
      ),
    },
    {
      label: 'PKG (PCS)',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.pkg)}
        </Typography>
      ),
    },
    {
      label: 'Original Weight',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(numeral(data.original_weight).format('O,O'))}
        </Typography>
      ),
    },
    {
      label: 'Original Unit',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(numeral(data.original_unit).format('O,O'))}
        </Typography>
      ),
    },
    {
      label: 'WGT (KGS)',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.wgt)}
        </Typography>
      ),
    },
    {
      label: 'Unit Price',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.unit_price)}
        </Typography>
      ),
    },
    {
      label: 'Value USD',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.value_usd)}
        </Typography>
      ),
    },
    {
      label: 'Invoice Value',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.invoice_value)}
        </Typography>
      ),
    },
    {
      label: 'Applied Tariffs',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.applied_tariffs)}
        </Typography>
      ),
    },
    {
      label: 'Freight Value',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.freight_value)}
        </Typography>
      ),
    },
    {
      label: 'Insurance Value',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.insurance_value)}
        </Typography>
      ),
    },
    {
      label: 'Total Cost (Inclusive of Taxes/Tariffs)',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.total_cost)}
        </Typography>
      ),
    },
  ];

  return (
    <ExpandableBox title="Packing Information" openDefault={false} flex={1}>
      <Box padding={3} display="flex" flexDirection="column" bgcolor={theme.palette.background.default}>
        <TableHorizontal colKeyWidth={350} configColumn={configColumn} data={tradeDataDetail} />
      </Box>
    </ExpandableBox>
  );
};

export default PackingInformation;
