import { alpha, Autocomplete, Box, Chip, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { every, find, isArray, isEmpty, map } from 'lodash';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import PaperSelect from 'src/components/common/PaperSelect';

// Utilities
import { useTheme } from '@mui/system';
import { useMemo } from 'react';
import iconSearch from 'src/asset/icons/icon-search.svg';
import { useQueryJurisdictions } from 'src/services/queries';
import completeJurisdictions from 'src/utils/constants/completeJurisdictions';
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { checkCountryHaveState, getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';

interface IProps {
  required?: boolean;
  error?: boolean;
  id: string;
}

interface IJurisdictionOption extends IJurisdictions {
  optionLabel: string;
}

const FormItemJurisdiction = ({ required, id, error }: IProps) => {
  const { data, isFetching } = useQueryJurisdictions();
  const { data: listJurisdiction = [] } = data;
  const { values, setFieldValue, handleBlur } = useFormikContext<ExpectedAny>();
  const theme = useTheme();
  const options: IJurisdictionOption[] = useMemo(() => {
    const copy = [...listJurisdiction];
    const mappingWithCountryState: IJurisdictionOption[] = map(copy, (item: IJurisdictions) => {
      const isCountryHaveState = checkCountryHaveState(item?.country_code);
      if (isCountryHaveState) {
        const country = getCountryByCountryCode(item.country_code);
        return {
          ...item,
          ...{
            optionLabel: `${country.label} - ${item.jurisdiction}`,
          },
        } as IJurisdictionOption;
      }
      return {
        ...item,
        ...{
          optionLabel: item.jurisdiction,
        },
      };
    });
    return mappingWithCountryState.sort((a, b) => a.optionLabel.localeCompare(b.optionLabel));
  }, [listJurisdiction]);

  const getJurisdiction = (jurisdictionName: string) => {
    if (!jurisdictionName || isEmpty(options)) {
      return {
        country_code: '',
        jurisdiction: '',
        optionLabel: '',
      };
    }
    const jurisdictionItem = find(options, (o) => o.jurisdiction === jurisdictionName);
    if (!jurisdictionItem) {
      return {
        country_code: '',
        jurisdiction: '',
        optionLabel: '',
      };
    }
    return jurisdictionItem;
  };

  const getValue = () => {
    // if value is a list of Jurisdiction => get list Jurisdiction from list listJurisdiction
    if (isArray(values[id]) && every(values[id], (jurisdiction) => typeof jurisdiction === 'string')) {
      return values[id].map((jurisdiction: string) => {
        return getJurisdiction(jurisdiction);
      });
    }
    return values[id];
  };

  return (
    <Autocomplete
      value={getValue()}
      loading={isFetching}
      multiple
      fullWidth
      size="small"
      onBlur={handleBlur}
      getOptionKey={(option) => option.optionLabel}
      options={getArrayDefault(options)}
      popupIcon={<Box component={'img'} src={iconSearch} />}
      PaperComponent={PaperSelect}
      onChange={(_, value) => {
        setFieldValue(id, value);
      }}
      getOptionLabel={(option: IJurisdictionOption) => option.optionLabel}
      renderTags={(value: readonly IJurisdictionOption[], getTagProps) =>
        getArrayDefault(value).map((option: IJurisdictionOption, index: number) => {
          const tagProps = getTagProps({ index });
          return (
            <Chip
              variant="filled"
              label={option?.optionLabel || EmptyText}
              icon={
                <FlagCountry
                  width={16}
                  code={option?.country_code || ''}
                  className={tagProps.className}
                  iconProps={{
                    sx: {
                      marginLeft: '5px',
                      marginRight: '-6px',
                    },
                  }}
                />
              }
              {...tagProps}
              key={`jurisdiction-${index}`}
            />
          );
        })
      }
      renderOption={(props, option) => {
        const isCompleteData = completeJurisdictions[option?.optionLabel];
        return (
          <Box component="li" display="flex" flexDirection="row" {...props}>
            <Stack direction="row" alignItems="flex-start" spacing={1}>
              <Box>
                <FlagCountry width={20} code={option?.country_code || ''} />
              </Box>
              <Typography variant="body2">{option?.optionLabel}</Typography>
              <Chip color="secondary" label={'GLEIF Data'} size={'small'} />
              {isCompleteData && (
                <Chip
                  sx={{
                    '&:hover': {
                      color: theme.palette.success.main,
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                    },
                  }}
                  color="success"
                  label={'Company Registry Data'}
                  size={'small'}
                  clickable={false}
                />
              )}
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          id={id}
          name={id}
          placeholder="Search"
          InputLabelProps={{ required: required }}
          label={<span>Jurisdictions</span>}
        />
      )}
    />
  );
};

export default FormItemJurisdiction;
