import { Paper, Table, TableBody, TableContainer, TableRow, Typography, useTheme } from '@mui/material';
import { StyledTableCell } from 'src/components/TableHorizontal/TableCell';
import { IConfigCellHorizontal } from 'src/components/TableHorizontal/type';
import { v4 as uuidv4 } from 'uuid';

export default function TableHorizontal<T>({
  data,
  configColumn,
  colKeyWidth = 300,
}: {
  data: T;
  configColumn: Array<IConfigCellHorizontal<T>>;
  colKeyWidth?: number;
}) {
  const {
    palette: { background },
  } = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {configColumn.map((config) => {
            return (
              <TableRow key={uuidv4()} sx={{ '&:last-child td': { border: 0 } }}>
                <StyledTableCell
                  key={uuidv4()}
                  sx={{ background: background.paper2, width: colKeyWidth }}
                  component="th"
                  scope="row"
                >
                  <Typography variant="subtitle2">{config.label}</Typography>
                </StyledTableCell>
                <StyledTableCell key={uuidv4()} component="th" scope="row">
                  {config.renderCell(data)}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
