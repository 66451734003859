import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Grid, MobileStepper, Stack, Typography, useTheme } from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { Flag, Globe } from 'react-feather';

// Components
import IconFeather from 'src/components/common/Icon';
import { EmptyText } from 'src/utils/constants/config';

const ListWebsite = ({
  companyWebDetail,
  selectedIndexDetail,
  setSelectedIndexDetail,
}: {
  companyWebDetail: ICompanyWebDetail[];
  selectedIndexDetail: number;
  setSelectedIndexDetail: (index: number) => void;
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = Math.ceil(companyWebDetail.length / 4);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Stack direction="column" alignItems="center">
      <Grid container spacing={2}>
        {companyWebDetail.map((website, index) => (
          <Grid key={`${get(website, 'whois_record_domain.name', '')}-${index}`} item xs={6} md={4} lg={3}>
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              height="100%"
              gap={1}
              borderRadius={theme.shape.borderRadius}
              border={`2px solid ${selectedIndexDetail === index ? theme.palette.primary.main : theme.palette.divider}`}
              bgcolor={theme.palette.background.paper}
              sx={{ cursor: 'pointer' }}
              onClick={() => setSelectedIndexDetail(index)}
            >
              <Box
                width="100%"
                src={get(website, 'performance_results.desktop.screenshots', '')}
                component="img"
                sx={{ objectFit: 'cover' }}
              />
              <Box textAlign="left">
                <Typography variant="subtitle1">{get(website, 'name', EmptyText)}</Typography>
              </Box>
              <Box gap={1} display="flex" flexDirection={'row'} alignItems="center">
                <IconFeather
                  muiProps={{
                    sx: {
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                    },
                  }}
                  icon={<Globe />}
                />
                <Typography color={theme.palette.text.secondary} variant="caption">
                  {get(website, 'whois_record_domain.name', '')}
                </Typography>
              </Box>
              <Box gap={1} display="flex" flexDirection={'row'} alignItems="center">
                <IconFeather
                  muiProps={{
                    sx: {
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                    },
                  }}
                  icon={<Flag />}
                />
                <Typography color={theme.palette.text.secondary} variant="caption">
                  {get(website, 'whois_record_domain.ip', '')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <MobileStepper
        sx={{ backgroundColor: 'transparent', width: 'fit-content', gap: theme.spacing(2) }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </Stack>
  );
};
export default ListWebsite;
