import { Divider, Typography, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ReactNode } from 'react';
import { Box as FeatherBox, Calendar, ChevronsRight } from 'react-feather';
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import OverviewBox from 'src/components/common/OverviewBox';

const BillOfLandingDetailOverview = ({ id }: { id?: string }) => {
  const {
    palette: { common },
  } = useTheme();

  const renderItem = (icon: ReactNode, label: ReactNode) => {
    return (
      <Stack spacing={1} direction={'row'} alignItems="center">
        {icon} <Box color={common.white}>{label} </Box>
      </Stack>
    );
  };

  return (
    <OverviewBox>
      <Stack direction={'row'} spacing={3}>
        <Stack flexBasis={800} spacing={1}>
          <Typography color={'inherit'} variant="h5">
            {id}
          </Typography>
          <Typography color={'inherit'} variant="body2">
            Dairy produce; milk and cream, concentrated or containing added sugar or other sweetening matter, in powder,
            granules or other solid forms, of a fat content not exceeding 1.5% (by weight)
          </Typography>
        </Stack>

        <Divider orientation="vertical" flexItem />

        <Stack spacing={2}>
          {renderItem(
            <IconFeather muiProps={{ fontSize: 'small' }} icon={<Calendar />} />,
            <Typography variant="caption2" color={'inherit'}>
              12/04/2022
            </Typography>,
          )}
          {renderItem(
            <IconFeather muiProps={{ fontSize: 'small' }} icon={<FeatherBox />} />,
            <Typography variant="caption2" color={'inherit'}>
              8421390500
            </Typography>,
          )}
          <Stack spacing={1} direction={'row'} alignItems="center">
            <FlagCountry width={20} code="TW" />
            <Typography variant="caption2" color={'inherit'}>
              Taiwan
            </Typography>
            <IconFeather muiProps={{ fontSize: 'small' }} icon={<ChevronsRight />} />
            <FlagCountry width={20} code="US" />
            <Typography variant="caption2" color={'inherit'}>
              USA
            </Typography>
          </Stack>
          <Stack spacing={1} direction={'row'} alignItems="center">
            <Typography variant="caption2" color={'inherit'}>
              HWA HSIA GLASS CO LTD
            </Typography>
            <IconFeather muiProps={{ fontSize: 'small' }} icon={<ChevronsRight />} />
            <Typography variant="caption2" color={'inherit'}>
              9330 NW 110TH AVENUE, MIAMI, FL 33178, USA
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </OverviewBox>
  );
};

export default BillOfLandingDetailOverview;
