import { Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { upperFirst } from 'lodash';
import { Check } from '@mui/icons-material';
import { AlertTriangle } from 'react-feather';
import update from 'immutability-helper';
import { parsePhoneNumber } from 'libphonenumber-js';

// Components
import Table from 'src/components/Table';
import PopoverAction from 'src/components/Table/PopoverAction';
import IconFeather from 'src/components/common/Icon';
import { convertToStartCase, getColorContactStatus } from 'src/utils/helpers/stringHelpers';
import { EmptyText, UnknownText } from 'src/utils/constants/config';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';

// Utilities
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';
import { useMutateCheckEmailValidation } from 'src/services/mutate';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { OtherContactStatusCode } from 'src/utils/constants/enum';

const OtherContacts = ({
  handleOpen,
  listContact,
}: {
  handleOpen: (id: string) => void;
  listContact: Array<IOtherContactCompany>;
}) => {
  const { handleOpenContribute } = useCompanyContribute((state) => state);
  const { mutateAsync, isPending } = useMutateCheckEmailValidation();
  const [listOtherContacts, setListOtherContacts] = useState<Array<IOtherContactCompany>>([]);
  const [isValidateEmail, setIsValidateEmail] = useState<Record<string, boolean>>({});
  const [idValidating, setIdValidating] = useState<string>('');

  useEffect(() => {
    setListOtherContacts(listContact);
  }, [listContact]);

  const handleValidateEmail = async (data: IOtherContactCompany) => {
    if (data?.id) {
      setIdValidating(data.id);
    }
    const resValidate = await mutateAsync({ email: data?.company_email || '' });
    if (resValidate.status_code === SUCCESS_CODE.GET) {
      const index = listOtherContacts.findIndex((contact) => contact?.id === data?.id);
      const newListOtherContacts = update(listOtherContacts, {
        [index]: {
          $set: {
            ...listOtherContacts[index],
            status: resValidate?.data,
          },
        },
      });
      setListOtherContacts(newListOtherContacts);
      if (data?.id) {
        const newValidateEmail = update(isValidateEmail, {
          [data.id]: {
            $set: true,
          },
        });
        setIsValidateEmail(newValidateEmail);
      }
      setIdValidating('');
    }
  };

  const checkEnableValidateEmail = (data: IOtherContactCompany) => {
    /* Condition validate email
     _ Email existed
     _ status not include Invalid, Risky, Valid
     _ Not in the validation process
    */
    return (
      !data?.company_email ||
      [OtherContactStatusCode.Invalid, OtherContactStatusCode.Risky, OtherContactStatusCode.Valid].includes(
        data?.status,
      ) ||
      isValidateEmail[data?.id || ''] ||
      idValidating === data?.id
    );
  };

  const configColumn: ConfigCell<IOtherContactCompany>[] = [
    {
      id: 'full_name',
      disablePadding: true,
      label: 'Full Name',
      cell: (row: IOtherContactCompany) => {
        return (
          <Typography variant="body2" color="secondary" onClick={() => handleOpen(row.id || '')}>
            {convertToStartCase(row.name || EmptyText)}
          </Typography>
        );
      },
    },
    {
      id: 'title',
      disablePadding: false,
      label: 'Title',
      cell: (row: IOtherContactCompany) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {convertToStartCase(row?.title || row?.position || EmptyText)}
          </Typography>
        );
      },
    },
    {
      id: 'phone_number',
      disablePadding: false,
      label: 'Phone Number',
      cell: (row: IOtherContactCompany) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row?.company_phone_number ? parsePhoneNumber(row.company_phone_number).format('INTERNATIONAL') : EmptyText}
          </Typography>
        );
      },
    },
    {
      id: 'email_address',
      disablePadding: false,
      label: 'Email Address',
      cell: (row: IOtherContactCompany) => {
        return (
          <Stack gap={1} direction="row">
            {isPending && idValidating === row?.id && <CircularProgress color="inherit" size={16} />}
            <Typography variant="body2" color="text.secondary">
              {row.company_email || EmptyText}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'status',
      disablePadding: false,
      label: 'Email Status',
      cell: (row: IOtherContactCompany) => {
        return (
          <Chip
            size="small"
            label={row?.status ? upperFirst(row?.status) : UnknownText}
            color={getColorContactStatus(row?.status)}
          />
        );
      },
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'Action',
      cell: (row: IOtherContactCompany) => {
        return (
          <PopoverAction
            id={`action-other-contact-${row.id}`}
            disabled={isPending}
            listAction={[
              {
                icon: <Check color="primary" fontSize="small" />,
                text: 'Validate Email',
                action: () => handleValidateEmail(row),
                disabled: checkEnableValidateEmail(row),
              },
              {
                icon: (
                  <IconFeather
                    icon={<AlertTriangle />}
                    muiProps={{
                      color: 'primary',
                      fontSize: 'small',
                    }}
                  />
                ),
                text: 'Report Wrong',
                action: () =>
                  handleOpenContribute(TypeContribute.ReportWrongOtherContact, {
                    full_name: row.name || '',
                    title: row.title || '',
                    email: row.company_email || '',
                    phone_number: row.company_phone_number || '',
                  }),
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Typography variant="h5" align="left">
        Other Contacts
      </Typography>
      <Table data={listOtherContacts} configColumn={configColumn} dataKey="id" isPagination={false} />
    </Stack>
  );
};

export default OtherContacts;
