import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import { get } from 'lodash';

import { CommonResponse } from 'src/model/common';
import { ACCESS_TOKEN_KEY, IP_ADDRESS_KEY, REFRESH_TOKEN_KEY } from 'src/utils/constants/config';
import { ERROR_CODE } from 'src/utils/constants/statusCode';
import AuthService, { getDeviceId, handleError } from './auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000 * 60,
  headers: {
    'Access-Control-Allow-Credentials': true,
  },
});

instance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig<ExpectedAny>) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const ipAddress = localStorage.getItem(IP_ADDRESS_KEY);
    const deviceId = getDeviceId();

    config.headers['Ip-Address'] = ipAddress;
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    config.headers['Device-Id'] = deviceId;
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (axiosResponse: AxiosResponse) => {
    if (axiosResponse.status >= 500) {
      alert('Server Error!');
    }

    // if (axiosResponse.status === ERROR_CODE.FORBIDDEN) {
    //   handleError();
    //   return axiosResponse;
    // }

    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken && axiosResponse.status === ERROR_CODE.UNAUTHORIZED) {
      AuthService.handleRefreshToken({ refresh_token: refreshToken || '' })
        .then((response) => {
          if (response.status_code === ERROR_CODE.UNAUTHORIZED || response.status_code === 0) {
            handleError();
            // return instance(axiosResponse.config);
            return axiosResponse;
          }
          const newAccessToken = response.data;
          localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken);
          return instance(axiosResponse.config);
        })
        .catch(() => {
          handleError();
          // return instance(axiosResponse.config);
          return axiosResponse;
        });
    }
    return axiosResponse;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  },
);

instance.defaults.validateStatus = (status) => status >= 200 && status < 500;

class HTTP {
  instance: AxiosInstance;
  constructor() {
    this.instance = instance;
  }
}

const http = new HTTP().instance;

export const postRequest = (
  url: string,
  body: ExpectedAny,
  headers?: AxiosRequestHeaders,
  defaultResult = new CommonResponse(),
) => {
  return http
    .post(url, body, {
      headers: headers,
    })
    .then((axiosResponse: AxiosResponse) => {
      const data = axiosResponse.data?.data ?? [];
      return { ...axiosResponse.data, ...{ data: data } };
    })
    .catch(() => {
      return defaultResult;
    });
};

export const getRequest = (
  url: string,
  params: ExpectedAny,
  defaultResult = new CommonResponse(),
  headers?: RawAxiosRequestHeaders,
) => {
  return http
    .get(url, {
      params: params,
      headers: headers,
    })
    .then((axiosResponse: AxiosResponse) => {
      const defaultData = get(defaultResult, 'data', {});
      return { ...axiosResponse.data, data: axiosResponse.data?.data || defaultData };
    })

    .catch(() => {
      return defaultResult;
    });
};
