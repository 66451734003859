import { alpha, Theme, useTheme } from '@mui/material';
import Highcharts, { TopoJSON } from 'highcharts/highmaps';
import getEmojiFlag from 'src/utils/helpers/emojiFlag';
import { getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';

export enum WorldMapOptions {
  company_bill_of_landing = 'company_bill_of_landing',
  customer = 'customer',
}

type TypeGetOption = (props: IWorldMapProps) => Highcharts.Options;

export interface IWorldMapProps {
  mapData: string | TopoJSON | Array<ExpectedAny>;
  height: number;
  theme: Theme;
  data: Array<number | [string, number | null] | null | Highcharts.SeriesMapDataOptions>;
  dataPoint: Array<number | [number, number | null] | null | Highcharts.SeriesMappointDataOptions>;
  tooltipFormatter?: Highcharts.TooltipFormatterCallbackFunction;
}

export const getDataLabelOptionBillOfLanding = ({
  code,
  companyName,
  address,
  companyType,
}: {
  code: string;
  companyName: string;
  address: string;
  companyType: 'Buyer' | 'Supplier';
}) => {
  const { palette } = useTheme();
  return {
    formatter: () => {
      const flag = getEmojiFlag(code);
      const countryName = getCountryByCountryCode(code).label;
      return `
             <div style="color: #fff; width: 220px; line-height: 150%">
              <span style="color: inherit; display: block; font-size: 14px">${flag} ${countryName}</span>
              <span style="color: inherit; display: block; font-size: 16px; margin-top: 10px; margin-bottom: 5px">${companyName}</span>
              <span style="color: ${palette.text.secondary}; display: block; ">Address of ${companyType}</span>
              <span style="color: inherit; display: block; text-wrap: wrap">${address}</span>
             </div>
             `;
    },
  };
};

export const getWorldMapBillOfLandingOption: TypeGetOption = (props: IWorldMapProps) => {
  return {
    chart: {
      map: props.mapData,
      height: props.height,
      backgroundColor: props.theme.palette.background.paper2,
    },
    title: {
      text: '',
    },
    plotOptions: {
      mapline: {
        nullColor: props.theme.palette.error.main,
      },
      mappoint: {
        marker: {
          fillColor: props.theme.palette.error.main,
          lineWidth: 1,
          symbol: 'mapmarker',
          radius: 8,
        },
      },
    },
    mapView: {
      projection: {
        name: 'WebMercator',
        // rotation: [180],
      },
      center: [0, 40],
      zoom: 2,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    xAxis: {
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'map',
        data: props.data,
        joinBy: 'hc-key',
        name: 'Map Data',
        nullColor: '#FFFFFF',
        states: {
          hover: {
            color: props.theme.palette.mapChart.main,
          },
        },
        color: props.theme.palette.mapChart.main,
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
      },
      {
        type: 'mappoint',
        name: 'State',
        dataLabels: {
          enabled: true,
          y: -30,
          backgroundColor: props.theme.palette.commonBlackStatesMain.main,
          borderRadius: 4,
          padding: 8,
          useHTML: true,
        },
        data: props.dataPoint,
      },
      {
        type: 'mapline',
        dashStyle: 'Dash',
        lineWidth: 2,
        data: [
          {
            geometry: {
              type: 'LineString',
              coordinates: [
                [121.59, 25.1],
                [-118.24, 34.05],
              ],
            },
          },
        ],
      },
    ],
  };
};

export const getWorldMapCustomerOption: TypeGetOption = (props: IWorldMapProps) => {
  return {
    chart: {
      map: props.mapData,
      height: props.height,
      backgroundColor: props.theme.palette.background.paper2,
    },
    title: {
      text: '',
    },
    plotOptions: {
      mapline: {
        nullColor: props.theme.palette.error.main,
      },
      mappoint: {
        marker: {
          fillColor: props.theme.palette.error.main,
          lineWidth: 1,
          symbol: 'mapmarker',
          radius: 8,
        },
      },
    },
    mapView: {
      projection: {
        name: 'WebMercator',
      },
      center: [0, 40],
      zoom: 1.5,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    xAxis: {
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'bottom',
    },
    colorAxis: {
      minColor: alpha(props.theme.palette.mapChart.main, 0.01),
      maxColor: props.theme.palette.mapChart.main,
      reversed: false,
    },
    tooltip: {
      valueDecimals: 1,
      backgroundColor: props.theme.palette.commonBlackStatesMain.main,
      padding: 14,
      borderRadius: props.theme.shape.borderRadius * 2,
      useHTML: true,
      formatter: props.tooltipFormatter,
      style: {
        color: props.theme.palette.common.white,
        lineHeight: '20px',
      },
    },
    series: [
      {
        type: 'map',
        data: props.data,
        nullColor: '#FFFFFF',
        joinBy: 'hc-key',
        name: 'Map Data',
        states: {
          hover: {
            color: props.theme.palette.mapChart.main,
          },
          color: 'red',
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
      },
    ],
  };
};
