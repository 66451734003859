import { Chip, Grid, Stack, Typography, useTheme } from '@mui/material';
import { first, get, has, isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { Globe, Mail, MapPin, PhoneCall } from 'react-feather';

// Components
// import ButtonContribute from 'src/components/common/ButtonContribute';
import IconFeather from 'src/components/common/Icon';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import FacebookIcon from 'src/components/Icons/Facebook';
import LinkedInIcon from 'src/components/Icons/LinkedIn';
import TwitterIcon from 'src/components/Icons/Twitter';
import PeopleDetailCardContent from 'src/features/PeopleDetail/components/CardContent';

// Utilities
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import { EmptyText, UnknownText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatUrlNonProtocol } from 'src/utils/helpers/stringHelpers';

interface IProps {
  dataPeople: IPeopleDetail;
  isFetching: boolean;
}

const PeopleDetailOverview = ({ dataPeople, isFetching }: IProps) => {
  const {
    palette: { text, secondary },
  } = useTheme();

  const renderContactItem = (icon: ReactNode, label: ReactNode) => {
    return (
      <Stack gap={1} alignItems="center" direction={'row'}>
        <IconFeather icon={icon} muiProps={{ sx: { fontSize: 12 } }} />
        <Typography color={'inherit'} variant="body2">
          {label}
        </Typography>
      </Stack>
    );
  };

  if (isFetching) {
    return (
      <Grid item xs={12}>
        <PeopleDetailCardContent title="About">
          <LoadingSkeleton count={5} height={50} />
        </PeopleDetailCardContent>
      </Grid>
    );
  }

  return (
    <>
      {dataPeople?.job_summary && (
        <Grid item xs={12}>
          <PeopleDetailCardContent title="About">
            <Typography color={text.secondary} variant="body2">
              {dataPeople?.job_summary || EmptyText}
            </Typography>
          </PeopleDetailCardContent>
        </Grid>
      )}

      <Grid item xs={12}>
        <PeopleDetailCardContent title="Contact">
          <Stack color={text.secondary} gap={2} direction="column">
            <Stack gap={1}>
              {!isEmpty(dataPeople?.phone_numbers) &&
                renderContactItem(<PhoneCall />, getArrayDefault(dataPeople?.phone_numbers).join(', '))}
              {!isEmpty(dataPeople?.emails) &&
                renderContactItem(<Mail />, getArrayDefault(dataPeople?.emails).join(', '))}
              {has(dataPeople?.addresses, '[0].full_address') &&
                renderContactItem(
                  <MapPin />,
                  (get(first(dataPeople.addresses), 'full_address', EmptyText) || '').toUpperCase(),
                )}
              {get(dataPeople?.websites, '[0]', '') &&
                renderContactItem(
                  <Globe />,
                  <Typography variant="body2" color={secondary.main}>
                    <RouterLinkNoUnderline
                      target={has(dataPeople?.websites, '[0]') ? '_blank' : ''}
                      to={get(dataPeople?.websites, '[0]', '')}
                    >
                      {get(dataPeople?.websites, '[0]', UnknownText)}
                    </RouterLinkNoUnderline>
                  </Typography>,
                )}
            </Stack>

            <Stack alignItems={'center'} gap={2} direction="row">
              {dataPeople?.facebook_url && (
                <RouterLinkNoUnderline
                  target={'_blank'}
                  to={dataPeople?.facebook_url ? formatUrlNonProtocol(dataPeople?.facebook_url) : ''}
                >
                  <FacebookIcon />
                </RouterLinkNoUnderline>
              )}
              {dataPeople?.linkedin_url && (
                <RouterLinkNoUnderline
                  target={'_blank'}
                  to={dataPeople?.linkedin_url ? formatUrlNonProtocol(dataPeople?.linkedin_url) : ''}
                >
                  <LinkedInIcon />
                </RouterLinkNoUnderline>
              )}
              {dataPeople?.twitter_url && (
                <RouterLinkNoUnderline
                  target={'_blank'}
                  to={dataPeople?.twitter_url ? formatUrlNonProtocol(dataPeople?.twitter_url) : ''}
                >
                  <TwitterIcon />
                </RouterLinkNoUnderline>
              )}
              {/* <ButtonContribute onClick={() => null} titleTooltip="Coming Soon!" /> */}
            </Stack>
          </Stack>
        </PeopleDetailCardContent>
      </Grid>

      {!isEmpty(dataPeople?.skills) && (
        <Grid item xs={12}>
          <PeopleDetailCardContent title="Skills">
            <Stack flexWrap={'wrap'} color={text.secondary} gap={1} direction="row">
              {getArrayDefault(dataPeople?.skills).map((skill, index) => (
                <Chip key={`skill-people-detail-${index}-${skill}`} label={skill} />
              ))}
            </Stack>
          </PeopleDetailCardContent>
        </Grid>
      )}
    </>
  );
};

export default PeopleDetailOverview;
