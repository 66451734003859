import React from 'react';
import { Helmet } from 'react-helmet';
import TermAndCondition from 'src/features/TermAndCondition';

const TermAndConditionPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <TermAndCondition />
    </React.Fragment>
  );
};

export default TermAndConditionPage;
