import { Box, Container, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import TabComponent from 'src/components/Tab/TabComponent';
import DeviceManager from 'src/features/Setting/DeviceManager';
import Information from 'src/features/Setting/Information';
import Notification from 'src/features/Setting/Notification';
import Password from 'src/features/Setting/Password';
import { useQueryParam } from 'src/hooks/useQueryParam';
import { TABS_SETTING, TypeTabSetting } from './config';

const Setting = () => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState<string>(TypeTabSetting.information as TypeTabSetting);

  const tab = useQueryParam('tab');

  useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    } else {
      setSelectedTab(TypeTabSetting.information);
    }
  }, [tab]);

  const renderForm = () => {
    switch (selectedTab) {
      case TypeTabSetting.information:
        return <Information />;
      case TypeTabSetting.notifications:
        return <Notification />;
      case TypeTabSetting.password:
        return <Password />;
      case TypeTabSetting.devices_manager:
        return <DeviceManager />;
      default:
        return <Information />;
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: '0px auto',
        py: 4,
      }}
    >
      <Box
        padding={4}
        sx={{
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography variant="h4">Account Settings</Typography>

        <Box sx={{ mt: 3.75 }} />

        <TabComponent
          tabs={TABS_SETTING}
          selected={selectedTab}
          handleChange={(e, value) => {
            setSelectedTab(value);
          }}
        />

        <Box sx={{ mt: 3.75 }} />

        {renderForm()}
      </Box>
    </Container>
  );
};

export default Setting;
