import { find, isNumber, isString, lowerCase, split, startsWith, upperCase, upperFirst } from 'lodash';
import { EmptyText, WEBSITE_PROTOCOL, COUNTRIES_HAVE_STATE } from 'src/utils/constants/config';
import { COUNTRIES } from 'src/utils/constants/countries';
import { CompanyStatusCode, OtherContactStatusCode } from 'src/utils/constants/enum';
import numeral from 'numeral';

export const getCurrentUrl = () => {
  if (window?.location) {
    return window.location.origin + window.location.pathname;
  }
  return '';
};

export const getOriginUrl = () => {
  if (window?.location) {
    return window.location.origin;
  }
  return '';
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatUrlNonProtocol = (url?: string) => {
  if (!url) {
    return '';
  }
  if (url && !startsWith(url, WEBSITE_PROTOCOL.HTTP) && !startsWith(url, WEBSITE_PROTOCOL.HTTPS)) {
    return `${WEBSITE_PROTOCOL.HTTPS}${url}`;
  }
  return url;
};

export const formatPhoneNumberBeforeSent = (phoneCode?: string, number?: string) => {
  if (!phoneCode || !number) {
    return '';
  }
  if (number.charAt(0) === '0') {
    return phoneCode + ' ' + number.slice(1).replaceAll(' ', '');
  }
  return phoneCode + ' ' + number.replaceAll(' ', '');
};

export const getDefaultPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return {};
  }
  return {
    phoneCode: split(phoneNumber, ' ')[0] || '',
    phoneNumber: split(phoneNumber, ' ')[1] || '',
  };
};

export const getCountryByPhoneCode = (phoneCode: string) => {
  if (!phoneCode) {
    return {
      code: '',
      label: '',
      phone: '',
    };
  }
  let country;
  if (startsWith(phoneCode, '+')) {
    country = find(COUNTRIES, (o) => '+' + o.phone === phoneCode);
  } else {
    country = find(COUNTRIES, (o) => '+' + o.phone === phoneCode);
  }

  if (!country) {
    return {
      code: '',
      label: '',
      phone: '',
    };
  }

  return country;
};

export const getCountryByCountryCode = (countryCode: string) => {
  if (!countryCode) {
    return {
      code: '',
      label: '',
      phone: '',
    };
  }
  const country = find(COUNTRIES, (o) => upperCase(o.code) === upperCase(countryCode));

  if (!country) {
    return {
      code: '',
      label: '',
      phone: '',
    };
  }

  return country;
};

export const getColorCompanyStatus = (status_code: CompanyStatusCode) => {
  switch (status_code) {
    case CompanyStatusCode.Success:
      return 'success';
    case CompanyStatusCode.Error:
      return 'error';
    case CompanyStatusCode.Warning:
      return 'warning';
    case CompanyStatusCode.Unknown:
      return 'default';
    default:
      return 'default';
  }
};

export const getColorContactStatus = (status_code: OtherContactStatusCode) => {
  switch (status_code) {
    case OtherContactStatusCode.Valid:
      return 'success';
    case OtherContactStatusCode.Invalid:
      return 'warning';
    case OtherContactStatusCode.Risky:
      return 'error';
    case OtherContactStatusCode.Unknown:
      return 'default';
    default:
      return 'default';
  }
};

export const getColorPercentMatch = (percentMatch: number | null) => {
  if (!isNumber(percentMatch)) return 'default';
  if (isNumber(percentMatch) && percentMatch >= 80) return 'success';
  if (isNumber(percentMatch) && percentMatch < 80 && percentMatch >= 25) return 'warning';
  return 'error';
};

export const renderTextOrDefault = (text?: string | number | null) => {
  if (isString(text) || isNumber(text)) {
    return text;
  }
  return EmptyText;
};

export const renderNumberOrDefault = (number?: number | null) => {
  if (!number) return EmptyText;

  return numeral(number).format('0,0');
};

export const convertSnakeCaseToUpperFirst = (text: string) => {
  return lowerCase(text)
    .split('_')
    .map((text) => upperFirst(text))
    .join(' ');
};

export const convertToStartCase = (text: string) => {
  return (text || '')
    .toLowerCase()
    .split(' ')
    .map((text) => upperFirst(text))
    .join(' ');
};

export const getCompanyAgeText = (company_age?: number | null) => {
  if (!isNumber(company_age)) return EmptyText;

  return company_age > 1 ? `${company_age} years` : `${company_age} year`;
};

export const checkCountryHaveState = (country_code: string) => {
  return COUNTRIES_HAVE_STATE.includes(country_code.toUpperCase());
};
