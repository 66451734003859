export class PeopleProfiles implements IResponseList<IPeopleProfile> {
  message?: string;
  status_code: number;
  warning?: string | null | undefined;
  data: IPeopleProfile[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  constructor() {
    (this.status_code = 200), (this.data = []);
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
  total_records: number;
}

export class PeopleProfileQuickView implements IResponse<IPeopleProfileQuickView> {
  message?: string | undefined;
  status_code: number;
  data: IPeopleProfileQuickView;
  detail?: string | undefined;
  errors?: ExpectedAny[] | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
  total_pages?: number | undefined;
  constructor() {
    this.data = { uuid: null, skills: [], companies: [] };
    this.status_code = 200;
  }
}

export class PeopleDetail implements IResponse<IPeopleDetail> {
  message: string;
  status_code: number;
  data: IPeopleDetail;
  detail?: string;
  errors?: ExpectedAny[];
  constructor() {
    this.message = '';
    this.data = {
      id: '',
      image_url: '',
      full_name: '',
      phone_numbers: [],
      birthday: '',
      country_name: '',
      country_code: '',
      is_sanctioned: false,
      description: '',
      job_summary: '',
      websites: [],
      emails: [],
      linkedin_url: '',
      twitter_url: '',
      facebook_url: '',
      addresses: [],
      educations: [],
      skills: [],
      updated_at: '',
    };
    this.status_code = 200;
  }
}

export class PeopleRelatedCompany implements IResponseList<IPeopleRelatedCompany> {
  status_code: number;
  data: IPeopleRelatedCompany[];
  message?: string;
  warning?: string | null | undefined;
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  constructor() {
    this.status_code = 200;
    this.data = [];
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
}

export class PeopleRelatedPeoples implements IResponseList<IPeopleRelatedPeople> {
  status_code: number;
  data: IPeopleRelatedPeople[];
  message?: string;
  warning?: string | null | undefined;
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  constructor() {
    this.status_code = 200;
    this.data = [];
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
}

export class PeopleInformationSuggestion implements IResponseList<string> {
  status_code: number;
  data: string[];
  message?: string;
  warning?: string | null | undefined;
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  constructor() {
    this.status_code = 200;
    this.data = [];
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
}
