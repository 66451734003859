import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

export const useQueryParam = (tab: string) => {
  const [searchParams] = useSearchParams();
  return searchParams.get(tab);
};

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();
  const parsed = queryString.parse(searchParams.toString());
  return parsed as any;
};
