import { AxiosRequestHeaders } from 'axios';
import { isArray } from 'lodash';
import { CommonResponse } from 'src/model/common';
import { ConstantSettings, ImageContributeUpload, ImageUpload } from 'src/model/utils';
import { CONSTANT_SETTING_TYPES } from 'src/services/api/config';
import { getRequest, postRequest } from 'src/services/api/http';
import { URL_ENDPOINT } from 'src/utils/constants/service';

function getConstantSetting(params: Record<string, CONSTANT_SETTING_TYPES>) {
  const url = URL_ENDPOINT.user.utils + 'constant-setting';
  return getRequest(url, params, new ConstantSettings()) as Promise<ConstantSettings>;
}

function handleUploadAvatarUser(file?: File) {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }

  const headers = {
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
  } as AxiosRequestHeaders;
  const url = URL_ENDPOINT.user.utils + 'upload-profile-images';
  return postRequest(url, formData, headers) as Promise<ImageUpload>;
}

function handleDeleteAvatar() {
  const url = URL_ENDPOINT.user.utils + 'delete-profile-images';
  return postRequest(url, {}) as Promise<CommonResponse>;
}

function handleUploadContributeImages(files?: Array<File>) {
  const formData = new FormData();
  if (isArray(files)) {
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  }

  const headers = {
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
  } as AxiosRequestHeaders;
  const url = URL_ENDPOINT.company.utils + 'upload-contribution-images';
  return postRequest(url, formData, headers, new CommonResponse()) as Promise<CommonResponse>;
}

function checkImageContributeNudity(requestId: string) {
  const url = URL_ENDPOINT.company.utils + 'nudity-check/' + requestId;
  return getRequest(url, {}, new ImageContributeUpload()) as Promise<ImageContributeUpload>;
}

function getImageContributeUrl(requestId: string) {
  const url = URL_ENDPOINT.company.utils + 'get-image/' + requestId;
  return getRequest(url, {}, new CommonResponse()) as Promise<CommonResponse>;
}
function checkImageNudity(requestId: string) {
  const url = URL_ENDPOINT.user.utils + 'nudity-check/' + requestId;
  return postRequest(url, {}) as Promise<ImageUpload>;
}
function getImageUrl(requestId: string) {
  const url = URL_ENDPOINT.user.utils + 'users/image-url/' + requestId;
  return getRequest(url, {}, new CommonResponse()) as Promise<CommonResponse>;
}
const UtilsService = {
  getConstantSetting,
  handleUploadAvatarUser,
  handleDeleteAvatar,
  handleUploadContributeImages,
  checkImageContributeNudity,
  getImageContributeUrl,
  checkImageNudity,
  getImageUrl,
};

export default UtilsService;
