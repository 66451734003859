import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { omit, get, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

// Components
import OtherContacts from 'src/features/CompanyDetail/Contacts/OtherContacts';
import ContactByRole from 'src/features/CompanyDetail/Contacts/ContactByRole';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import QuickViewContact from 'src/features/CompanyDetail/Common/QuickViewContact';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import ContributeForNoData from 'src/features/CompanyDetail/Common/ContributeForNoData';

// Utilities
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { useQueryCompanyContacts } from 'src/services/queries';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { CompanyContactPositionCode } from 'src/utils/constants/enum';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';

const ContactsTab = () => {
  const [open, setOpen] = useState(false);
  const [idSelect, setIdSelect] = useState('');
  const { id } = useParams();
  const { handleOpenContribute } = useCompanyContribute((state) => state);

  const { data, isFetching } = useQueryCompanyContacts({
    id: id || '',
  });
  const dataContacts = omit(data?.data, [CompanyContactPositionCode.Unknown, CompanyContactPositionCode.Employee]);
  const dataOtherContacts = get(data?.data, CompanyContactPositionCode.Unknown, []).concat(
    get(data?.data, CompanyContactPositionCode.Employee, []),
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (id: string) => {
    setIdSelect(id);
    setOpen(true);
  };

  const getTitlePosition = (
    positionCode: Omit<
      CompanyContactPositionCode,
      CompanyContactPositionCode.Employee | CompanyContactPositionCode.Unknown
    >,
  ) => {
    switch (positionCode) {
      case CompanyContactPositionCode.Director:
        return 'Directors';
      case CompanyContactPositionCode.BeneficialOwner:
        return 'Beneficial Owner';
      case CompanyContactPositionCode.Shareholder:
        return 'Shareholder';
      case CompanyContactPositionCode.RegisteredAgent:
        return 'Registered Agent';
      default:
        return '';
    }
  };

  if (isFetching) {
    return (
      <StyledWhiteBox>
        <LoadingSkeleton count={5} height={50} />
      </StyledWhiteBox>
    );
  }

  if (
    Object.values(dataContacts)
      .concat([dataOtherContacts])
      .every((item) => isEmpty(item))
  ) {
    return (
      <StyledWhiteBox display="flex" flexDirection="column">
        <ContributeForNoData action={() => handleOpenContribute(TypeContribute.ContactInformation)} />
      </StyledWhiteBox>
    );
  }

  return (
    <React.Fragment>
      {open && <QuickViewContact id={idSelect} open={open} handleClose={handleClose} />}
      <Stack spacing={3}>
        {Object.keys(dataContacts).map(
          (
            key: Omit<
              CompanyContactPositionCode,
              CompanyContactPositionCode.Employee | CompanyContactPositionCode.Unknown
            >,
            index,
          ) => (
            <StyledWhiteBox key={String(key) + index}>
              <ContactByRole
                handleOpen={handleOpen}
                listContact={getArrayDefault(get(dataContacts, key.toString()))}
                title={getTitlePosition(key)}
              />
            </StyledWhiteBox>
          ),
        )}
        {getArrayDefault(dataOtherContacts).length > 0 && (
          <StyledWhiteBox>
            <OtherContacts handleOpen={handleOpen} listContact={getArrayDefault(dataOtherContacts)} />
          </StyledWhiteBox>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default ContactsTab;
