import BillOfLandingDetail from 'src/features/BillOfLandingDetail';

const BillOfLandingDetailPage = () => {
  return (
    <>
      <BillOfLandingDetail />
    </>
  );
};

export default BillOfLandingDetailPage;
