import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Avatar, Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

//Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import FormItemAddress from 'src/components/Form/FormItemAddress';
import ButtonContinue from 'src/features/Onboarding/ButtonContinue';
import useUploadImage from 'src/hooks/useUploadImage';
import { DEFAULT_AVATARS } from 'src/utils/constants/imagesUrl';
import { checkIsValidType } from 'src/utils/helpers/utilities';
import { useOnboardingStore } from 'src/zustand/useOnboardingStore';

interface IProps {
  onClickContinue: () => void;
}

const StepGetStarted = (props: IProps) => {
  const theme = useTheme();
  const { dispatch, address, avatar } = useOnboardingStore((state) => state);
  const { isPending, onUploadImage } = useUploadImage();

  const renderAvatar = () => {
    return DEFAULT_AVATARS.map((avatar: string, i: number) => (
      <span
        key={i}
        onClick={() => {
          dispatch({
            avatar: avatar,
          });
        }}
      >
        <Avatar src={avatar} sx={{ width: 50, height: 50, cursor: 'pointer' }} />
      </span>
    ));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: address,
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required('Address is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
    validateOnMount: true,
  });

  //Update Data form onboarding global state
  useEffect(() => {
    dispatch({
      address: formik?.values?.address,
    });
  }, [formik.values]);

  const handleUploadAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file || !checkIsValidType(file)) return;

    const avatar = await onUploadImage(file);
    dispatch({
      avatar: avatar,
    });
  };

  return (
    <FormikForm formik={formik}>
      <Typography textAlign="center" variant="h4">
        Welcome 👋🏻 <br />
        Let’s create your profile
      </Typography>
      <Box sx={{ mt: 1 }} />
      <Typography variant="body1" textAlign="center" color="text.secondary">
        Let others get to know you better!
      </Typography>

      <Divider sx={{ mt: 4, mb: 4 }} flexItem orientation="horizontal" />

      <Typography variant="subtitle2">Add your avatar</Typography>

      <Box sx={{ mt: 2 }} />

      <Stack height={150} direction={'row'} justifyContent={'space-between'} spacing={8}>
        <Avatar src={avatar} sx={{ height: 150, width: 150 }} />
        <Stack justifyContent={'space-between'} direction={'column'} spacing={1}>
          <ButtonWithLoading
            endIcon={<FileUploadOutlinedIcon fontSize="small" sx={{ marginLeft: theme.spacing(1) }} />}
            sx={{ py: 1.5 }}
            isLoading={isPending}
            component="label"
            isRounded
            color="primary"
            variant="outlined"
          >
            <Stack alignItems={'center'}>
              <span>Upload your picture</span>
              <Typography fontWeight={700} color={'inherit'} fontSize={10}>
                PNG, JPEG, JPG (max. 1MB)
              </Typography>
            </Stack>
            <input onChange={handleUploadAvatar} hidden accept="image/*" multiple type="file" />
          </ButtonWithLoading>
          <Typography color={theme.palette.text.secondary} variant="body2">
            Or choose one of our standard avatars.
          </Typography>
          <Stack gap={0.3} direction={'row'} flexWrap={'wrap'} p={1}>
            {renderAvatar()}
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ mt: 4 }} />

      <Typography variant="subtitle2">Add your location</Typography>

      <Box sx={{ mt: 2 }} />

      <FormItemAddress required id="address" />

      <Box sx={{ mt: 4 }} />

      <ButtonContinue
        disabled={!formik.isValid}
        onClick={() => {
          if (formik.isValid) {
            props.onClickContinue();
          }
        }}
      />
    </FormikForm>
  );
};

export default StepGetStarted;
