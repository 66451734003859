import { Box, Button, MobileStepper, useTheme } from '@mui/material';
import React from 'react';

// Components
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { LOGO_COMPANY_DEFAULT_URL } from 'src/utils/constants/imagesUrl';

const CarouselImages = ({ images, startIndex = 0 }: { images: Array<{ url: string }>; startIndex?: number }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(startIndex);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Box
        component={'img'}
        width="800px"
        height={600}
        src={images[activeStep].url}
        border={`1px solid ${theme.palette.divider}`}
        borderRadius={theme.shape.borderRadius / 4}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = LOGO_COMPANY_DEFAULT_URL;
        }}
        sx={{ objectFit: 'cover' }}
      />
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </Box>
  );
};
export default CarouselImages;
