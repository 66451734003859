import { Pagination } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';

const StyledPagination = ({
  total_pages,
  page,
  onChange,
}: {
  total_pages: number;
  page: number;
  onChange: (_: React.ChangeEvent<unknown>, value: number) => void;
}) => {
  const theme = useTheme();

  return (
    <Pagination
      sx={{
        gap: theme.spacing(0.75),
        '& .MuiPagination-ul': {
          justifyContent: 'center',
        },
      }}
      onChange={onChange}
      color="primary"
      page={page}
      count={total_pages}
      size="small"
      shape="rounded"
    />
  );
};

export default StyledPagination;
