import { IconButton, IconButtonProps, SvgIconProps, Tooltip, useTheme } from '@mui/material';
import { Bookmark } from 'react-feather';
import IconFeather from 'src/components/common/Icon';

interface IProps {
  buttonProps?: IconButtonProps;
  iconProps?: SvgIconProps;
  isMarked: boolean;
}

const ButtonBookmark = ({ isMarked, buttonProps, iconProps }: IProps) => {
  const { palette } = useTheme();

  return (
    <Tooltip placement="top" title="Bookmarks Coming Soon!">
      <IconButton
        color={isMarked ? 'primary' : 'default'}
        sx={{
          border: isMarked ? '1px solid' + palette.primaryStateHover.main : '1px solid' + palette.divider,
          background: isMarked ? palette.primaryStateHover.main : 'transparent',
        }}
        {...buttonProps}
      >
        <IconFeather muiProps={iconProps} icon={<Bookmark fill={isMarked ? palette.primary.main : 'transparent'} />} />
      </IconButton>
    </Tooltip>
  );
};

export default ButtonBookmark;
