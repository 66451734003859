import { Box, BoxProps, useTheme } from '@mui/material';

type IProps = BoxProps;
const WhiteBoxContent = ({ children, ...others }: IProps) => {
  const {
    palette: { background },
    shape: { borderRadius },
  } = useTheme();
  return (
    <Box textAlign={'left'} borderRadius={borderRadius / 2} bgcolor={background.default} padding={3} {...others}>
      {children}
    </Box>
  );
};

export default WhiteBoxContent;
