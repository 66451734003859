import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikContextType } from 'formik';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { Edit } from 'react-feather';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import IconFeather from 'src/components/common/Icon';
import { TypeChange } from 'src/features/Setting/Password';
import { useMutateCheckEmail } from 'src/services/mutate';
import { LINK_DATA_BREACHES } from 'src/utils/constants/config';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { validateEmail } from 'src/utils/helpers/validate';

const FormItemUserName = ({
  formik,
  isEdit,
  onCancel,
  onClickEdit,
  open2FA,
}: {
  formik: FormikContextType<ExpectedAny>;
  isEdit: { type: TypeChange; visible: boolean };
  onCancel: () => void;
  onClickEdit: () => void;
  open2FA: () => void;
}) => {
  const theme = useTheme();
  const { mutateAsync, isPending } = useMutateCheckEmail();
  const [showWarning, setShowWarning] = useState(false);

  const handleChange = async () => {
    if (formik.values.email === formik.initialValues.email) {
      onCancel();
      return;
    }
    if (formik.errors.email) return;

    open2FA();
  };

  const checkEmail = useCallback(
    debounce(async (e) => {
      if (e.target.value && validateEmail(String(e.target.value)) && e.target.value !== formik.initialValues.email) {
        const resCheckEmail = await mutateAsync({ email: String(e.target.value) });
        if (resCheckEmail.status_code !== SUCCESS_CODE.GET) {
          formik.setFieldError('email', 'This email address is already registered.');
          setShowWarning(false);
          return;
        }
        if (resCheckEmail.warning) {
          formik.setFieldError('email', 'Please confirm to continue');
          setShowWarning(true);
          return;
        }
      }
      setShowWarning(false);
    }, 1000),
    [],
  );

  const renderWaningPopup = () => {
    if (showWarning) {
      return (
        <Box>
          <Alert severity="warning">
            <AlertTitle>Security Warning</AlertTitle>
            Please note that your email address has been identified as having previously been included in an online data
            breach incident. To avoid unintentional access to your account, we strongly recommend that you use a new,
            never-before-used password for your ADAMftd account, to help ensure the security of your account.
            <Box mt={1} mb={1}>
              <Link sx={{ margin: 0 }} target="_blank" href={LINK_DATA_BREACHES}>
                Click here to learn more about data breaches
              </Link>
            </Box>
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <Checkbox
                  size="small"
                  onChange={(e, value) => {
                    formik.setFieldValue('confirm_agree_data_breaches', value);
                    if (value) {
                      formik.setFieldError('email', '');
                    } else {
                      formik.setFieldError('email', 'Please confirm to continue');
                    }
                  }}
                  checked={formik.values.confirm_agree_data_breaches}
                  color="secondary"
                />
              }
              label={
                <Typography color={theme.palette.secondary.main} variant="body2">
                  I confirm that I will use a new email and password combination, that has not previously been used on
                  any other website .
                </Typography>
              }
            />
          </Alert>
        </Box>
      );
    }
  };

  const renderFormEdit = (
    <Stack spacing={3}>
      <Stack alignItems={'baseline'} direction={'row'} spacing={2}>
        <TextField
          value={formik.values.email}
          sx={{ width: 350 }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          onChange={(e) => {
            formik.handleChange(e);
            checkEmail(e);
          }}
          onBlur={formik.handleBlur}
          id="email"
          name="email"
          size="small"
          label="Email Account"
          helperText={<>{formik.touched.email && formik.errors.email}</>}
        />
        <ButtonWithLoading
          type="submit"
          disabled={isPending || !!formik.errors.email || formik.values.email === formik.initialValues.email}
          isLoading={isPending}
          onClick={handleChange}
          size="large"
          variant="contained"
        >
          CHANGE
        </ButtonWithLoading>
        <ButtonWithLoading onClick={onCancel} size="large" variant="text" color="inherit">
          CANCEL
        </ButtonWithLoading>
      </Stack>
      {renderWaningPopup()}
    </Stack>
  );

  const renderFormNoEdit = (
    <Box>
      <Typography variant="subtitle1">Email Account</Typography>

      <Stack alignItems={'center'} direction={'row'} gap={2}>
        <Typography color={theme.palette.text.secondary} variant="body1">
          {formik.values.email}
        </Typography>

        <Chip size="small" color="success" label="VERIFIED" />

        <IconButton onClick={onClickEdit}>
          <IconFeather muiProps={{ color: 'primary' }} icon={<Edit size={24} />} />
        </IconButton>
      </Stack>
    </Box>
  );

  return isEdit.type === TypeChange.Email && isEdit.visible ? renderFormEdit : renderFormNoEdit;
};

export default FormItemUserName;
