import { SvgIconProps } from '@mui/material';
import { Flag as FlagIcon } from 'react-feather';
import Flag from 'react-world-flags';
import IconFeather from 'src/components/common/Icon';

const FlagCountry = ({
  code,
  width = 16,
  colorDefaultFlag = '',
  iconProps = {},
  className = '',
}: {
  code: string;
  width?: number;
  colorDefaultFlag?: string;
  iconProps?: SvgIconProps;
  className?: string;
}) => {
  return (
    <Flag
      width={width}
      code={code}
      className={className}
      fallback={
        <IconFeather
          muiProps={{ fontSize: 'small', color: 'disabled', sx: { color: colorDefaultFlag }, ...iconProps }}
          icon={<FlagIcon />}
        />
      }
    />
  );
};

export default FlagCountry;
