import { Typography, Box, Stack, useTheme, Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';

// Components
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import Table from 'src/components/Table';
import TextWithTooltip from 'src/components/common/TextWithTooltip';

// Utilities
import TradeImg from 'src/asset/images/tariff-and-rule/saving-money-bank.svg';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { get } from 'lodash';
import { EmptyText } from 'src/utils/constants/config';

const TradeRemedies = ({ dataTradeRemedy, isFetching }: { dataTradeRemedy?: ITradeRemedy; isFetching: boolean }) => {
  const theme = useTheme();
  const [tradeRemedyItem, setTradeRemedyItem] = useState<ITradeRemedyData | null>(null);

  const configColumnTariff: ConfigCell<ITradeRemedyData>[] = [
    {
      id: 'remedy_type',
      disablePadding: true,
      label: 'Remedy Type',
      cell: (row: ITradeRemedyData) => {
        return (
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {row.RemedyType || EmptyText}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'remedy_status',
      disablePadding: true,
      label: 'Remedy Status',
      cell: (row: ITradeRemedyData) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {get(row, 'TradeRemedyDetails[0].RemedyStatus', EmptyText)}
          </Typography>
        );
      },
    },
    {
      id: 'start_date',
      disablePadding: true,
      label: 'Start Date',
      cell: (row: ITradeRemedyData) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {get(row, 'TradeRemedyDetails[0].StartDate', EmptyText)}
          </Typography>
        );
      },
    },
    {
      id: 'end_date',
      disablePadding: true,
      label: 'End Date',
      cell: (row: ITradeRemedyData) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {get(row, 'TradeRemedyDetails[0].EndDate', EmptyText)}
          </Typography>
        );
      },
    },
  ];

  const dataExport = useMemo(() => {
    return get(tradeRemedyItem, 'TradeRemedyDetails[0].CompanyDetails', []);
  }, [tradeRemedyItem]);

  const configColumnExport: ConfigCell<ITradeRemedyCompanyDetails>[] = [
    {
      id: 'exporting_firm',
      disablePadding: true,
      label: 'Exporting Firm',
      cell: (row: ITradeRemedyCompanyDetails) => {
        return (
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {row.ExportingFirm}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'measure',
      disablePadding: true,
      label: 'Measure',
      cell: (row: ITradeRemedyCompanyDetails) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.Duty}
          </Typography>
        );
      },
    },
    {
      id: 'note',
      disablePadding: true,
      label: 'Note',
      cell: (row: ITradeRemedyCompanyDetails) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.Note}
          </Typography>
        );
      },
    },
  ];

  if (isFetching) {
    return <SkeletonContent />;
  }

  return (
    <StyledWhiteBox spacing={2}>
      <Typography variant="h6">
        Trade Remedies{' '}
        <TextWithTooltip title="Trade remedies are trade policy tools that allow governments to take remedial action against imports." />
      </Typography>
      {getArrayDefault(dataTradeRemedy?.TradeRemedyData).length > 0 ? (
        <Table
          data={getArrayDefault(dataTradeRemedy?.TradeRemedyData)}
          configColumn={configColumnTariff}
          dataKey="RemedyId"
          isPagination={false}
          onRowClick={(data: ITradeRemedyData) => {
            setTradeRemedyItem(data);
          }}
        />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          p={2}
          bgcolor={theme.palette.background.paper2}
          borderRadius={theme.shape.borderRadius / 2}
        >
          <Box width={150} src={TradeImg} component="img" flex={1} />
          <Typography variant="body2" color="text.secondary">
            Does not apply any trade remedy on the{' '}
            <Typography variant="subtitle2" component="span" color="text.secondary">
              Selected Product.
            </Typography>
          </Typography>
        </Stack>
      )}

      {tradeRemedyItem && (
        <Table data={dataExport} configColumn={configColumnExport} dataKey="StatusCode" isPagination={false} />
      )}
    </StyledWhiteBox>
  );
};

export default TradeRemedies;

const SkeletonContent = () => {
  return (
    <StyledWhiteBox spacing={2}>
      <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
      <Stack>
        <Skeleton animation="wave" variant="rectangular" height={200} />
      </Stack>
    </StyledWhiteBox>
  );
};
