export enum RelationshipData {
  OwnEntity = 'own_entity',
  Staff = 'staff',
  BusinessPartner = 'business_partner',
  Supplier = 'supplier',
  Customer = 'customer',
  Other = 'other',
}

export const RELATIONSHIP_DATA = [
  { value: RelationshipData.OwnEntity, label: 'Own entity' },
  { value: RelationshipData.Staff, label: 'Staff' },
  { value: RelationshipData.BusinessPartner, label: 'Business Partner' },
  { value: RelationshipData.Supplier, label: 'Supplier' },
  { value: RelationshipData.Customer, label: 'Customer' },
  { value: RelationshipData.Other, label: 'Other' },
];

export enum PeopleRelatedRole {
  Chairman = 'chairman',
  ChiefExecutiveOfficer = 'chief_executive_officer',
  Director = 'director',
  ExportPromotionAgency = 'export_promotion_agency',
  Manager = 'manager',
  TeamLeader = 'team_leader',
  TeamMember = 'team_member',
}

export const PEOPLE_RELATED_ROLE = [
  { value: PeopleRelatedRole.Chairman, label: 'Chairman' },
  { value: PeopleRelatedRole.ChiefExecutiveOfficer, label: 'Chief Executive Officer' },
  { value: PeopleRelatedRole.Director, label: 'Director' },
  { value: PeopleRelatedRole.ExportPromotionAgency, label: 'Export Promotion Agency' },
  { value: PeopleRelatedRole.Manager, label: 'Manager' },
  { value: PeopleRelatedRole.TeamLeader, label: 'Team Leader' },
  { value: PeopleRelatedRole.TeamMember, label: 'Team Member' },
];

export enum TypeContribute {
  ClaimCompanyProfile = 'claim_company_profile',
  Email = 'email_company_profile',
  WebsiteCompanyProfile = 'website_company_profile',
  RelatedCorporate = 'related_corporate_company_profile',
  OtherNames = 'other_names_company_profile',
  ContactInformation = 'contact_company_profile',
  SocialMedia = 'social_media_company_profile',
  RegisteredAddressCompany = 'registered_address_company_profile',
  PrimaryAddressCompany = 'primary_address_company_profile',
  AlternativeAddressCompany = 'alternative_address_company_profile',
  Review = 'review_company_profile',
  ReportWrongOtherContact = 'report_wrong_other_contact',
}

export const TYPE_CONTRIBUTE = [
  { value: TypeContribute.ClaimCompanyProfile, label: 'Claim Your Company Profile' },
  { value: TypeContribute.Email, label: 'Email - Company Profile' },
  { value: TypeContribute.WebsiteCompanyProfile, label: 'Website - Company Profile' },
  { value: TypeContribute.RelatedCorporate, label: 'People Related Corporates - Company Profile' },
  { value: TypeContribute.OtherNames, label: 'Other Names - Company Profile' },
  { value: TypeContribute.ContactInformation, label: 'Contact  - Company Profile' },
  { value: TypeContribute.SocialMedia, label: 'Social Media - Company Profile' },
  { value: TypeContribute.RegisteredAddressCompany, label: 'Registered Address - Company Profile' },
  { value: TypeContribute.PrimaryAddressCompany, label: 'Primary Address - Company Profile' },
  { value: TypeContribute.AlternativeAddressCompany, label: 'Alternative Addresses - Company Profile' },
  { value: TypeContribute.Review, label: 'Review - Company Profile', disabled: true },
  {
    value: TypeContribute.ReportWrongOtherContact,
    label: 'Other Contact Company Profile - Report Wrong Data',
    disabled: true,
  },
];

// List types can contribute multi records
export const TYPE_CONTRIBUTE_ARRAY = [
  TypeContribute.Email,
  TypeContribute.WebsiteCompanyProfile,
  TypeContribute.RelatedCorporate,
  TypeContribute.OtherNames,
  TypeContribute.ContactInformation,
  TypeContribute.SocialMedia,
];

export enum FormSearchSortBy {
  CompanyName = 'company_name',
  OldestFirst = 'oldest_first',
  RecentFirst = 'recent_first',
}

export const SORT_BY_LIST = [
  { value: FormSearchSortBy.CompanyName, label: 'Company name, alphabetical' },
  { value: FormSearchSortBy.OldestFirst, label: 'Incorporation date, oldest first' },
  { value: FormSearchSortBy.RecentFirst, label: 'Incorporation date, most recent first' },
];

export interface IPropsDataContribute {
  type_contribute: TypeContribute;
  relation_to_data: string;
  note: string;
  is_confirm: boolean;
  contribution_claim_your_company_profile: {
    company_email: string;
  };
  contribution_email_company_profile: Array<{ email: string }>;
  contribution_website_company_profile: Array<{ website: string }>;
  contribution_other_names_company_profile: Array<{ other_name: string }>;
  contribution_social_media_company_profile: Array<{ social_url: string }>;
  contribution_related_corporates: Array<{ people_name: string; people_role: string }>;
  contribution_contact_company_profile: Array<{
    full_name: string;
    title: string;
    email: string;
    phone_number: string;
    phone_code: string;
  }>;
  contribution_registered_address_company_profile: Array<string>;
  contribution_alternative_address_company_profile: Array<string>;
  contribution_primary_address_company_profile: Array<string>;
}
export interface IPropsDataReviewCompany {
  type_contribute: TypeContribute;
  relationship_to_data: string;
  is_confirm: boolean;
  rating_value: number;
  review_text: string;
}

export interface IPropsDataReportWrong {
  type_contribute: TypeContribute;
  relation_to_data: string;
  note: string;
  is_confirm: boolean;
  old_data_full_name: string;
  old_data_title: string;
  old_data_email: string;
  old_data_phone_number: string;
  new_data_full_name: string;
  new_data_title: string;
  new_data_email: string;
  new_data_phone_number: string;
  phone_code: string;
}
