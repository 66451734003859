import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ReactNode, useEffect, useMemo } from 'react';

// Components
import PaperSelect from 'src/components/common/PaperSelect';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';

// Utilities
import { find } from 'lodash';
import { useQueryNationalCodeTariffs } from 'src/services/queries';
import { LEVEL_PRODUCT } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

interface IProps {
  id: string;
  countryCode: string;
  hsCode: string;
  required?: boolean;
  tooltipText?: string;
  label?: ReactNode;
  disabled?: boolean;
}

const FormItemNationalTariffCode = ({
  id,
  countryCode,
  hsCode,
  tooltipText = '',
  label,
  required = false,
  disabled = false,
}: IProps) => {
  const formik = useFormikContext<ExpectedAny>();

  const { data, isFetching } = useQueryNationalCodeTariffs({
    countryCode: countryCode,
    level: LEVEL_PRODUCT.NATIONAL_CODE,
    hsCode: hsCode,
  });

  useEffect(() => {
    if (getArrayDefault(data.data).length > 0) {
      formik.setFieldValue(id, getArrayDefault(data.data)[0]?.Code);
    }
  }, [hsCode, data.data]);

  const getProductByCode = (productCode: string) => {
    if (!productCode) {
      return null;
    }
    const product = find(data.data, (o) => o.Code === productCode);

    if (!product) {
      return null;
    }

    return product;
  };

  const product = useMemo(() => {
    return getProductByCode(formik.values[id]);
  }, [formik.values[id], data.data]);

  return (
    <Autocomplete
      value={product}
      onChange={(_, value) => {
        formik.setFieldValue(id, value?.Code);
      }}
      loading={isFetching}
      onBlur={formik.handleBlur}
      id={id}
      fullWidth
      size="small"
      autoComplete
      options={getArrayDefault(data.data)}
      disabled={disabled}
      // popupIcon={<IconFeather muiProps={{ fontSize: 'small' }} icon={<Search />} />}
      PaperComponent={PaperSelect}
      autoHighlight
      getOptionLabel={(option) => (option ? `${option.Code} - ${option.Name}` : '')}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          InputLabelProps={{
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: isFetching ? <CircularProgress color="inherit" size={16} /> : null,
          }}
          placeholder="Typing..."
          // onChange={handleChangeHsCode}
          label={
            label ? label : <span>Product / HS Code {tooltipText && <TooltipFormLabel title={tooltipText} />}</span>
          }
        />
      )}
    />
  );
  return (
    <TextField
      type="select"
      id={id}
      name={id}
      value={formik.values[id]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      size="small"
      select
      fullWidth
      required={required}
      disabled={disabled}
      InputProps={{
        startAdornment: isFetching ? <CircularProgress color="inherit" size={16} /> : null,
      }}
      label={
        label ? (
          label
        ) : (
          <span>Product National Tariff Line Code {tooltipText && <TooltipFormLabel title={tooltipText} />}</span>
        )
      }
    >
      {getArrayDefault(data.data).map((type) => (
        <MenuItem value={type.Code} key={type.Code}>
          {`${type.Code} - ${type.Name}`}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default FormItemNationalTariffCode;
