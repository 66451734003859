export default {
  "United States - Alaska": true,
  "United States - California": true,
  "United States - Colorado": true,
  "United States - Delaware": true,
  "United States - District of Columbia": true,
  "United States - Florida": true,
  "United States - Idaho": true,
  "United States - Illinois": true,
  "United States - Iowa": true,
  "United States - Nevada": true,
  "United States - New York": true,
  "United States - Washington": true,
  "Singapore": true,
  "Norway": true,
  "Slovakia": true,
  "India": true,
  "Canada": true,
} as any
