const NATIONALITIES = {
  Global: 'zz',
  'European Union': 'eu',
  Zaire: 'zr',
  'Czech Republic': 'cz',
  Kosovo: 'xk',
  'East Germany': 'dd',
  Yugoslavia: 'yucs',
  'Serbia and Montenegro': 'csxx',
  Czechoslovakia: 'cshh',
  'Soviet Union': 'suhh',
  'Abkhazia (Occupied Georgia)': 'ge-ab',
  'South Ossetia (Occupied Georgia)': 'x-so',
  'Luhansk (Occupied Ukraine)': 'ua-lpr',
  'Donetsk (Occupied Ukraine)': 'ua-dpr',
  'Crimea (Occupied Ukraine)': 'ua-cri',
  Somaliland: 'so-som',
  'Northern Cyprus': 'cy-trnc',
  'Nagorno-Karabakh': 'az-nk',
  Tibet: 'cn-xz',
  Sark: 'gg-srk',
  Wales: 'gb-wls',
  Scotland: 'gb-sct',
  'Northern Ireland': 'gb-nir',
  'Transnistria (PMR)': 'md-pmr',
  'Ascension Island': 'ac',
  Andorra: 'ad',
  'United Arab Emirates': 'ae',
  Afghanistan: 'af',
  'Antigua & Barbuda': 'ag',
  Anguilla: 'ai',
  Albania: 'al',
  Armenia: 'am',
  Angola: 'ao',
  Antarctica: 'aq',
  Argentina: 'ar',
  'American Samoa': 'as',
  Austria: 'at',
  Australia: 'au',
  Aruba: 'aw',
  'Åland Islands': 'ax',
  Azerbaijan: 'az',
  'Bosnia & Herzegovina': 'ba',
  Barbados: 'bb',
  Bangladesh: 'bd',
  Belgium: 'be',
  'Burkina Faso': 'bf',
  Bulgaria: 'bg',
  Bahrain: 'bh',
  Burundi: 'bi',
  Benin: 'bj',
  'St. Barthélemy': 'bl',
  Bermuda: 'bm',
  Brunei: 'bn',
  Bolivia: 'bo',
  'Caribbean Netherlands': 'bq',
  Brazil: 'br',
  Bahamas: 'bs',
  Bhutan: 'bt',
  'Bouvet Island': 'bv',
  Botswana: 'bw',
  Belarus: 'by',
  Belize: 'bz',
  Canada: 'ca',
  'Cocos (Keeling) Islands': 'cc',
  'Congo - Kinshasa': 'cd',
  'Central African Republic': 'cf',
  'Congo - Brazzaville': 'cg',
  Switzerland: 'ch',
  'Côte d’Ivoire': 'ci',
  'Cook Islands': 'ck',
  Chile: 'cl',
  Cameroon: 'cm',
  China: 'cn',
  Colombia: 'co',
  'Clipperton Island': 'cp',
  'Costa Rica': 'cr',
  Cuba: 'cu',
  'Cape Verde': 'cv',
  Curaçao: 'cw',
  'Christmas Island': 'cx',
  Cyprus: 'cy',
  Germany: 'de',
  'Diego Garcia': 'dg',
  Djibouti: 'dj',
  Denmark: 'dk',
  Dominica: 'dm',
  'Dominican Republic': 'do',
  Algeria: 'dz',
  'Ceuta & Melilla': 'ea',
  Ecuador: 'ec',
  Estonia: 'ee',
  Egypt: 'eg',
  'Western Sahara': 'eh',
  Eritrea: 'er',
  Spain: 'es',
  Ethiopia: 'et',
  Eurozone: 'ez',
  Finland: 'fi',
  Fiji: 'fj',
  'Falkland Islands': 'fk',
  Micronesia: 'fm',
  'Faroe Islands': 'fo',
  France: 'fr',
  Gabon: 'ga',
  'United Kingdom': 'gb',
  Grenada: 'gd',
  Georgia: 'ge',
  'French Guiana': 'gf',
  Guernsey: 'gg',
  Ghana: 'gh',
  Gibraltar: 'gi',
  Greenland: 'gl',
  Gambia: 'gm',
  Guinea: 'gn',
  Guadeloupe: 'gp',
  'Equatorial Guinea': 'gq',
  Greece: 'gr',
  'South Georgia & South Sandwich Islands': 'gs',
  Guatemala: 'gt',
  Guam: 'gu',
  'Guinea-Bissau': 'gw',
  Guyana: 'gy',
  'Hong Kong SAR China': 'hk',
  'Heard & McDonald Islands': 'hm',
  Honduras: 'hn',
  Croatia: 'hr',
  Haiti: 'ht',
  Hungary: 'hu',
  'Canary Islands': 'ic',
  Indonesia: 'id',
  Ireland: 'ie',
  Israel: 'il',
  'Isle of Man': 'im',
  India: 'in',
  'British Indian Ocean Territory': 'io',
  Iraq: 'iq',
  Iran: 'ir',
  Iceland: 'is',
  Italy: 'it',
  Jersey: 'je',
  Jamaica: 'jm',
  Jordan: 'jo',
  Japan: 'jp',
  Kenya: 'ke',
  Kyrgyzstan: 'kg',
  Cambodia: 'kh',
  Kiribati: 'ki',
  Comoros: 'km',
  'St. Kitts & Nevis': 'kn',
  'North Korea': 'kp',
  'South Korea': 'kr',
  Kuwait: 'kw',
  'Cayman Islands': 'ky',
  Kazakhstan: 'kz',
  Laos: 'la',
  Lebanon: 'lb',
  'St. Lucia': 'lc',
  Liechtenstein: 'li',
  'Sri Lanka': 'lk',
  Liberia: 'lr',
  Lesotho: 'ls',
  Lithuania: 'lt',
  Luxembourg: 'lu',
  Latvia: 'lv',
  Libya: 'ly',
  Morocco: 'ma',
  Monaco: 'mc',
  Moldova: 'md',
  Montenegro: 'me',
  'St. Martin': 'mf',
  Madagascar: 'mg',
  'Marshall Islands': 'mh',
  'North Macedonia': 'mk',
  Mali: 'ml',
  'Myanmar (Burma)': 'mm',
  Mongolia: 'mn',
  'Macao SAR China': 'mo',
  'Northern Mariana Islands': 'mp',
  Martinique: 'mq',
  Mauritania: 'mr',
  Montserrat: 'ms',
  Malta: 'mt',
  Mauritius: 'mu',
  Maldives: 'mv',
  Malawi: 'mw',
  Mexico: 'mx',
  Malaysia: 'my',
  Mozambique: 'mz',
  Namibia: 'na',
  'New Caledonia': 'nc',
  Niger: 'ne',
  'Norfolk Island': 'nf',
  Nigeria: 'ng',
  Nicaragua: 'ni',
  Netherlands: 'nl',
  Norway: 'no',
  Nepal: 'np',
  Nauru: 'nr',
  Niue: 'nu',
  'New Zealand': 'nz',
  Oman: 'om',
  Panama: 'pa',
  Peru: 'pe',
  'French Polynesia': 'pf',
  'Papua New Guinea': 'pg',
  Philippines: 'ph',
  Pakistan: 'pk',
  Poland: 'pl',
  'St. Pierre & Miquelon': 'pm',
  'Pitcairn Islands': 'pn',
  'Puerto Rico': 'pr',
  'Palestinian Territories': 'ps',
  Portugal: 'pt',
  Palau: 'pw',
  Paraguay: 'py',
  Qatar: 'qa',
  'Outlying Oceania': 'qo',
  Réunion: 're',
  Romania: 'ro',
  Serbia: 'rs',
  Russia: 'ru',
  Rwanda: 'rw',
  'Saudi Arabia': 'sa',
  'Solomon Islands': 'sb',
  Seychelles: 'sc',
  Sudan: 'sd',
  Sweden: 'se',
  Singapore: 'sg',
  'St. Helena': 'sh',
  Slovenia: 'si',
  'Svalbard & Jan Mayen': 'sj',
  Slovakia: 'sk',
  'Sierra Leone': 'sl',
  'San Marino': 'sm',
  Senegal: 'sn',
  Somalia: 'so',
  Suriname: 'sr',
  'South Sudan': 'ss',
  'São Tomé & Príncipe': 'st',
  'El Salvador': 'sv',
  'Sint Maarten': 'sx',
  Syria: 'sy',
  Eswatini: 'sz',
  'Tristan da Cunha': 'ta',
  'Turks & Caicos Islands': 'tc',
  Chad: 'td',
  'French Southern Territories': 'tf',
  Togo: 'tg',
  Thailand: 'th',
  Tajikistan: 'tj',
  Tokelau: 'tk',
  'Timor-Leste': 'tl',
  Turkmenistan: 'tm',
  Tunisia: 'tn',
  Tonga: 'to',
  Türkiye: 'tr',
  'Trinidad & Tobago': 'tt',
  Tuvalu: 'tv',
  Taiwan: 'tw',
  Tanzania: 'tz',
  Ukraine: 'ua',
  Uganda: 'ug',
  'U.S. Outlying Islands': 'um',
  'United Nations': 'un',
  'United States': 'us',
  Uruguay: 'uy',
  Uzbekistan: 'uz',
  'Vatican City': 'va',
  'St. Vincent & Grenadines': 'vc',
  Venezuela: 've',
  'British Virgin Islands': 'vg',
  'U.S. Virgin Islands': 'vi',
  Vietnam: 'vn',
  Vanuatu: 'vu',
  'Wallis & Futuna': 'wf',
  Samoa: 'ws',
  'Pseudo-Accents': 'xa',
  'Pseudo-Bidi': 'xb',
  Yemen: 'ye',
  Mayotte: 'yt',
  'South Africa': 'za',
  Zambia: 'zm',
  Zimbabwe: 'zw',
};

export { NATIONALITIES };
