import {
  Container,
  Box,
  Typography,
  Grid,
  useTheme,
  styled,
  List,
  ListItemText,
  ListItemButton,
  Stack,
} from '@mui/material';
import React from 'react';
import { LIST_MENU_SIDEBAR_DISCLAIMER, MenuDisclaimer } from './config';

//Components
// import ButtonBack from 'src/components/common/ButtonBack';
import Footer from 'src/components/Footer';
import SignatureImg from 'src/asset/images/signature.png';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
  height: 250,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(7),
  marginTop: 8,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  '&.Mui-selected': {
    borderRight: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const GeneralDisclaimer = () => {
  const theme = useTheme();
  const [tab, setTab] = React.useState(MenuDisclaimer.DataAccuracySourcing);

  const onClick = (id: MenuDisclaimer) => {
    setTab(id);
    const ele = document.getElementById(id);
    if (ele) {
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        background: theme.palette.background.paper1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <ButtonBack sx={{ position: 'absolute', top: 40, left: 40 }} color="inheritWhite" /> */}
      <StyledBox>
        <Typography variant="h2" color="primary.contrastText" align="center">
          General Disclaimer
        </Typography>
        <Typography variant="body1" color="primary.contrastText" align="center">
          Last updated on August 22, 2024
        </Typography>
      </StyledBox>
      <StyledContainer maxWidth="xl">
        <Grid container spacing={8}>
          <Grid item xs={12} md={3}>
            <List>
              {LIST_MENU_SIDEBAR_DISCLAIMER.map((menu, index) => {
                return (
                  <StyledListItemButton
                    key={`cookies-policy-${menu.id}-${index}`}
                    onClick={() => onClick(menu.id)}
                    selected={tab === menu.id}
                  >
                    <ListItemText primary={<Typography variant="subtitle2">{menu.label}</Typography>} />
                  </StyledListItemButton>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Stack spacing={2}>
              <Typography variant="body1" color="text.secondary">
                At ADAMftd we are dedicated to maintaining high standards of integrity, transparency, and ethical
                conduct in our operations. Our mission is to provide users with ready access to publicly available
                comprehensive trade data that can support their obligation to comply with international regulations, can
                promote ethical business practices and can help businesses to grow. However, a user needs to understand
                the nature of the information we provide through the ADAMftd.com platform.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuDisclaimer.DataAccuracySourcing}>
                Data Accuracy and Sourcing
              </Typography>
              <Typography variant="body1" color="text.secondary">
                The data and information presented on ADAMftd.com is already publicly available and is sourced and
                complied from a wide range of third-party providers. This includes governmental databases, industry
                reports, and other public or commercially available sources.{' '}
                <Typography component="span" fontWeight={600}>
                  We do not own or control the content and are not responsible for the accuracy or correctness of the
                  original sources.
                </Typography>{' '}
                Our contribution is compiling publicly available original sources for ready access.
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                We make no representation or warranty, and we give no guarantees, regarding the accuracy, correctness,
                completeness, or reliability of the information provided.
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                All data and information presented on the ADAMftd.com platform is provided "as-is" and "as available,"
                and we expressly disclaim any warranties, whether express or implied, including, but not limited to,
                warranties of accuracy, completeness, merchantability, fitness for a particular purpose, and
                non-infringement.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuDisclaimer.NoLiability}>
                No Liability
              </Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd Pte Ltd is not responsible or liable for any errors, omissions, or inaccuracies in the data and
                information presented, or for the underlying source of such date or information, including its
                reliability and accuracy; and we are not responsible or liable for any actions taken based on this data
                or information.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <Typography component="span" fontWeight={600}>
                  The use of the ADAMftd.com platform, and the data and information thereon made available, is entirely
                  at the user's own responsibility, risk and liability.
                </Typography>{' '}
                We are not responsible for any decisions made or actions taken by users based on the data or information
                provided on this platform, nor for any consequential, incidental, or direct or indirect damages,
                including, but not limited to, loss of profits, business interruption, injury or harm to reputation, or
                loss of data.
              </Typography>
              <Typography
                variant="h5"
                color="primary.main"
                fontWeight={700}
                id={MenuDisclaimer.UserResponsibilityDiligence}
              >
                User Responsibility and Due Diligence
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Users are fully responsible for conducting, and should conduct, their own due diligence and verification
                processes before relying on the data or information provided through ADAMftd.com. While the platform is
                designed to be a resource tool to aid in research, compliance, and decision-making,{' '}
                <Typography component="span" fontWeight={600}>
                  users are fully responsible and liable for assessing the relevance, accuracy, and reliability of the
                  information presented
                </Typography>{' '}
                and for using it in accordance with their specific needs and contexts.
              </Typography>
              <Typography
                variant="h5"
                color="primary.main"
                fontWeight={700}
                id={MenuDisclaimer.ComplianceLawsRegulations}
              >
                Compliance with Laws and Regulations
              </Typography>
              <Typography variant="body1" color="text.secondary">
                While we strive to help support compliance with relevant laws and regulations,{' '}
                <Typography component="span" fontWeight={600}>
                  the information provided on ADAMftd.com should not, and cannot, be construed as legal advice.
                </Typography>{' '}
                Users should seek professional, including legal, advice to ensure their operations and decisions comply
                with all applicable laws and regulations.{' '}
                <Typography component="span" fontWeight={600}>
                  ADAMftd Pte Ltd does not guarantee that the use of the platform will ensure compliance with any laws
                  or regulations.
                </Typography>
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuDisclaimer.AmendmentsUpdates}>
                Amendments and Updates
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Please note that the information and data provided on ADAMftd.com may be updated or amended regularly to
                reflect new data or changes in the underlying sources. However,{' '}
                <Typography component="span" fontWeight={600}>
                  we do not guarantee that updates will be current, timely, complete, correct or accurate.
                </Typography>{' '}
                Users should regularly check the platform for updates and verify the currentness of the information with
                other sources.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuDisclaimer.ContactInformation}>
                Contact Information
              </Typography>
              <Typography variant="body1" color="text.secondary">
                For any questions or concerns regarding this disclaimer, or to report any potential inaccuracies or
                issues with the information provided, users can contact us at support@adamftd.com.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We welcome feedback and are committed to regularly improving the quality of the data we provide.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                By accessing and using the ADAMftd.com platform,{' '}
                <Typography component="span" fontWeight={600}>
                  you acknowledge that you have read, understood, and agreed to this disclaimer in its entirety and to
                  all the terms of use, without exception.
                </Typography>
              </Typography>
            </Stack>
            <Stack spacing={2} mt={4} direction="column" alignItems="flex-end">
              <Typography variant="h6" align="right">
                Document Ownership and Approval
              </Typography>
              <Typography variant="body1" color="text.secondary" align="right" maxWidth="50%">
                This document is owned and maintained by the ADAMftd Accessibility Team. It is regularly reviewed and
                updated to reflect the ongoing changes and improvements in our accessibility practices.
              </Typography>
              <Box src={SignatureImg} component="img" />
              <Typography variant="body1" color="text.secondary" align="right" maxWidth="50%">
                James York
                <br />
                Chief Executive Officer
                <br />
                ADAMftd Pte Ltd
                <br />
                30th June 2024
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Footer />
      </StyledContainer>
    </Box>
  );
};

export default GeneralDisclaimer;
