import { Typography, Box } from '@mui/material';

// Components
import Table from 'src/components/Table';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

const Keywords = ({ selectedWebDetail }: { selectedWebDetail?: ICompanyWebDetail }) => {
  const listData = getArrayDefault(selectedWebDetail?.keywords).map((keyword, index) => ({
    key: `keyword-${index}`,
    keyword: keyword.keyword,
    frequency: keyword.frequency,
  }));
  const configColumn: ConfigCell<Keyword>[] = [
    {
      id: 'keyword',
      disablePadding: true,
      label: 'Keywords',
      cell: (row: Keyword) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.keyword}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box padding={1}>
      <Table data={listData} configColumn={configColumn} dataKey="keyword" isPagination={false} />
    </Box>
  );
};

export default Keywords;
