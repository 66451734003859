import React from 'react';
import { Helmet } from 'react-helmet';
import Onboarding from 'src/features/Onboarding';

const OnboardingPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      <Onboarding />
    </React.Fragment>
  );
};

export default OnboardingPage;
