import { Box, Stack, Typography, useTheme, styled } from '@mui/material';
import { LINK_SUPPORT, PRODUCT_ROADMAP } from 'src/utils/constants/config';
import {
  ICON_SUPPORT_NO_DATA_COMPANY,
  ICON_QUICK_TIP_SEARCH_COMPANY,
  ICON_NO_MATCH_SEARCH_COMPANY,
} from 'src/utils/constants/imagesUrl';

// Components
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import IconFeather from 'src/components/common/Icon';
import { CheckCircle } from 'react-feather';

// Utilities

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  margin: '0px 3px',
  textDecoration: 'underline',
}));

const NoDataSearchCompany = () => {
  const theme = useTheme();

  return (
    <Stack direction="column" alignItems="center" mt={6} spacing={3}>
      <Stack direction="row" width="80%" spacing={6}>
        <Box component="img" height="200px" src={ICON_NO_MATCH_SEARCH_COMPANY} />
        <Stack direction="column" spacing={1} justifyContent="center">
          <Typography variant="h4">Sorry, No Matching Results</Typography>
          <Typography variant="body1" color="text.secondary">
            Please note that we are still onboarding additional data during our beta phase.
            <br /> We are currently gathering the most recent information for your search. This may take a few moments.
            <br />
            If the page remains blank, please refresh it. If you still cannot find what you're looking for, we apologize
            for the inconvenience. We are continually working to improve our database.
          </Typography>
        </Stack>
      </Stack>
      <StyledWhiteBox display="flex" flexDirection="column" width="70%" border={`1px solid ${theme.palette.divider}`}>
        <Stack direction="column" margin={3} spacing={4}>
          <Stack direction="row" spacing={4}>
            <Box component="img" height="150px" src={ICON_QUICK_TIP_SEARCH_COMPANY} />
            <Stack direction="column" spacing={2}>
              <Typography variant="h4">Quick Tips</Typography>
              <Typography variant="body1" color="text.secondary">
                We are actively retrieving the most up-to-date information for your search. This process may take a
                moment. If the page remains empty, please try refreshing it after a few moments. If the information
                you're looking for is still not available, data to enhance our database.
              </Typography>
              <Stack direction="row" spacing={1}>
                <IconFeather icon={<CheckCircle />} muiProps={{ color: 'success' }} />
                <Typography variant="body1" color="text.secondary">
                  Check your search for typos
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <IconFeather icon={<CheckCircle />} muiProps={{ color: 'success' }} />
                <Typography variant="body1" color="text.secondary">
                  Use more generic search terms
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <IconFeather icon={<CheckCircle />} muiProps={{ color: 'success' }} />
                <Typography variant="body1" color="text.secondary">
                  Try searching with advanced filters (only include this on no results for company page)
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing={1}
            padding={2}
            alignItems="flex-start"
            position="relative"
            bgcolor={theme.palette.success.light}
            borderRadius={theme.shape.borderRadius / 2}
          >
            <Typography variant="h6">Need assistance with an issue? </Typography>
            <Typography variant="body1">
              Visit our{' '}
              <Link href={LINK_SUPPORT} target="_blank">
                support
              </Link>{' '}
              platform. You might also like to check out our{' '}
              <Link href={PRODUCT_ROADMAP} target="_blank">
                development roadmap
              </Link>
              .
            </Typography>
            <Box
              component="img"
              height="100px"
              src={ICON_SUPPORT_NO_DATA_COMPANY}
              sx={{ position: 'absolute', top: theme.spacing(-4), right: theme.spacing(2) }}
            />
          </Stack>
        </Stack>
      </StyledWhiteBox>
    </Stack>
  );
};
export default NoDataSearchCompany;
