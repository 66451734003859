import { TextField, Stack, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { get, isArray } from 'lodash';
import { useMemo } from 'react';

// Components
import { IPropsDataContribute } from 'src/features/CompanyDetail/Common/config';

const EmailProfile = ({ indexData }: { indexData: number }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<IPropsDataContribute>();
  const errorsObject = useMemo(() => {
    if (isArray(errors.contribution_email_company_profile)) {
      return { email: get(errors.contribution_email_company_profile?.[indexData], 'email', '') };
    }

    return {
      email: '',
    };
  }, [errors, touched]);
  return (
    <Stack spacing={0.5} flex={1}>
      <TextField
        required
        id={`contribution_email_company_profile[${indexData}].email`}
        name={`contribution_email_company_profile[${indexData}].email`}
        value={get(values.contribution_email_company_profile, `[${indexData}].email`, '')}
        onChange={handleChange}
        onBlur={handleBlur}
        size="small"
        fullWidth
        label={<span>Email Address</span>}
        error={Boolean(touched.contribution_email_company_profile?.[indexData]?.email && errorsObject?.email)}
      />
      {touched.contribution_email_company_profile?.[indexData]?.email && (
        <FormHelperText error>{errorsObject?.email}</FormHelperText>
      )}
    </Stack>
  );
};

export default EmailProfile;
