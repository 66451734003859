export class CommonResponse implements IResponse<ExpectedAny> {
  message?: string;
  status_code: number;
  warning?: string | null;
  data?: ExpectedAny;
  detail?: string;
  errors?: ExpectedAny[];
  public_link?: string;
  public_links?: { message: string; file_name: string; status_code: number }[];
  request_id?: string;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.warning = null;
    this.public_link = '';
    this.public_links = [];
    this.request_id = '';
  }
}
