import MainLayout from 'src/layouts';
import CompareCompany from 'src/features/CompareCompany';
import { Helmet } from 'react-helmet';

const CompareCompanyPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Compare Company</title>
      </Helmet>
      <CompareCompany />
    </MainLayout>
  );
};

export default CompareCompanyPage;
