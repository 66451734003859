import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import EditNotificationsOutlinedIcon from '@mui/icons-material/EditNotificationsOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

export enum TypeTabSetting {
  information = 'information',
  notifications = 'notifications',
  password = 'password',
  visibility = 'visibility',
  devices_manager = 'devices_manager',
  plan_usage = 'plan_usage',
  members = 'members',
  billing_history = 'billing_history',
  payment_method = 'payment_method',
}

export const TABS_SETTING: ITabSetting[] = [
  {
    key: TypeTabSetting.information,
    label: 'Information',
    icon: AccountCircleOutlinedIcon,
  },
  {
    key: TypeTabSetting.password,
    label: 'Password',
    icon: AdminPanelSettingsOutlinedIcon,
  },
  {
    key: TypeTabSetting.devices_manager,
    label: 'Device Manager',
    icon: DesktopWindowsOutlinedIcon,
    disable: false,
  },
  {
    key: TypeTabSetting.notifications,
    label: 'Notifications',
    icon: EditNotificationsOutlinedIcon,
    disable: true,
  },
  {
    key: TypeTabSetting.visibility,
    label: 'Visibility',
    icon: LocalPoliceOutlinedIcon,
    disable: true,
  },
  {
    key: TypeTabSetting.plan_usage,
    label: 'Plan & Usage',
    icon: EnergySavingsLeafOutlinedIcon,
    disable: true,
  },
  {
    key: TypeTabSetting.members,
    label: 'Members',
    icon: PeopleAltOutlinedIcon,
    disable: true,
  },
  {
    key: TypeTabSetting.billing_history,
    label: 'Billing History',
    icon: AttachMoneyOutlinedIcon,
    disable: true,
  },

  {
    key: TypeTabSetting.payment_method,
    label: 'Payment Method',
    icon: CreditCardOutlinedIcon,
    disable: true,
  },
];
