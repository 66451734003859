import { Box, Chip, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { FormikContextType } from 'formik';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { Edit } from 'react-feather';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import IconFeather from 'src/components/common/Icon';
import { TypeChange } from 'src/features/Setting/Password';
import { useMutateCheckUsername } from 'src/services/mutate/useMutateAuth';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';

const FormItemUserName = ({
  formik,
  isEdit,
  onCancel,
  onClickEdit,
  open2FA,
}: {
  formik: FormikContextType<ExpectedAny>;
  isEdit: { type: TypeChange; visible: boolean };
  onCancel: () => void;
  onClickEdit: () => void;
  open2FA: () => void;
}) => {
  const theme = useTheme();
  const { mutateAsync, isPending } = useMutateCheckUsername();

  const handleChange = async () => {
    if (formik.values.username === formik.initialValues.username) {
      onCancel();
      return;
    }
    if (formik.errors.username) return;

    open2FA();
  };

  const checkUsername = useCallback(
    debounce(async (e) => {
      if (e.target.value && e.target.value !== formik.initialValues.username) {
        const resCheckEmail = await mutateAsync({ username: e.target.value });
        if (resCheckEmail.status_code !== SUCCESS_CODE.GET) {
          formik.setFieldError('username', resCheckEmail.message);
          return;
        }
      }
    }, 500),
    [],
  );

  const renderFormEdit = (
    <Stack alignItems={'baseline'} direction={'row'} spacing={2}>
      <TextField
        value={formik.values.username}
        sx={{ width: 350 }}
        error={formik.touched.username && Boolean(formik.errors.username)}
        onChange={(e) => {
          formik.handleChange(e);
          if (e.target.value.match(/^[a-zA-Z0-9_]+$/)) {
            checkUsername(e);
          }
        }}
        onBlur={formik.handleBlur}
        id="username"
        name="username"
        size="small"
        label="Username"
        helperText={<>{formik.touched.username && formik.errors.username}</>}
      />
      <ButtonWithLoading
        type="submit"
        disabled={
          isPending ||
          !!formik.errors.username ||
          (formik.values.username && formik.values.username === formik.initialValues.username)
        }
        isLoading={isPending}
        onClick={handleChange}
        size="large"
        variant="contained"
      >
        CHANGE
      </ButtonWithLoading>
      <ButtonWithLoading onClick={onCancel} size="large" variant="text" color="inherit">
        CANCEL
      </ButtonWithLoading>
    </Stack>
  );

  const renderFormNoEdit = (
    <Box>
      <Typography variant="subtitle1">Username</Typography>

      <Stack alignItems={'center'} direction={'row'} gap={2}>
        <Typography color={theme.palette.text.secondary} variant="body1">
          {formik.values.username}
        </Typography>

        <Chip size="small" color="success" label="VALID" />

        <IconButton onClick={onClickEdit}>
          <IconFeather muiProps={{ color: 'primary' }} icon={<Edit size={24} />} />
        </IconButton>
      </Stack>
    </Box>
  );

  return isEdit.type === TypeChange.Username && isEdit.visible ? renderFormEdit : renderFormNoEdit;
};

export default FormItemUserName;
