import { useTheme, Stack, Typography } from '@mui/material';
import React from 'react';

// Components
import DataTable from 'src/features/CompanyDetail/Common/DataTable';
import ModalComponent from 'src/components/Modal/ModalComponent';
import WhiteBoxContent from 'src/components/common/WhiteBoxContent';

// Utilities
import LogoCompany from 'src/components/common/LogoCompany';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const PopupTrademark: React.FC<IProps> = ({ open, handleClose }) => {
  const theme = useTheme();
  const listDataGeneral = [
    {
      key: 'brand_name',
      title: 'Brand Name',
      value: 'intelliAd',
    },
    {
      key: 'logo',
      title: 'Logo',
      value: <LogoCompany logoURL="" width={34} height={34} />,
    },
    {
      key: 'serial_number',
      title: 'Serial Number',
      value: '3020090352569',
    },
    {
      key: 'registration_number',
      title: 'Registration Number',
      value: '3020090352569',
    },
    {
      key: 'kind_mark',
      title: 'Kind Of Mark',
      value: 'Individual',
    },
    {
      key: 'type_mark',
      title: 'Type Of Mark',
      value: 'Word',
    },
    {
      key: 'application_date',
      title: 'Application Date',
      value: 'June 17, 2009',
    },
    {
      key: 'publication_date',
      title: 'Publication Date',
      value: 'September 4, 2009',
    },
    {
      key: 'registration_date',
      title: 'Registration Date',
      value: 'September 4, 2009',
    },
    {
      key: 'expiry_date',
      title: 'Expiry Date',
      value: 'September 4, 2009',
    },
  ];

  return (
    <ModalComponent padding={0} pt={0} open={open} handleClose={handleClose}>
      <Stack direction="column" spacing={3} width="80vw" bgcolor={theme.palette.background.paper2} p={4}>
        <Typography variant="h4" align="center">
          Trademark Detail
        </Typography>
        <Stack direction="row" spacing={3}>
          <Stack direction="column" spacing={3} flex={1}>
            <WhiteBoxContent>
              <Typography variant="h6">General Information</Typography>
              <DataTable listData={listDataGeneral} />
            </WhiteBoxContent>
            <WhiteBoxContent>
              <Typography variant="h6">Names And Addresses</Typography>
            </WhiteBoxContent>
          </Stack>
          <Stack direction="column" spacing={3} flex={1}>
            <WhiteBoxContent>
              <Typography variant="h6">Vienna Classifications</Typography>
            </WhiteBoxContent>
            <WhiteBoxContent>
              <Typography variant="h6">Nice Classifications</Typography>
            </WhiteBoxContent>
            <WhiteBoxContent>
              <Typography variant="h6">IP Office</Typography>
            </WhiteBoxContent>
            <WhiteBoxContent>
              <Typography variant="h6">Further Information</Typography>
            </WhiteBoxContent>
          </Stack>
        </Stack>
      </Stack>
    </ModalComponent>
  );
};

export default PopupTrademark;
