import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

// Components
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { slice } from 'lodash';
import CarouselImages from 'src/components/common/CarouselImages';
import ImageWithOverlay from 'src/components/common/ImageWithOverlay';
import ModalComponent from 'src/components/Modal/ModalComponent';
import { LOGO_COMPANY_DEFAULT_URL } from 'src/utils/constants/imagesUrl';

// Utilities

interface IProps {
  listImg: Array<{ url: string }>;
  gap?: number;
  limit?: number;
  widthImg?: number;
  heightImg?: number;
  onClickImgAction?: () => void;
}

const ListImages = ({ listImg = [], gap = 1, limit = 1, widthImg = 64, heightImg, onClickImgAction }: IProps) => {
  const theme = useTheme();
  const [openSlider, setOpenSlider] = React.useState(false);
  const [startIndex, setStartIndex] = React.useState(0);

  const images = useMemo(() => {
    return slice(listImg, 0, limit - 1);
  }, [listImg, limit]);

  return (
    <Stack columnGap={2} direction="row" spacing={gap} useFlexGap flexWrap="wrap">
      {images.map((image, index) => {
        if (index === images.length - 1) {
          return (
            <Box height={heightImg || widthImg} key={image.url} width={widthImg}>
              <ImageWithOverlay
                onClick={() => {
                  setStartIndex(index);
                  setOpenSlider(true);
                }}
                imgSrc={image.url}
                textOverlay={<>+ {listImg.length - limit + 1}</>}
                hasBackdrop={true}
              />
            </Box>
          );
        }
        return (
          <Box
            key={image.url + index}
            component={'img'}
            width={widthImg}
            height={heightImg || widthImg}
            src={image.url}
            border={`1px solid ${theme.palette.divider}`}
            borderRadius={theme.shape.borderRadius / 4}
            sx={{ cursor: 'pointer' }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = LOGO_COMPANY_DEFAULT_URL;
            }}
            onClick={() => {
              setStartIndex(index);
              setOpenSlider(true);
            }}
          />
        );
      })}

      {new Array(Math.abs(limit - images.length)).fill('k').map((_, i) => (
        <Tooltip key={i} title="Contribute data">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={widthImg}
            height={heightImg || widthImg}
            border={`1px solid ${theme.palette.divider}`}
            borderRadius={theme.shape.borderRadius / 4}
            bgcolor={theme.palette.background.paper1}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => onClickImgAction?.()}
          >
            <AddCircleOutlineOutlined color="primary" />
          </Box>
        </Tooltip>
      ))}

      <ModalComponent open={openSlider} handleClose={() => setOpenSlider(false)}>
        <CarouselImages images={listImg} startIndex={startIndex} />
      </ModalComponent>
    </Stack>
  );
};

export default ListImages;
