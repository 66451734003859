import { Box, FormHelperText, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { get, isArray } from 'lodash';
import { useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import { IPropsDataContribute } from 'src/features/CompanyDetail/Common/config';
import PopupSelectPhoneCode from 'src/features/Setting/PopupSelectPhoneCode';
import { getCountryByPhoneCode } from 'src/utils/helpers/stringHelpers';

const ContactInformation = ({ indexData }: { indexData: number }) => {
  const theme = useTheme();
  const [visiblePhoneCode, setVisiblePhoneCode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<IPropsDataContribute>();
  const errorsObject = useMemo(() => {
    if (isArray(errors.contribution_contact_company_profile)) {
      return {
        full_name: get(errors.contribution_contact_company_profile?.[indexData], 'full_name', ''),
        title: get(errors.contribution_contact_company_profile?.[indexData], 'title', ''),
        email: get(errors.contribution_contact_company_profile?.[indexData], 'email', ''),
        phone_number: get(errors.contribution_contact_company_profile?.[indexData], 'phone_number', ''),
      };
    }
    return {
      full_name: '',
      title: '',
      email: '',
      phone_number: '',
    };
  }, [errors, touched]);

  const countryCode = useMemo(() => {
    const country = getCountryByPhoneCode(
      get(values.contribution_contact_company_profile, `[${indexData}].phone_code`, ''),
    );
    return country.code;
  }, [values.contribution_contact_company_profile]);

  return (
    <Stack flex={1} spacing={2}>
      <Stack spacing={0.5} flex={1}>
        <TextField
          type="text"
          size="small"
          id={`contribution_contact_company_profile[${indexData}].full_name`}
          name={`contribution_contact_company_profile[${indexData}].full_name`}
          value={get(values.contribution_contact_company_profile, `[${indexData}].full_name`, '')}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          fullWidth
          placeholder="Please describe detail hereSelect option"
          error={Boolean(
            touched.contribution_contact_company_profile?.[indexData]?.full_name && errorsObject?.full_name,
          )}
          label={<span>Full Name</span>}
        />
        {touched.contribution_contact_company_profile?.[indexData]?.full_name && (
          <FormHelperText error>{errorsObject?.full_name}</FormHelperText>
        )}
      </Stack>
      <Stack spacing={0.5} flex={1}>
        <TextField
          type="text"
          size="small"
          id={`contribution_contact_company_profile[${indexData}].title`}
          name={`contribution_contact_company_profile[${indexData}].title`}
          value={get(values.contribution_contact_company_profile, `[${indexData}].title`, '')}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          fullWidth
          placeholder="Please describe detail hereSelect option"
          error={Boolean(touched.contribution_contact_company_profile?.[indexData]?.title && errorsObject?.title)}
          label={<span>Title</span>}
        />
        {touched.contribution_contact_company_profile?.[indexData]?.title && (
          <FormHelperText error>{errorsObject?.title}</FormHelperText>
        )}
      </Stack>
      <Stack spacing={0.5} flex={1}>
        <TextField
          type="text"
          size="small"
          id={`contribution_contact_company_profile[${indexData}].email`}
          name={`contribution_contact_company_profile[${indexData}].email`}
          value={get(values.contribution_contact_company_profile, `[${indexData}].email`, '')}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          fullWidth
          placeholder="Please describe detail hereSelect option"
          error={Boolean(touched.contribution_contact_company_profile?.[indexData]?.email && errorsObject?.email)}
          label={<span>Email Address</span>}
        />
        {touched.contribution_contact_company_profile?.[indexData]?.email && (
          <FormHelperText error>{errorsObject?.email}</FormHelperText>
        )}
      </Stack>
      <Stack spacing={0.5} flex={1}>
        <TextField
          id={`contribution_contact_company_profile[${indexData}].phone_number`}
          name={`contribution_contact_company_profile[${indexData}].phone_number`}
          value={get(values.contribution_contact_company_profile, `[${indexData}].phone_number`, '')}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  background: theme.palette.grey[100],
                  height: 'fit-content',
                  padding: '4px 8px',
                  borderRadius: theme.shape.borderRadius / 4,
                }}
                onClick={(e) => {
                  setVisiblePhoneCode(true);
                  setAnchorEl(e.currentTarget);
                }}
                component={Box}
                position="start"
              >
                <FlagCountry width={16} code={countryCode} />
                <Typography sx={{ ml: 1 }} color={theme.palette.text.secondary} variant="body2">
                  {get(values.contribution_contact_company_profile, `[${indexData}].phone_code`, '')}
                </Typography>
                <IconFeather muiProps={{ fontSize: 'small', sx: { ml: 1 } }} icon={<ChevronDown />} />
              </InputAdornment>
            ),
          }}
          error={Boolean(
            touched.contribution_contact_company_profile?.[indexData]?.phone_number && errorsObject?.phone_number,
          )}
          size="small"
          fullWidth
          label="Your Phone Number"
        />
        {touched.contribution_contact_company_profile?.[indexData]?.phone_number && (
          <FormHelperText error>{errorsObject?.phone_number}</FormHelperText>
        )}
        <PopupSelectPhoneCode
          anchorEl={anchorEl}
          id={`contribution_contact_company_profile[${indexData}].phone_code`}
          open={visiblePhoneCode}
          handleClose={() => setVisiblePhoneCode(false)}
          disablePortal={true}
        />
      </Stack>
    </Stack>
  );
};

export default ContactInformation;
