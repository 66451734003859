import React from 'react';
import { Helmet } from 'react-helmet';
import TwoStepVerification from 'src/features/TwoStepVerification';

const TwoStepVerificationPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Verification Code</title>
      </Helmet>
      <TwoStepVerification />
    </React.Fragment>
  );
};

export default TwoStepVerificationPage;
