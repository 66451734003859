import { FormHelperText } from '@mui/material';
import { FormikContextType } from 'formik';
import { isString } from 'lodash';

const renderFormItemError = (text?: string) => {
  if (!text) {
    return null;
  }
  return (
    <FormHelperText error id="standard-weight-helper-text-email-login">
      {text}
    </FormHelperText>
  );
};

const getFormItemError = (type: string, formik: FormikContextType<ExpectedAny>) => {
  if (formik.touched[type]) {
    const error = formik.errors[type];
    return isString(error) ? error : '';
  }
  return '';
};

export { renderFormItemError, getFormItemError };
