import { Stack, Typography } from '@mui/material';

// Components
import Table from 'src/components/Table';
import FlagCountry from 'src/components/common/FlagCountry';
import { FORMAT_DATE_TIME, EmptyText } from 'src/utils/constants/config';
import { formatUTCTime } from 'src/utils/helpers/dayjs';

// Utilities
import { convertToStartCase, getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';

const ContactByRole = ({
  title,
  handleOpen,
  listContact,
}: {
  title: string;
  listContact: Array<IContactCompanyRole>;
  handleOpen: (id: string) => void;
}) => {
  const configColumn: ConfigCell<IContactCompanyRole>[] = [
    {
      id: 'full_name',
      disablePadding: true,
      label: 'Full Name',
      cell: (row: IContactCompanyRole) => {
        return (
          <Typography variant="body2" color="secondary" onClick={() => handleOpen(row.id || '')}>
            {convertToStartCase(row.name || EmptyText)}
          </Typography>
        );
      },
    },
    {
      id: 'title',
      disablePadding: false,
      label: 'Title',
      cell: (row: IContactCompanyRole) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {convertToStartCase(row?.position || EmptyText)}
          </Typography>
        );
      },
    },
    {
      id: 'dob',
      disablePadding: false,
      label: 'Date of Birth',
      cell: (row: IContactCompanyRole) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.dob || EmptyText}
          </Typography>
        );
      },
    },
    {
      id: 'nationality_code',
      disablePadding: false,
      label: 'Nationality',
      cell: (row: IContactCompanyRole) => {
        const country = getCountryByCountryCode(row.nationality || '');
        return (
          <Stack direction="row" spacing={0.5}>
            <FlagCountry width={20} code={row.nationality || ''} />
            <Typography variant="body2" color="text.secondary">
              {country.label}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'date_appointed',
      disablePadding: false,
      label: 'Date Appointed',
      cell: (row: IContactCompanyRole) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, row.date_appointed || '')}
          </Typography>
        );
      },
    },
    {
      id: 'address',
      disablePadding: false,
      label: 'Address',
      cell: (row: IContactCompanyRole) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {(row?.address || '').toUpperCase() || EmptyText}
          </Typography>
        );
      },
    },
  ];
  return (
    <Stack spacing={2}>
      <Typography variant="h5" align="left">
        {title}
      </Typography>
      <Table
        data={listContact}
        configColumn={configColumn}
        dataKey="id"
        id={'table-company' + title}
        isPagination={false}
      />
    </Stack>
  );
};

export default ContactByRole;
