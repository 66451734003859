import { AlertTitle, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import useSnackbarStore from 'src/zustand/useSnackbarStore';
import { DRAWER_WIDTH_CLOSE } from 'src/utils/constants/config';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const SnackbarGlobal = () => {
  const { open, dispatch, title, description, ...props } = useSnackbarStore();

  const onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      open: false,
      sx: {},
    });
  };

  return (
    <Snackbar
      style={{
        left: 44 + DRAWER_WIDTH_CLOSE,
      }}
      sx={{
        zIndex: 10000,
        '& .MuiPaper-root': {
          pr: 4,
        },
      }}
      autoHideDuration={6000}
      open={open}
      onClose={onClose}
    >
      <Alert {...props}>
        <AlertTitle>{title}</AlertTitle>
        {description}
        <IconButton
          onClick={() => {
            dispatch({
              open: false,
            });
          }}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          size="small"
          color="inherit"
        >
          <CloseOutlinedIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Alert>
    </Snackbar>
  );
};

export default SnackbarGlobal;
