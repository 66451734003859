import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { NoAuthRouteKeys } from 'src/utils/constants/routes';
import { useSignupStore } from 'src/zustand/useSignupStore';

const ProtectRoute = ({ children }: { children: ReactNode }) => {
  const { email } = useSignupStore();

  return <>{email ? children : <Navigate to={NoAuthRouteKeys.Login} />}</>;
};

export default ProtectRoute;
