import MainLayout from 'src/layouts';
import { Helmet } from 'react-helmet';
import TradeDataDetail from 'src/features/TradeDataDetail';

const TradeDataPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Trade Data Detail</title>
      </Helmet>
      <TradeDataDetail />
    </MainLayout>
  );
};

export default TradeDataPage;
