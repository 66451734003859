import useSnackbarStore from 'src/zustand/useSnackbarStore';
import { SUCCESS_CODE } from '../constants/statusCode';

const handleNotification = () => {
  const { dispatch } = useSnackbarStore();
  return (
    response: IResponse<ExpectedAny>,
    success?: { title: string; description: string },
    error?: { title: string; description: string },
  ) => {
    if (response.status_code === SUCCESS_CODE.GET || response.status_code === SUCCESS_CODE.CREATE) {
      dispatch({
        open: true,
        title: success?.title || 'Success',
        description: success?.description || response.message,
        variant: 'standard',
        color: 'success',
        severity: 'success',
      });
    } else {
      dispatch({
        open: true,
        title: error?.title || 'Error',
        description: error?.description || response.message,
        variant: 'standard',
        color: 'error',
        severity: 'error',
      });
    }
    return null;
  };
};

export default handleNotification;
