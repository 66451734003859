import { useMutation } from '@tanstack/react-query';
import { AuthService } from 'src/services/api';

export const useMutateCreateOTP = () => {
  const { isPending, isSuccess, data, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOCreateOTP) => AuthService.handleCreateOTP(body),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutateAsync,
  };
};

export const useMutateForgotPassword = () => {
  const { isPending, isSuccess, data, mutate, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOResetPassword) => AuthService.handleResetPassword(body),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutate,
    mutateAsync,
  };
};

export const useMutateLogin = () => {
  const { isPending, isSuccess, data, mutate, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOLogin) => AuthService.handleLogin(body),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutate,
    mutateAsync,
  };
};

export const useMutateResendOTP = () => {
  const { isPending, isSuccess, data, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOCreateOTP) => AuthService.handleResendOTP(body),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutateAsync,
  };
};

export const useMutateSignup = () => {
  const { isPending, isSuccess, data, mutateAsync, mutate } = useMutation({
    mutationFn: (body: TypeDTOSignup) => AuthService.handleSignup(body),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutateAsync,
    mutate,
  };
};

export const useMutateVerifyOTP = () => {
  const { isPending, isSuccess, data, mutate, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOVerifyOTP) => AuthService.handleVerifyOTP(body),
  });
  return {
    isPending,
    isSuccess,
    data,
    mutate,
    mutateAsync,
  };
};
export const useMutateCheckEmail = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (query: Record<string, string>) => AuthService.checkEmailExists(query),
  });
  return {
    isPending,
    mutateAsync,
  };
};
export const useMutateCheckWorkEmail = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (query: Record<string, string>) => AuthService.checkWorkEmailExists(query),
  });
  return {
    isPending,
    mutateAsync,
  };
};
export const useMutateCheckUsername = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (query: Record<string, string>) => AuthService.checkUsernameExist(query),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateLogoutUserDevice = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (id: string) => AuthService.logoutUserDevice(id),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateValidatePassword = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (password: string) => AuthService.validatePassword({ password: password ?? '' }),
  });
  return { isPending, mutateAsync };
};
