import {
  Container,
  Box,
  Typography,
  Grid,
  useTheme,
  styled,
  List,
  ListItemText,
  ListItemButton,
  Stack,
} from '@mui/material';
import React from 'react';
import { LIST_MENU_SIDEBAR_TERMS, MenuTerms } from './config';

//Components
// import ButtonBack from 'src/components/common/ButtonBack';
import Footer from 'src/components/Footer';
import SignatureImg from 'src/asset/images/signature.png';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
  height: 250,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(7),
  marginTop: 8,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  '&.Mui-selected': {
    borderRight: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const TermAndCondition = () => {
  const theme = useTheme();
  const [tab, setTab] = React.useState(MenuTerms.OverviewWebsiteSystem);

  const onClick = (id: MenuTerms) => {
    setTab(id);
    const ele = document.getElementById(id);
    if (ele) {
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        background: theme.palette.background.paper1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <ButtonBack sx={{ position: 'absolute', top: 40, left: 40 }} color="inheritWhite" /> */}
      <StyledBox>
        <Typography variant="h2" color="primary.contrastText" align="center">
          Terms and Conditions
        </Typography>
        <Typography variant="body1" color="primary.contrastText" align="center">
          Last updated on August 22, 2024
        </Typography>
      </StyledBox>
      <StyledContainer maxWidth="xl">
        <Grid container spacing={8}>
          <Grid item xs={12} md={3}>
            <List>
              {LIST_MENU_SIDEBAR_TERMS.map((menu, index) => {
                return (
                  <StyledListItemButton
                    key={`cookies-policy-${menu.id}-${index}`}
                    onClick={() => onClick(menu.id)}
                    selected={tab === menu.id}
                  >
                    <ListItemText primary={<Typography variant="subtitle2">{menu.label}</Typography>} />
                  </StyledListItemButton>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Stack spacing={2}>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.OverviewWebsiteSystem}>
                Overview of the Website and System
              </Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd Ltd (“ADAMftd”) operates a comprehensive platform designed to provide users with access to a
                wide array of data related to international trade, including company data, economic data, and trade
                transaction data. Our website and system facilitate in-depth analysis and insights into global trade
                dynamics, enabling users to conduct due diligence, market research, and compliance checks through a
                single interface.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.AcceptanceTerms}>
                Acceptance of Terms
              </Typography>
              <Typography variant="body1" color="text.secondary">
                By accessing and using the ADAMftd website and system, you agree to be bound by these Terms and
                Conditions of Use and all applicable laws and regulations. If you do not agree with any of these terms,
                you are prohibited from using or accessing this site. The terms “we”, “us”, “our”, or “ADAMftd” refer to
                ADAMftd Ltd, and “you” or “your” refer to the user or viewer of our website.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.UserAccountRegistration}>
                User Account and Registration
              </Typography>
              <Typography variant="h6">Account Creation and Requirements</Typography>
              <Typography variant="body1" color="text.secondary">
                To fully access the features and services offered by ADAMftd, you must register for an account. During
                the registration process, you will be required to provide certain information, such as your name, email
                address, and company organization and, if applicable group affiliation, ownership, control persons, and
                create a password. You may also be asked to provide additional information.
              </Typography>
              <Typography variant="h6">User Responsibilities and Account Security</Typography>
              <Typography variant="body1" color="text.secondary">
                As a registered user, you are responsible for maintaining the confidentiality of your account and
                password and for restricting access to your computer, and you agree to accept responsibility for all
                activities that occur under your account or password. You must take all necessary steps to ensure that
                the password is kept confidential and secure and should inform us immediately if you have any reason to
                believe that your password has become known to anyone else, or if the password is being, or is likely to
                be, used in an unauthorized manner.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                You are responsible for ensuring that the details you provide us with are correct and complete, and for
                informing us of any changes to the information you provided at the time of registration. We reserve the
                right to disable any user account at our sole discretion, should you have failed to comply with any of
                the provisions of these Terms and Conditions, including if any information you have provided is
                incorrect or incomplete.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Each account is personal and cannot be shared or used by more than one user. However, within your
                account, you may create multiple user profiles (if allowed by your subscription plan) to allow your
                colleagues within the same legal entity, but not an affiliated or associate entity, to access our
                services under your account umbrella.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.UseOfPlatform}>
                Use of the Platform
              </Typography>
              <Typography variant="h6">Permitted Uses of ADAMftd Services</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd services are designed to support a wide range of business and compliance activities. Permitted
                uses include:
              </Typography>
              <ul style={{ marginLeft: '2rem', color: theme.palette.text.secondary }}>
                <li>
                  <b>AML Compliance:</b> Using the platform to verify adherence to Anti-Money Laundering regulations.
                </li>
                <li>
                  <b>KYC Compliance:</b> Conducting Know Your Customer checks to verify the identities of clients.
                </li>
                <li>
                  <b>Company Due Diligence</b>: Investigating business entities to assess their legitimacy and
                  operational history.
                </li>
                <li>
                  <b>Supply Chain Mapping:</b> Visualizing and analyzing supply chain connections and dependencies.
                </li>
                <li>
                  <b>Supply Chain Due Diligence:</b>Assessing risks associated with suppliers to ensure they meet
                  compliance standards.
                </li>
                <li>
                  <b>Compliance with Laws Relating to International Trade:</b> Ensuring adherence to international trade
                  regulations and laws.
                </li>
                <li>
                  <b>Market Research:</b> Gathering data to analyze market trends and consumer behavior.
                </li>
                <li>
                  <b>Economic Analysis:</b> Conducting economic studies and forecasts based on available data.
                </li>
                <li>
                  <b>Tracking Shipments of Goods Internationally:</b> Monitoring the movement of goods across borders to
                  ensure compliance with customs regulations.
                </li>
                <li>
                  <b>Economic Modelling:</b> Using data to build models that predict economic outcomes.
                </li>
                <li>
                  <b>Trademark and Patent Searches:</b> Searching for existing trademarks and patents to avoid
                  infringement.
                </li>
                <li>
                  <b>Related Entity Searches and Mapping:</b> Identifying affiliations between entities to assess
                  potential risks and compliance.
                </li>
              </ul>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.UserContributions}>
                User Contributions
              </Typography>
              <Typography variant="h6">Types of Data Users Can Contribute</Typography>
              <Typography variant="body1" color="text.secondary">
                Users of the ADAMftd platform are prohibited from engaging in activities that could harm or damage other
                users, entities, or the platform itself or ADAMftd. This includes actions that are unlawful or that
                contravene the ethical use of the platform. Specifically, the following activities are prohibited:
              </Typography>
              <ul style={{ marginLeft: '2rem', color: theme.palette.text.secondary }}>
                <li>
                  <b>Uploading Inappropriate Content:</b> Posting harmful or damaging images or content, particularly
                  those aimed at discrediting competitors.
                </li>
                <li>
                  <b> Harmful Impact:</b> Making false, misleading or malicious statements which may harm the reputation
                  or business of other companies.
                </li>
                <li>
                  <b> Contributing False Information:</b> Submitting false, incorrect or misleading data about companies
                  or business activities.
                </li>
                <li>
                  <b>Facilitating Counterfeit Activities</b>: Using data to support the production or distribution of
                  counterfeit goods.
                </li>
                <li>
                  <b>Violating International or Domestic Laws:</b> Such laws include those related to trade, sanctions,
                  customs, and tax evasion.
                </li>
                <li>
                  <b>Engaging in Trade-Based Money Laundering:</b> Utilizing trade transactions to launder money.
                </li>
                <li>
                  <b>Money Laundering:</b> Using the platform to disguise the proceeds of criminal activities as
                  legitimate income.
                </li>
                <li>
                  <b>Violating or Facilitating the Violation of Sanctions Laws</b>: Engaging in or facilitating
                  activities that could violate applicable sanctions laws, which prohibit or restrict transactions with
                  certain designated individuals, companies, or countries.
                </li>
                <li>
                  <b>Violating Anti-Money Laundering Laws:</b> Using the platform in any way that could violate
                  anti-money laundering laws, which include requirements to prevent, detect, and report potentially
                  suspicious financial activity.
                </li>
                <li>
                  <b>Violating Anti-Competitive Laws</b>: Users must avoid activities that breach laws designed to
                  promote competition and prohibit practices that could lead to market domination or unfair competitive
                  advantages. This includes price fixing, market sharing, bid rigging, and other forms of
                  anti-competitive agreements.
                </li>
              </ul>
              <Typography variant="body1" color="text.secondary">
                Violations of these prohibitions can result in immediate termination of access to the platform,
                potential legal action, and our cooperation with law enforcement authorities, which we retain an
                unrestricted right to do. ADAMftd Ltd is committed to maintaining a safe, lawful, and ethical platform
                for all users and will enforce these restrictions rigorously and any expense incurred by ADAMftd Ltd.
                Including for legal fees, shall be repaid to ADAMftd Ltd by the user.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.IntellectualPropertyRights}>
                Intellectual Property Rights
              </Typography>
              <Typography variant="h6">Ownership of ADAMftd Content and Materials</Typography>
              <Typography variant="body1" color="text.secondary">
                All content available on the ADAMftd platform, including text, graphics, logos, images, data
                compilations, software, and all other material, is the property of ADAMftd Ltd or its content suppliers
                and is protected by Singapore and international copyright and intellectual property laws. ADAMftd Ltd
                retains full ownership of all its intellectual property rights in all content and materials provided on
                the platform.
              </Typography>
              <Typography variant="h6">User Licenses and Restrictions</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd grants users a limited, non-exclusive, non-transferable license to access and use the ADAMftd
                platform and its content for lawful, internal business purposes, subject to the terms and conditions of
                this agreement. This license does not include any resale or commercial use of any ADAMftd content;
                collection and use of any product listings, descriptions, or prices; derivative use of the platform or
                its contents; or any use of data mining, robots, or similar data gathering and extraction tools.
              </Typography>
              <Typography variant="h6">Copyright and Trademark Information</Typography>
              <Typography variant="body1" color="text.secondary">
                All trademarks, service marks, and trade names of ADAMftd used on the site are trademarks or registered
                trademarks of ADAMftd Ltd. You are not permitted to use these marks without the prior written consent of
                ADAMftd Ltd. All other marks not owned by ADAMftd that appear on this site are the property of their
                respective owners, who may or may not be affiliated with, connected to, or sponsored by ADAMftd.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.FeesPayments}>
                Fees and Payments
              </Typography>
              <Typography variant="h6">Subscription</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd operates on a credits-based subscription system. Each annual subscription provides users with a
                certain number of credits, which can be used to access specific data within the system. These credits
                are allocated monthly and do not roll over; they will expire if unused by the end of each subscription
                month. This model is designed to maintain fairness and accessibility while supporting the ongoing
                provision and improvement of our services.
              </Typography>
              <Typography variant="h6">Payment Terms and Conditions</Typography>
              <Typography variant="body1" color="text.secondary">
                Subscription fees are due in full at the beginning of each subscription period. Payments can be made via
                our secure online payment system, which accepts a variety of payment methods including credit card,
                debit card, and direct bank transfers. All financial transactions are encrypted and securely processed.
              </Typography>
              <Typography variant="h6">Refund and Cancellation Policy</Typography>
              <Typography variant="body1" color="text.secondary">
                Subscribers may cancel their subscriptions at any time. However, ADAMftd does not provide refunds for
                any unused portion of the subscription term. If you choose to cancel your subscription, you will retain
                access to your subscription until the end of your current billing cycle. Changes in pricing for
                subscriptions will not affect current subscription periods but may apply upon renewal. At the end of
                your prepaid period, and in case of changes in subscription plans or pricing, you may be automatically
                migrated to the nearest equivalent new plan.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We aim to provide transparent and fair payment terms and encourage users to review their subscription
                details during renewal periods to ensure their continued satisfaction with our services.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.PrivacyDataProtection}>
                Privacy and Data Protection
              </Typography>
              <Typography variant="h6">General Information</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd Ltd is committed to protecting the privacy and security of our users’ information. This section
                provides a brief overview of our practices; for more detailed information, please refer to our full
                Privacy Policy.
              </Typography>
              <Typography variant="h6">Privacy Policy Reference</Typography>
              <Typography variant="body1" color="text.secondary">
                Our comprehensive Privacy Policy outlines the ways in which we collect, use, store, and protect personal
                data provided by our users. It also describes how users can exercise their rights regarding their
                personal data. We expect all users to have read this policy so that they understand our practices, and
                by becoming a user, you confirm you agreement and acceptance thereof. The Privacy Policy is available
                here.
              </Typography>
              <Typography variant="h6">Public Records Privacy Policy</Typography>
              <Typography variant="body1" color="text.secondary">
                In addition to our general Privacy Policy, we also maintain a Public Records Privacy Policy that governs
                the data collected from public records and used on our platform. This policy provides additional details
                on the handling of such data. We encourage users to review this policy, which is available here.
              </Typography>
              <Typography variant="h6">Data Security Measures</Typography>
              <Typography variant="body1" color="text.secondary">
                We implement a variety of security measures to maintain the safety of your personal information when you
                enter, submit, or access your personal information. These measures include advanced encryption
                techniques and protocols to protect data from unauthorized access, alteration, and destruction. We
                regularly review our security policies and procedures to ensure our systems are secure and protected.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.ThirdPartyLinksServices}>
                Third-Party Links and Services
              </Typography>
              <Typography variant="h6">Relationship with Third-Party Services</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd may integrate with or offer links to services provided by third parties, such as data analytics
                or payment processing services. These third-party services are governed by their own privacy and data
                protection policies, and we do not accept any responsibility or liability for these policies or for any
                personal data that may be collected through these third-party services.
              </Typography>
              <Typography variant="h6">Disclaimer for Third-Party Links</Typography>
              <Typography variant="body1" color="text.secondary">
                Our platform may contain links to external websites that are not operated by us. If you click on a
                third-party link, you will be directed to that third party’s site. We strongly advise you to review the
                Privacy Policy and terms and conditions of every site you visit. We have no control over, and assume no
                responsibility or liability for the content, privacy policies, or practices of any third-party sites,
                products, or services.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.TerminationSuspension}>
                Termination and Suspension
              </Typography>
              <Typography variant="h6">Grounds for Termination and Suspension of User Accounts</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd reserves the right to terminate or suspend your account and access to the services without prior
                notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms
                and Conditions. Possible grounds for termination or suspension include:
              </Typography>
              <ul style={{ marginLeft: '2rem', color: theme.palette.text.secondary }}>
                <li>Non-payment of fees</li>
                <li>Unauthorized use of the platform</li>
                <li>Violations of intellectual property rights</li>
                <li>Breach of user conduct or prohibited activities as outlined in these Terms</li>
              </ul>
              <Typography variant="h6">Consequences of Account Termination</Typography>
              <Typography variant="body1" color="text.secondary">
                Upon termination, your right to use the service will immediately cease. If you wish to terminate your
                account, you may simply discontinue using the service. All provisions of the Terms which by their nature
                should survive termination shall survive termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnity, and limitations of liability. Any contributions you have
                made to the platform may remain and continue to be used under the terms agreed upon at the time of
                contribution, as outlined in the User Contributions section.
              </Typography>
              <Typography
                variant="h5"
                color="primary.main"
                fontWeight={700}
                id={MenuTerms.DisclaimersLimitationsLiability}
              >
                Disclaimers and Limitations of Liability
              </Typography>
              <Typography variant="h6">Service Availability and Limitations</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd strives to ensure that its services are available at all times. However, the availability can be
                affected by factors beyond our control, such as technical failures, maintenance periods, and external
                network issues. Therefore, ADAMftd does not guarantee uninterrupted or error-free operation of its
                services and shall not be liable for any such interruptions.
              </Typography>
              <Typography variant="h6">Limitations of Liability for Damages</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary
                damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other
                intangible losses resulting from the use of or inability to use the service. Where the exclusion or
                limitation of liability for consequential or incidental damages is not allowed, our liability is limited
                to the greatest extent permitted by law.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.Indemnification}>
                Indemnification
              </Typography>
              <Typography variant="h6">User Responsibilities for Indemnity</Typography>
              <Typography variant="body1" color="text.secondary">
                You agree to indemnify and hold harmless ADAMftd, its officers, directors, employees, and agents, from
                any and all claims, demands, losses, liabilities, and expenses (including attorneys' fees), arising out
                of or in connection with: (i) your use of the service; (ii) your breach or violation of any of these
                Terms; (iii) ADAMftd's use of your user-contributed data; or (iv) your violation of the rights of any
                third party.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.DisputeResolution}>
                Dispute Resolution
              </Typography>
              <Typography variant="h6">Governing Law</Typography>
              <Typography variant="body1" color="text.secondary">
                These Terms and Conditions are governed by and construed in accordance with the laws of Singapore,
                without giving effect to any principles of conflicts of law.
              </Typography>
              <Typography variant="h6">Dispute Resolution Mechanisms</Typography>
              <Typography variant="body1" color="text.secondary">
                For any disputes arising from or related to the use of ADAMftd services, you agree to attempt to resolve
                the dispute informally first. If you and ADAMftd are unable to resolve the dispute informally, you agree
                to submit the dispute to binding arbitration in the jurisdiction. The arbitration will be conducted
                under the rules of Singapore International Arbitration Centre, and the governing law of the Arbitration.
              </Typography>
              <Typography
                variant="h5"
                color="primary.main"
                fontWeight={700}
                id={MenuTerms.ModificationsTermsConditions}
              >
                Modifications to Terms and Conditions
              </Typography>
              <Typography variant="h6">Notification of Changes to Terms</Typography>
              <Typography variant="body1" color="text.secondary">
                ADAMftd reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a
                revision is material, we will provide at least 30 days’ notice prior to any new terms taking effect.
                What constitutes a material change will be determined at our sole discretion.
              </Typography>
              <Typography variant="h6">Acceptance of Revised Terms</Typography>
              <Typography variant="body1" color="text.secondary">
                By continuing to access or use our service after those revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the
                service.
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.ContactInformation}>
                Contact Information
              </Typography>
              <Typography variant="body1" color="text.secondary">
                For questions or support related to the use of ADAMftd services, please contact us via:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Email: support@adamftd.com <br /> Phone: +1 202 991 2218
              </Typography>
              <Typography variant="h5" color="primary.main" fontWeight={700} id={MenuTerms.AdditionalProvisions}>
                Additional Provisions
              </Typography>
              <Typography variant="h6">Severability</Typography>
              <Typography variant="body1" color="text.secondary">
                If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect. It is agreed that the courts of Singapore shall have
                exclusive jurisdiction. The invalid or unenforceable provision will be replaced by a valid, enforceable
                provision that most closely matches the intent of the original provision.
              </Typography>
              <Typography variant="h6">Waiver</Typography>
              <Typography variant="body1" color="text.secondary">
                No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any
                other term, and ADAMftd's failure to assert any right or provision under these Terms shall not
                constitute a waiver of such right or provision.
              </Typography>
            </Stack>
            <Stack spacing={2} mt={4} direction="column" alignItems="flex-end">
              <Typography variant="h6" align="right">
                Document Ownership and Approval
              </Typography>
              <Typography variant="body1" color="text.secondary" align="right" maxWidth="50%">
                This document is owned and maintained by the ADAMftd Accessibility Team. It is regularly reviewed and
                updated to reflect the ongoing changes and improvements in our accessibility practices.
              </Typography>
              <Box src={SignatureImg} component="img" />
              <Typography variant="body1" color="text.secondary" align="right" maxWidth="50%">
                James York
                <br />
                Chief Executive Officer
                <br />
                ADAMftd Pte Ltd
                <br />
                30th June 2024
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Footer />
      </StyledContainer>
    </Box>
  );
};

export default TermAndCondition;
