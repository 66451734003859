import { useMutation } from '@tanstack/react-query';
import { useNotification } from 'src/hooks/useNotification';
import { CompanyService } from 'src/services/api';

export const useMutateContributeCompany = () => {
  const { notification } = useNotification();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOContributeCompany | TypeDTOReportWrong) => CompanyService.contributeData(body),
    onSuccess: (response: IResponse<ExpectedAny>) => {
      notification(response);
    },
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateCreateReview = () => {
  const { notification } = useNotification();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOReviewCompany) => CompanyService.createReview(body),
    onSuccess: (response: IResponse<ExpectedAny>) => {
      notification(response);
    },
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateCheckEmailValidation = () => {
  const { notification } = useNotification();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ email }: { email: string }) => CompanyService.checkEmailValidation({ email }),
    onSuccess: (response: IResponse<string>) => {
      notification({ ...response, message: 'Validated email successfully' });
    },
  });
  return {
    isPending,
    mutateAsync,
  };
};
