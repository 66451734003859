import { alpha, Grid, Stack, Typography, useTheme } from '@mui/material';
import { startCase } from 'lodash';
import { useState } from 'react';
import AvatarUser from 'src/components/common/AvatarUser';

// Components
import CustomTable from 'src/components/Table';
import QuickViewContact from 'src/features/CompanyDetail/Common/QuickViewContact';
import PeopleDetailCardContent from 'src/features/PeopleDetail/components/CardContent';

// Utilities
import { useQueryPeopleRelatedPeople } from 'src/services/queries';
import { FORMAT_DATE_TIME, LIMIT_GET_LIST } from 'src/utils/constants/config';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

const PeopleDetailPeopleRelated = ({ id }: { id?: string }) => {
  const {
    palette: { secondary, text, primary },
  } = useTheme();
  const [openQuickViewContact, setOpenQuickViewContact] = useState(false);
  const [idContactSelect, setIdContactSelect] = useState('');
  const [params, setParams] = useState({
    offset: 0,
    limit: LIMIT_GET_LIST,
  });
  const {
    isFetching,
    data: { data, total_records },
  } = useQueryPeopleRelatedPeople({
    id: id,
    params: params,
  });

  const handleClose = () => {
    setOpenQuickViewContact(false);
  };

  const handleOpen = (id: string) => {
    setIdContactSelect(id);
    setOpenQuickViewContact(true);
  };

  const configColumn: ConfigCell<IPeopleRelatedPeople>[] = [
    {
      disablePadding: false,
      id: 'name',
      label: 'Name',
      cell: (data: IPeopleRelatedPeople) => (
        <Stack direction={'row'} alignItems="center" spacing={1} onClick={() => handleOpen(data?.id || '')}>
          <AvatarUser
            alt={data.image_url}
            src={data.image_url}
            sx={{ backgroundColor: alpha(primary.main, 0.07) }}
            sizes="20"
          />
          <Typography color={secondary.main} variant="body2">
            {renderTextOrDefault(startCase(data.full_name))}
          </Typography>
        </Stack>
      ),
    },
    {
      disablePadding: false,
      id: 'company_name',
      label: 'Company Name',
      cell: (data: IPeopleRelatedPeople) => (
        <Typography color={text.secondary} variant="body2">
          {renderTextOrDefault(data.company_name)}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'position',
      label: 'Position',
      cell: (data: IPeopleRelatedPeople) => (
        <Typography color={text.secondary} variant="body2">
          {renderTextOrDefault(startCase(data.position))}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'start_end',
      label: 'Start date',
      cell: (data: IPeopleRelatedPeople) => (
        <Typography color={text.secondary} variant="body2">
          {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, data.start_date)}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'end_date',
      label: 'End date',
      cell: (data: IPeopleRelatedPeople) => (
        <Typography color={text.secondary} variant="body2">
          {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, data.end_date)}
        </Typography>
      ),
    },
  ];

  return (
    <>
      {openQuickViewContact && (
        <QuickViewContact id={idContactSelect} open={openQuickViewContact} handleClose={handleClose} />
      )}
      <Grid item xs={12}>
        <PeopleDetailCardContent title="Related People">
          <CustomTable
            page={params.offset}
            pageSize={params.limit}
            totalRecords={total_records}
            onChangePage={(e) => setParams({ ...params, ...{ offset: e } })}
            onChangePageSize={(e) => setParams({ ...params, ...{ offset: 0, limit: e } })}
            maxRow={25}
            loading={isFetching}
            dataKey={'id'}
            data={data}
            configColumn={configColumn}
          />
        </PeopleDetailCardContent>
      </Grid>
    </>
  );
};

export default PeopleDetailPeopleRelated;
