import { Box, useTheme } from '@mui/material';
import { useState } from 'react';
import { LOGO_COMPANY_DEFAULT_URL } from 'src/utils/constants/imagesUrl';

const LogoCompany = ({
  logoURL,
  width,
  height,
  borderRadius,
  padding,
}: {
  logoURL?: string;
  width: number;
  height?: number;
  padding?: number;
  borderRadius?: number;
}) => {
  const [isError, setIsError] = useState(false);
  const { palette, shape } = useTheme();

  if (!logoURL || isError) {
    return (
      <Box
        width={'fit-content'}
        padding={padding || 0.5}
        sx={{
          background: palette.background.paper1,
          placeItems: 'center',
          borderRadius: borderRadius || shape.borderRadius,
          display: 'grid',
        }}
      >
        <Box component={'img'} width={width} height={height || width} src={LOGO_COMPANY_DEFAULT_URL} />
      </Box>
    );
  }

  return (
    <Box
      component={'img'}
      width={width}
      height={height || width}
      src={logoURL || ''}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        setIsError(true);
        currentTarget.src = LOGO_COMPANY_DEFAULT_URL;
      }}
    />
  );
};

export default LogoCompany;
