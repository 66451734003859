import { Box, Checkbox, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { isEmpty, keys, omitBy, pickBy, size, values } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonBack from 'src/features/Onboarding/ButtonBack';
import ButtonContinue from 'src/features/Onboarding/ButtonContinue';
import { TYPE_FEATURE } from 'src/features/Onboarding/config';
import { useMutateUserInfo } from 'src/services/mutate/useMutateSetting';
import { AccountType } from 'src/utils/constants/enum';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { formatUrlNonProtocol } from 'src/utils/helpers/stringHelpers';
import { useOnboardingStore } from 'src/zustand/useOnboardingStore';

const StepFeature = (props: IFormOnboardingProps) => {
  const theme = useTheme();
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { dispatch, features, accountType, address, avatar, informations } = useOnboardingStore((state) => state);
  const { mutateAsync } = useMutateUserInfo();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      //government
      [TYPE_FEATURE.government.identify_counterfeit_goods.key]: features?.identify_counterfeit_goods,
      [TYPE_FEATURE.government.investigate_market_trends.key]: features?.investigate_market_trends,
      [TYPE_FEATURE.government.map_a_supply_chain.key]: features?.map_a_supply_chain,
      [TYPE_FEATURE.government.source_new_suppliers.key]: features?.source_new_suppliers,
      [TYPE_FEATURE.government.predicting_future_demand.key]: features?.predicting_future_demand,
      [TYPE_FEATURE.government.understand_taxes_and_tariffs_of_products.key]:
        features?.understand_taxes_and_tariffs_of_products,
      [TYPE_FEATURE.government.explore_supply_chain_disruptions.key]: features?.explore_supply_chain_disruptions,
      [TYPE_FEATURE.government.ensure_compliance_with_trade_transparency_regulations.key]:
        features?.ensure_compliance_with_trade_transparency_regulations,
      [TYPE_FEATURE.government.negative_media_monitoring.key]: features?.negative_media_monitoring,
      [TYPE_FEATURE.government.obtain_company_contacts.key]: features?.obtain_company_contacts,
      //company
      [TYPE_FEATURE.company.KYC_AML.key]: features?.KYC_AML,
      [TYPE_FEATURE.company.find_new_customers.key]: features?.find_new_customers,
      [TYPE_FEATURE.company.mapping_my_supply_chain.key]: features?.mapping_my_supply_chain,
      [TYPE_FEATURE.company.understand_tariffs_and_taxes_for_my_product.key]:
        features?.understand_tariffs_and_taxes_for_my_product,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  //Update Data form onboarding global state
  useEffect(() => {
    dispatch({
      features: formik.values,
    });
  }, [formik.values]);

  const setValueCheckbox = (field: string, value: boolean) => {
    const newSelected = [...selected];
    const index = newSelected.indexOf(field);

    if (index === -1) {
      newSelected.push(field);
    } else {
      newSelected.splice(index, 1);
    }
    setSelected(newSelected);
    if (size(selected) >= 3) return;
    formik.setFieldValue(field, value);
  };

  const checkDisabled = (type: string) => {
    const isMaxValue = size(selected) >= 3;
    if (!isMaxValue) {
      return false;
    }
    if (isMaxValue && selected.indexOf(type) === -1) {
      return true;
    }
    if (isMaxValue && selected.indexOf(type) !== -1) {
      return false;
    }
  };

  const renderItems = () => {
    let state: { [key: string]: { key: string; label: string } } = TYPE_FEATURE.company;
    if (accountType === AccountType.GovermentAgency) {
      state = TYPE_FEATURE.government;
    }
    return values(state).map((item: { key: string; label: string }) => {
      return (
        <FormControlLabel
          key={item.key}
          id={item.key}
          name={item.key}
          onChange={(e, value) => {
            setValueCheckbox(item.key, value);
          }}
          control={<Checkbox checked={selected.includes(item.key)} disabled={checkDisabled(item.key)} />}
          label={
            <Typography color={theme.palette.text.secondary} variant="body1">
              {item.label}
            </Typography>
          }
        />
      );
    });
  };

  const onFinish = async () => {
    setLoading(true);
    const dto = {
      address: address,
      work_for: accountType,
      looking_to_achieve: keys(pickBy(features)),
      company_name: informations?.company_name,
      department: informations?.department,
      role: informations?.role,
      work_email: informations?.work_email,
      website_address: informations?.website_address,
      type_organization: informations?.type_organization,
      type_of_agency_working_for: informations?.type_of_agency_working_for,
      confirm_work_email: informations?.confirm_work_email,
      industry: informations?.industry,
      avatar_url: avatar,
    } as TypeDTOSettingInformation;

    dto.website_address = formatUrlNonProtocol(dto.website_address);

    const res = await mutateAsync(omitBy(dto, isEmpty));
    if (res.status_code === SUCCESS_CODE.GET) {
      navigate({ pathname: AuthRouteKeys.Home });
    }
    setLoading(false);
  };

  return (
    <>
      <Typography variant="h4">Please Indicate Your Goals for Using ADAMftd</Typography>
      <Typography variant="body2" color={'text.secondary'}>
        Please select at least 1 option and maximum 3 options.
      </Typography>

      <Box sx={{ mt: 4 }} />

      <Stack direction={'column'}>{renderItems()}</Stack>

      <Box sx={{ mt: 4 }} />

      <Stack alignItems={'center'} direction={'row'} justifyContent={'end'} gap={3}>
        <ButtonBack onClick={props.onClickBack} />
        <ButtonContinue
          isLoading={loading}
          text="Confirm"
          onClick={onFinish}
          disabled={!keys(pickBy(features)).length || loading}
        />
      </Stack>
    </>
  );
};

export default StepFeature;
