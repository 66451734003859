import * as React from 'react';
import {
  Stack,
  // Typography
} from '@mui/material';

// Components
import ButtonBack from 'src/components/common/ButtonBack';
import Breadcrumbs from 'src/components/common/Breadcrumbs';
// import { formatUTCTime } from 'src/utils/helpers/dayjs';
// import { FORMAT_DATE_TIME } from 'src/utils/constants/config';

type TypeItem = {
  text: string;
  icon?: React.ReactNode;
  link?: string;
};

export default function BreadcrumbWithBtnBack({
  listItem,
  // updatedAt,
  showButtonBack = true,
}: {
  listItem: TypeItem[];
  updatedAt: string;
  showButtonBack?: boolean;
}) {
  return (
    <Stack direction={showButtonBack ? 'row' : 'row-reverse'} alignItems="center" justifyContent={'space-between'}>
      {showButtonBack && <ButtonBack />}
      {/* <Typography gap={0.5} display={'flex'} variant="body2" color={'text.secondary'}>
        The data has been updated to
        <Typography fontWeight={500} variant="body2" color={'inherit'}>
          {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, updatedAt ?? '')}
        </Typography>
      </Typography> */}
      <Breadcrumbs listItem={listItem} />
    </Stack>
  );
}
