export const LOGO_HEADER_URL =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/logo-company/logo-header.svg';
export const LOGO_COMPANY_DEFAULT_URL =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/logo-company/logo-default.svg';
export const LOGO_LOGIN_URL =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/logo-company/ic-adamftd.svg';

export const LOGO_GOOGLE_URL =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/logo-social/google.png';
export const LOGO_TRUSTPILOT_URL =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/logo-social/trustpilot.png';
export const LOGO_YELP_URL =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/logo-social/yelp.png';
export const BACKGROUND_LOGIN =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/background-login.png';
export const BANNER_NOTIFY_HOME_PAGE =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/icons/banner-notify-home-page.png';
export const ICON_BANNER_HOME_PAGE =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/icons/icon-banner-home-page.png';
export const ICON_SUPPORT_NO_DATA_COMPANY =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/icons/icon-support-no-data-company.png';
export const ICON_QUICK_TIP_SEARCH_COMPANY =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/icons/icon-quick-tips-search-company.png';
export const ICON_NO_MATCH_SEARCH_COMPANY =
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/icons/icon-no-matching-search-company.png';

export const DEFAULT_AVATARS = [
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar1.png',
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar2.png',
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar3.png',
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar4.png',
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar5.png',
  'https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar6.png',
  // "https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar7.png",
  // "https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar8.png",
  // "https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar9.png",
  // "https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar10.png",
  // "https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar11.png",
  // "https://eu2.contabostorage.com/cc09a00c2815458eb8d1efae3c1fe5f2:front-end/images/avatar-user-default/avatar12.png"
] as Array<string>;
