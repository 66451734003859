import { Typography } from '@mui/material';
import WhiteBoxContent from 'src/components/common/WhiteBoxContent';
import WorldMapChart from 'src/components/HighChart/WorldMapChart';
import { getDataLabelOptionBillOfLanding, WorldMapOptions } from 'src/components/HighChart/WorldMapChart/config';

const MapAddress = () => {
  return (
    <WhiteBoxContent>
      <Typography mb={3} variant="h5">
        Maps Address
      </Typography>

      <WorldMapChart
        data={[
          ['us', 10],
          ['tw', 20],
        ]}
        dataPoint={[
          {
            lon: 121.59,
            lat: 25.1,
            name: 'tw',
            dataLabels: getDataLabelOptionBillOfLanding({
              code: 'tw',
              companyName: 'HWA HSIA GLASS CO LTD',
              address: 'No. 49, Puu Chien Road, Hsinchu, Taiwan',
              companyType: 'Buyer',
            }),
          },
          {
            lon: -118.24,
            lat: 34.05,
            dataLabels: getDataLabelOptionBillOfLanding({
              code: 'us',
              companyName: 'HWA HSIA GLASS CO LTD',
              address: 'No. 49, Puu Chien Road, Hsinchu, Taiwan',
              companyType: 'Supplier',
            }),
          },
        ]}
        height={600}
        type={WorldMapOptions.company_bill_of_landing}
      />
    </WhiteBoxContent>
  );
};

export default MapAddress;
