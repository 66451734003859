export enum MenuDisclaimer {
  DataAccuracySourcing = 'DataAccuracySourcing',
  NoLiability = 'NoLiability',
  UserResponsibilityDiligence = 'UserResponsibilityDiligence',
  ComplianceLawsRegulations = 'ComplianceLawsRegulations',
  AmendmentsUpdates = 'AmendmentsUpdates',
  ContactInformation = 'ContactInformation',
}

export const LIST_MENU_SIDEBAR_DISCLAIMER = [
  {
    id: MenuDisclaimer.DataAccuracySourcing,
    label: 'Data Accuracy and Sourcing',
  },
  {
    id: MenuDisclaimer.NoLiability,
    label: 'No Liability',
  },
  {
    id: MenuDisclaimer.UserResponsibilityDiligence,
    label: 'User Responsibility and Due Diligence',
  },
  {
    id: MenuDisclaimer.ComplianceLawsRegulations,
    label: 'Compliance with Laws and Regulations',
  },
  {
    id: MenuDisclaimer.AmendmentsUpdates,
    label: 'Amendments and Updates',
  },
  {
    id: MenuDisclaimer.ContactInformation,
    label: 'Contact Information',
  },
];
