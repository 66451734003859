import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table';
import WhiteBoxContent from 'src/components/common/WhiteBoxContent';
import { useQueryCompanyMarketPlace } from 'src/services/queries';
import { EmptyText, MINUTE_REFETCH_STOCK_PRICE } from 'src/utils/constants/config';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

interface IProps {
  id?: string;
}

const StockPlace = ({ id = '' }: IProps) => {
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
  });

  const { data, isFetching, refetch } = useQueryCompanyMarketPlace({
    id: id,
    params: params,
  });

  useEffect(() => {
    const refetchApi = setInterval(
      () => {
        refetch();
      },
      MINUTE_REFETCH_STOCK_PRICE * 60 * 1000,
    );

    return () => {
      clearInterval(refetchApi);
    };
  }, []);

  setInterval;

  const title = (
    <Stack direction="column" spacing={1}>
      <Typography textAlign={'left'} variant="h5">
        Stock / Share Prices
      </Typography>
      <Typography textAlign="left" variant="caption" color="text.secondary">
        Updated every 5 minutes.
      </Typography>
    </Stack>
  );

  const configColumn: ConfigCell<ICompanyMarketPlace>[] = [
    {
      disablePadding: false,
      id: 'symbol',
      label: 'Symbol',
      cell: (data: ICompanyMarketPlace) => (
        <Typography variant="body2" color={'text.secondary'}>
          {renderTextOrDefault(data.symbol)}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'name',
      label: 'Name',
      cell: (data: ICompanyMarketPlace) => (
        <Typography variant="body2" color={'text.secondary'}>
          {renderTextOrDefault(data.name)}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'stock_price',
      label: 'Stock Price',
      cell: (data: ICompanyMarketPlace) => (
        <Typography variant="body2" color={'text.secondary'}>
          {renderTextOrDefault(data.stock_price)}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'daily_change',
      label: 'Daily Change (%)',
      cell: (data: ICompanyMarketPlace) => {
        if (!data.daily_change) {
          return EmptyText;
        }
        const color = data.daily_change
          ? Number(data.daily_change) > 0
            ? 'success.main'
            : 'error.main'
          : 'text.secondary';
        return (
          <Typography variant="body2" color={color}>
            {data.daily_change + '%'}
          </Typography>
        );
      },
    },
    {
      disablePadding: false,
      id: 'stock_exchange',
      label: 'Stock Exchange',
      cell: (data: ICompanyMarketPlace) => (
        <Typography variant="body2" color={'text.secondary'}>
          {renderTextOrDefault(data.stock_exchange_name)}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'stock_exchange_mic',
      label: 'Stock Exchange (MIC)',
      cell: (data: ICompanyMarketPlace) => (
        <Typography variant="body2" color={'text.secondary'}>
          {renderTextOrDefault(data.stock_exchange_mic)}
        </Typography>
      ),
    },
    {
      disablePadding: false,
      id: 'country',
      label: 'Country',
      cell: (data: ICompanyMarketPlace) => (
        <Typography variant="body2" color={'text.secondary'}>
          {renderTextOrDefault(data.country)}
        </Typography>
      ),
    },
  ];

  const table = (
    <Table
      onChangePage={(e) => setParams({ ...params, ...{ offset: e } })}
      onChangePageSize={(e) => setParams({ ...params, ...{ offset: 0, limit: e } })}
      loading={isFetching}
      page={data.page}
      pageSize={data.page_size}
      totalRecords={data?.total_records || 0}
      dataKey={'id'}
      data={data.data}
      configColumn={configColumn}
    />
  );

  return (
    <WhiteBoxContent>
      {title}
      <Box mt={3} />
      {table}
    </WhiteBoxContent>
  );
};

export default StockPlace;
