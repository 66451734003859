import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GoogleMap, GoogleMapProps, Marker, useLoadScript } from '@react-google-maps/api';
import React, { useMemo } from 'react';

interface IProps {
  lat: number;
  lng: number;
  zoom?: number;
  children?: GoogleMapProps['children'];
}

const GoogleMapComponent: React.FC<IProps> = ({ lat, lng, zoom = 15, children }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  });
  const center = {
    lat: lat,
    lng: lng,
  };
  const map = useMemo(() => {
    if (loadError) {
      return <Typography variant="body2">Error loading Google Maps</Typography>;
    }
    if (!isLoaded) {
      return <Box height={'100%'}>Loading Google Maps...</Box>;
    }
    return (
      <Box height={'100%'}>
        <GoogleMap mapContainerStyle={{ width: '100%', height: 'inherit' }} center={center} zoom={zoom}>
          <Marker position={center} />
          {children}
        </GoogleMap>
      </Box>
    );
  }, [isLoaded, loadError, lat, lng]);

  return map;
};

export default GoogleMapComponent;
