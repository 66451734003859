import { Helmet } from 'react-helmet';
import PeopleDetail from 'src/features/PeopleDetail';
import PeopleLayout from 'src/pages/PeopleDetail/PeopleLayout';

const PeopleDetailPage = () => {
  return (
    <PeopleLayout>
      <Helmet>
        <title>People Detail</title>
      </Helmet>
      <PeopleDetail />
    </PeopleLayout>
  );
};

export default PeopleDetailPage;
