import { Box, Grid, IconButton, Skeleton, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import Lottie from 'react-lottie';
// import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import { Bookmark } from 'react-feather';
import { get } from 'lodash';

// Components
import SearchIcon from 'src/asset/lottie/searches.json';
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import LogoCompany from 'src/components/common/LogoCompany';
import { UserIcon } from 'src/components/Icons';
import { LIMIT_GET_LIST_PEOPLE, UnknownText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { convertToStartCase, getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';
import AvatarUser from 'src/components/common/AvatarUser';
import { NATIONALITIES } from 'src/utils/constants/nationality';

// Utilities

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `2px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: 1,
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 2,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));
interface IProps {
  listPeople: IPeopleProfile[];
  isFetching: boolean;
  selected?: IPeopleProfile;
  onSelect: (people: IPeopleProfile) => void;
}

const ListPeople = ({ listPeople, isFetching, selected, onSelect }: IProps) => {
  const theme = useTheme();
  if (isFetching) {
    return (
      <Grid container spacing={2}>
        {Array(Number(LIMIT_GET_LIST_PEOPLE))
          .fill('')
          .map((_, index) => (
            <Grid key={`skeleton-profile-company-${index}`} sm={12} md={6} lg={4} xl={3} item>
              <SkeletonProfile />
            </Grid>
          ))}
      </Grid>
    );
  }

  if (getArrayDefault(listPeople).length === 0) {
    return (
      <Grid sm={12} item>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: SearchIcon,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={600}
          width={540}
        />
        <Typography variant="h2" sx={{ color: '#3658C1' }} align="center">
          No Results Found
        </Typography>
        <Typography variant="body1" color="text.secondary" align="center">
          Sorry, we did not find any results matching your search criteria. Please try an{' '}
          <Typography component="span" color="secondary">
            Advanced Search
          </Typography>{' '}
          to search again.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {listPeople.map((people, index) => {
        const isSelected = selected?.id === people.id ? true : false;
        const countryCode = people?.country_code || get(NATIONALITIES, people?.nationality || '', '');
        const country = getCountryByCountryCode(countryCode || '');

        return (
          <Grid key={index + (people?.id || '')} item xs={3}>
            <StyledBox
              onClick={() => onSelect(people)}
              sx={{
                borderColor: isSelected ? theme.palette.primary.main : theme.palette.divider,
                cursor: 'pointer',
              }}
              height="100%"
            >
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Box display="flex" flexDirection="row" justifyContent="center" position="relative">
                    <AvatarUser
                      alt={people.full_name}
                      variant="rounded"
                      src=""
                      sx={{ width: 32, height: 32, border: '0.5px solid #A195FD', backgroundColor: '#F4F7FE' }}
                    />
                    <Tooltip title="Bookmarks Coming Soon!">
                      <IconButton
                        sx={{
                          border: `1px solid ${theme.palette.divider}`,
                          height: 'fit-content',
                          position: 'absolute',
                          right: 0,
                        }}
                        size="small"
                      >
                        <IconFeather
                          muiProps={{ fontSize: 'small' }}
                          icon={<Bookmark fill={people.is_marked ? theme.palette.primary.main : 'transparent'} />}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography variant="subtitle1" align="center">
                    {convertToStartCase(people?.full_name || UnknownText)}
                  </Typography>
                  {people?.position && (
                    <Typography variant="caption2" color="text.secondary" align="center">
                      {convertToStartCase(people?.position || UnknownText)}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={1}>
                  {people?.company_name && (
                    <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                      <LogoCompany logoURL={''} width={20} height={20} />
                      <Typography variant="caption2" color="text.secondary">
                        {(people?.company_name || UnknownText).toUpperCase()}
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                    <FlagCountry width={20} code={country.code || ''} />
                    <Typography variant="caption2" color="text.secondary">
                      {country?.label || UnknownText}
                    </Typography>
                  </Box>
                  {/* <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                  <TransgenderOutlinedIcon fontSize="small" color="disabled" />
                  <Typography variant="caption2" color="text.secondary">
                    {people.gender}
                  </Typography>
                </Box> */}
                  {people?.birthday && (
                    <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                      <UserIcon fontSize="small" color="disabled" />
                      <Typography variant="caption2" color="text.secondary">
                        {people?.birthday || UnknownText}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Stack>
            </StyledBox>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ListPeople;

const SkeletonProfile = () => {
  return (
    <StyledBox display={'flex'} flexDirection="column" gap={1} padding={1.5}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
      <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
      <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
    </StyledBox>
  );
};
