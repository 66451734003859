import { v4 as uuidv4 } from 'uuid';
import { DeviceManagers, User, UserSignUp, ResponseLogin } from 'src/model/auth';
import { CommonResponse } from 'src/model/common';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/utils/constants/config';
import { DEVICE_ID_KEY } from 'src/utils/constants/config';
import { getRequest, postRequest } from './http';
import { URL_ENDPOINT } from 'src/utils/constants/service';
import { NoAuthRouteKeys } from 'src/utils/constants/routes';

export function handleError() {
  window.location.replace(NoAuthRouteKeys.Login);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function getDeviceId() {
  const deviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (deviceId) {
    return deviceId;
  }
  const newDeviceId = `${uuidv4()}-${new Date().getTime()}`;
  localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
  return newDeviceId;
}

async function handleRefreshToken(body: TypeDTORefreshToken) {
  const url = URL_ENDPOINT.user.users + 'refresh-token';
  return postRequest(url, body) as Promise<CommonResponse>;
}

async function getUserInfo() {
  const url = URL_ENDPOINT.user.users + 'get-user';
  return getRequest(url, {}, new User()) as Promise<User>;
}

async function handleLogin(body: TypeDTOLogin) {
  const url = URL_ENDPOINT.user.users + 'login';
  return postRequest(url, body) as Promise<ResponseLogin>;
}

function handleSignup(body: TypeDTOSignup) {
  const url = URL_ENDPOINT.user.users + 'register';
  return postRequest(url, body) as Promise<UserSignUp>;
}

function handleCreateOTP(body: TypeDTOCreateOTP) {
  const url = URL_ENDPOINT.user.users + 'create-otp-code';
  return postRequest(url, body) as Promise<CommonResponse>;
}

function handleVerifyOTP(body: TypeDTOVerifyOTP) {
  const url = URL_ENDPOINT.user.users + 'verify-otp-code';
  return postRequest(url, body) as Promise<CommonResponse>;
}

function handleResendOTP(body: TypeDTOCreateOTP) {
  const url = URL_ENDPOINT.user.users + 'resend-otp-code';
  return postRequest(url, body) as Promise<CommonResponse>;
}

function handleResetPassword(body: TypeDTOResetPassword) {
  const url = URL_ENDPOINT.user.users + 'forgot-password';
  return postRequest(url, body) as Promise<CommonResponse>;
}

function handleGetUser(params: Record<string, string>) {
  const url = URL_ENDPOINT.user.users + 'get-user';
  return getRequest(url, params) as Promise<CommonResponse>;
}

function checkEmailExists(params: Record<string, string>) {
  const url = URL_ENDPOINT.user.users + 'check-email-exists';
  return getRequest(url, params) as Promise<CommonResponse>;
}

function checkWorkEmailExists(params: Record<string, string>) {
  const url = URL_ENDPOINT.user.users + 'check-work-email';
  return getRequest(url, params) as Promise<CommonResponse>;
}

function validatePassword(params: Record<string, string>) {
  const url = URL_ENDPOINT.user.users + 'validate-password';
  return postRequest(url, params) as Promise<CommonResponse>;
}

function checkUsernameExist(params: Record<string, string>) {
  const url = URL_ENDPOINT.user.users + 'check-username';
  return getRequest(url, params) as Promise<CommonResponse>;
}

function getDeviceMangers(params: Record<string, string | number>) {
  const url = URL_ENDPOINT.user.users + 'device-management';
  return getRequest(url, params, new DeviceManagers()) as Promise<DeviceManagers>;
}

function logoutUserDevice(id: string) {
  const url = URL_ENDPOINT.user.users + `device-management/${id}/logout`;
  return postRequest(url, {}) as Promise<DeviceManagers>;
}

const AuthService = {
  getUserInfo,
  handleLogin,
  handleSignup,
  handleCreateOTP,
  handleVerifyOTP,
  handleResendOTP,
  handleResetPassword,
  handleGetUser,
  handleRefreshToken,
  checkEmailExists,
  checkWorkEmailExists,
  checkUsernameExist,
  validatePassword,
  getDeviceMangers,
  logoutUserDevice,
};

export default AuthService;
