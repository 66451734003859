import { Box, styled, useTheme, Container } from '@mui/material';
import { ReactNode } from 'react';
import Drawer from 'src/components/Drawer';
import { DRAWER_WIDTH_CLOSE } from 'src/utils/constants/config';
import Footer from 'src/components/Footer';

const ContainerMainLayout = styled(Box)(() => ({
  minHeight: '100vh',
  // minWidth: '1200px',
  overflowX: 'scroll',
}));

const MainLayout = ({ children, hideSideBar }: { children: ReactNode; hideSideBar?: boolean }) => {
  const theme = useTheme();

  return (
    <ContainerMainLayout sx={{ background: theme.palette.background.paper1 }}>
      <Drawer hideSideBar={hideSideBar} />
      <Box
        sx={{
          marginLeft: DRAWER_WIDTH_CLOSE + 'px',
          mt: theme.spacing(10),
          minHeight: `calc(100vh - ${theme.spacing(20)})`,
        }}
      >
        {children}
      </Box>
      <Container maxWidth="xl">
        <Box sx={{ marginLeft: DRAWER_WIDTH_CLOSE + 'px' }}>
          <Footer />
        </Box>
      </Container>
    </ContainerMainLayout>
  );
};

export default MainLayout;
