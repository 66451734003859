import { Typography } from '@mui/material';
import React from 'react';

// Component
import DataTable from 'src/features/CompanyDetail/Common/DataTable';
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

export default function IndustryClassifications({ dataCompany }: { dataCompany: ICompanyDetail }) {
  const listData = getArrayDefault(dataCompany?.industries).map((industry, index) => ({
    key: `${industry?.id} + ${index}`,
    title: industry.name || EmptyText,
    value: industry.description || EmptyText,
  }));
  return (
    <React.Fragment>
      <Typography variant="h5" align="left">
        Industry Classifications
      </Typography>
      <DataTable listData={listData} />
    </React.Fragment>
  );
}
