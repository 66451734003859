import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { get, isEmpty, omitBy } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import * as Yup from 'yup';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FlagCountry from 'src/components/common/FlagCountry';
import FormikForm from 'src/components/common/FormikForm';
import IconFeather from 'src/components/common/Icon';
import FormItemCompanyName from 'src/components/Form/FormItemCompanyName';
import FormItemConstantSetting from 'src/components/Form/FormItemConstantSetting';
import FormItemCountry from 'src/components/Form/FormItemCountry';
import UploadImage from 'src/features/Setting/components/UploadImage';
import PopupSelectPhoneCode from 'src/features/Setting/PopupSelectPhoneCode';

// Utilities
import { useTranslation } from 'react-i18next';
import iconFaceBook from 'src/asset/icons/social-media/ic-facebook.svg';
import iconInstagram from 'src/asset/icons/social-media/ic-instagram.svg';
import iconLinkedIn from 'src/asset/icons/social-media/ic-linkedIn.svg';
import iconTwitter from 'src/asset/icons/social-media/ic-twitter.svg';
import iconYoutube from 'src/asset/icons/social-media/ic-youtube.svg';
import { useNotification } from 'src/hooks/useNotification';
import { CONSTANT_SETTING_TYPES } from 'src/services/api/config';
import { useMutateCheckWorkEmail } from 'src/services/mutate/useMutateAuth';
import { useMutateUserInfo } from 'src/services/mutate/useMutateSetting';
import { useMutateDeleteAvatar } from 'src/services/mutate/useMutateUtil';
import { useQueryUserInfo } from 'src/services/queries';
import { FORMAT_DATE_TIME } from 'src/utils/constants/config';
import { TypeSocialProfile } from 'src/utils/constants/enum';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import {
  formatPhoneNumberBeforeSent,
  formatUrlNonProtocol,
  getCountryByPhoneCode,
  getDefaultPhoneNumber,
} from 'src/utils/helpers/stringHelpers';
import {
  checkBadWords,
  validateEmail,
  validateEmailBlocked,
  validateProfileURL,
  validateURL,
} from 'src/utils/helpers/validate';

const Information = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { mutateAsync } = useMutateUserInfo();
  const { mutateAsync: mutateAsyncCheckWorkEmail, isPending: isPendingCheckWorkEmail } = useMutateCheckWorkEmail();
  const {
    refetch,
    data: { data },
  } = useQueryUserInfo();

  const removeAvatarMutation = useMutateDeleteAvatar();
  const { notification } = useNotification();
  const { t } = useTranslation();

  const [visiblePhoneCode, setVisiblePhoneCode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const defaultPhoneNumber = useMemo(() => {
    return getDefaultPhoneNumber(data.phone_number);
  }, [data.phone_number]);

  const formik = useFormik<TypeDTOSettingInformation & { confirm_work_email: boolean }>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      name_display: data.name_display || '',
      introduction: data.introduction || '',
      birthday: data.birthday || '',
      gender: data.gender || '',
      nationality: data.nationality || '',
      address: data.address || '',
      phone_number: data.phone_number ? defaultPhoneNumber.phoneNumber : '',
      company_name: data.company_name || '',
      industry: data.industry || '',
      department: data.department || '',
      role: data.role || '',
      website_address: data.website_address || '',
      facebook: data.facebook || '',
      twitter: data.twitter || '',
      linkedin: data.linkedin || '',
      instagram: data.instagram || '',
      youtube: data.youtube || '',
      work_email: data.work_email || '',
      company_email: data.company_email || '',
      avatar_url: data.avatar_url || '',
      phone_code: defaultPhoneNumber.phoneCode,
      company_website: data.company_website || '',
      company_address: data.company_address || '',
      confirm_work_email: false,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .max(255)
        .required('First name is required')
        .test('first-name-contain-bad-word', 'First name cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      last_name: Yup.string()
        .max(255)
        .required('Last name is required')
        .test('last-name-contain-bad-word', 'Last name cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      name_display: Yup.string()
        .max(255)
        .test('display-name-contain-bad-word', 'Name display cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      company_address: Yup.string()
        .max(255)
        .test('company-address-contain-bad-word', 'Company address cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      introduction: Yup.string()
        .max(255)
        .test('introduction-contain-bad-word', 'Introduction cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      birthday: Yup.string().max(255),
      gender: Yup.string().max(255),
      nationality: Yup.string().max(255),
      address: Yup.string()
        .max(255)
        .test('address-contain-bad-word', 'Address cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      phone_number: Yup.string()
        .max(255)
        .test('phone_code', 'Phone number is invalid', (text?: string, context?: ExpectedAny) => {
          const code = context?.parent?.phone_code;
          const phoneNumber = code + (text || '');
          if (!text) {
            return true;
          }
          return isValidPhoneNumber(phoneNumber || '');
        }),
      company_name: Yup.string()
        .max(255)
        .test('company-name-contain-bad-word', 'Company name cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      industry: Yup.string().max(255),
      department: Yup.string().max(255),
      role: Yup.string().max(255),
      phone_code: Yup.string().max(255),
      website_address: Yup.string()
        .max(255)
        .test('website_address', 'URL is invalid', (text?: string) => validateURL(text || ''))
        .test('website-address-contain-bad-word', 'URL cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      company_website: Yup.string()
        .max(255)
        .test('company_website', 'URL is invalid', (text?: string) => validateURL(text || '')),
      facebook: Yup.string()
        .max(255)
        .test('validate_url_facebook', 'Please fill in the correct platform link', (text?: string) =>
          validateProfileURL(text || '', TypeSocialProfile.Facebook),
        ),
      twitter: Yup.string()
        .max(255)
        .test('validate_url_twitter', 'Please fill in the correct platform link', (text?: string) =>
          validateProfileURL(text || '', TypeSocialProfile.Twitter),
        ),
      linkedin: Yup.string()
        .max(255)
        .test('validate_url_linkedin', 'Please fill in the correct platform link', (text?: string) =>
          validateProfileURL(text || '', TypeSocialProfile.Linkedin),
        ),
      instagram: Yup.string()
        .max(255)
        .test('validate_url_instagram', 'Please fill in the correct platform link', (text?: string) =>
          validateProfileURL(text || '', TypeSocialProfile.Instagram),
        ),
      youtube: Yup.string()
        .max(255)
        .test('validate_url_instagram', 'Please fill in the correct platform link', (text?: string) =>
          validateProfileURL(text || '', TypeSocialProfile.Youtube),
        ),
      work_email: Yup.string()
        .max(255)
        .test('company_email_valid', 'Email is invalid', (text?: string) => {
          return validateEmail(text || '');
        }),
      confirm_work_email: Yup.bool().test(
        'email',
        'Please use a working email address',
        (confirm?: boolean, context?: ExpectedAny) => {
          return Boolean(confirm) || validateEmailBlocked(context?.parent?.work_email);
        },
      ),
      company_email: Yup.string()
        .max(255)
        .test('company_email_valid', 'Email is invalid', (text?: string) => {
          return validateEmail(text || '');
        }),
      // .test('company_email', 'Please use a working email address', (text?: string) => {
      //   return validateEmailBlocked(text || '');
      // }),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const dto = omitBy(values, isEmpty) as TypeDTOSettingInformation;

      const phoneNumber = formatPhoneNumberBeforeSent(values.phone_code, values.phone_number);
      dto.phone_number = phoneNumber;
      dto.website_address = formatUrlNonProtocol(dto.website_address);
      dto.facebook = formatUrlNonProtocol(dto.facebook);
      dto.twitter = formatUrlNonProtocol(dto.twitter);
      dto.linkedin = formatUrlNonProtocol(dto.linkedin);
      dto.instagram = formatUrlNonProtocol(dto.instagram);
      dto.youtube = formatUrlNonProtocol(dto.youtube);

      if (data.avatar_url && !values.avatar_url) {
        await removeAvatarMutation.mutateAsync();
      }

      const response = await mutateAsync(dto);
      notification(
        response,
        {
          title: t('setting.password.notification.update_user_info.success.title'),
          description: t('setting.password.notification.update_user_info.success.description'),
        },
        {
          title: t('setting.password.notification.update_user_info.failed.title'),
          description: t('setting.password.notification.update_user_info.failed.description'),
        },
      );
      if (response.status_code === SUCCESS_CODE.GET) {
        setTimeout(() => {
          refetch();
        }, 100);
      }
      setLoading(false);
    },
  });

  const checkEmail = useCallback(async () => {
    if (
      formik.values.work_email &&
      validateEmail(String(formik.values.work_email)) &&
      formik.values.work_email !== data.work_email
    ) {
      const resCheckEmail = await mutateAsyncCheckWorkEmail({ work_email: String(formik.values.work_email) });
      if (resCheckEmail.status_code !== SUCCESS_CODE.GET) {
        formik.setFieldError('work_email', resCheckEmail.message);
        return;
      }
    }
  }, [formik.values.work_email]);

  const getError = (type: string) => {
    return get(formik.touched, type) && Boolean(get(formik.errors, type));
  };

  const renderError = (type: string) => {
    return (
      get(formik.touched, type) &&
      get(formik.errors, type) && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {get(formik.errors, type)}
        </FormHelperText>
      )
    );
  };
  const renderErrorWorkingEmail = () => {
    if (isPendingCheckWorkEmail) return;
    if (get(formik.errors, 'work_email')) {
      return renderError('work_email');
    }

    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {get(formik.errors, 'confirm_work_email') && (
          <FormHelperText error>{get(formik.errors, 'confirm_work_email')}</FormHelperText>
        )}
        {!validateEmailBlocked(String(formik.values.work_email)) && (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Checkbox
                size="small"
                onChange={(e, value) => {
                  formik.setFieldValue('confirm_work_email', value);
                }}
                checked={Boolean(formik.values.confirm_work_email)}
                color="primary"
              />
            }
            label={<Typography variant="body2">I promise this is my work email</Typography>}
          />
        )}
      </Stack>
    );
  };

  const countryCode = useMemo(() => {
    const country = getCountryByPhoneCode(formik.values.phone_code || '');
    return country.code;
  }, [formik.values.phone_code]);

  const handleCancel = () => {
    formik.resetForm();
  };

  return (
    <FormikForm formik={formik}>
      <Stack gap={4} direction={'column'}>
        <Box>
          <FormItemLayout
            formTitle="Your Name"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <>
                <Box flex={1}>
                  <TextField
                    id="first_name"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={getError('first_name')}
                    required
                    size="small"
                    fullWidth
                    label="Your First Name"
                  />
                  {renderError('first_name')}
                </Box>

                <Box flex={1}>
                  <TextField
                    id="last_name"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={getError('last_name')}
                    required
                    size="small"
                    fullWidth
                    label="Your Last Name"
                  />
                  {renderError('last_name')}
                </Box>
              </>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Your Profile Display Name"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width={'100%'}>
                <TextField
                  id="name_display"
                  name="name_display"
                  value={formik.values.name_display}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  size="small"
                  fullWidth
                  label="Name Display"
                />
                {renderError('name_display')}
              </Box>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Introduction"
            formSubtitle="Please describe yourself and the company that you belong to."
            inputs={
              <Box width={'100%'}>
                <TextField
                  sx={{ '& .MuiInputBase-sizeSmall .MuiInputBase-input': { padding: 0 } }}
                  id="introduction"
                  name="introduction"
                  value={formik.values.introduction}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiline
                  rows={4}
                  size="small"
                  fullWidth
                  label="Your Bio"
                />
                {renderError('introduction')}
              </Box>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Your Birthday"
            formSubtitle="Please set displayed at Term & Policy."
            inputs={
              <>
                <DatePicker
                  value={formik.values.birthday ? dayjs(formik.values.birthday, FORMAT_DATE_TIME.FULL_ONLY_DATE) : null}
                  onChange={(value: Dayjs | null) => {
                    const day = dayjs(value).format(FORMAT_DATE_TIME.FULL_ONLY_DATE);
                    formik.setFieldValue('birthday', day);
                  }}
                  sx={{ width: '100%' }}
                  label={FORMAT_DATE_TIME.FULL_ONLY_DATE}
                  slotProps={{ textField: { size: 'small' } }}
                  format={FORMAT_DATE_TIME.FULL_ONLY_DATE}
                  views={['year', 'month', 'day']}
                />
              </>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Gender"
            formSubtitle="Please set displayed at Term & Policy."
            inputs={
              <>
                <TextField
                  type="select"
                  id="gender"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  size="small"
                  select
                  fullWidth
                  label="Your Gender"
                >
                  <MenuItem value="male" key={'male'}>
                    Male
                  </MenuItem>
                  <MenuItem value="female" key={'female'}>
                    Female
                  </MenuItem>
                  <MenuItem value="other" key={'other'}>
                    Other
                  </MenuItem>
                  <MenuItem value="prefer_not_to_say" key={'prefer_not_to_say'}>
                    Prefer Not To Say
                  </MenuItem>
                </TextField>
              </>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Country"
            formSubtitle="Please set displayed at Term & Policy."
            inputs={
              <>
                <FormItemCountry id="nationality" tooltipText="nationality" />
              </>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Your Address"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width="100%">
                <TextField
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  size="small"
                  fullWidth
                  label="Address Detail"
                />
                {renderError('address')}
              </Box>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Your Work Email"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width={'100%'}>
                <TextField
                  error={getError('work_email')}
                  id="work_email"
                  name="work_email"
                  value={formik.values.work_email}
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    checkEmail();
                  }}
                  size="small"
                  fullWidth
                  label="Email Address"
                  InputProps={{
                    endAdornment: isPendingCheckWorkEmail ? (
                      <InputAdornment position="end">
                        <CircularProgress color="inherit" size={16} />
                      </InputAdornment>
                    ) : null,
                  }}
                />
                {renderErrorWorkingEmail()}
              </Box>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Phone Number"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width={'100%'}>
                <TextField
                  onBlur={formik.handleBlur}
                  error={Boolean(getError('phone_number'))}
                  id="phone_number"
                  name="phone_number"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          background: theme.palette.grey[100],
                          height: 'fit-content',
                          padding: '4px 8px',
                          borderRadius: theme.shape.borderRadius / 4,
                        }}
                        onClick={(e) => {
                          setVisiblePhoneCode(true);
                          setAnchorEl(e.currentTarget);
                        }}
                        component={Box}
                        position="start"
                      >
                        <FlagCountry width={16} code={countryCode} />
                        <Typography sx={{ ml: 1 }} color={theme.palette.text.secondary} variant="body2">
                          {formik.values['phone_code']}
                        </Typography>
                        <IconFeather muiProps={{ fontSize: 'small', sx: { ml: 1 } }} icon={<ChevronDown />} />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  fullWidth
                  label="Your Phone Number"
                />
                {renderError('phone_number')}
              </Box>
            }
          />
        </Box>

        <Divider flexItem orientation="horizontal" />

        <FormItemLayout
          formTitle="Your Avatar"
          formSubtitle="This will be displayed on your profile."
          inputs={
            <UploadImage
              imageURL={formik.values.avatar_url}
              updateImage={(avatar_url: string) => {
                formik.setFieldValue('avatar_url', avatar_url);
              }}
            />
          }
        />

        <Divider flexItem orientation="horizontal" />

        <Box>
          <FormItemLayout
            formTitle="Company"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width="100%">
                <FormItemCompanyName freeSolo id="company_name" />
                {renderError('company_name')}
              </Box>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Industry"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <>
                <FormItemConstantSetting
                  type={CONSTANT_SETTING_TYPES.information_industry}
                  id="industry"
                  label={'Your Industry'}
                />
              </>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Department"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <>
                <FormItemConstantSetting
                  type={CONSTANT_SETTING_TYPES.information_department}
                  id="department"
                  label={'Department'}
                />
              </>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Title"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <>
                <FormItemConstantSetting
                  type={CONSTANT_SETTING_TYPES.information_role}
                  id="role"
                  label={'Your Title'}
                />
              </>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Company Address"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width="100%">
                <TextField
                  error={getError('company_address')}
                  id="company_address"
                  name="company_address"
                  value={formik.values.company_address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  size="small"
                  label="Address"
                />
                {renderError('company_address')}
              </Box>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Company Email"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width={'100%'}>
                <TextField
                  error={getError('company_email')}
                  id="company_email"
                  name="company_email"
                  value={formik.values.company_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  size="small"
                  label="Email Address"
                />
                {renderError('company_email')}
              </Box>
            }
          />
        </Box>

        <Box>
          <FormItemLayout
            formTitle="Company Website"
            formSubtitle="This will be displayed on your profile."
            inputs={
              <Box width={'100%'}>
                <TextField
                  error={getError('website_address')}
                  id="website_address"
                  name="website_address"
                  value={formik.values.website_address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  size="small"
                  label="URL Website"
                />
                {renderError('website_address')}
              </Box>
            }
          />
        </Box>

        <Divider />

        <FormItemSocialMedia
          input={
            <Box>
              <TextField
                onBlur={formik.handleBlur}
                error={getError('facebook')}
                id="facebook"
                name="facebook"
                value={formik.values.facebook}
                onChange={formik.handleChange}
                fullWidth
                size="small"
                label="Profile URL"
              />
              {renderError('facebook')}
            </Box>
          }
          name="Facebook"
          icon={iconFaceBook}
        />

        <FormItemSocialMedia
          input={
            <Box>
              <TextField
                onBlur={formik.handleBlur}
                id="twitter"
                error={getError('twitter')}
                name="twitter"
                value={formik.values.twitter}
                onChange={formik.handleChange}
                fullWidth
                size="small"
                label="Profile URL"
              />
              {renderError('twitter')}
            </Box>
          }
          name="Twitter"
          icon={iconTwitter}
        />

        <FormItemSocialMedia
          input={
            <Box>
              <TextField
                onBlur={formik.handleBlur}
                error={getError('linkedin')}
                id="linkedin"
                name="linkedin"
                value={formik.values.linkedin}
                onChange={formik.handleChange}
                fullWidth
                size="small"
                label="Profile URL"
              />
              {renderError('linkedin')}
            </Box>
          }
          name="LinkedIn"
          icon={iconLinkedIn}
        />

        <FormItemSocialMedia
          input={
            <Box>
              <TextField
                onBlur={formik.handleBlur}
                error={getError('instagram')}
                id="instagram"
                name="instagram"
                value={formik.values.instagram}
                onChange={formik.handleChange}
                fullWidth
                size="small"
                label="Profile URL"
              />
              {renderError('instagram')}
            </Box>
          }
          name="Instagram"
          icon={iconInstagram}
        />

        <FormItemSocialMedia
          input={
            <Box>
              <TextField
                onBlur={formik.handleBlur}
                id="youtube"
                error={getError('youtube')}
                name="youtube"
                value={formik.values.youtube}
                onChange={formik.handleChange}
                fullWidth
                size="small"
                label="Profile URL"
              />
              {renderError('youtube')}
            </Box>
          }
          name="Youtube"
          icon={iconYoutube}
        />

        <PopupSelectPhoneCode
          anchorEl={anchorEl}
          id="phone_code"
          open={visiblePhoneCode}
          handleClose={() => setVisiblePhoneCode(false)}
        />

        <Stack direction={'row'} alignItems={'center'} justifyContent={'end'} gap={2}>
          <Button onClick={handleCancel} variant="text" color="inherit">
            CANCEL
          </Button>
          <ButtonWithLoading
            disabled={!formik.isValid || loading}
            isLoading={loading}
            type="submit"
            onClick={() => null}
            variant="contained"
          >
            SAVE CHANGE
          </ButtonWithLoading>
        </Stack>
      </Stack>
    </FormikForm>
  );
};

export default Information;

interface IFormItemLayoutProps {
  formTitle: string;
  formSubtitle: string;
  inputs: ReactNode;
}
const FormItemLayout = (props: IFormItemLayoutProps) => {
  const theme = useTheme();
  return (
    <Stack direction={'row'} spacing={3}>
      <Box width={350}>
        <Typography variant="subtitle2">{props.formTitle}</Typography>
        <Typography color={theme.palette.text.secondary} variant="caption">
          {props.formSubtitle}
        </Typography>
      </Box>

      <Stack flexGrow={1}>
        <Stack gap={3} direction={'row'}>
          {props.inputs}
        </Stack>
      </Stack>
    </Stack>
  );
};

const FormItemSocialMedia = ({ name, icon, input }: { name: string; icon: string; input: ReactNode }) => {
  return (
    <Stack alignItems={'center'} direction={'row'} spacing={3}>
      <Box width={140}>
        <Typography gap={1} alignItems={'center'} display={'flex'} variant="subtitle2">
          <Box component={'img'} src={icon} /> {name}
        </Typography>
      </Box>
      <Stack flexGrow={1}>{input}</Stack>
    </Stack>
  );
};
