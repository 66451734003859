import MuiDrawer from '@mui/material/Drawer';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import { CSSObject, styled, Theme } from '@mui/material/styles';

// Utilities
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSE } from 'src/utils/constants/config';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: DRAWER_WIDTH_CLOSE,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: theme.zIndex.drawer,
  '& .MuiDrawer-paperAnchorDockedLeft': {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      boxShadow: '10px 4px 10px 0px rgba(66, 42, 251, 0.03)',
      paddingBottom: theme.spacing(3),
    },
    '& .MuiListItemIcon-root': {
      marginRight: theme.spacing(1),
    },
    '& .MuiListItemButton-root': {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& .MuiList-root': {
      rowGap: theme.spacing(1),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      boxShadow: '10px 4px 10px 0px rgba(66, 42, 251, 0.03);',
      paddingBottom: theme.spacing(2),
    },
    '& .MuiList-root': {
      rowGap: theme.spacing(2),
    },
  }),
}));

export const List = styled(MuiList)(() => ({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
}));

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 'auto',
  ' &:hover': {
    backgroundColor: 'transparent',
  },
}));

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  justifyContent: 'center',
  '&.Mui-disabled': {
    ' &:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.common.white,
    },
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export const ListItemIcon = styled(MuiListItemIcon)(() => ({
  justifyContent: 'center',
  minWidth: 0,
}));
