import { CommonResponse } from 'src/model/common';
import {
  CheckEmailValidation,
  CompanyAddresses,
  CompanyContacts,
  CompanyDetail,
  CompanyIndustry,
  CompanyMarketPlaces,
  CompanyNames,
  CompanyProfileQuickView,
  CompanyProfiles,
  CompanyReviewDetail,
  CompanyReviews,
  CompanySocialReviews,
  CompanyWebDetail,
  CompareCompanies,
  CompareSocialMedia,
  Jurisdictions,
  NegativeMediaList,
  CompanyType,
  RelatedCompanies,
  SanctionCompanies,
} from 'src/model/company';
import { getRequest, postRequest } from 'src/services/api/http';
import { URL_ENDPOINT } from 'src/utils/constants/service';
import { handleQueryParams } from 'src/utils/helpers/queryParam';
import queryString from 'query-string';

const CompanyService = {
  getJurisdictions,
  getCompanyTypes,
  getCompanyIndustry,
  getListCompany,
  getQuickViewCompanyProfile,
  getCompanyDetail,
  getCompanyNames,
  contributeData,
  getCompanyWebDetail,
  getCompanyReviewFromSocial,
  getCompanyReviewDetail,
  getCompanyReviews,
  getCompareCompanies,
  getCompanyMarketplace,
  getCompanyContacts,
  getCompanyAddress,
  getCompanySocialMedia,
  createReview,
  getListNegativeMedia,
  getListExactNegativeMedia,
  checkEmailValidation,
  getListRelatedCompanies,
  getSanctionsCompanies,
};

export default CompanyService;

function getJurisdictions() {
  const url = URL_ENDPOINT.company.commons + 'jurisdictions';
  return getRequest(url, {}, new Jurisdictions()) as Promise<Jurisdictions>;
}

function getCompanyTypes(jurisdiction: string[]) {
  const params = handleQueryParams('jurisdiction', jurisdiction);
  const url = URL_ENDPOINT.company.commons + `company_type?${params}`;
  return getRequest(url, {}, new CompanyType()) as Promise<CompanyType>;
}

function getCompanyIndustry(countryCode: string[]) {
  const params = handleQueryParams('country_code', countryCode);
  const url = URL_ENDPOINT.company.commons + `industry?${params}`;
  return getRequest(url, {}, new CompanyIndustry()) as Promise<CompanyIndustry>;
}

function getListCompany(query: string) {
  const url = URL_ENDPOINT.company.companies + 'list?' + query;
  return getRequest(url, {}, new CompanyProfiles()) as Promise<CompanyProfiles>;
}

function getQuickViewCompanyProfile(id: string) {
  const url = URL_ENDPOINT.company.companies + `quick-view/${id}`;
  return getRequest(url, {}, new CompanyProfileQuickView()) as Promise<CompanyProfileQuickView>;
}

function getCompanyNames(params: Record<string, string | number>, jurisdiction: string[]) {
  const paramJurisdiction = handleQueryParams('jurisdictions', jurisdiction);
  const url = URL_ENDPOINT.company.commons + `list-search-companies?${paramJurisdiction}`;
  return getRequest(url, params, new CompanyNames()) as Promise<CompanyNames>;
}

function getCompanyDetail(id: string) {
  const url = URL_ENDPOINT.company.companies + 'detail/' + id;
  return getRequest(url, {}, new CompanyDetail()) as Promise<CompanyDetail>;
}

function contributeData(body: TypeDTOContributeCompany | TypeDTOReportWrong) {
  const url = URL_ENDPOINT.company.companies + 'contribute';
  return postRequest(url, body) as Promise<CommonResponse>;
}

function getCompanyWebDetail(id: string) {
  const url = URL_ENDPOINT.company.companies + 'list-web-detail/' + id;
  return getRequest(url, {}, new CompanyWebDetail()) as Promise<CompanyWebDetail>;
}

function getCompanyReviewFromSocial(id: string) {
  const url = URL_ENDPOINT.company.companies + `social-review/${id}`;
  return getRequest(url, {}, new CompanySocialReviews()) as Promise<CompanySocialReviews>;
}

function getCompanyReviewDetail(id: string) {
  const url = URL_ENDPOINT.company.companies + `review/${id}/detail`;
  return getRequest(url, {}, new CompanyReviewDetail()) as Promise<CompanyReviewDetail>;
}

function getCompanyReviews(id: string, query: Record<string, string | number>) {
  const url = URL_ENDPOINT.company.companies + `review/${id}/reviews`;
  return getRequest(url, query, new CompanyReviews()) as Promise<CompanyReviews>;
}

function getCompareCompanies(companyIds: string[]) {
  const url = `${URL_ENDPOINT.company.companies}compare-companies?${queryString.stringify({ company_ids: companyIds }, { arrayFormat: 'none' })}`;
  return getRequest(url, {}, new CompareCompanies()) as Promise<CompareCompanies>;
}

function getCompanyMarketplace(id: string, query: Record<string, string | number>) {
  const url = URL_ENDPOINT.company.companies + `market-price/${id}`;
  return getRequest(url, query, new CompanyMarketPlaces()) as Promise<CompanyMarketPlaces>;
}

function getCompanyContacts(id: string) {
  const url = URL_ENDPOINT.company.companies + `contacts/${id}`;
  return getRequest(url, {}, new CompanyContacts()) as Promise<CompanyContacts>;
}
function getCompanyAddress(id: string) {
  const url = URL_ENDPOINT.company.companies + `get-lat-lng-by-company-id/${id}`;
  return getRequest(url, {}, new CompanyAddresses()) as Promise<CompanyAddresses>;
}

function getCompanySocialMedia(id: string) {
  const url = URL_ENDPOINT.company.companies + `social-media/${id}`;
  return getRequest(url, {}, new CompareSocialMedia()) as Promise<CompareSocialMedia>;
}

function createReview(body: TypeDTOReviewCompany) {
  const url = URL_ENDPOINT.company.companies + 'review';
  return postRequest(url, body) as Promise<CommonResponse>;
}

function getListNegativeMedia(params: Record<string, string | number>, id?: string) {
  const url = URL_ENDPOINT.company.companies + `negative-story-web/${id}`;
  return getRequest(url, params, new NegativeMediaList()) as Promise<NegativeMediaList>;
}

function getListExactNegativeMedia(params: Record<string, string | number>, id?: string) {
  const url = URL_ENDPOINT.company.companies + `negative-exactly-story-web/${id}`;
  return getRequest(url, params, new NegativeMediaList()) as Promise<NegativeMediaList>;
}

function checkEmailValidation({ email }: { email: string }) {
  const url = URL_ENDPOINT.company.companies + 'check-email-validation';
  return getRequest(url, { email }, new CheckEmailValidation()) as Promise<CheckEmailValidation>;
}

function getListRelatedCompanies(id: string, query: Record<string, string | number>) {
  const url = URL_ENDPOINT.company.companies + `related-companies/${id}?`;
  return getRequest(url, query, new RelatedCompanies()) as Promise<RelatedCompanies>;
}

function getSanctionsCompanies(id: string, param: Record<string, string | number>) {
  const url = URL_ENDPOINT.company.companies + `sanction/${id}?`;
  return getRequest(url, param, new SanctionCompanies()) as Promise<SanctionCompanies>;
}
