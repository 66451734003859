import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { EmptyText } from 'src/utils/constants/config';

const StyledTable = styled(Table)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid #D9D9F9',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.paper1,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DataTable({
  listData,
}: {
  listData: Array<{ key: string; title: string; value: React.ReactNode | string } | null>;
}) {
  const theme = useTheme();
  return (
    <StyledTable aria-label="customized table">
      <TableBody>
        {listData
          .filter((data) => data !== null)
          .map((data, index) => (
            <StyledTableRow key={(data?.key || '') + index}>
              <StyledTableCell component="th">
                <Box sx={{ ...theme.typography.subtitle2 }}>{data?.title || EmptyText}</Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...theme.typography.body2, color: theme.palette.text.secondary }}>
                  {data?.value || EmptyText}
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </TableBody>
    </StyledTable>
  );
}
