import { useQuery } from '@tanstack/react-query';
import {
  PeopleDetail,
  PeopleInformationSuggestion,
  PeopleProfileQuickView,
  PeopleProfiles,
  PeopleRelatedCompany,
  PeopleRelatedPeoples,
} from 'src/model/people';
import { PeopleService } from 'src/services/api';

const QUERY_KEY_SEARCH_LIST_PEOPLE = 'QUERY_KEY_SEARCH_LIST_PEOPLE';
const QUERY_KEY_SEARCH_LIST_PEOPLE_DETAIL = 'QUERY_KEY_SEARCH_LIST_PEOPLE_DETAIL';
const QUERY_KEY_PEOPLE_DETAIL = 'QUERY_KEY_PEOPLE_DETAIL';
const QUERY_KEY_PEOPLE_RELATED_COMPANY = 'QUERY_KEY_PEOPLE_RELATED_COMPANY';
const QUERY_KEY_PEOPLE_RELATED_PEOPLE = 'QUERY_KEY_PEOPLE_RELATED_PEOPLE';
const QUERY_KEY_SUGGESTION_PEOPLE = 'QUERY_KEY_SUGGESTION_PEOPLE';

export const useQueryListPeople = (query: string) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_LIST_PEOPLE, query],
    queryFn: () => PeopleService.getListPeople(query),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new PeopleProfiles(),
    placeholderData: (previousData) => previousData,
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryQuickViewPeopleProfile = (id: string) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_LIST_PEOPLE_DETAIL, id],
    queryFn: () => PeopleService.getQuickViewPeopleProfile(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new PeopleProfileQuickView(),
    placeholderData: (previousData) => previousData,
    enabled: Boolean(id),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryPeopleDetail = (id: string) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_PEOPLE_DETAIL, id],
    queryFn: () => PeopleService.getPeopleDetail(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new PeopleDetail(),
    placeholderData: (previousData) => previousData,
  });
  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryPeopleRelatedCompany = ({ id = '', params }: { id?: string; params: Record<string, number> }) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_PEOPLE_RELATED_COMPANY, id, params],
    queryFn: () => PeopleService.getPeopleRelatedCompany(id, params),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new PeopleRelatedCompany(),
    placeholderData: (previousData) => previousData,
    enabled: Boolean(id),
  });
  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};
export const useQueryPeopleRelatedPeople = ({ id = '', params }: { id?: string; params: Record<string, number> }) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_PEOPLE_RELATED_PEOPLE, id, params],
    queryFn: () => PeopleService.getPeopleRelatedPeople(id, params),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new PeopleRelatedPeoples(),
    placeholderData: (previousData) => previousData,
    enabled: Boolean(id),
  });
  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryPeopleInformationSuggestion = (params: Record<string, string>, countryCode: string[]) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_SUGGESTION_PEOPLE, params],
    queryFn: () => PeopleService.getPeopleInformationSuggestion(params, countryCode),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new PeopleInformationSuggestion(),
    placeholderData: (previousData) => previousData,
    enabled: Object.values(params).some((param) => param),
  });
  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};
