import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Components
import App from 'src/App';
import reportWebVitals from './reportWebVitals';

// Utilities
import { queryClient } from 'src/configs/queryClient';
import ThemeCustomization from 'src/themes';
import ErrorBoundary from './components/ErrorBoundary';
import SnackbarGlobal from './components/common/Snackbar';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            {(process.env.NODE_ENV === 'development' && (
              <>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </>
            )) || <App />}
            <SnackbarGlobal />
          </QueryClientProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeCustomization>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
