import { create } from 'zustand';

export interface IVerifyOTP {
  type?: TypeVerify;
}

interface IAction {
  dispatch: (type: TypeVerify) => void;
}

export const useVerifyTypeStore = create<IVerifyOTP & IAction>((set) => ({
  type: undefined,
  dispatch: (type: TypeVerify) => set({ type: type }),
}));
