import { SvgIcon, SvgIconProps } from '@mui/material';

const TrustpilotStarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path d="M16.5732 0.5H0.573242V16.5H16.5732V0.5Z" fill="#219653" />
        <path
          d="M8.57314 11.2835L11.0065 10.6669L12.0231 13.8002L8.57314 11.2835ZM14.1731 7.23353H9.88981L8.57314 3.2002L7.25648 7.23353H2.97314L6.43981 9.73353L5.12314 13.7669L8.58981 11.2669L10.7231 9.73353L14.1731 7.23353Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default TrustpilotStarIcon;
