import { Clear } from '@mui/icons-material';
import { Box, IconButton, Modal, ModalProps, styled, SxProps } from '@mui/material';
import { ReactNode } from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.common.white,
  borderRadius: 8,
  maxHeight: 'calc(100% - 64px)',
  maxWidth: 'calc(100% - 64px)',
  overflow: 'auto',
}));

interface IProps extends ModalProps {
  open: boolean;
  closeOnBackdropClick?: boolean;
  handleClose: () => void;
  padding?: number;
  pt?: number;
  disabledCloseIcon?: boolean;
  showCloseIcon?: boolean;
  styleBox?: SxProps;
  headerAlert?: ReactNode;
}

const ModalComponent = ({
  open,
  closeOnBackdropClick = true,
  handleClose,
  padding = 4,
  pt = 4,
  disabledCloseIcon = false,
  showCloseIcon = false,
  headerAlert,
  children,
  styleBox,
  ...params
}: IProps) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={(event: object, reason: string) => {
        if (!closeOnBackdropClick && reason === 'backdropClick') return;
        handleClose();
      }}
      {...params}
    >
      <StyledBox sx={styleBox} padding={padding} pt={pt}>
        {headerAlert && headerAlert}
        {showCloseIcon && (
          <IconButton
            sx={{
              position: 'absolute',
              top: '4px',
              right: '4px',
            }}
            disabled={disabledCloseIcon}
            size="small"
            onClick={handleClose}
          >
            <Clear fontSize="small" />
          </IconButton>
        )}
        {children}
      </StyledBox>
    </Modal>
  );
};

export default ModalComponent;
