export function getTrademarkIds() {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyz';

  // Generate the first 3 characters randomly
  const randomChars = [];
  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomChars.push(characters[randomIndex]);
  }

  // Initialize the result list with 3 values
  const result = [];

  // Generate 3 values with the last character increasing
  for (let i = 0; i < 3; i++) {
    // Last character increases with each iteration
    const lastChar = (((i + 1) % 5) + 1).toString();
    const value = randomChars.join('') + lastChar;
    result.push(value);
  }

  return result;
}
