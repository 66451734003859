import { useQuery } from '@tanstack/react-query';

import { CountryTariffs, ProductTariffs } from 'src/model/tariffs';
import { TariffsService } from 'src/services/api';

export const QUERY_KEY_COUNTRY_TARIFFS = 'QUERY_KEY_COUNTRY_TARIFFS';
const QUERY_KEY_PRODUCT_HS_CODE_TARIFFS = 'QUERY_KEY_PRODUCT_HS_CODE_TARIFFS';
export const QUERY_KEY_PRODUCT_NATIONAL_CODE_TARIFFS = 'QUERY_KEY_PRODUCT_NATIONAL_CODE_TARIFFS';
const QUERY_KEY_GET_NTM_MEASURES = 'QUERY_KEY_GET_NTM_MEASURES';
const QUERY_KEY_GET_CUSTOM_DUTIES = 'QUERY_KEY_GET_CUSTOM_DUTIES';
const QUERY_KEY_GET_TRADE_REMEDY = 'QUERY_KEY_GET_TRADE_REMEDY';
const QUERY_KEY_REGIME_COUNTRY_TARIFFS = 'QUERY_KEY_REGIME_COUNTRY_TARIFFS';
const QUERY_KEY_FTA_TARIFFS = 'QUERY_KEY_FTA_TARIFFS';
const QUERY_KEY_ROO_FTA_TARIFFS = 'QUERY_KEY_ROO_FTA_TARIFFS';

export const useQueryCountriesTariffs = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_COUNTRY_TARIFFS],
    queryFn: () => TariffsService.getCountryTariffs(),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CountryTariffs(),
    placeholderData: (previousData) => previousData,
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryHsCodeTariffs = ({
  countryCode,
  level,
  keyword,
}: {
  countryCode: string;
  level: number;
  keyword: string;
}) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_PRODUCT_HS_CODE_TARIFFS, countryCode, keyword],
    queryFn: () =>
      TariffsService.searchHsCodeTariffs({
        countryCode,
        level,
        keyword,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new ProductTariffs(),
    enabled: Boolean(keyword),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryNationalCodeTariffs = ({
  countryCode,
  level,
  hsCode,
}: {
  countryCode: string;
  level: number;
  hsCode: string;
}) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_PRODUCT_NATIONAL_CODE_TARIFFS, countryCode, hsCode],
    queryFn: () =>
      TariffsService.searchNationalCodeTariffs({
        countryCode,
        level,
        hsCode,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new ProductTariffs(),
    enabled: Boolean(hsCode),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryGetNtmMeasures = ({
  reporter,
  partner,
  product,
}: {
  reporter: string;
  partner: string;
  product: string;
}) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_GET_NTM_MEASURES, reporter, partner, product],
    queryFn: () =>
      TariffsService.getNtmMeasures({
        reporter,
        partner,
        product,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(reporter) && Boolean(partner) && Boolean(product),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryGetCustomDuties = ({
  reporter,
  partner,
  product,
}: {
  reporter: string;
  partner: string;
  product: string;
}) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_GET_CUSTOM_DUTIES, reporter, partner, product],
    queryFn: () =>
      TariffsService.getCustomDuties({
        reporter,
        partner,
        product,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(reporter) && Boolean(partner) && Boolean(product),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryGetTradeRemedy = ({
  reporter,
  partner,
  product,
}: {
  reporter: string;
  partner: string;
  product: string;
}) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_GET_TRADE_REMEDY, reporter, partner, product],
    queryFn: () =>
      TariffsService.getTradeRemedy({
        reporter,
        partner,
        product,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(reporter) && Boolean(partner) && Boolean(product),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryGetRegimeCountryTariffs = ({ reporter, agId }: { reporter: string; agId: number }) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_REGIME_COUNTRY_TARIFFS, reporter, agId],
    queryFn: () =>
      TariffsService.getRegimeCountryTariffs({
        reporter,
        agId,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(reporter) && Boolean(agId),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryGetFtaTariffs = ({
  reporter,
  partner,
  product,
  ftaId,
}: {
  reporter: string;
  partner: string;
  product: string;
  ftaId: number;
}) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_FTA_TARIFFS, reporter, partner, product, ftaId],
    queryFn: () =>
      TariffsService.getFtaTariffs({
        reporter,
        partner,
        product,
        ftaId,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(reporter) && Boolean(partner) && Boolean(product) && Boolean(ftaId),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryGetRooFtaTariffs = ({
  reporter,
  partner,
  ftaId,
}: {
  reporter: string;
  partner: string;
  ftaId: number;
}) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_ROO_FTA_TARIFFS, reporter, partner, ftaId],
    queryFn: () =>
      TariffsService.getRooFtaTariffs({
        reporter,
        partner,
        ftaId,
      }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(reporter) && Boolean(partner) && Boolean(ftaId),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};
