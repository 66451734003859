import { useFormik } from 'formik';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
  debounce,
  useTheme,
} from '@mui/material';
import * as Yup from 'yup';

//Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import ButtonBack from 'src/components/common/ButtonBack';

//Utilities
import { useMutateCreateOTP } from 'src/services/mutate/useMutateAuth';
import { NoAuthRouteKeys } from 'src/utils/constants/routes';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { useSignupStore } from 'src/zustand/useSignupStore';
import { useVerifyTypeStore } from 'src/zustand/useVerifyTypeStore';
import { useQueryCheckEmailExist } from 'src/services/queries';
import { validateEmail } from 'src/utils/helpers/validate';

const FormForgotPassword = () => {
  const theme = useTheme();
  const [emailCheck, setEmailCheck] = useState<string>('');
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useMutateCreateOTP();
  const { data: dataCheckEmail, isFetching: isFetchingCheckEmail } = useQueryCheckEmailExist(emailCheck);
  const { dispatch } = useVerifyTypeStore();
  const useSignup = useSignupStore();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().max(255).email('Email is invalid').required('Email is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const dto = {
        email: values.email,
      } as TypeDTOCreateOTP;
      const response = await mutateAsync(dto);
      if (response.status_code === SUCCESS_CODE.GET) {
        navigate({ pathname: NoAuthRouteKeys.TwoStepVerification });
      }
      setSubmitting(false);
      dispatch('FORGOT_PASSWORD');
    },
  });

  useEffect(() => {
    useSignup.dispatch({
      email: formik.values.email,
      password: '',
      first_name: '',
      last_name: '',
      password_repeat: '',
    });
  }, [formik.values]);

  const checkEmail = useCallback(
    debounce((e) => {
      if (validateEmail(String(e.target.value).toLowerCase())) {
        setEmailCheck(e.target.value);
      } else {
        setEmailCheck('');
      }
    }, 300),
    [],
  );
  return (
    <FormikForm formik={formik}>
      <Box sx={{ width: 500 }}>
        <ButtonBack sx={{ position: 'absolute', top: 40 }} />
        <Typography variant="h3">Forgot Your Password?</Typography>
        <Box sx={{ mt: 1 }} />
        <Typography variant="body1" color={theme.palette.text.secondary}>
          Please enter the email you registered your account at ADAMftd. We will send a 2-step authentication code to
          your email.
        </Typography>

        <Box sx={{ mt: 4 }} mt={4} mb={2} display={'flex'} flexDirection={'column'}>
          <FormControl>
            <TextField
              fullWidth
              size="small"
              placeholder="john@gmail.com"
              required
              onChange={(e) => {
                formik.handleChange(e);
                checkEmail(e);
              }}
              onBlur={formik.handleBlur}
              id="email"
              name="email"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              label="Email Address"
              InputProps={{
                endAdornment: isFetchingCheckEmail ? (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={16} />
                  </InputAdornment>
                ) : null,
              }}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText error id="standard-weight-helper-text-email-login">
                {formik.errors.email}
              </FormHelperText>
            )}
            {dataCheckEmail.status_code === SUCCESS_CODE.GET && dataCheckEmail.message && (
              <FormHelperText error>This email address has not been registered</FormHelperText>
            )}
          </FormControl>
        </Box>
        <ButtonWithLoading
          isLoading={isPending || isFetchingCheckEmail}
          sx={{ fontWeight: theme.typography.fontWeightMedium, height: 50 }}
          size="large"
          isRounded
          variant="contained"
          fullWidth
          type="submit"
          disabled={
            isPending || isFetchingCheckEmail || !formik.isValid || dataCheckEmail.status_code === SUCCESS_CODE.GET
          }
        >
          Send Verify Code
        </ButtonWithLoading>
      </Box>
    </FormikForm>
  );
};

export default FormForgotPassword;
