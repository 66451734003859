import { useQuery } from '@tanstack/react-query';
import { UserSettingNoti } from 'src/model/auth';
import { SettingService } from '../api';

export const QUERY_USER_SETTING_NOTI_KEY = 'QUERY_USER_SETTING_NOTI_KEY';

export const useQueryUserNoti = () => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<UserSettingNoti, Error>({
    queryKey: [QUERY_USER_SETTING_NOTI_KEY],
    queryFn: SettingService.getNotification,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {
    isLoading,
    error,
    data,
    isError,
    isFetching,
    isLoadingError,
    refetch,
  };
};
