import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';

const NoAuthProvider = ({ children }: { children: ReactNode }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (!accessToken && !refreshToken) {
    return <>{children}</>;
  }
  return <Navigate to={{ pathname: AuthRouteKeys.Home }} />;
};

export default NoAuthProvider;
