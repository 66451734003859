import { Box, Card, CardActions, CardContent, styled, Typography, useTheme } from '@mui/material';
import { isFunction } from 'lodash';
import React, { useState } from 'react';
import { PlayCircle } from 'react-feather';
import Lottie from 'react-lottie';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import IconFeather from 'src/components/common/Icon';

const StyledBoxIcon = styled(Box)({
  textAlign: 'left',
  display: 'inline-flex',
  mb: 2,
});

export default function CardSearchItem({
  tittle,
  content,
  textButton,
  iconLottie,
  disabledAction = false,
  onClickLearnMore,
  onClick,
}: {
  tittle: React.ReactNode;
  content: string;
  textButton: string;
  iconLottie: ExpectedAny;
  disabledAction?: boolean;
  onClickLearnMore?: () => void;
  onClick?: () => void;
}) {
  const theme = useTheme();
  const [isStopped, setIsStopped] = useState(true);

  return (
    <Card
      sx={{
        height: '100%',
        border: '1px solid #D9D9F9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <CardContent
        sx={{ textAlign: 'left', pb: 0 }}
        onMouseEnter={() => setIsStopped(false)}
        onMouseLeave={() => setIsStopped(true)}
      >
        <StyledBoxIcon>
          <Lottie
            options={{
              loop: true,
              autoplay: false,
              animationData: iconLottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            speed={2}
            isStopped={isStopped}
            height={100}
            width={100}
          />
        </StyledBoxIcon>
        {tittle}
        <Typography variant="body2" component="p" align="left" color="text.secondary">
          {content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ButtonWithLoading onClick={onClick} isRounded color="primary" variant="outlined" disabled={disabledAction}>
          {textButton}
        </ButtonWithLoading>
        {isFunction(onClickLearnMore) && (
          <ButtonWithLoading
            isRounded
            color="inherit"
            variant="text"
            size="small"
            sx={{ textTransform: 'none', marginLeft: 'auto' }}
            onClick={onClickLearnMore}
          >
            <IconFeather
              icon={<PlayCircle />}
              muiProps={{ fontSize: 'small', sx: { mr: theme.spacing(0.5), color: theme.palette.text.secondary } }}
            />
            <Typography variant="caption2" color="text.secondary">
              Learn More (2m)
            </Typography>
          </ButtonWithLoading>
        )}
      </CardActions>
    </Card>
  );
}
