import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { LENGTH_OTP_VALIDATE } from 'src/utils/constants/config';

const StyledInput = styled('input')(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabled}`,
  borderRadius: 8,
  fontSize: 20,
  flexGrow: 1,
  color: theme.palette.text.primary,
  '&:focus': {
    border: `2px solid ${theme.palette.primary.main}`,
    outline: theme.palette.primary.main,
  },
  minWidth: 64,
  minHeight: 64,
}));

const InputOtp = ({ setOtp, otp }: { setOtp: React.Dispatch<React.SetStateAction<string>>; otp: string }) => {
  useEffect(() => {
    setOtp('');
  }, []);

  return (
    <OtpInput
      shouldAutoFocus
      value={otp}
      onChange={setOtp}
      numInputs={LENGTH_OTP_VALIDATE}
      placeholder={new Array(LENGTH_OTP_VALIDATE + 1).join(' ')}
      renderSeparator={<Box sx={{ flexGrow: 1 }} />}
      renderInput={(props) => <StyledInput {...props} id="input-otp" />}
    />
  );
};

export default InputOtp;
