import { Typography, Box, useTheme, Table, TableBody, TableRow, TableCell, styled } from '@mui/material';

// Components
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import FlagCountry from 'src/components/common/FlagCountry';
import { get } from 'lodash';
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatUrlNonProtocol } from 'src/utils/helpers/stringHelpers';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& th': {
    fontWeight: 500,
  },
}));

const RecordDomain = ({ selectedWebDetail }: { selectedWebDetail?: ICompanyWebDetail }) => {
  const theme = useTheme();
  return (
    <Box padding={1}>
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th">Name</StyledTableCell>
            <StyledTableCell>
              <RouterLinkNoUnderline
                to={formatUrlNonProtocol(get(selectedWebDetail, 'whois_record_domain.name', ''))}
                target="_blank"
              >
                <Typography color="secondary.main">
                  {get(selectedWebDetail, 'whois_record_domain.name', EmptyText)}
                </Typography>
              </RouterLinkNoUnderline>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">Registrar</StyledTableCell>
            <StyledTableCell>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {get(selectedWebDetail, 'whois_record_domain.registrar.registrar_name', EmptyText)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  IANA ID:
                  {get(selectedWebDetail, 'whois_record_domain.registrar.iana_id', EmptyText)}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  URL:{' '}
                  <Typography component="span" color="secondary.main">
                    https://www.godaddy.com
                  </Typography>
                  ,{' '}
                  <Typography component="span" color="secondary.main">
                    http://www.godaddy.com
                  </Typography>
                </Typography> */}
                <Typography variant="body2" color="text.secondary">
                  Whois Server: {get(selectedWebDetail, 'whois_record_domain.registrar.whois_server', EmptyText)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {get(selectedWebDetail, 'whois_record_domain.registrar.registrar_phone', EmptyText)}
                </Typography>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">Registrar Status</StyledTableCell>
            <StyledTableCell>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {get(selectedWebDetail, 'whois_record_domain.registrar_status', EmptyText)}
                </Typography>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">Dates</StyledTableCell>
            <StyledTableCell>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {get(selectedWebDetail, 'whois_record_domain.dates.days_old', EmptyText)} days old
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Created on {get(selectedWebDetail, 'whois_record_domain.dates.creation_date', EmptyText)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Expires on {get(selectedWebDetail, 'whois_record_domain.dates.expiration_date', EmptyText)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Updated on {get(selectedWebDetail, 'whois_record_domain.dates.last_updated', EmptyText)}
                </Typography>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">Name Servers</StyledTableCell>
            <StyledTableCell>
              <Box>
                {getArrayDefault(selectedWebDetail?.whois_record_domain.name_servers).map((server, index) => (
                  <Typography key={`server-name-${server}-${index}`} variant="body2" color="text.secondary">
                    {server}
                  </Typography>
                ))}
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">IP Address</StyledTableCell>
            <StyledTableCell>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {get(selectedWebDetail, 'whois_record_domain.ip', EmptyText)}
                </Typography>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">IP Location</StyledTableCell>
            <StyledTableCell>
              <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                <FlagCountry code={get(selectedWebDetail, 'whois_record_domain.ip_location.country', '')} width={48} />
                <Typography variant="body2" color="text.secondary">
                  {`${get(selectedWebDetail, 'whois_record_domain.ip_location.state', EmptyText)} - ${get(selectedWebDetail, 'whois_record_domain.ip_location.city', EmptyText)} - ${get(selectedWebDetail, 'whois_record_domain.ip_location.isp', EmptyText)}`}
                </Typography>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">ASN</StyledTableCell>
            <StyledTableCell>
              <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                <FlagCountry code={get(selectedWebDetail, 'whois_record_domain.ip_location.country', '')} width={24} />
                <Typography variant="body2" color="text.secondary">
                  {get(selectedWebDetail, 'whois_record_domain.asn', EmptyText)}
                </Typography>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th">Domain Status</StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" color="text.secondary">
                {get(selectedWebDetail, 'whois_record_domain.domain_status', EmptyText)}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <Box p={2} bgcolor={theme.palette.background.paper2} textAlign="left">
        <Typography variant="caption" color="text.secondary" align="left" component="p">
          {get(selectedWebDetail, 'whois_record_domain.whois', EmptyText)}
        </Typography>
      </Box>
    </Box>
  );
};

export default RecordDomain;
