import { FactoryRounded, Flag, FmdGood, GroupsRounded, WorkRounded, Link } from '@mui/icons-material';
import { Box, Collapse, Grid, Pagination, Stack, styled, Theme, Typography, useTheme } from '@mui/material';
import { isEmpty, isNumber, size, startCase, truncate } from 'lodash';
import React, { ReactNode, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, Globe, MapPin } from 'react-feather';
import { useParams } from 'react-router-dom';

// Components
import IconFeather from 'src/components/common/Icon';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import LogoCompany from 'src/components/common/LogoCompany';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import WhiteBoxContent from 'src/components/common/WhiteBoxContent';
import GoogleMapComponent from 'src/components/GoogleMap/GoogleMap';
import FacebookIcon from 'src/components/Icons/Facebook';
import LinkedInIcon from 'src/components/Icons/LinkedIn';
import TwitterIcon from 'src/components/Icons/Twitter';
import PeopleDetailCardContent from 'src/features/PeopleDetail/components/CardContent';

// Utilities
import { useQueryPeopleRelatedCompany } from 'src/services/queries';
import { FORMAT_DATE_TIME, LIMIT_GET_LIST, MAX_LENGTH_CONTENT, UnknownText } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { formatUrlNonProtocol, renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

const StyledBox = styled(Box)<{ theme: Theme; isSelected?: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  border: '1px solid' + theme.palette.divider,
  borderRadius: isSelected
    ? `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0px 0px`
    : theme.shape.borderRadius * 2,
  cursor: 'pointer',
  alignItems: 'center',
  background: isSelected ? theme.palette.primary.dark : theme.palette.background.default,
  color: isSelected ? theme.palette.common.white : theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    color: isSelected ? theme.palette.common.white : theme.palette.text.secondary,
  },
}));

const StyledButtonRead = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'underline',
  textTransform: 'uppercase',
  cursor: 'pointer',
}));

const PeopleDetailCompanyRelated = () => {
  const theme = useTheme();
  const {
    palette: { text, secondary },
  } = theme;
  const [params, setParams] = useState({
    offset: 0,
    limit: LIMIT_GET_LIST,
  });
  const { id } = useParams();

  const {
    data: { data, total_pages },
    isFetching,
  } = useQueryPeopleRelatedCompany({
    id: id,
    params: params,
  });

  const [selected, setSelected] = React.useState(undefined as undefined | IPeopleRelatedCompany);
  const [visibleFullDescription, setVisibleFullDescription] = useState(false);

  const center = useMemo(() => {
    let state:
      | undefined
      | {
          lat: number;
          lng: number;
        };
    if (!selected || isEmpty(selected.addresses)) {
      return state;
    }
    selected.addresses.forEach((item: IPeopleAddress) => {
      if (isNumber(item.latitude) && isNumber(item.longitude)) {
        state = {
          lat: item.latitude,
          lng: item.longitude,
        };
        return;
      }
    });
    return state;
  }, [selected]);

  const renderContactItem = (icon: ReactNode, label: ReactNode) => {
    return (
      <Typography variant="body2" color={text.secondary}>
        <IconFeather muiProps={{ sx: { fontSize: 12, mr: 1 }, color: 'inherit' }} icon={icon} />
        {label}
      </Typography>
    );
  };

  const handleSelect = (company: IPeopleRelatedCompany) => {
    if (!selected) {
      setSelected(company);
    } else {
      setSelected(undefined);
    }
  };
  const renderExpandContent = (company: IPeopleRelatedCompany) => {
    const headerQuarters = company.addresses.filter((address: IPeopleAddress) => address.is_headquarter);
    return (
      <Box display={'flex'} flexDirection="column" gap={2} padding={1}>
        <Box>
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle1">Overview</Typography>
            <RouterLinkNoUnderline to={AuthRouteKeys.CompanyDetail.replace(':id?', company?.company_id || '')}>
              <Link fontSize="small" color="secondary" />
            </RouterLinkNoUnderline>
          </Stack>
          <Typography my={1} color={text.secondary} variant="body2">
            {!company.description && UnknownText}
            {truncate(company.description, {
              length: visibleFullDescription ? Infinity : MAX_LENGTH_CONTENT.COMPANY_RELATED,
            })}
          </Typography>
          {size(company.description) >= MAX_LENGTH_CONTENT.COMPANY_RELATED && (
            <StyledButtonRead onClick={() => setVisibleFullDescription(!visibleFullDescription)} variant="body2">
              {!visibleFullDescription ? 'Read More' : 'Read Less'}
            </StyledButtonRead>
          )}
        </Box>
        <Stack flexWrap={'wrap'} direction={'row'} justifyContent={'space-between'}>
          <Box>
            <Typography mb={1} variant="subtitle2" color={'inherit'}>
              Industry
            </Typography>
            {isEmpty(company.industry) && (
              <Typography color={text.secondary} variant="caption">
                {UnknownText}
              </Typography>
            )}
            {company.industry.map((label: string, index: number) => (
              <Typography key={index} display={'flex'} alignItems="center" color={text.secondary} variant="caption">
                <FactoryRounded sx={{ fontSize: 12, mr: 0.5 }} /> {label}
              </Typography>
            ))}
          </Box>
          <Box>
            <Typography mb={1} variant="subtitle2" color={'inherit'}>
              Company size
            </Typography>
            <Typography display={'flex'} alignItems="center" color={text.secondary} variant="caption">
              <GroupsRounded sx={{ fontSize: 12, mr: 0.5 }} /> {renderTextOrDefault(company.company_size)} employee(s)
            </Typography>
          </Box>
          <Box>
            <Typography mb={1} variant="subtitle2" color={'inherit'}>
              Position
            </Typography>
            {isEmpty(company.timeline) && (
              <Typography color={text.secondary} variant="caption">
                {UnknownText}
              </Typography>
            )}
            {(company.timeline || []).map((item: IPeopleRelatedCompanyTimeline) => (
              <Typography
                key={item.start_date}
                display={'flex'}
                alignItems="center"
                color={text.secondary}
                variant="caption"
              >
                <WorkRounded sx={{ fontSize: 12, mr: 0.5 }} /> {startCase(item.position)}
              </Typography>
            ))}
          </Box>
          <Box>
            <Typography mb={1} variant="subtitle2" color={'inherit'}>
              Founded
            </Typography>
            <Typography display={'flex'} alignItems="center" color={text.secondary} variant="caption">
              <Flag sx={{ fontSize: 12, mr: 0.5 }} />{' '}
              {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, company.date_incorporated)}
            </Typography>
          </Box>
          <Box>
            <Typography mb={1} variant="subtitle2" color={'inherit'}>
              Headquarters
            </Typography>
            {isEmpty(headerQuarters) && (
              <Typography color={text.secondary} variant="caption">
                {UnknownText}
              </Typography>
            )}
            {headerQuarters.map((address: IPeopleAddress) => {
              return (
                <Typography
                  key={address.id}
                  display={'flex'}
                  alignItems="center"
                  color={text.secondary}
                  variant="caption"
                >
                  <FmdGood sx={{ fontSize: 12, mr: 0.5 }} /> {address.full_address}
                </Typography>
              );
            })}
          </Box>
        </Stack>

        <Box>
          <Typography mb={1} variant="subtitle1">
            Social Media
          </Typography>
          <Stack alignItems={'baseline'} direction={'row'} gap={1}>
            <RouterLinkNoUnderline
              target="_blank"
              to={company.facebook_url ? formatUrlNonProtocol(company.facebook_url) : ''}
              style={{ pointerEvents: !company.facebook_url ? 'none' : undefined }}
            >
              <Box sx={{ filter: company.facebook_url ? undefined : 'opacity(0.1)' }}>
                <FacebookIcon sx={{ fontSize: 16 }} />
              </Box>
            </RouterLinkNoUnderline>
            <RouterLinkNoUnderline
              target="_blank"
              to={company.linkedin_url ? formatUrlNonProtocol(company.linkedin_url) : ''}
              style={{ pointerEvents: !company.linkedin_url ? 'none' : undefined }}
            >
              <Box sx={{ filter: company.linkedin_url ? undefined : 'opacity(0.1)' }}>
                <LinkedInIcon sx={{ fontSize: 16 }} />
              </Box>
            </RouterLinkNoUnderline>
            <RouterLinkNoUnderline
              target="_blank"
              to={company.twitter_url ? formatUrlNonProtocol(company.twitter_url) : ''}
              style={{ pointerEvents: !company.twitter_url ? 'none' : undefined }}
            >
              <Box sx={{ filter: company.twitter_url ? undefined : 'opacity(0.1)' }}>
                <TwitterIcon sx={{ fontSize: 16 }} />
              </Box>
            </RouterLinkNoUnderline>
          </Stack>
        </Box>

        {!isEmpty(company.addresses) && (
          <Box>
            <Typography mb={1} variant="subtitle1">
              Address(es)
            </Typography>
            <Stack gap={1} direction={'column'}>
              {company.addresses.map((address: IPeopleAddress) =>
                renderContactItem(<MapPin />, (address.full_address || '').toUpperCase()),
              )}
            </Stack>
          </Box>
        )}

        {!isEmpty(company.websites) && (
          <Box>
            <Typography mb={1} variant="subtitle1">
              Website
            </Typography>
            <Stack gap={1} direction={'column'}>
              {company.websites.map((website) =>
                renderContactItem(
                  <Globe />,
                  <RouterLinkNoUnderline color={secondary.main} target={'_blank'} to={formatUrlNonProtocol(website)}>
                    {website}
                  </RouterLinkNoUnderline>,
                ),
              )}
            </Stack>
          </Box>
        )}

        {center && (
          <Box>
            <GoogleMapComponent lat={center.lat} lng={center.lng} />
          </Box>
        )}
      </Box>
    );
  };

  if (isFetching) {
    return (
      <Grid item xs={12}>
        <WhiteBoxContent>
          <LoadingSkeleton height={70} count={5} />
        </WhiteBoxContent>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <PeopleDetailCardContent title="Related Companies">
          <Stack direction="column" gap={2}>
            {data.map((company: IPeopleRelatedCompany, i) => {
              const isSelected = company.company_id === selected?.company_id;
              return (
                <Box key={i}>
                  <ExpandableHeader
                    onClick={() => handleSelect(company)}
                    key={company.company_id}
                    company={company}
                    isSelected={isSelected}
                  />
                  <Collapse in={isSelected} timeout="auto" unmountOnExit>
                    {renderExpandContent(company)}
                  </Collapse>
                </Box>
              );
            })}
            <Stack mt={2} alignItems="center">
              <Pagination
                page={params.offset + 1}
                count={total_pages}
                onChange={function (_, value: number): void {
                  setParams({ ...params, offset: value - 1 });
                }}
                color="primary"
                shape="rounded"
              />
            </Stack>
          </Stack>
        </PeopleDetailCardContent>
      </Grid>
    </>
  );
};

export default PeopleDetailCompanyRelated;

const ExpandableHeader = ({
  isSelected,
  onClick,
  company,
}: {
  company: IPeopleRelatedCompany;
  isSelected?: boolean;
  onClick: () => void;
}) => {
  const theme = useTheme();
  return (
    <StyledBox onClick={onClick} component={'div'} isSelected={isSelected} theme={theme}>
      <Stack direction={'row'} alignItems="center" gap={2}>
        <LogoCompany logoURL={company.image_url} padding={0.5} borderRadius={0.5} width={23} />
        <Typography sx={{ color: 'inherit' }} typography={'subtitle1'}>
          {company.name}
        </Typography>
      </Stack>
      <IconFeather muiProps={{ color: 'inherit' }} icon={isSelected ? <ChevronUp /> : <ChevronDown />} />
    </StyledBox>
  );
};
