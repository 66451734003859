import { useMemo } from 'react';
import { TextField, Stack, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { get, isArray } from 'lodash';

// Components
import { IPropsDataContribute } from 'src/features/CompanyDetail/Common/config';

const SocialMedia = ({ indexData }: { indexData: number }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<IPropsDataContribute>();
  const errorsObject = useMemo(() => {
    if (isArray(errors.contribution_social_media_company_profile)) {
      return { social_url: get(errors.contribution_social_media_company_profile?.[indexData], 'social_url', '') };
    }

    return {
      social_url: '',
    };
  }, [errors, touched]);

  return (
    <Stack spacing={0.5} flex={1}>
      <TextField
        required
        id={`contribution_social_media_company_profile[${indexData}].social_url`}
        name={`contribution_social_media_company_profile[${indexData}].social_url`}
        value={get(values.contribution_social_media_company_profile, `[${indexData}].social_url`, '')}
        onChange={handleChange}
        onBlur={handleBlur}
        size="small"
        fullWidth
        error={Boolean(
          touched.contribution_social_media_company_profile?.[indexData]?.social_url && errorsObject?.social_url,
        )}
        label={<span>Social URL Contribute</span>}
      />
      {touched.contribution_social_media_company_profile?.[indexData]?.social_url && (
        <FormHelperText error>{errorsObject?.social_url}</FormHelperText>
      )}
      {/* {touched.contribution_social_media_company_profile?.[indexData]?.social_url &&
        Array.isArray(errors.contribution_social_media_company_profile) &&
        errors.contribution_social_media_company_profile && <FormHelperText error>{errorsObject}</FormHelperText>} */}
    </Stack>
  );
};

export default SocialMedia;
