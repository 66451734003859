import { CloseOutlined, Delete, UploadFile } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  alpha,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { first, isEmpty } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUploadImage from 'src/hooks/useUploadImage';
import { ACCEPT_TYPE_IMAGE, MAX_SIZE_IMAGE_UPLOAD } from 'src/utils/constants/config';
import { convertBytesToSize } from 'src/utils/helpers/utilities';
import useSnackbarStore from 'src/zustand/useSnackbarStore';

const StyledButtonRemove = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  top: -6,
  right: -6,
  background: theme.palette.action.active,
  width: 14,
  height: 14,
  color: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.action.active,
  },
}));

const UploadImage = ({ updateImage, imageURL }: { updateImage: (img: string) => void; imageURL?: string }) => {
  const theme = useTheme();
  const { dispatch } = useSnackbarStore();
  const { t } = useTranslation();
  const { isPending, onUploadImage, onCancel, isError } = useUploadImage({
    imageDefault: imageURL,
  });
  const [selectedFile, setSelectedFile] = useState(undefined as undefined | File);
  const boxDropRef = useRef<null | HTMLDivElement>(null);

  const checkIsValidType = (file: File) => {
    return ACCEPT_TYPE_IMAGE.includes(file.type);
  };
  const handleDrop = useCallback(async (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && !isEmpty(event.dataTransfer.files)) {
      const file = event.dataTransfer.files[0];
      const isValidSize = checkSize(file);
      const isValidType = checkIsValidType(file);
      if (!isValidSize || !isValidType) {
        handleAlertWarning();
      } else {
        handleChangeImage(file);
      }
    }
    handleChangeBackground(theme.palette.neutroState100.primary);
  }, []);

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleChangeBackground(alpha(theme.palette.primary.main, 0.1));
  }, []);

  const handleChangeBackground = (color: string) => {
    if (boxDropRef && boxDropRef.current && boxDropRef.current) {
      boxDropRef.current.style.backgroundColor = color;
    }
  };

  const handleAlertWarning = () => {
    dispatch({
      open: true,
      title: t('setting.information.notification.upload_image.warning.title'),
      description: t('form_contribute.notification.upload_image.warning.description'),
      severity: 'error',
      color: 'error',
    });
  };

  const handleChangeImage = async (file: File) => {
    setSelectedFile(file);
    const imgURL = await onUploadImage(file);
    if (imgURL && imgURL !== '') {
      setSelectedFile(undefined);
      updateImage(imgURL);
      return;
    }
    updateImage(imageURL || '');
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = first(e.target.files);
    if (!file) return;
    const isValidSize = checkSize(file);
    const isValidType = checkIsValidType(file);

    if (!isValidType || !isValidSize) {
      handleAlertWarning();
    } else {
      handleChangeImage(file);
    }
  };

  const checkSize = (file: File) => {
    const size = file.size || 0;
    if (size > MAX_SIZE_IMAGE_UPLOAD) {
      return false;
    }
    return true;
  };

  const renderLoading = () => {
    if (!selectedFile) return null;
    const fileName = isError ? 'Upload failed' : selectedFile.name;
    const valueKB = convertBytesToSize(selectedFile.size, 'mb');
    return (
      <Box padding={2}>
        <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
          <Stack alignItems={'center'} spacing={2} direction={'row'}>
            <UploadFile color="action" />
            <Stack>
              <Typography color={isError ? 'error' : 'text.primary'} variant="body2">
                {fileName}
              </Typography>
              <Typography variant="caption" color={isError ? 'error' : 'text.secondary'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  {valueKB} MB
                </Stack>
              </Typography>
            </Stack>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <IconButton
              onClick={() => {
                onCancel();
                resetDefault();
                setSelectedFile(undefined);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
            {isPending && <CircularProgress size={24} />}
          </Stack>
        </Stack>
      </Box>
    );
  };

  const resetDefault = () => updateImage(imageURL || '');

  return (
    <>
      <Paper sx={{ position: 'relative' }}>
        <Avatar
          src={imageURL}
          variant={'rounded'}
          sx={{ width: 100, height: 100, objectFit: 'scale-down', border: '1px solid' + theme.palette.divider }}
        />
        {imageURL && (
          <StyledButtonRemove onClick={() => updateImage('')} size="small">
            <CloseOutlined sx={{ fontSize: 12 }} />
          </StyledButtonRemove>
        )}
      </Paper>
      <Stack width={'100%'}>
        <Box
          ref={boxDropRef}
          sx={{
            background: theme.palette.neutroState100.primary,
            borderRadius: theme.shape.borderRadius / 2,
            height: 150,
            gap: theme.spacing(1),
            display: 'grid',
            placeItems: 'center',
            padding: theme.spacing(3),
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <FileUploadOutlinedIcon color="action" />
          <Stack alignItems={'center'} direction={'row'}>
            <Button sx={{ textTransform: 'none' }} component="label" role={undefined}>
              Click to upload
              <input onChange={onChange} hidden accept="image/jpg, image/png, image/jpeg" type="file" />
            </Button>
            <Typography variant="body1">or drag and drop</Typography>
          </Stack>
          <Typography color={'text.secondary'} variant="body2">
            PNG, JPEG, JPG (max. 1MB)
          </Typography>
        </Box>

        {renderLoading()}
      </Stack>
    </>
  );
};

export default UploadImage;
