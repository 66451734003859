import React from 'react';
import { Box } from '@mui/material';

// Components
import ModalComponent from 'src/components/Modal/ModalComponent';
import PeopleProfileDetail from 'src/features/PeopleProfiles/PeopleProfileDetail';
interface IProps {
  open: boolean;
  id: string;
  handleClose: () => void;
}

const QuickViewContact: React.FC<IProps> = ({ id = '', open, handleClose }) => {
  return (
    <ModalComponent padding={0} pt={0} handleClose={handleClose} open={open} showCloseIcon={true}>
      <Box width={376}>
        <PeopleProfileDetail id={id} callback={handleClose} />
      </Box>
    </ModalComponent>
  );
};
export default QuickViewContact;
