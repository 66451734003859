import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import { get, includes, isArray, isString } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import ModalComponent from 'src/components/Modal/ModalComponent';

import { useTranslation } from 'react-i18next';
import IconFeather from 'src/components/common/Icon';
import DatePickerWithDefaultIcon from 'src/components/DatePicker';
import { ALL_TYPE_VALUE } from 'src/components/Form/FormItemCompanyType';
import FormItemCountry from 'src/components/Form/FormItemCountry';
import FormItemCountryGeneral from 'src/components/Form/FormItemCountryGeneral';
import FormItemHsCode from 'src/components/Form/FormItemHsCode';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { LIMIT_GET_LIST } from 'src/utils/constants/config';
import { COUNTRIES } from 'src/utils/constants/countries';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { sortObjectsArrayByKey } from 'src/utils/helpers/arrayHelpers';
import { handleQueryParamFromObject } from 'src/utils/helpers/queryParam';
import { getFormItemError, renderFormItemError } from 'src/utils/helpers/renderFormItemError';

const LabelCheckbox = ({ children }: { children: ReactNode }) => {
  return (
    <Typography color={'text.secondary'} variant="caption2">
      {children}
    </Typography>
  );
};
interface IProps {
  open: boolean;
  handleClose: () => void;
}

const FormSearchTradeData: React.FC<IProps> = ({ open, handleClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [visibleMoreFilter, setVisibleMoreFilter] = useState(false as boolean);

  const params = useQueryParams();

  const { t } = useTranslation();

  const getDefaultValue = (key: string) => {
    return get(params, key, '') as string;
  };

  const getDefaultArray = (key: string) => {
    const value = params[key];
    if (isArray(value)) {
      return value as string[];
    }
    if (isString(value)) {
      return [value] as string[];
    }
    return [];
  };

  const formik = useFormik<TypeCompanySearch>({
    enableReinitialize: true,
    initialValues: {
      limit: LIMIT_GET_LIST,
      offset: 0,
      company_name: getDefaultValue('company_name'),
      company_code: getDefaultValue('company_code'),
      jurisdiction: getDefaultArray('jurisdiction'),
      city: getDefaultValue('city'),
      state: getDefaultValue('state'),
      industry: getDefaultValue('industry'),
      incorporation_date: getDefaultValue('incorporation_date'),
      to_date: getDefaultValue('to_date'),
      company_type: getDefaultValue('company_type'),
      sort_by: getDefaultValue('sort_by'),
      non_profit_status: getDefaultValue('non_profit_status'),
      company_branches: getDefaultValue('company_branches'),
      contact_person_available: getDefaultArray('contact_person_available'),
      include_anomalies: getDefaultArray('include_anomalies'),
      include_all_company_status: getDefaultValue('include_all_company_status') === 'true',
    },
    validationSchema: Yup.object().shape({
      jurisdiction: Yup.array(),
      company_type: Yup.string().max(255),
      company_name: Yup.string().max(255),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      handleClose();
      setSubmitting(false);
      values.jurisdiction = getListJurisdiction();
      if (values.company_type === ALL_TYPE_VALUE) {
        values.company_type = '';
        return;
      }
      const params = handleQueryParamFromObject(values);
      navigate({ pathname: AuthRouteKeys.TradeData, search: params });
    },
  });

  const getListJurisdiction = () => {
    return (formik.values?.jurisdiction || []).map((jurisdiction) =>
      typeof jurisdiction === 'string' ? jurisdiction : get(jurisdiction, 'jurisdiction', ''),
    );
  };

  useEffect(() => {
    if (!open) {
      setVisibleMoreFilter(false);
      formik.resetForm();
    }
    if (open && params['incorporation_date']) {
      setVisibleMoreFilter(true);
    }
  }, [open]);

  const handleClickMoreFilter = () => {
    setVisibleMoreFilter((prev) => {
      if (prev) {
        formik.setFieldValue('incorporation_date', '');
        formik.setFieldValue('to_date', '');
        formik.setFieldValue('sort_by', '');
        formik.setFieldValue('non_profit_status', '');
        formik.setFieldValue('company_branches', '');
        formik.setFieldValue('contact_person_available', []);
        formik.setFieldValue('include_anomalies', []);
      }
      return !prev;
    });
  };

  const renderButtonMoreFilter = () => {
    if (!visibleMoreFilter) {
      return (
        <Button onClick={handleClickMoreFilter} color="suggestion" size="small" variant="text">
          Advanced Search{' '}
          <IconFeather muiProps={{ color: 'inherit', fontSize: 'small' }} icon={<Icon.ChevronsDown size={24} />} />
        </Button>
      );
    } else {
      return (
        <Button onClick={handleClickMoreFilter} color="suggestion" size="small" variant="text">
          Basic Search{' '}
          <IconFeather muiProps={{ color: 'inherit', fontSize: 'small' }} icon={<Icon.ChevronsUp size={24} />} />
        </Button>
      );
    }
  };

  const handleChangeContactPerson = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const contact_person_available = formik.values.contact_person_available;
    if (value) {
      const state = update(contact_person_available, {
        $push: [e.target.name],
      });
      formik.setFieldValue('contact_person_available', state);
    } else {
      const index = contact_person_available.indexOf(e.target.name);
      const state = update(contact_person_available, {
        $splice: [[index, 1]],
      });
      formik.setFieldValue('contact_person_available', state);
    }
  };

  const defaultValueCheckbox = (type: string, key: string) => {
    return includes(get(formik.values, type), key);
  };

  const formMoreFilter = (
    <React.Fragment>
      <Stack spacing={2} direction={'row'}>
        <FormItemCountryGeneral
          id={'supplier_name'}
          options={sortObjectsArrayByKey(COUNTRIES, 'label')}
          label={t('trade_data.search_form.input.supplier_name')}
        />
        <FormItemCountryGeneral
          id={'buyer_name'}
          options={sortObjectsArrayByKey(COUNTRIES, 'label')}
          label={t('trade_data.search_form.input.buyer_name')}
        />
      </Stack>

      <Stack spacing={2} direction={'row'}>
        <FormItemCountryGeneral
          id={'supplier_name'}
          options={sortObjectsArrayByKey(COUNTRIES, 'label')}
          label={t('trade_data.search_form.input.origin_port')}
        />
        <FormItemCountryGeneral
          id={'buyer_name'}
          options={sortObjectsArrayByKey(COUNTRIES, 'label')}
          label={t('trade_data.search_form.input.delivery_port')}
        />
      </Stack>

      <Stack width={'100%'} spacing={2} direction={'row'}>
        <Box flex={1}>
          <TextField
            fullWidth={true}
            inputProps={{
              step: 5,
              min: 0,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label={t('trade_data.search_form.input.unit_price')}
            size={'small'}
            type={'number'}
          />
        </Box>
        <Box flex={1}>
          <TextField
            fullWidth={true}
            inputProps={{
              step: 5,
              min: 0,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label={t('trade_data.search_form.input.total_value')}
            size={'small'}
            type={'number'}
          />
        </Box>
      </Stack>

      <Stack spacing={2} direction={'row'}>
        <Box flex={1}>
          <TextField
            label={t('trade_data.search_form.input.no_of_tue')}
            fullWidth={true}
            type={'number'}
            size={'small'}
          />
        </Box>
        <Box flex={1}>
          <TextField
            label={t('trade_data.search_form.input.no_of_weight')}
            fullWidth={true}
            type={'number'}
            size={'small'}
          />
        </Box>
      </Stack>
      <Box>
        <InputLabel shrink color="primary">
          {t('trade_data.search_form.input.contact_available')}
        </InputLabel>
        <FormGroup id="contact_person_available" sx={{ marginTop: 0, paddingLeft: 1 }} row>
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'name')}
                onChange={handleChangeContactPerson}
                size="small"
                color="default"
                id="name"
                name="name"
              />
            }
            label={<LabelCheckbox>Name</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'job_title')}
                onChange={handleChangeContactPerson}
                size="small"
                color="default"
                id="job_title"
                name="job_title"
              />
            }
            label={<LabelCheckbox>Job Title</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'email')}
                onChange={handleChangeContactPerson}
                size="small"
                color="default"
                id="email"
                name="email"
              />
            }
            label={<LabelCheckbox>Email</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'phone')}
                onChange={handleChangeContactPerson}
                size="small"
                color="default"
                id="phone"
                name="phone"
              />
            }
            label={<LabelCheckbox>Phone</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'website')}
                onChange={handleChangeContactPerson}
                size="small"
                color="default"
                id="website"
                name="website"
              />
            }
            label={<LabelCheckbox>Website</LabelCheckbox>}
          />
        </FormGroup>
      </Box>
    </React.Fragment>
  );

  return (
    <ModalComponent padding={4} handleClose={handleClose} open={open}>
      <FormikForm formik={formik}>
        <Box width={540}>
          <Typography textAlign={'center'} variant="h5">
            {t('trade_data.search_form.title')}
          </Typography>

          <Box sx={{ mt: 1 }} />

          <Typography textAlign={'center'} color={theme.palette.text.secondary} variant="body1">
            {t('trade_data.search_form.sub_title')}
          </Typography>

          <Box sx={{ mt: 4 }} />

          <Stack direction={'column'} spacing={2}>
            <Box>
              <FormItemHsCode id={'hs_code'} countryCode={'vn'} />
              {renderFormItemError(getFormItemError('jurisdiction', formik))}
            </Box>
            <Stack spacing={2} direction={'row'}>
              <FormItemCountry id={'export_country'} label={t('trade_data.search_form.input.export_country')} />
              <FormItemCountry id={'import_country'} label={t('trade_data.search_form.input.import_country')} />
            </Stack>

            <Stack direction={'row'} spacing={2}>
              <DatePickerWithDefaultIcon
                label={t('trade_data.search_form.input.trade_from')}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                  },
                }}
                views={['month', 'year']}
              />
              <DatePickerWithDefaultIcon
                label={t('trade_data.search_form.input.trade_from')}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                  },
                }}
                views={['month', 'year']}
              />
            </Stack>

            {/* <Box>
              <DateRangePicker
                onChange={(value: DateRange<Dayjs>) => {
                  const [from, to] = value;
                  console.log(dayjs(from).format('MM-YYYY'));
                  console.log(dayjs(to).format('MM-YYYY'));
                }}
                format="MMMM-YYYY"
                slotProps={{ textField: { size: 'small' } }}
                localeText={{
                  start: t('trade_data.search_form.input.trade_from'),
                  end: t('trade_data.search_form.input.trade_to'),
                }}
              />
              {renderFormItemError(getFormItemError('company_type', formik))}
            </Box> */}

            {visibleMoreFilter && formMoreFilter}

            {renderButtonMoreFilter()}

            <ButtonWithLoading sx={{ height: 53 }} size="large" variant="contained" isRounded type="submit">
              Search Now
            </ButtonWithLoading>
          </Stack>
        </Box>
      </FormikForm>
    </ModalComponent>
  );
};

export default FormSearchTradeData;
