export enum TypeSocialProfile {
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  Twitter = 'twitter',
  Instagram = 'instagram',
  Youtube = 'youtube',
}

export enum UserType {
  Guest = 'guest',
  User = 'user',
}

export enum AccountType {
  PrivateBussiness = 'PrivateBussiness',
  GovermentAgency = 'GovermentAgency',
  Other = 'Other',
}

export enum CompanyStatus {
  All = 'all',
  Active = 'active',
  Closed = 'closed',
  Suspended = 'suspended',
  Unknown = 'unknown',
}

export enum CompanyStatusCode {
  Success = 0,
  Error = 1,
  Warning = 2,
  Unknown = 3,
}

export enum CompanyReviewType {
  Google = 'google',
  Trustpilot = 'trustpilot',
  AdamFtd = 'adamftd',
}

export enum CompanyRankingType {
  Up = 'up',
  Down = 'down',
}

export enum OtherContactStatusCode {
  Valid = 'valid',
  Invalid = 'invalid',
  Risky = 'risky',
  Unknown = 'unknown',
}

export enum CompanyContactPositionCode {
  Unknown = '0',
  Director = '1',
  BeneficialOwner = '2',
  Shareholder = '3',
  RegisteredAgent = '4',
  Employee = '5',
}

export enum KeyFilterChartUnitBy {
  shipments = 'shipments',
  weight = 'weight',
  teu = 'teu',
  value = 'value',
}

export enum KeyFilterChartViewDataForLast {
  six_month = '6_month',
  twelve_month = '12_month',
  twenty_four_month = '24_month',
  all_time = 'all_time',
}
export enum KeyFilterChartViewBy {
  selling = 'selling',
  buying = 'buying',
}
