import { Container, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import BreadcrumbWithBtnBack from 'src/components/BreadcrumbWithBtnBack';
import WhiteBoxContent from 'src/components/common/WhiteBoxContent';
import TableHorizontal from 'src/components/TableHorizontal';
import { IConfigCellHorizontal } from 'src/components/TableHorizontal/type';
import MapAddress from 'src/features/BillOfLandingDetail/MapAddress';
import { MOCKUP_BILL_OF_LANDING_DETAIL } from 'src/features/BillOfLandingDetail/mockup';
import BillOfLandingDetailOverview from 'src/features/BillOfLandingDetail/Overview';
import MainLayout from 'src/layouts';
import { EmptyText } from 'src/utils/constants/config';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

const BillOfLandingDetail = () => {
  const { id } = useParams();

  const overview = <BillOfLandingDetailOverview id={id} />;

  const configColumn = [
    {
      label: 'BL Identification Number',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.identification_number)}
        </Typography>
      ),
    },
    {
      label: 'HS Code',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.hs_code)}
        </Typography>
      ),
    },
    {
      label: 'Description',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.description)}
        </Typography>
      ),
    },
    {
      label: 'Date of Transaction',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.date_of_transaction)}
        </Typography>
      ),
    },
    {
      label: 'Supplier',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.supplier)}
        </Typography>
      ),
    },
    {
      label: 'Buyer',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.buyer)}
        </Typography>
      ),
    },
    {
      label: 'Supplier Address',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.supplier)}
        </Typography>
      ),
    },
    {
      label: 'Buyer Address',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.buyer_address)}
        </Typography>
      ),
    },
    {
      label: 'Exporting Country',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.exporting_country)}
        </Typography>
      ),
    },
    {
      label: 'Importing Country',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.importing_country)}
        </Typography>
      ),
    },
    {
      label: 'Country of Origin',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.country_of_origin)}
        </Typography>
      ),
    },
    {
      label: 'Destination Country',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.destination_country)}
        </Typography>
      ),
    },
    {
      label: 'Export Port ',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.export_port)}
        </Typography>
      ),
    },
    {
      label: 'Import Port ',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.import_port)}
        </Typography>
      ),
    },
    {
      label: 'Currency Transaction',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.currency_transaction)}
        </Typography>
      ),
    },
    {
      label: 'Exchange Rate',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.exchange_rate)}
        </Typography>
      ),
    },
    {
      label: 'Vessel Name',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.vessel_name)}
        </Typography>
      ),
    },
    {
      label: 'Shipper Company Name',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.shipper_company_name)}
        </Typography>
      ),
    },
    {
      label: 'Quantity',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.quantity)}
        </Typography>
      ),
    },
    {
      label: 'Unit Price',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.unit_price)}
        </Typography>
      ),
    },
    {
      label: 'PKG (PCS)',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.pkg)}
        </Typography>
      ),
    },
    {
      label: 'WGT (KGS)',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.wgt)}
        </Typography>
      ),
    },
    {
      label: 'Value USD',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.value_usd)}
        </Typography>
      ),
    },
    {
      label: 'Freight Value',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.freight_value)}
        </Typography>
      ),
    },
    {
      label: 'Insurance Value',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.insurance_value)}
        </Typography>
      ),
    },
    {
      label: 'Applied Tariffs',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.applied_tariffs)}
        </Typography>
      ),
    },
    {
      label: 'Invoice Value',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.invoice_value)}
        </Typography>
      ),
    },
    {
      label: 'Total Cost (Inclusive of Taxes/Tariffs)',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.total_cost)}
        </Typography>
      ),
    },
  ] as IConfigCellHorizontal<IBillOfLandingDetail>[];

  return (
    <MainLayout>
      <Container maxWidth="xl">
        <Stack py={3} spacing={3}>
          <BreadcrumbWithBtnBack
            listItem={[
              {
                text: 'Trade Data',
              },
              {
                text: 'United State',
              },
              {
                text: id || EmptyText,
              },
            ]}
            updatedAt="03-08-2009T00:00:00Z"
          />
          {overview}
          <WhiteBoxContent>
            <Typography mb={3} variant="h5">
              Bills of Lading Detail
            </Typography>
            <TableHorizontal colKeyWidth={350} configColumn={configColumn} data={MOCKUP_BILL_OF_LANDING_DETAIL} />
          </WhiteBoxContent>
          <MapAddress />
        </Stack>
      </Container>
    </MainLayout>
  );
};

export default BillOfLandingDetail;
