import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Search, Sliders } from 'react-feather';
import { useSearchParams } from 'react-router-dom';
import IconFeather from 'src/components/common/Icon';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';

const FilterCompanyProfile = () => {
  const query = useQueryParams();

  const { handleOpenSearch } = useFormSearch((state) => state);
  const [, setSearchParams] = useSearchParams();
  const [defaultQuery, setDefaultQuery] = useState(query?.company_name);

  useEffect(() => {
    if (query?.company_name) {
      setDefaultQuery(query?.company_name);
    }
  }, [query?.company_name]);

  const onChangeSearch = useCallback(
    debounce(async (e) => {
      // Clear data advance search when type Quick Search
      // TODO: Improve UI, Show advance search on screen
      setSearchParams({ company_name: String(e.target.value), offset: '0', limit: '10' });
      // setSearchParams((searchParams) => {
      //   searchParams.set('company_name', String(e.target.value));
      //   searchParams.set('offset', '0');
      //   return searchParams;
      // });
    }, 500),
    [query],
  );

  return (
    <Grid sx={{ placeItems: 'center' }} spacing={3} container>
      <Grid sm={12} md={8} lg={8} xl={9} item>
        <TextField
          sx={{ flex: 1 }}
          placeholder="Company Name"
          size="small"
          fullWidth
          value={defaultQuery}
          onChange={(e) => {
            onChangeSearch(e);
            setDefaultQuery(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconFeather icon={<Search />} muiProps={{ color: 'disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid sm={12} md={4} lg={4} xl={3} item>
        <Button
          onClick={() => {
            handleOpenSearch(FormType.SearchCompany);
          }}
          color="primary"
          startIcon={<IconFeather muiProps={{ fontSize: 'small' }} icon={<Sliders />} />}
          variant="text"
        >
          Advanced Filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterCompanyProfile;
