import { UserSettingNoti } from 'src/model/auth';
import { CommonResponse } from 'src/model/common';
import { URL_ENDPOINT } from 'src/utils/constants/service';
import { getRequest, postRequest } from './http';

function handleInformation(dto: TypeDTOSettingInformation) {
  const url = URL_ENDPOINT.user.users + 'update-user';
  return postRequest(url, dto) as Promise<CommonResponse>;
}

function handleNotification(dto: TypeDTOSettingNotification) {
  const url = URL_ENDPOINT.user.users + 'update-settings';
  return postRequest(url, dto) as Promise<CommonResponse>;
}

function getNotification() {
  const url = URL_ENDPOINT.user.users + 'get-settings';
  return getRequest(url, {}) as Promise<UserSettingNoti>;
}

function handleUpdateUsername(dto: TypeDTOUpdateUsername) {
  const url = URL_ENDPOINT.user.users + 'update-username';
  return postRequest(url, dto) as Promise<CommonResponse>;
}

function handleUpdateEmail(dto: TypeDTOCreateOTP) {
  const url = URL_ENDPOINT.user.users + 'update-email';
  return postRequest(url, dto) as Promise<CommonResponse>;
}

function handleUpdatePassword(dto: TypeDTOUpdatePassword) {
  const url = URL_ENDPOINT.user.users + 'update-password';
  return postRequest(url, dto) as Promise<CommonResponse>;
}

export const SettingService = {
  handleInformation,
  handleNotification,
  getNotification,
  handleUpdateUsername,
  handleUpdateEmail,
  handleUpdatePassword,
};

export default SettingService;
