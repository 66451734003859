import { SupervisedUserCircleRounded, WorkHistoryOutlined } from '@mui/icons-material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

export enum PEOPLE_DETAIL_TAB_KEYS {
  overview = 'overview',
  company_related = 'company_related',
  people_related = 'people_related',
}

export const PEOPLE_DETAIL_TABS: ITabSetting[] = [
  {
    key: PEOPLE_DETAIL_TAB_KEYS.overview,
    label: 'OVERVIEW',
    icon: AssignmentIndIcon,
  },
  {
    key: PEOPLE_DETAIL_TAB_KEYS.company_related,
    label: 'Related Companies',
    icon: WorkHistoryOutlined,
  },
  {
    key: PEOPLE_DETAIL_TAB_KEYS.people_related,
    label: 'RELATED PEOPLE',
    icon: SupervisedUserCircleRounded,
  },
];

export enum SanctionsStatus {
  All = 'all',
  Sanctioned = 'SANCTIONED',
  Normal = 'NO_SANCTIONED',
}

export const SANCTION_STATUS = [
  { value: SanctionsStatus.All, label: 'All Status' },
  { value: SanctionsStatus.Sanctioned, label: 'Sanctioned' },
  { value: SanctionsStatus.Normal, label: 'Normal' },
];
