export enum MenuTerms {
  OverviewWebsiteSystem = 'OverviewWebsiteSystem',
  AcceptanceTerms = 'AcceptanceTerms',
  UserAccountRegistration = 'UserAccountRegistration',
  UseOfPlatform = 'UseOfPlatform',
  UserContributions = 'UserContributions',
  IntellectualPropertyRights = 'IntellectualPropertyRights',
  FeesPayments = 'FeesPayments',
  PrivacyDataProtection = 'PrivacyDataProtection',
  ThirdPartyLinksServices = 'ThirdPartyLinksServices',
  TerminationSuspension = 'TerminationSuspension',
  DisclaimersLimitationsLiability = 'DisclaimersLimitationsLiability',
  Indemnification = 'Indemnification',
  DisputeResolution = 'DisputeResolution',
  ModificationsTermsConditions = 'ModificationsTermsConditions',
  ContactInformation = 'ContactInformation',
  AdditionalProvisions = 'AdditionalProvisions',
}

export const LIST_MENU_SIDEBAR_TERMS = [
  {
    id: MenuTerms.OverviewWebsiteSystem,
    label: 'Overview of the Website and System',
  },
  {
    id: MenuTerms.AcceptanceTerms,
    label: 'Acceptance of Terms',
  },
  {
    id: MenuTerms.UserAccountRegistration,
    label: 'User Account and Registration',
  },
  {
    id: MenuTerms.UseOfPlatform,
    label: 'Use of the Platform',
  },
  {
    id: MenuTerms.UserContributions,
    label: 'User Contributions',
  },
  {
    id: MenuTerms.IntellectualPropertyRights,
    label: 'Intellectual Property Rights',
  },
  {
    id: MenuTerms.FeesPayments,
    label: 'Fees and Payments',
  },
  {
    id: MenuTerms.PrivacyDataProtection,
    label: 'Privacy and Data Protection',
  },
  {
    id: MenuTerms.TerminationSuspension,
    label: 'Termination and Suspension',
  },
  {
    id: MenuTerms.ThirdPartyLinksServices,
    label: 'Third-Party Links and Services',
  },
  {
    id: MenuTerms.DisclaimersLimitationsLiability,
    label: 'Disclaimers and Limitations of Liability',
  },
  {
    id: MenuTerms.Indemnification,
    label: 'Indemnification',
  },
  {
    id: MenuTerms.DisputeResolution,
    label: 'Dispute Resolution',
  },
  {
    id: MenuTerms.ModificationsTermsConditions,
    label: 'Modifications to Terms and Conditions',
  },
  {
    id: MenuTerms.ContactInformation,
    label: 'Contact Information',
  },
  {
    id: MenuTerms.AdditionalProvisions,
    label: 'Additional Provisions',
  },
];
