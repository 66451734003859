import { useEffect, useState } from 'react';

const useCountDown = (seconds: number, visible: boolean) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    setTimeLeft(seconds);
  }, [visible]);

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const resetimer = () => {
    setTimeLeft(seconds);
  };

  return { timeLeft, resetimer };
};

export default useCountDown;
