export class User implements IResponse<IUser> {
  message: string;
  status_code: number;
  data: IUser;
  detail?: string;
  errors?: ExpectedAny[];
  constructor() {
    this.message = '';
    this.data = {
      uuid: '',
      email: '',
      first_name: '',
      last_name: '',
      type: '',
      is_individual: '',
      alt: '',
      avatar_url: '',
      role: '',
      address: '',
      user_information: '',
      username: '',
      gender: '',
      user_uuid: '',
      work_email: '',
      facebook: '',
      looking_to_achieve: '',
      linkedin: '',
      name_display: '',
      twitter: '',
      company_name: '',
      introduction: '',
      instagram: '',
      website_address: '',
      birthday: '',
      youtube: '',
      work_for: '',
      industry: '',
      created_at: '',
      nationality: '',
      department: '',
      updated_at: '',
      password_change_at: '',
      company_website: '',
      company_email: '',
      phone_number: '',
      company_address: '',
    };
    this.status_code = 200;
  }
}

export class UserSignUp implements IResponse<IUserSignup> {
  message: string;
  status_code: number;
  data: IUserSignup;
  detail?: string;
  errors?: ExpectedAny[];
  constructor() {
    this.message = '';
    this.data = {};
    this.status_code = 200;
  }
}

export class UserSettingNoti implements IResponse<TypeDTOSettingNotification> {
  message: string;
  status_code: number;
  data?: TypeDTOSettingNotification | undefined;
  detail?: string | undefined;
  errors?: ExpectedAny[] | undefined;
  constructor() {
    this.message = '';
    this.data = {
      email_news_and_updates: false,
      email_tips_and_tutorials: false,
      email_comments: false,
      email_group_messages: false,
      email_credit_usage: false,
      email_security_alerts: false,
      email_reminders: false,
      push_notification_news_and_updates: false,
      push_notification_tips_and_tutorials: false,
      push_notification_comments: false,
      push_notification_group_messages: false,
      push_notification_credit_usage: false,
      push_notification_security_alerts: false,
      push_notification_reminders: false,
    };
    this.status_code = 0;
  }
}

export class DeviceManagers implements IResponseList<IDeviceManger> {
  message?: string | undefined;
  status_code: number;
  warning?: string | null | undefined;
  data: IDeviceManger[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  constructor() {
    this.data = [];
    this.status_code = 200;
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
  total_records: number;
}

export class ResponseLogin implements IResponse<IResponseLogin> {
  message: string;
  status_code: number;
  data?: IResponseLogin;
  detail?: string;
  errors?: ExpectedAny[];
  constructor() {
    this.message = '';
    this.status_code = 0;
  }
}
