import { Divider, Grid, Stack, Typography, Box, styled } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Lottie from 'react-lottie';

// Component
import TextWithTooltip from 'src/components/common/TextWithTooltip';
import { EmptyText } from 'src/utils/constants/config';
import { CompanyRankingType } from 'src/utils/constants/enum';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import AnalyticsIcon from 'src/asset/lottie/analytics.json';

const StyledBox = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius / 2,
  border: '1px solid #D9D9F9',
}));

export default function GlobalRanking({ dataCompany }: { dataCompany: ICompanyDetail }) {
  return (
    <StyledWhiteBox position="relative">
      <Stack spacing={1}>
        <Typography variant="h5" align="left">
          Global Ranking
          <TextWithTooltip title="Global Ranking" />
        </Typography>
        <Stack direction="row" spacing={3}>
          <StyledBox p={2} flex={1} divider={<Divider />}>
            <Typography variant="subtitle1" align="left" gutterBottom>
              As a Buyer
            </Typography>
            <Grid container mt={1} spacing={1}>
              <Grid item xs={3}>
                <Typography variant="subtitle2" align="left">
                  Global Rank
                  <TextWithTooltip title="Global Rank" />
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {dataCompany.ranking_as_a_buyer.global_rank ? (
                  <Typography variant="body2" component="p" align="left">
                    {dataCompany.ranking_as_a_buyer.global_rank}
                    {dataCompany.ranking_as_a_buyer.global_rank_type === CompanyRankingType.Up ? (
                      <TrendingUpIcon fontSize="small" color="success" />
                    ) : (
                      <TrendingDownIcon fontSize="small" color="error" />
                    )}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p" align="left">
                    {EmptyText}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2" align="left">
                  Country Rank
                  <TextWithTooltip title="Country Rank" />
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {dataCompany.ranking_as_a_buyer.country_rank ? (
                  <Typography variant="body2" component="p" align="left">
                    {dataCompany.ranking_as_a_buyer.country_rank}
                    {dataCompany.ranking_as_a_buyer.country_rank_type === CompanyRankingType.Up ? (
                      <TrendingUpIcon fontSize="small" color="success" />
                    ) : (
                      <TrendingDownIcon fontSize="small" color="error" />
                    )}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p" align="left">
                    {EmptyText}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </StyledBox>
          <StyledBox p={2} flex={1} divider={<Divider />}>
            <Typography variant="subtitle1" align="left" gutterBottom>
              As A Seller
            </Typography>
            <Grid container mt={1} spacing={1}>
              <Grid item xs={3}>
                <Typography variant="subtitle2" align="left">
                  Global Rank
                  <TextWithTooltip title="Global Rank" />
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {dataCompany.ranking_as_a_seller.global_rank ? (
                  <Typography variant="body2" component="p" align="left">
                    {dataCompany.ranking_as_a_seller.global_rank}
                    {dataCompany.ranking_as_a_seller.global_rank_type === CompanyRankingType.Up ? (
                      <TrendingUpIcon fontSize="small" color="success" />
                    ) : (
                      <TrendingDownIcon fontSize="small" color="error" />
                    )}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p" align="left">
                    {EmptyText}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2" align="left">
                  Country Rank
                  <TextWithTooltip title="Country Rank" />
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {dataCompany.ranking_as_a_seller.country_rank ? (
                  <Typography variant="body2" component="p" align="left">
                    {dataCompany.ranking_as_a_seller.country_rank}
                    {dataCompany.ranking_as_a_seller.country_rank_type === CompanyRankingType.Up ? (
                      <TrendingUpIcon fontSize="small" color="success" />
                    ) : (
                      <TrendingDownIcon fontSize="small" color="error" />
                    )}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p" align="left">
                    {EmptyText}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </StyledBox>
        </Stack>
      </Stack>
      {/* Overlay coming soon */}
      <Box
        position="absolute"
        bgcolor="rgba(255, 255, 255, 0.80)"
        left={0}
        right={0}
        top={0}
        bottom={0}
        sx={{
          backdropFilter: 'blur(1px)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          transform: 'translate(-50%, -50%);',
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: AnalyticsIcon,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={150}
          width={140}
        />
        <Typography variant="h6">Coming Soon</Typography>
        <Typography variant="subtitle2" color="text.secondary" align="center">
          This feature is currently under development. Please check back soon!
        </Typography>
      </Box>
    </StyledWhiteBox>
  );
}
