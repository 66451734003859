import { queryClient } from 'src/configs/queryClient';

const useRefetch = () => {
  const refetchQuery = (queryKey: string[]) => {
    queryClient.invalidateQueries({ queryKey: queryKey });
  };
  return { refetchQuery };
};

export default useRefetch;
