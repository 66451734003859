import { Typography, Box, Stack, Skeleton, useTheme } from '@mui/material';

// Components
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import TextWithTooltip from 'src/components/common/TextWithTooltip';
import { isString } from 'lodash';

//Utilities
import PfdIcon from 'src/asset/icons/pdf-icon.svg';
import { EmptyText } from 'src/utils/constants/config';
import { useQueryGetFtaTariffs, useQueryGetRooFtaTariffs } from 'src/services/queries';
import { useQueryParams } from 'src/hooks/useQueryParam';

const FtaDetail = ({ ftaId }: { ftaId: number }) => {
  const theme = useTheme();
  const { partner, reporter, product } = useQueryParams();

  const GetFtaTariffsQuery = useQueryGetFtaTariffs({
    reporter: reporter && isString(reporter) ? reporter : '',
    partner: partner && isString(partner) ? partner : '',
    product: product && isString(product) ? product : '',
    ftaId: ftaId || 0,
  });
  const GetRooFtaTariffsQuery = useQueryGetRooFtaTariffs({
    reporter: reporter && isString(reporter) ? reporter : '',
    partner: partner && isString(partner) ? partner : '',
    ftaId: ftaId || 0,
  });
  const dataFtaTariffs = GetFtaTariffsQuery.data?.data;
  const dataRooFtaTariffs = GetRooFtaTariffsQuery.data?.data;

  if (GetFtaTariffsQuery.isFetching || GetRooFtaTariffsQuery.isFetching) {
    return <SkeletonContent />;
  }

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        bgcolor={theme.palette.background.paper2}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius * 2,
          borderTopRightRadius: theme.shape.borderRadius * 2,
        }}
      >
        <Box display="flex" flex={1} p={2}>
          <Typography variant="subtitle2">Agreement Information</Typography>
        </Box>
        <Box display="flex" flex={1} p={2}>
          <Typography variant="subtitle2">Rules Of Origin</Typography>
        </Box>
        <Box display="flex" flex={1} p={2}>
          <Typography variant="subtitle2">Certificate Of Origin</Typography>
        </Box>
      </Stack>
      <Stack
        direction="row"
        border={`1px dashed ${theme.palette.divider}`}
        borderTop="none"
        sx={{
          borderBottomLeftRadius: theme.shape.borderRadius * 2,
          borderBottomRightRadius: theme.shape.borderRadius * 2,
        }}
      >
        <Box display="flex" flex={1} flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            p={2}
            gap={1}
            borderBottom={`1px dashed ${theme.palette.divider}`}
          >
            <Typography variant="subtitle2" color="text.secondary">
              NAME
            </Typography>
            <Typography variant="subtitle2">{dataFtaTariffs?.AgreementName || EmptyText}</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            p={2}
            gap={1}
            borderBottom={`1px dashed ${theme.palette.divider}`}
          >
            <Typography variant="subtitle2" textTransform="uppercase">
              {dataFtaTariffs?.Status || EmptyText}{' '}
              <TextWithTooltip title="Date of entry into force between exporting country and destination country" />
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {dataFtaTariffs?.StatusDate || EmptyText}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            p={2}
            gap={1}
            borderBottom={`1px dashed ${theme.palette.divider}`}
          >
            <Typography variant="subtitle2">TYPE</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {dataFtaTariffs?.Type || EmptyText}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            p={2}
            gap={1}
            borderBottom={`1px dashed ${theme.palette.divider}`}
          >
            <Typography variant="subtitle2">SCOPE</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {dataFtaTariffs?.Scope || EmptyText}
            </Typography>
          </Box>{' '}
          <Box display="flex" flexDirection="column" flex={1} p={2} gap={1}>
            <Typography variant="subtitle2">
              MEMBER STATES{' '}
              <TextWithTooltip title="List of signatories of the agreement. the list does not reflect bilateral complexities between each pair of individual members in relation to bilateral application of the agreement." />
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {dataFtaTariffs?.MemberStates || EmptyText}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          flex={1}
          p={2}
          borderLeft={`1px dashed ${theme.palette.divider}`}
          borderRight={`1px dashed ${theme.palette.divider}`}
        >
          {(dataRooFtaTariffs?.RooDataViewModel?.[0]?.RulesofOrigins || []).map((rule, index) => (
            <Box
              key={`Tariff-RulesOfOrigin-${ftaId}-${index}`}
              display="flex"
              flexDirection="row"
              gap={1}
              height="fit-content"
            >
              <Box component="img" src={PfdIcon} width={16} />
              <Typography variant="body2" color="secondary">
                <div dangerouslySetInnerHTML={{ __html: rule.RooLink || '' }} />
              </Typography>
            </Box>
          ))}
        </Box>
        <Box display="flex" flex={1} p={2} gap={2}>
          {(dataRooFtaTariffs?.RooDataViewModel?.[0]?.Certificates || []).map((rule, index) => (
            <Box
              key={`Tariff-Certificate-${ftaId}-${index}`}
              display="flex"
              flexDirection="row"
              gap={1}
              height="fit-content"
            >
              <Box component="img" src={PfdIcon} width={16} />
              <Typography variant="body2" color="secondary">
                <div dangerouslySetInnerHTML={{ __html: rule.CertificateLink || '' }} />
              </Typography>
            </Box>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default FtaDetail;

const SkeletonContent = () => {
  return (
    <StyledWhiteBox spacing={2}>
      <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
      <Stack>
        <Skeleton animation="wave" variant="rectangular" height={200} />
      </Stack>
    </StyledWhiteBox>
  );
};
