import { Route } from 'react-router-dom';

// Routes
import { NoAuthRouteKeys } from 'src/utils/constants/routes';

// Components
import NoAuthProvider from 'src/contexts/NoAuthContext';
import ForgotPasswordPage from 'src/pages/ForgotPassword';
import LoginPage from 'src/pages/Login';
import ResetPasswordPage from 'src/pages/ResetPassword';
import SignUpPage from 'src/pages/SignUp';
import TwoStepVerificationPage from 'src/pages/TwoStepVerification';
import TermAndConditionPage from 'src/pages/TermAndCondition';
import ProtectRoute from 'src/routes/ProtectRoute';
import GeneralDisclaimerPage from 'src/pages/GeneralDisclaimer';

const NoAuthRoutes = [
  <Route
    key={NoAuthRouteKeys.Login}
    path={NoAuthRouteKeys.Login}
    element={
      <NoAuthProvider>
        <LoginPage />
      </NoAuthProvider>
    }
  />,
  <Route
    key={NoAuthRouteKeys.SignUp}
    path={NoAuthRouteKeys.SignUp}
    element={
      <NoAuthProvider>
        <SignUpPage />
      </NoAuthProvider>
    }
  />,
  <Route
    key={NoAuthRouteKeys.TwoStepVerification}
    path={NoAuthRouteKeys.TwoStepVerification}
    element={
      <NoAuthProvider>
        <ProtectRoute>
          <TwoStepVerificationPage />
        </ProtectRoute>
      </NoAuthProvider>
    }
  />,
  <Route
    key={NoAuthRouteKeys.ForgotPassword}
    path={NoAuthRouteKeys.ForgotPassword}
    element={
      <NoAuthProvider>
        <ForgotPasswordPage />
      </NoAuthProvider>
    }
  />,
  <Route
    key={NoAuthRouteKeys.ResetPassword}
    path={NoAuthRouteKeys.ResetPassword}
    element={
      <NoAuthProvider>
        <ProtectRoute>
          <ResetPasswordPage />
        </ProtectRoute>
      </NoAuthProvider>
    }
  />,
  <Route
    key={NoAuthRouteKeys.GeneralDisclaimer}
    path={NoAuthRouteKeys.GeneralDisclaimer}
    element={<GeneralDisclaimerPage />}
  />,
  <Route
    key={NoAuthRouteKeys.TermAndCondition}
    path={NoAuthRouteKeys.TermAndCondition}
    element={<TermAndConditionPage />}
  />,
];

export default NoAuthRoutes;
