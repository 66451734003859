import emojiFlags from 'emoji-flags';
import { isString, upperCase } from 'lodash';

function getEmojiFlag(code?: string) {
  if (!isString(code)) {
    return null;
  }
  const flag = emojiFlags.countryCode(upperCase(code));
  if (!flag) {
    return null;
  }
  return flag.emoji;
}

export default getEmojiFlag;
