import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import { get } from 'lodash';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';

// Utilities
import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/hooks/useNotification';
import { CommonResponse } from 'src/model/common';
import { useMutateLogin } from 'src/services/mutate/useMutateAuth';
import { ACCESS_TOKEN_KEY, MIN_LENGTH_PASSWORD, REFRESH_TOKEN_KEY } from 'src/utils/constants/config';
import { AuthRouteKeys, NoAuthRouteKeys } from 'src/utils/constants/routes';
import { ERROR_CODE, SUCCESS_CODE } from 'src/utils/constants/statusCode';
import useSnackbarStore from 'src/zustand/useSnackbarStore';

const FormLogin = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { notification } = useNotification();
  const [countLoginFail, setCountLoginFail] = useState(0);
  const { isPending, data, mutateAsync } = useMutateLogin();
  const { dispatch } = useSnackbarStore();
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      email: String(get(location.state, 'email', '')),
      password: String(get(location.state, 'password', '')),
      keep_login: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().max(255).email('Email is invalid').required('Email is required'),
      password: Yup.string()
        .min(MIN_LENGTH_PASSWORD, `Your password must be at least ${MIN_LENGTH_PASSWORD} characters`)
        .max(255)
        .required('Password is required'),
      keep_login: Yup.bool(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const { email, password } = values;
      const dto: TypeDTOLogin = {
        email: email,
        password: password,
      };

      const res: CommonResponse = await mutateAsync(dto);

      notification(
        res,
        {
          title: t('login.notification.success.title'),
          description: t('login.notification.success.description'),
        },
        {
          title: t('login.notification.error.title'),
          description: t('login.notification.error.description'),
        },
      );

      if (res.status_code === SUCCESS_CODE.GET) {
        localStorage.setItem(ACCESS_TOKEN_KEY, res.data?.access_token || '');
        localStorage.setItem(REFRESH_TOKEN_KEY, res.data?.refresh_token || '');
        setTimeout(() => {
          navigate({ pathname: AuthRouteKeys.Home });
        }, 0);
      } else {
        handleAlertFail(res);
      }

      setSubmitting(false);
    },
  });
  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleAlertFail = (res: CommonResponse) => {
    if (res.status_code === ERROR_CODE.NOT_ACCEPTABLE) {
      setCountLoginFail(0);
    }
    if (countLoginFail >= 2 && res.status_code !== ERROR_CODE.NOT_ACCEPTABLE) {
      dispatch({
        severity: 'warning',
        color: 'warning',
        open: true,
        title: 'You have entered the wrong password 3 times',
        description: (
          <Typography sx={{ color: 'inherit' }}>
            Your have reached 3 failed password attempts. Please note that a 4th incorrect attempt will lock your
            account for 15 minutes before you can try logging in again. If you have forgotten your password, please
            reset your password here.
          </Typography>
        ),
      });
      return;
    }
    if (res.status_code === ERROR_CODE.UNAUTHORIZED) {
      setCountLoginFail(countLoginFail + 1);
    }
  };

  const toggleVisiblePassword = () => {
    const state = update(visiblePassword, {
      $set: !visiblePassword,
    });
    setVisiblePassword(state);
  };

  const renderIconHidePassword = () => {
    return visiblePassword ? (
      <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
    ) : (
      <VisibilityOffOutlinedIcon sx={{ fontSize: 16 }} />
    );
  };

  return (
    <FormikForm formik={formik}>
      <Box sx={{ width: 500 }}>
        <Typography variant="h3">Sign In</Typography>

        <Box sx={{ mt: 1 }} />

        <Typography variant="body1" color={theme.palette.text.secondary}>
          Please enter your email address and password to sign in.
        </Typography>

        <Box sx={{ mt: 4 }} />

        <Box display={'flex'} gap={2} flexDirection={'column'}>
          <FormControl>
            <TextField
              size="small"
              placeholder="john@gmail.com"
              required
              label="Email Address"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText error id="standard-weight-helper-text-email-login">
                {formik.errors.email}
              </FormHelperText>
            )}
            <FormHelperText error id="standard-weight-helper-text-password-login">
              {data?.status_code === ERROR_CODE.NOT_FOUND &&
                'No account exists for this email address. Please try again.'}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <TextField
              id="password"
              name="password"
              size="small"
              required
              label={<span>Password</span>}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              type={visiblePassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisiblePassword()} size="small">
                      {renderIconHidePassword()}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="********"
            />
            {formik.touched.password && formik.errors.password && (
              <FormHelperText error id="standard-weight-helper-text-password-login">
                {formik.errors.password}
              </FormHelperText>
            )}
          </FormControl>
          <FormHelperText error id="standard-weight-helper-text-password-login">
            {data?.status_code === ERROR_CODE.UNAUTHORIZED && 'Your password is incorrect'}
          </FormHelperText>
        </Box>

        <Box sx={{ mt: 1 }} />

        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={(e, value) => {
                  formik.setFieldValue('keep_login', value);
                }}
                checked={formik.values.keep_login}
                color="primary"
              />
            }
            label={
              <Typography color={theme.palette.text.secondary} variant="body2">
                Keep me logged in
              </Typography>
            }
          />
          <RouterLinkNoUnderline to={NoAuthRouteKeys.ForgotPassword}>
            <Typography color={theme.palette.secondary.main} variant="body2">
              Forgot Password ?
            </Typography>
          </RouterLinkNoUnderline>
        </Stack>

        <Box sx={{ mt: 1 }} />

        <ButtonWithLoading
          isLoading={isPending}
          sx={{ fontWeight: theme.typography.fontWeightMedium, height: 50 }}
          size="large"
          isRounded
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          Sign In
        </ButtonWithLoading>

        <Box sx={{ mt: 4 }} />

        <Stack gap={1} direction={'row'} justifyContent={'center'}>
          <Typography color={theme.palette.text.secondary} variant="body2">
            Not registered yet?
          </Typography>

          <RouterLinkNoUnderline to={NoAuthRouteKeys.SignUp}>
            <Typography color={theme.palette.secondary.main} variant="body2">
              Create an Account
            </Typography>
          </RouterLinkNoUnderline>
        </Stack>
      </Box>
    </FormikForm>
  );
};

export default FormLogin;
