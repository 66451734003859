import { Tab, Tabs, styled, tabsClasses } from '@mui/material';
import React from 'react';

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 20,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(1),
  borderRadius: theme.shape.borderRadius * 2,
  '&.Mui-selected': {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&.MuiTab-root': {
    textTransform: 'uppercase',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`& .${tabsClasses.scrollButtons}`]: {
    '&.Mui-disabled': { opacity: 0.3 },
  },
  [`& .${tabsClasses.flexContainer}`]: {
    border: 'none',
  },
  borderTop: '1px solid' + theme.palette.divider,
  borderBottom: '1px solid' + theme.palette.divider,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const TabComponent = ({
  selected,
  handleChange,
  tabs,
}: {
  selected: string;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  tabs: ITabSetting[];
}) => {
  return (
    <StyledTabs
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
      value={selected}
      onChange={(event: React.SyntheticEvent, newValue: string) => {
        handleChange(event, newValue);
        // setSearchParam({ tab: newValue });
      }}
      variant="scrollable"
      scrollButtons
    >
      {tabs.map((tab: ITabSetting) => {
        const Icon = tab.icon;
        return (
          <StyledTab value={tab.key} disabled={tab.disable} key={tab.key} icon={<Icon />} label={tab.label}></StyledTab>
        );
      })}
    </StyledTabs>
  );
};

export default TabComponent;
