import { useQuery } from '@tanstack/react-query';
import { ConstantSettings } from 'src/model/utils';
import { UtilsService } from 'src/services/api';
import { CONSTANT_SETTING_TYPES } from 'src/services/api/config';

const QUERY_KEY_CONSTANT_SETTING = 'QUERY_KEY_CONSTANT_SETTING';

export const useQueryConstantSetting = (params: Record<string, CONSTANT_SETTING_TYPES>) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<ConstantSettings, Error>({
    queryKey: [QUERY_KEY_CONSTANT_SETTING, params],
    queryFn: () => UtilsService.getConstantSetting(params),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new ConstantSettings(),
  });

  return {
    isLoading,
    error,
    data,
    isError,
    isFetching,
    isLoadingError,
    refetch,
  };
};
