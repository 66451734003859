import { useTheme, Box, Typography, styled, Stack, Tooltip, IconButton } from '@mui/material';

import {
  Bookmark,
  Box as BoxIcon,
  Calendar,
  ChevronsRight,
  //  Printer
} from 'react-feather';
import FlagCountry from 'src/components/common/FlagCountry';

// Components
import IconFeather from 'src/components/common/Icon';

// Utilities
// import { usePrinter } from 'src/hooks/usePrinter';

const TypographyWhite = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  overflow: 'hidden',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
}));

const IconButtonWhite = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 16,
  border: `1px solid ${theme.palette.common.white}`,
}));

const TradeDataInformation = () => {
  const theme = useTheme();
  // const { handlePrint } = usePrinter();

  return (
    <StyledBox position="relative">
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <TypographyWhite align="left" variant="h5" gutterBottom>
          080300 -- Fruit, edible; bananas, (including plantains), fresh or dried
        </TypographyWhite>
        <Box>
          {/* <Tooltip title="Print">
              <IconButtonWhite onClick={() => handlePrint()}>
                <IconFeather icon={<Printer />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip> */}
          <Tooltip title="Bookmarks Coming Soon!">
            <IconButtonWhite>
              <IconFeather icon={<Bookmark />} muiProps={{ fontSize: 'small' }} />
            </IconButtonWhite>
          </Tooltip>
        </Box>
      </Stack>
      <TypographyWhite align="left" variant="body2" gutterBottom>
        This is the result of all import and export data of Fruit, edible; bananas, (including plantains), fresh or
        dried. Exported from China to the USA from August 1, 2021 until August 1, 2024.
      </TypographyWhite>
      <Stack spacing={4} direction="row" alignItems="center">
        <Stack spacing={0.5} direction="row" alignItems="center">
          <IconFeather icon={<BoxIcon />} muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }} />
          <TypographyWhite variant="caption" align="left">
            080300
          </TypographyWhite>
        </Stack>
        <Stack spacing={0.5} direction="row" alignItems="center">
          <FlagCountry code="CN" />
          <TypographyWhite variant="caption" align="left">
            China
          </TypographyWhite>
          <IconFeather
            icon={<ChevronsRight />}
            muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }}
          />
          <FlagCountry code="US" />
          <TypographyWhite variant="caption" align="left">
            USA
          </TypographyWhite>
        </Stack>
        <Stack spacing={0.5} direction="row" alignItems="center">
          <IconFeather icon={<Calendar />} muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }} />
          <TypographyWhite variant="caption" align="left">
            01/08/2021
          </TypographyWhite>
          <TypographyWhite variant="caption" align="left">
            -
          </TypographyWhite>
          <IconFeather icon={<Calendar />} muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }} />
          <TypographyWhite variant="caption" align="left">
            01/08/2024
          </TypographyWhite>
        </Stack>
      </Stack>
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        sx={{
          backdropFilter: 'blur(2px)',
          borderRadius: theme.shape.borderRadius * 2,
        }}
      />
    </StyledBox>
  );
};

export default TradeDataInformation;
