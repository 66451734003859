import { FormikContextType, FormikProvider } from 'formik';

type Props = {
  formik: FormikContextType<ExpectedAny>;
  children: React.ReactNode;
};

const FormikForm = ({ formik, children, ...props }: Props) => {
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} {...props}>
        {children}
      </form>
    </FormikProvider>
  );
};

export default FormikForm;
