import { Typography, Stack, Skeleton, Grid } from '@mui/material';
import { isString } from 'lodash';

// Components
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import ModalComponent from 'src/components/Modal/ModalComponent';

// Utilities
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { useQueryGetRegimeCountryTariffs } from 'src/services/queries';
import { useQueryParams } from 'src/hooks/useQueryParam';

interface IProps {
  tariffItem: {
    tariffRegime: string;
    agId: number;
  };
  tariffYear: string;
  open: boolean;
  handleClose: () => void;
}

const PopupRegimeCountry = ({ tariffItem, tariffYear, open, handleClose }: IProps) => {
  const { reporter } = useQueryParams();
  const GetRegimeCountryTariffsQuery = useQueryGetRegimeCountryTariffs({
    reporter: reporter && isString(reporter) ? reporter : '',
    agId: tariffItem.agId || 0,
  });

  const dataRegimeCountry = getArrayDefault(GetRegimeCountryTariffsQuery.data?.data);

  return (
    <ModalComponent padding={4} open={open} handleClose={handleClose} showCloseIcon={true}>
      {GetRegimeCountryTariffsQuery.isFetching ? (
        <Stack direction="column" alignItems="center" width="100%" flex={1}>
          <SkeletonContent />
        </Stack>
      ) : (
        <Stack direction="column" spacing={2} width="100%" flex={1} alignItems="center">
          <Typography textAlign="center" variant="h6">
            {tariffItem.tariffRegime}
          </Typography>
          <Typography textAlign="center" variant="subtitle1" color="text.secondary">
            {dataRegimeCountry.length > 1
              ? `${dataRegimeCountry?.length} Countries Or Territories`
              : `${dataRegimeCountry?.length} Country or Territory`}{' '}
            Eligible For This Tariff Regime In {tariffYear}
          </Typography>
          <Grid container>
            {dataRegimeCountry.map((country: ICountryTariffs, index) => (
              <Grid key={`country-tariff-${index}-${country.Code}`} item xs={6} md={3}>
                <Typography variant="caption" color="text.secondary">
                  {index + 1}. {country.Name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </ModalComponent>
  );
};

export default PopupRegimeCountry;

const SkeletonContent = () => {
  return (
    <StyledWhiteBox alignItems="center" spacing={1}>
      <Skeleton animation="wave" variant="rectangular" width={200} />
      <Skeleton animation="wave" variant="rectangular" width={400} />
      <Stack spacing={1} direction="row">
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
      </Stack>
      <Stack spacing={1} direction="row">
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
      </Stack>
      <Stack spacing={1} direction="row">
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
        <Skeleton animation="wave" variant="rectangular" width={150} />
      </Stack>
    </StyledWhiteBox>
  );
};
