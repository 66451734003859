import { Typography, Box, Stack } from '@mui/material';
import { Monitor, Smartphone } from 'react-feather';
import { get } from 'lodash';

// Components
import PerformanceScore from 'src/features/CompanyDetail/Common/PerformanceScore';

// Utilities
import IconFeather from 'src/components/common/Icon';
import { LOGO_COMPANY_DEFAULT_URL } from 'src/utils/constants/imagesUrl';

const Performance = ({ selectedWebDetail }: { selectedWebDetail?: ICompanyWebDetail }) => {
  return (
    <Box padding={2} alignItems="center" mb={1} display="flex" flexDirection="column" gap={3}>
      <Box
        width={300}
        mb={3}
        src={get(selectedWebDetail, 'performance_results.desktop.screenshots', '')}
        component="img"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = LOGO_COMPANY_DEFAULT_URL;
        }}
      />
      <Box>
        <Typography variant="h6" gutterBottom>
          {get(selectedWebDetail, 'name', '')}
        </Typography>
      </Box>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack direction="row" gap={1}>
          <IconFeather icon={<Monitor />} />
          <Typography variant="subtitle1">Desktop</Typography>
        </Stack>
        <Stack spacing={4} direction="row">
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.desktop.performance_score', 0)} />
            <Typography variant="subtitle1">Performance</Typography>
          </Stack>
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.desktop.accessibility_score', 0)} />
            <Typography variant="subtitle1">Accessbility</Typography>
          </Stack>
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.desktop.best_practices_score', 0)} />
            <Typography variant="subtitle1">Best Practices</Typography>
          </Stack>
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.desktop.seo_score', 0)} />
            <Typography variant="subtitle1">SEO</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack direction="row" gap={1}>
          <IconFeather icon={<Smartphone />} />
          <Typography variant="subtitle1">Mobile</Typography>
        </Stack>
        <Stack spacing={4} direction="row">
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.mobile.performance_score', 0)} />
            <Typography variant="subtitle1">Performance</Typography>
          </Stack>
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.mobile.accessibility_score', 0)} />
            <Typography variant="subtitle1">Accessbility</Typography>
          </Stack>
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.mobile.best_practices_score', 0)} />
            <Typography variant="subtitle1">Best Practices</Typography>
          </Stack>
          <Stack spacing={1} direction="column" alignItems="center">
            <PerformanceScore value={get(selectedWebDetail, 'performance_results.mobile.seo_score', 0)} />
            <Typography variant="subtitle1">SEO</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Performance;
