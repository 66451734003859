import { Backdrop, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// Pages
import AuthRoutes from './routes/Auth';
import NoAuthRoutes from './routes/NoAuth';

// Utilities
import { AdapterDayjs } from '@icttm-dev/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@icttm-dev/x-date-pickers-pro/LocalizationProvider';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import localization from 'src/localization';
import { IP_ADDRESS_KEY } from 'src/utils/constants/config';

// Components
import analytics from 'src/analytics';
import SmallScreenNotice from 'src/components/common/SmallScreenNotice';
import WarningSensitiveImage from 'src/components/common/WarningSensitiveImage';
import FormSearchCompany from 'src/features/CompanyDetail/Common/FormSearchCompany';
import FormCompareCompany from 'src/features/CompareCompany/Common/FormCompareCompany';
import FormSearchPeople from 'src/features/PeopleDetail/Common/FormSearchPeople';
import FormSearchTradeData from 'src/features/TradeData/Common/FormSearchTradeData';
import NotFoundPage from 'src/pages/NotFound';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';

function App() {
  localization.init();
  useScrollToTop();

  analytics.initialize();

  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const ipAddress = localStorage.getItem(IP_ADDRESS_KEY);
  const { type, visible, selectedCompanyCompare, handleCloseSearch } = useFormSearch((state) => state);

  // Get Client's IP Address
  async function getIPFromAmazon() {
    setLoading(true);
    await fetch('https://checkip.amazonaws.com/')
      .then((res) => res.text())
      .then((data) => {
        localStorage.setItem(IP_ADDRESS_KEY, data);
        return data;
      });
    setLoading(false);
  }

  useEffect(() => {
    if (!ipAddress) getIPFromAmazon();
  }, [ipAddress]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  if (isSmallScreen) {
    return <SmallScreenNotice />;
  }

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <FormSearchCompany open={type === FormType.SearchCompany && visible} handleClose={handleCloseSearch} />
        <FormSearchPeople open={type === FormType.SearchPeople && visible} handleClose={handleCloseSearch} />
        <FormSearchTradeData open={type === FormType.SearchTradeData && visible} handleClose={handleCloseSearch} />
        <FormCompareCompany
          open={type === FormType.CompareCompany && visible}
          handleClose={handleCloseSearch}
          selectedCompanyCompare={selectedCompanyCompare}
        />
        <WarningSensitiveImage
          open={type === FormType.WarningSensitiveImage && visible}
          handleClose={handleCloseSearch}
        />
        <Routes>
          {NoAuthRoutes.map((route) => route)}
          {AuthRoutes.map((route) => route)}
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </LocalizationProvider>
    </React.Fragment>
  );
}

export default App;
