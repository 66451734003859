import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

// Components
import { isArray, isEmpty, size } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { useQueryCompanyReviews } from 'src/services/queries/useQueryCompany';
import { v4 as uuidv4 } from 'uuid';
import ReviewItem, { ReviewItemLoading } from './ReviewItem';
import ReviewOverview from './ReviewOverview';
import SocialReview from './SocialReview';

// Utilities

const Review = () => {
  const { id } = useParams();

  const [limit, setLimit] = useState(10);
  const [reviews, setReviews] = useState([] as ICompanyReview[]);

  const { data, isFetching } = useQueryCompanyReviews({
    id: id,
    params: {
      limit: limit,
      offset: 0,
    },
  });

  useEffect(() => {
    if (!isFetching && isArray(data.data)) {
      setReviews(data.data);
    }
  }, [data.data, isFetching]);

  return (
    <React.Fragment>
      <Stack spacing={3}>
        <SocialReview id={id} />
        <StyledWhiteBox>
          <ReviewOverview id={id} />
        </StyledWhiteBox>

        {!isEmpty(reviews) && (
          <StyledWhiteBox
            id="company-review-scrollable"
            display="flex"
            flexDirection="column"
            rowGap={3}
            maxHeight={1200}
            overflow="auto"
          >
            <Typography variant="h5" align="left">
              All Reviews
            </Typography>

            <InfiniteScroll
              scrollableTarget={'company-review-scrollable'}
              next={() => {
                setLimit((prevLimit) => prevLimit + 10);
              }}
              hasMore={size(reviews) < data.total_records}
              loader={null}
              dataLength={data.total_records || 0}
            >
              <Stack gap={3}>
                {reviews.map((item) => (
                  <ReviewItem key={uuidv4()} item={item} />
                ))}
                {isFetching && <ReviewItemLoading />}
              </Stack>
            </InfiniteScroll>
          </StyledWhiteBox>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Review;
