/* eslint-disable no-unused-vars */
import {
  AddLocationAltRounded,
  AltRouteRounded,
  BarChartRounded,
  CompareRounded,
  ConnectWithoutContactRounded,
  DirectionsBoatRounded,
  GroupRounded,
  HomeWorkRounded,
  Inventory2Outlined,
  NewspaperRounded,
  PermContactCalendarRounded,
  QrCodeRounded,
  ReceiptLongRounded,
  ReviewsRounded,
  // StarRounded,
  ShareLocationRounded,
  StorefrontRounded,
  TravelExploreRounded,
  VpnLockRounded,
  WhereToVoteRounded,
} from '@mui/icons-material';
import { Box, styled } from '@mui/material';

export enum TypeTabCompany {
  Overview = 'Overview',
  Addresses = 'Addresses',
  Contacts = 'Contacts',
  Reviews = 'Reviews',
  SocialMedia = 'Social Media',
  WebDetails = 'Web Details',
  NegativeMedia = 'Negative Media',
  Trademark = 'Trademarks',
  StockPlace = 'Stock / Share Prices',
  Alerts = 'Alerts',
  DataAnalytics = 'Trade Analytics',
  SupplyChainMap = 'Supply Chain Map',
  BillsOfLading = 'Bills of Lading',
  HSCode = 'HS Code',
  Customers = 'Customers',
  Suppliers = 'Suppliers',
  Competitors = 'Competitors',
  PortsOfDelivery = 'Ports of Delivery ',
  PortsOfOrigin = 'Ports of Origin',
  TradingArea = 'Trading Area',
}

export const TABS_COMPANY: ITabSetting[] = [
  {
    key: TypeTabCompany.Overview,
    label: TypeTabCompany.Overview,
    icon: HomeWorkRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.Contacts,
    label: TypeTabCompany.Contacts,
    icon: PermContactCalendarRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.SocialMedia,
    label: TypeTabCompany.SocialMedia,
    icon: ConnectWithoutContactRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.Addresses,
    label: TypeTabCompany.Addresses,
    icon: AddLocationAltRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.Reviews,
    label: TypeTabCompany.Reviews,
    icon: ReviewsRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.WebDetails,
    label: TypeTabCompany.WebDetails,
    icon: TravelExploreRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.NegativeMedia,
    label: TypeTabCompany.NegativeMedia,
    icon: NewspaperRounded,
    disable: false,
  },
  // {
  //   key: TypeTabCompany.Trademark,
  //   label: TypeTabCompany.Trademark,
  //   icon: StarRounded,
  //   disable: false,
  // },
  {
    key: TypeTabCompany.StockPlace,
    label: TypeTabCompany.StockPlace,
    icon: Inventory2Outlined,
    disable: false,
  },
  {
    key: TypeTabCompany.Alerts,
    label: TypeTabCompany.Alerts,
    icon: VpnLockRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.DataAnalytics,
    label: TypeTabCompany.DataAnalytics,
    icon: BarChartRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.SupplyChainMap,
    label: TypeTabCompany.SupplyChainMap,
    icon: AltRouteRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.BillsOfLading,
    label: TypeTabCompany.BillsOfLading,
    icon: ReceiptLongRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.HSCode,
    label: TypeTabCompany.HSCode,
    icon: QrCodeRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.Customers,
    label: TypeTabCompany.Customers,
    icon: GroupRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.Suppliers,
    label: TypeTabCompany.Suppliers,
    icon: StorefrontRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.Competitors,
    label: TypeTabCompany.Competitors,
    icon: CompareRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.PortsOfDelivery,
    label: TypeTabCompany.PortsOfDelivery,
    icon: DirectionsBoatRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.PortsOfOrigin,
    label: TypeTabCompany.PortsOfOrigin,
    icon: WhereToVoteRounded,
    disable: false,
  },
  {
    key: TypeTabCompany.TradingArea,
    label: TypeTabCompany.TradingArea,
    icon: ShareLocationRounded,
    disable: false,
  },
];

// Common Style WhiteBox use in multiple  tab
export const StyledWhiteBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.background.default,
}));
