import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

interface IProps extends CircularProgressProps {
  value: number;
}

const PerformanceScore = ({ value, ...props }: IProps) => {
  const theme = useTheme();

  const color = React.useMemo(() => {
    if (value >= 90) return 'success';
    if (value < 90 && value >= 50) return 'warning';
    return 'error';
  }, [value]);

  return (
    <Box
      sx={{ position: 'relative', display: 'inline-flex', borderRadius: '50%' }}
      bgcolor={theme.palette[color].light}
    >
      <CircularProgress variant="determinate" value={value} color={color} size={64} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{ color: theme.palette[color].main }}
        >{`${Math.round(value)}`}</Typography>
      </Box>
    </Box>
  );
};

export default PerformanceScore;
