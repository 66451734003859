import { Typography, Box } from '@mui/material';

// Components
import Table from 'src/components/Table';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatUrlNonProtocol } from 'src/utils/helpers/stringHelpers';

const BackLinks = ({ selectedWebDetail }: { selectedWebDetail?: ICompanyWebDetail }) => {
  const listData = getArrayDefault(selectedWebDetail?.backlinks).map((backlink, index) => ({
    key: `backlink-${backlink.title}-${index}`,
    title: backlink.title,
    link: backlink.link,
  }));

  const configColumn: ConfigCell<Backlink>[] = [
    {
      id: 'title',
      disablePadding: true,
      label: 'BackLinks',
      cell: (row: Backlink) => {
        return (
          <Typography variant="body2" color="text.secondary" sx={{ overflowWrap: 'break-word' }}>
            {row.title}
          </Typography>
        );
      },
    },
    {
      id: 'link',
      disablePadding: true,
      label: 'URL',
      cell: (row: Backlink) => {
        return (
          <RouterLinkNoUnderline to={formatUrlNonProtocol(row?.link || '')} target="_blank" color="secondary">
            <Typography variant="body2" color="secondary">
              URL
            </Typography>
          </RouterLinkNoUnderline>
        );
      },
    },
  ];

  return (
    <Box padding={1}>
      <Table data={listData} configColumn={configColumn} dataKey="link" isPagination={false} />
    </Box>
  );
};

export default BackLinks;
