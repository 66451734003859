import { Autocomplete, Box, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { ReactNode } from 'react';
import SearchIcon from '@mui/icons-material/Search';

// Components
import PaperSelect from 'src/components/common/PaperSelect';
import FlagCountry from 'src/components/common/FlagCountry';

// Utilities
import { EmptyText, LIMIT_GET_LIST_AUTO_COMPLETE } from 'src/utils/constants/config';
import { useQueryCompanyNames } from 'src/services/queries/useQueryCompany';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

interface IProps {
  id?: string;
  defaultValue?: ICompareCompany | null;
  tooltipText?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  label?: ReactNode;
  sx?: Record<string, ExpectedAny>;
  handleSubmit?: () => void;
  disabledList?: string[];
  onChange?: (value: string) => void;
  placeholder?: string;
}

const FormItemChooseCompany = ({
  disabled,
  id,
  defaultValue = null,
  required,
  error,
  label,
  sx = {},
  disabledList,
  onChange,
  placeholder,
}: IProps) => {
  const theme = useTheme();
  const { handleBlur, setFieldValue } = useFormikContext<TypeDTOSettingInformation>();
  const [query, setQuery] = React.useState('');
  const [selected, setSelected] = React.useState<ICompareCompany | null>(defaultValue);

  const { data, isFetching } = useQueryCompanyNames({
    limit: LIMIT_GET_LIST_AUTO_COMPLETE,
    offset: '0',
    company_name: query,
  });

  const debounceOnchange = React.useCallback(
    debounce((e: string) => {
      setQuery(e);
    }, 500),
    [],
  );

  return (
    <Autocomplete
      defaultValue={{
        id: '',
        name: selected?.name,
        address: '',
        country: '',
      }}
      loading={isFetching}
      onBlur={handleBlur}
      onChange={(_, value) => {
        setSelected(value);
        if (value) {
          setQuery(value?.name);
        }
        onChange?.(value?.id);
      }}
      autoComplete
      disabled={disabled}
      fullWidth
      size="small"
      id={id}
      autoHighlight
      options={getArrayDefault(data.data)}
      PaperComponent={PaperSelect}
      getOptionLabel={(option) => option?.name ?? ''}
      renderOption={(props, option) => {
        return (
          <Box component="li" display="flex" flexDirection="row" {...props}>
            <Stack direction="row" alignItems="flex-start" spacing={1}>
              <Box>
                <FlagCountry width={20} code={option.country ?? ''} />
              </Box>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="body2"> {option?.name ?? EmptyText}</Typography>
                <Typography color="text.secondary" variant="caption2">
                  {option?.full_address ?? EmptyText}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        );
      }}
      popupIcon={<SearchIcon sx={{ color: '#fff' }} />}
      sx={{ ...sx }}
      renderInput={({ ...params }) => (
        <TextField
          {...params}
          error={error}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: selected ? <FlagCountry width={20} code={selected?.country ?? ''} /> : null,
          }}
          onChange={(e) => debounceOnchange(e.target.value)}
          required={required}
          label={label || ''}
          sx={{
            color: theme.palette.common.white,
          }}
        />
      )}
      getOptionDisabled={(options) => {
        if (options.id === selected?.id) {
          return false;
        }
        if (disabledList) {
          return disabledList.includes(options.id);
        }
        return false;
      }}
    />
  );
};

export default FormItemChooseCompany;
