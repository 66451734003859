import { Box, Chip, Grid, IconButton, Pagination, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Bookmark, Briefcase, MapPin } from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import { UnknownText } from 'src/utils/constants/config';
import { getColorCompanyStatus } from 'src/utils/helpers/stringHelpers';

// Utilities

const StyledBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  position: 'relative',
}));

const DUMP_DATA = [
  {
    id: '1',
    name: 'Nicolae Sobari',
    country: 'BD',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Active',
    status_code: 0,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    country: 'VN',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Closed',
    status_code: 1,
  },
  {
    id: '1',
    name: 'Nicolae Sobari',
    country: 'CA',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Struck Off',
    status_code: 1,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    country: 'CO',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Dissolved',
    status_code: 2,
  },
  {
    id: '1',
    name: 'Nicolae Sobari',
    country: 'PH',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: null,
    status_code: 3,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    country: 'RU',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Active',
    status_code: 0,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    country: 'CO',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Dissolved',
    status_code: 2,
  },
  {
    id: '1',
    name: 'Nicolae Sobari',
    country: 'PH',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: null,
    status_code: 3,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    country: 'RU',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Active',
    status_code: 0,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    country: 'CO',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Dissolved',
    status_code: 2,
  },
  {
    id: '1',
    name: 'Nicolae Sobari',
    country: 'PH',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: null,
    status_code: 3,
  },
  {
    id: '2',
    name: 'Nicholas Sobar',
    country: 'RU',
    full_address: 'Chișinău, Moldova',
    company_code: '3746340',
    is_marked: true,
    status: 'Active',
    status_code: 0,
  },
];

const ListCompany = () => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {DUMP_DATA.map((company, index) => {
          return (
            <Grid xs={12} md={6} lg={6} xl={4} key={`company-bookmark-${company.id}-${index}`} item>
              <Profile company={company} />
            </Grid>
          );
        })}
      </Grid>
      <Stack mt={2} alignItems="center">
        <Pagination count={10} color="primary" shape="rounded" />
      </Stack>
    </React.Fragment>
  );
};

export default ListCompany;

const Profile = ({ company }: { company: ICompanyProfile }) => {
  const theme = useTheme();

  return (
    <StyledBox
      sx={{
        borderColor: theme.palette.divider,
      }}
      display={'flex'}
      flexDirection="column"
      gap={1}
      padding={1.5}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          border: '1px solid' + theme.palette.divider,
        }}
      >
        <IconFeather
          muiProps={{ fontSize: 'small' }}
          icon={<Bookmark fill={company.is_marked ? theme.palette.primary.main : 'transparent'} />}
        />
      </IconButton>

      <Typography color={theme.palette.primary.main} variant="subtitle1">
        {company.name || UnknownText}
      </Typography>

      <Stack alignItems={'center'} spacing={1} direction="row">
        <FlagCountry width={20} code={company.country} />
        <Typography color={'text.secondary'} variant="caption">
          {company.country || UnknownText}
        </Typography>
      </Stack>

      <Typography display={'flex'} gap={1} color={'text.secondary'} variant="caption" alignItems="center">
        <IconFeather muiProps={{ fontSize: 'small' }} icon={<Briefcase />} />
        {company.company_code || UnknownText}
        <Chip
          label={company?.status ?? UnknownText}
          size="small"
          color={getColorCompanyStatus(company?.status_code ?? 0)}
        />
      </Typography>
      <Typography display={'flex'} gap={1} color={'text.secondary'} variant="caption">
        <IconFeather muiProps={{ fontSize: 'small' }} icon={<MapPin />} />
        {company.full_address || UnknownText}
      </Typography>
    </StyledBox>
  );
};
