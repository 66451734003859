import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface IProps extends ButtonProps {
  isLoading?: boolean;
  isRounded?: boolean;
}

const ButtonWithLoading = ({ children, color, variant, size, isRounded, isLoading, sx, ...others }: IProps) => {
  let sxProps = sx;

  if (isRounded) {
    sxProps = { ...sxProps, ...{ borderRadius: 1000 } };
  }

  return (
    <Button
      {...others}
      sx={sxProps}
      size={size}
      variant={variant}
      color={color}
      startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : null}
    >
      {children}
    </Button>
  );
};

export default ButtonWithLoading;
