import { Box, CircularProgress, Stack, styled, Typography, useTheme } from '@mui/material';
import numeral from 'numeral';
import React, { useEffect } from 'react';
import ModalComponent from 'src/components/Modal/ModalComponent';
import { queryClient } from 'src/configs/queryClient';
import useCountDown from 'src/hooks/useCountDown';
import { User } from 'src/model/auth';
import { useMutateCreateOTP, useMutateResendOTP, useMutateVerifyOTP } from 'src/services/mutate/useMutateAuth';
import { ME_KEY } from 'src/services/queries/useQueryAuth';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import ButtonWithLoading from './ButtonWithLoading';
import InputOtp from './InputOtp';

export const DEFAULT_TIMER = 60 * 5;

const StyledButton = styled(ButtonWithLoading)(() => ({
  padding: 0,
  textTransform: 'none',
}));

const PopupOTP = ({
  open,
  handleClose,
  otp,
  setOtp,
  onVerifySuccess,
  sendEmailTo,
  isPending,
}: {
  open: boolean;
  handleClose: () => void;
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  onVerifySuccess: () => void;
  sendEmailTo?: string;
  isPending?: boolean;
}) => {
  const theme = useTheme();

  const userInfoFromCache: User = queryClient.getQueryData([ME_KEY]) as User;
  const { mutateAsync } = useMutateCreateOTP();
  const mutationResendOTP = useMutateResendOTP();
  const mutationVerifyOTP = useMutateVerifyOTP();
  const email = sendEmailTo || userInfoFromCache.data.email;

  const { timeLeft, resetimer } = useCountDown(DEFAULT_TIMER, open);

  useEffect(() => {
    if (!timeLeft) {
      handleClose();
    }
  }, [timeLeft]);

  const onCreateOTP = async () => {
    if (!email) {
      return;
    }

    await mutateAsync({ email: email } as TypeDTOCreateOTP);
  };

  const onResendOTP = async () => {
    resetimer();
    await mutationResendOTP.mutateAsync({ email: email } as TypeDTOCreateOTP);
  };

  const onVerifyOTP = async () => {
    const dto = {
      email: email,
      code: otp,
    } as TypeDTOVerifyOTP;
    const responseVerifyOTP = await mutationVerifyOTP.mutateAsync(dto);

    if (responseVerifyOTP.status_code === SUCCESS_CODE.GET) {
      onVerifySuccess();
    }
  };

  useEffect(() => {
    if (open) {
      onCreateOTP();
    }
  }, [open]);

  const minute = Math.floor(timeLeft / 60);
  const second = numeral(timeLeft % 60).format('00');
  const percent = Math.floor((timeLeft * 100) / DEFAULT_TIMER);

  return (
    <ModalComponent closeOnBackdropClick={false} showCloseIcon padding={4} open={open} handleClose={handleClose}>
      <Box width={850}>
        <Typography textAlign={'center'} variant="h4">
          Verify email account changes
        </Typography>

        <Box mt={2} />

        <Stack gap={0.5} justifyContent={'center'} direction={'row'}>
          <Typography color={theme.palette.text.secondary} variant="body1">
            We sent a code to email address
          </Typography>
          <Typography color={theme.palette.text.primary} variant="body1">
            {email}
          </Typography>
        </Stack>

        <Stack gap={0.5} justifyContent={'center'} direction={'row'}>
          <Typography color={theme.palette.text.secondary} variant="body1">
            Didn't receive the OTP code?
          </Typography>
          <StyledButton isLoading={mutationResendOTP.isPending} onClick={onResendOTP}>
            <Typography color={theme.palette.secondary.main} variant="body1">
              Click here to send again
            </Typography>
          </StyledButton>
        </Stack>

        <Typography textAlign={'center'} color={theme.palette.text.secondary} variant="body1">
          (and be sure to check your spam folder)
        </Typography>

        <Box mt={4} />

        <Stack gap={2} justifyContent={'center'} direction={'row'}>
          <CircularProgress thickness={22} size={20} value={percent} variant="determinate" color="primary" />
          <Typography color={theme.palette.text.primary} variant="body1">
            {minute}:{second}
          </Typography>
        </Stack>

        <Box mt={4} />

        <Box margin={'0 auto'} maxWidth={500}>
          <InputOtp otp={otp} setOtp={setOtp} />
          <Box mt={4} />
          <ButtonWithLoading
            isLoading={mutationVerifyOTP.isPending || isPending}
            disabled={mutationVerifyOTP.isPending || isPending}
            onClick={onVerifyOTP}
            fullWidth
            sx={{ height: 50 }}
            variant="contained"
            size="large"
            isRounded
          >
            VERIFY
          </ButtonWithLoading>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default PopupOTP;
