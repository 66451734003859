import { Box, Typography, useTheme } from '@mui/material';

// Components
import ExpandableBox from 'src/components/common/ExpandableBox';
import TableHorizontal from 'src/components/TableHorizontal';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

// Utilities

const Description = ({ tradeDataDetail }: { tradeDataDetail: IBillOfLandingDetail }) => {
  const theme = useTheme();

  const configColumn = [
    {
      label: 'BL Identification Number',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.identification_number)}
        </Typography>
      ),
    },
    {
      label: 'HS Code',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.hs_code)}
        </Typography>
      ),
    },
    {
      label: 'Description',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.description)}
        </Typography>
      ),
    },
    {
      label: 'Date of Transaction',
      renderCell: (data: IBillOfLandingDetail) => (
        <Typography color={'text.secondary'} variant="body2">
          {renderTextOrDefault(data.date_of_transaction)}
        </Typography>
      ),
    },
  ];

  return (
    <ExpandableBox title="Description" openDefault={false} flex={1}>
      <Box padding={3} display="flex" flexDirection="column" bgcolor={theme.palette.background.default}>
        <TableHorizontal colKeyWidth={350} configColumn={configColumn} data={tradeDataDetail} />
      </Box>
    </ExpandableBox>
  );
};

export default Description;
