import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';

const ButtonContributeAddress = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={'100%'}
      height={'100%'}
      border={`1px solid ${theme.palette.divider}`}
      borderRadius={theme.shape.borderRadius / 4}
      bgcolor={theme.palette.background.paper1}
      sx={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <AddCircleOutlineOutlined color="primary" />
    </Box>
  );
};

export default ButtonContributeAddress;
