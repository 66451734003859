import { SvgIcon, SvgIconProps } from '@mui/material';

const TrustpilotIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="48" viewBox="0 0 51 48" fill="none">
        <path
          d="M25.7569 36.3412L36.5463 33.6069L41.0541 47.5L25.7569 36.3412ZM50.5871 18.3836H31.595L25.7569 0.5L19.9189 18.3836H0.926758L16.2978 29.4686L10.4598 47.3522L25.8308 36.2673L35.29 29.4686L50.5871 18.3836Z"
          fill="#219653"
        />
      </svg>
    </SvgIcon>
  );
};

export default TrustpilotIcon;
