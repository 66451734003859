import MainLayout from 'src/layouts';
import TariffRules from 'src/features/TariffsAndRules';
import { Helmet } from 'react-helmet';

const TariffRulesPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Tariffs and Rules</title>
      </Helmet>
      <TariffRules />
    </MainLayout>
  );
};

export default TariffRulesPage;
