import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { entries, isArray } from 'lodash';
import { useNavigate } from 'react-router-dom';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import ModalComponent from 'src/components/Modal/ModalComponent';
import FormItemChooseCompany from 'src/features/CompareCompany/Common/FormItemChooseCompany';

import { TypeCompareCompany } from 'src/features/CompareCompany/Common/config';
import { useFormSearch } from 'src/zustand/useFormSearch';
import { AuthRouteKeys } from 'src/utils/constants/routes';

interface IProps {
  open: boolean;
  selectedCompanyCompare: ICompareCompany | null;
  handleClose: () => void;
}

const FormCompareCompany: React.FC<IProps> = ({ open, selectedCompanyCompare, handleClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { resetFormCompare } = useFormSearch((state) => state);

  const formik = useFormik<TypeCompareCompany>({
    enableReinitialize: true,
    initialValues: {
      company_1: '',
      company_2: '',
      company_3: '',
    },
    validationSchema: Yup.object().shape({
      company_1: Yup.string().max(255).required('This field is required'),
      company_2: Yup.string().max(255).required('This field is required'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      handleClose();
      setSubmitting(false);
      const params = entries(values)
        .map(([key, value]) => {
          if (isArray(value)) {
            const q = value.map((val: string) => {
              return `companyIds=${val}`;
            });
            return q.join('&');
          } else {
            return `companyIds=${value}`;
          }
        })
        .join('&');

      navigate({ pathname: AuthRouteKeys.CompareCompany, search: encodeURI(params) });
      resetForm();
      resetFormCompare();
    },
  });

  const selectedOptions = [formik.values.company_1, formik.values.company_2, formik.values.company_3].filter(
    Boolean,
  ) as string[];

  useEffect(() => {
    if (selectedCompanyCompare) {
      formik.setFieldValue('company_1', selectedCompanyCompare?.id || '');
    }
  }, [selectedCompanyCompare]);

  return (
    <ModalComponent padding={4} handleClose={handleClose} open={open}>
      <FormikForm formik={formik}>
        <Box width={540}>
          <Typography textAlign={'center'} variant="h5"></Typography>
          Compare Companies
          <Box mt={1} mb={4}>
            <Typography textAlign={'center'} color={theme.palette.text.secondary} variant="body1">
              Please select the company profiles you want to compare.
            </Typography>
          </Box>
          <Stack direction={'column'} spacing={2}>
            <FormItemChooseCompany
              id="company_1"
              required
              defaultValue={selectedCompanyCompare}
              label="Company Name 1"
              disabledList={selectedOptions}
              onChange={(value) => formik.setFieldValue('company_1', value)}
            />
            <FormItemChooseCompany
              id="company_2"
              required
              defaultValue={null}
              label="Company Name 2"
              disabledList={selectedOptions}
              onChange={(value) => formik.setFieldValue('company_2', value)}
            />
            <FormItemChooseCompany
              id="company_3"
              defaultValue={null}
              label="Company Name 3"
              disabledList={selectedOptions}
              onChange={(value) => formik.setFieldValue('company_3', value)}
            />
            <ButtonWithLoading sx={{ height: 53 }} size="large" variant="contained" isRounded type="submit">
              Search Now
            </ButtonWithLoading>
          </Stack>
        </Box>
      </FormikForm>
    </ModalComponent>
  );
};

export default FormCompareCompany;
