import Lottie from 'react-lottie';
import { Stack, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/utils/constants/config';
import { AuthRouteKeys, NoAuthRouteKeys } from 'src/utils/constants/routes';
import LogisticIcon from 'src/asset/lottie/logistics.json';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!accessToken && !refreshToken) {
      navigate(NoAuthRouteKeys.Login);
    } else {
      navigate(AuthRouteKeys.Home);
    }
  };
  return (
    <Stack alignItems="center" spacing={2}>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: LogisticIcon,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={600}
        width={540}
      />
      <Typography variant="h2" sx={{ color: '#3658C1' }}>
        Oops ! 404 Not Found
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Sorry, the link you accessed does not exist
      </Typography>
      <ButtonWithLoading color="primary" variant="contained" size="large" onClick={handleBack}>
        <KeyboardArrowLeft color="inherit" />
        Back to home
      </ButtonWithLoading>
    </Stack>
  );
};

export default NotFoundPage;
