import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { StreetViewPanorama } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
// import { GoogleMap, LoadScript, useLoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { MapPin } from 'react-feather';

// Components
import IconFeather from 'src/components/common/Icon';
import ListImages from 'src/components/common/ListImages';
import GoogleMapComponent from 'src/components/GoogleMap/GoogleMap';
import ButtonContributeAddress from 'src/features/CompanyDetail/Addresses/ButtonContributeAddress';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';

// Utilities

const RegisteredAddress = ({
  address,
  placesService,
}: {
  address?: ICompanyAddress;
  placesService: google.maps.places.PlacesService | null;
}) => {
  const { handleOpenContribute } = useCompanyContribute((state) => state);
  const theme = useTheme();
  const [photos, setPhotos] = useState([] as { url: string }[]);

  const lat = address?.registered?.location?.lat;
  const lng = address?.registered?.location?.lng;
  const placeId = address?.registered?.place_id;

  useEffect(() => {
    if (!placeId || !placesService) {
      return;
    }
    placesService.getDetails(
      {
        placeId: placeId,
      },
      (placeDetails) => {
        if (placeDetails?.photos) {
          const state = placeDetails.photos.map((item: google.maps.places.PlacePhoto) => ({ url: item.getUrl() }));
          setPhotos(state);
        }
      },
    );
  }, [placeId, placesService]);

  return (
    <StyledWhiteBox>
      <Stack spacing={1}>
        <Typography variant="h5" align="left">
          Registered Address
        </Typography>
        <Typography variant="body2" color="text.secondary" align="left">
          <IconFeather
            icon={<MapPin />}
            muiProps={{
              color: 'error',
              sx: { mr: theme.spacing(0.5), width: theme.spacing(1.5), height: theme.spacing(1.5) },
            }}
          />
          {renderTextOrDefault(address?.registered?.address)}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={4}>
            {lat && lng ? (
              <GoogleMapComponent lat={lat} lng={lng} />
            ) : (
              <ButtonContributeAddress onClick={() => handleOpenContribute(TypeContribute.RegisteredAddressCompany)} />
            )}
          </Grid>
          <Grid item xs={6} lg={4}>
            {lat && lng ? (
              <GoogleMapComponent lat={lat} lng={lng}>
                <StreetViewPanorama
                  options={{
                    position: { lat: lat, lng: lng },
                    visible: true,
                  }}
                />
              </GoogleMapComponent>
            ) : (
              <ButtonContributeAddress onClick={() => handleOpenContribute(TypeContribute.RegisteredAddressCompany)} />
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="h6">Photo of this address</Typography>
            <ListImages
              listImg={photos}
              limit={10}
              onClickImgAction={() => handleOpenContribute(TypeContribute.RegisteredAddressCompany)}
            />
          </Grid>
        </Grid>
      </Stack>
    </StyledWhiteBox>
  );
};

export default RegisteredAddress;
