import { HomeWorkRounded, PeopleAltRounded, CompareRounded, BarChartRounded } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { CompanyStatus } from 'src/utils/constants/enum';

export enum TypeTabBookmark {
  CompanyProfile = 'Company Profile',
  PeopleProfile = 'People Profile',
  CompareCompany = 'Compare Company',
  TradeData = 'Trade Data',
}
export const TABS_BOOKMARK: ITabSetting[] = [
  {
    key: TypeTabBookmark.CompanyProfile,
    label: TypeTabBookmark.CompanyProfile,
    icon: HomeWorkRounded,
    disable: false,
  },
  {
    key: TypeTabBookmark.PeopleProfile,
    label: TypeTabBookmark.PeopleProfile,
    icon: PeopleAltRounded,
    disable: false,
  },
  {
    key: TypeTabBookmark.CompareCompany,
    label: TypeTabBookmark.CompareCompany,
    icon: CompareRounded,
    disable: false,
  },
  {
    key: TypeTabBookmark.TradeData,
    label: TypeTabBookmark.TradeData,
    icon: BarChartRounded,
    disable: true,
  },
];

// Common Style WhiteBox use in multiple  tab
export const StyledWhiteBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.background.default,
}));

export const STATUS_COMPANY = [
  { value: CompanyStatus.All, label: 'All Status' },
  { value: CompanyStatus.Active, label: 'Active' },
  { value: CompanyStatus.Closed, label: 'Closed' },
  { value: CompanyStatus.Suspended, label: 'Suspended' },
  { value: CompanyStatus.Unknown, label: 'Unknown' },
];

export enum ContactFilter {
  HaveContact = 'have_contact',
  NoContact = 'no_contact',
}

export const CONTACT_FILTER = [
  { value: ContactFilter.HaveContact, label: 'Has Contact' },
  { value: ContactFilter.NoContact, label: 'No Contact' },
];
