import { SvgIconProps } from '@mui/material';
import FacebookIcon from './Facebook';
import InstagramIcon from './Instagram';
import LinkedInIcon from './LinkedIn';
import MessengerIcon from './Messenger';
import SnapchatIcon from './Snapchat';
import TelegramIcon from './Telegram';
import TwitterIcon from './Twitter';
import WechatIcon from './Wechat';
import WhatsappIcon from './Whatsapp';
import YoutubeIcon from './Youtube';

export enum TypeSocialIcon {
  Facebook = 'facebook',
  Instagram = 'instagram',
  LinkedIn = 'linkedin',
  Messenger = 'messenger',
  Snapchat = 'snapchat',
  Telegram = 'telegram',
  Twitter = 'twitter',
  WeChat = 'wechat',
  WhatsApp = 'whatsapp',
  Youtube = 'youtube',
}

const PlatformIcon = ({ id, sx }: { id?: string; sx?: SvgIconProps }) => {
  const renderIcon = () => {
    switch (id) {
      case TypeSocialIcon.Facebook:
        return <FacebookIcon {...sx} />;
      case TypeSocialIcon.Instagram:
        return <InstagramIcon {...sx} />;
      case TypeSocialIcon.LinkedIn:
        return <LinkedInIcon {...sx} />;
      case TypeSocialIcon.Messenger:
        return <MessengerIcon {...sx} />;
      case TypeSocialIcon.Snapchat:
        return <SnapchatIcon {...sx} />;
      case TypeSocialIcon.Telegram:
        return <TelegramIcon {...sx} />;
      case TypeSocialIcon.Twitter:
        return <TwitterIcon {...sx} />;
      case TypeSocialIcon.WeChat:
        return <WechatIcon {...sx} />;
      case TypeSocialIcon.WhatsApp:
        return <WhatsappIcon {...sx} />;
      case TypeSocialIcon.Youtube:
        return <YoutubeIcon {...sx} />;
      default:
        return null;
    }
  };

  return renderIcon();
};

export default PlatformIcon;
