import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
//Utilities

//Component
import { MENUS_LOGIN_PAGE } from 'src/features/Login/Common/config';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import { BACKGROUND_LOGIN, LOGO_LOGIN_URL } from 'src/utils/constants/imagesUrl';

const RightSight = () => {
  const theme = useTheme();

  const renderLogo = <Box width={291} src={LOGO_LOGIN_URL} component="img" />;

  const renderBg = (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        mixBlendMode: 'lighten',
        width: '100%',
      }}
      src={BACKGROUND_LOGIN}
      component={'img'}
    ></Box>
  );

  const renderMenu = (
    <Stack sx={{ pt: 6 }} justifyContent={'center'} direction={'row'} gap={6}>
      {MENUS_LOGIN_PAGE.map((item: { text: string; path: string; target?: string }) => (
        <RouterLinkNoUnderline target={item.target || ''} key={item.path} to={item.path}>
          <Typography variant="subtitle1" color={theme.palette.common.white}>
            {item.text}
          </Typography>
        </RouterLinkNoUnderline>
      ))}
    </Stack>
  );

  const renderContentRight = (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: ' 25%',
      }}
      flexDirection={'column'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={6}
    >
      {renderLogo}

      <Box
        sx={{
          border: `2px solid ${theme.palette.background.paper1} `,
          display: 'flex',
          gap: 1,
          flexDirection: 'column',
          borderRadius: 1,
          textAlign: 'center',
        }}
        padding={2}
      >
        <Typography noWrap color={theme.palette.common.white} variant={'h6'}>
          Learn More About ADAMftd
        </Typography>
        <Typography color={theme.palette.common.white} variant="h4">
          ADAMftd.com
        </Typography>
      </Box>
    </Box>
  );
  return (
    <Paper
      sx={{
        background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
        borderRadius: '0px 0px 0px 300px',
        position: 'relative',
        minHeight: '100%',
      }}
    >
      {renderMenu}

      {renderContentRight}

      {renderBg}
    </Paper>
  );
};

export default RightSight;
