import MainLayout from 'src/layouts';
import Bookmark from 'src/features/Bookmark';
import { Helmet } from 'react-helmet';

const BookmarkPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Bookmark Page</title>
      </Helmet>
      <Bookmark />
    </MainLayout>
  );
};

export default BookmarkPage;
