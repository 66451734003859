/* eslint-disable no-unused-vars */
import {
  BarChartRounded,
  DirectionsBoatRounded,
  HomeWorkRounded,
  MonetizationOnRounded,
  OutputRounded,
  RequestQuoteRounded,
  RouteRounded,
  StorefrontRounded,
  TroubleshootRounded,
} from '@mui/icons-material';

export enum TypeTabTradeData {
  TradeData = 'Trade Data',
  MarketAnalytics = 'Market Analytics',
  MarketForecasts = 'Market Forecasts',
  RouteMarket = 'Route to Market',
  SupplierCountry = 'Supplier Country',
  BuyerCountry = 'Buyer Country',
  PortOrigin = 'Port of Origin',
  PortDelivery = 'Port of Delivery',
  Suppliers = 'Suppliers',
  Buyers = 'Buyers',
}

export const TABS_TRADE_DATA: ITabSetting[] = [
  {
    key: TypeTabTradeData.TradeData,
    label: TypeTabTradeData.TradeData,
    icon: TroubleshootRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.MarketAnalytics,
    label: TypeTabTradeData.MarketAnalytics,
    icon: BarChartRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.MarketForecasts,
    label: TypeTabTradeData.MarketForecasts,
    icon: RequestQuoteRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.RouteMarket,
    label: TypeTabTradeData.RouteMarket,
    icon: RouteRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.SupplierCountry,
    label: TypeTabTradeData.SupplierCountry,
    icon: OutputRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.BuyerCountry,
    label: TypeTabTradeData.BuyerCountry,
    icon: OutputRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.PortOrigin,
    label: TypeTabTradeData.PortOrigin,
    icon: HomeWorkRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.PortDelivery,
    label: TypeTabTradeData.PortDelivery,
    icon: DirectionsBoatRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.Suppliers,
    label: TypeTabTradeData.Suppliers,
    icon: StorefrontRounded,
    disable: false,
  },
  {
    key: TypeTabTradeData.Buyers,
    label: TypeTabTradeData.Buyers,
    icon: MonetizationOnRounded,
    disable: false,
  },
];
