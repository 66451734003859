import { Stack, Typography, useTheme } from '@mui/material';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import {
  COOKIES,
  LINK_SUPPORT,
  PRIVACY,
  PRODUCT_ROADMAP,
  SERVER_MONITORING,
  TERMS,
  MARKETING_PAGE,
} from 'src/utils/constants/config';

//Components
const MENUS_LOGIN_PAGE = [
  {
    text: 'Privacy',
    path: PRIVACY,
    target: '_blank',
  },
  {
    text: 'Terms',
    path: TERMS,
    target: '_blank',
  },
  {
    text: 'Cookies',
    path: COOKIES,
    target: '_blank',
  },
  {
    text: 'Support',
    path: LINK_SUPPORT,
    target: '_blank',
  },
  {
    text: 'Product Roadmap',
    path: PRODUCT_ROADMAP,
    target: '_blank',
  },
  {
    text: 'Server Monitoring',
    path: SERVER_MONITORING,
    target: '_blank',
  },
];

const Footer = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      mt={4}
      borderTop={`1px solid ${theme.palette.divider}`}
      pt={2}
      pb={2}
    >
      <Typography variant="caption2" color="text.secondary">
        <RouterLinkNoUnderline target="_blank" to={MARKETING_PAGE}>
          <Typography variant="caption2" color="text.secondary">
            © 2024 ADAMftd
          </Typography>
        </RouterLinkNoUnderline>{' '}
        - All Rights Reserved
      </Typography>
      <Stack direction="row" spacing={3}>
        {MENUS_LOGIN_PAGE.map((item: { text: string; path: string; target?: string }) => (
          <RouterLinkNoUnderline target={item.target || ''} key={item.path} to={item.path}>
            <Typography variant="caption2" color="text.secondary">
              {item.text}
            </Typography>
          </RouterLinkNoUnderline>
        ))}
      </Stack>
    </Stack>
  );
};

export default Footer;
