import CompanyLayout from 'src/pages/CompanyDetail/CompanyLayout';
import CompanyProfiles from 'src/features/CompanyProfiles';
import { Helmet } from 'react-helmet';

const CompanyProfilesPage = () => {
  return (
    <CompanyLayout>
      <Helmet>
        <title>Search Companies</title>
      </Helmet>
      <CompanyProfiles />
    </CompanyLayout>
  );
};

export default CompanyProfilesPage;
