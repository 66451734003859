import { Button, useTheme } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useSignupStore } from 'src/zustand/useSignupStore';

const ButtonBack = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();

  const { reset } = useSignupStore();

  return (
    <Button
      onClick={() => {
        reset();
        onClick();
      }}
      sx={{ borderRadius: theme.shape.borderRadius * 1000 }}
      color="inherit"
      variant="text"
      size="large"
    >
      <ArrowBackIosNewOutlinedIcon sx={{ mr: 1 }} fontSize="small" /> Back
    </Button>
  );
};

export default ButtonBack;
