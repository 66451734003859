import { Typography, Box } from '@mui/material';

// Components
import Table from 'src/components/Table';
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

const TechnologyResults = ({ selectedWebDetail }: { selectedWebDetail?: ICompanyWebDetail }) => {
  const listData = getArrayDefault(selectedWebDetail?.technical_results?.tech).map((tech, index) => ({
    key: `keyword-${index}`,
    name: tech.name,
    version: tech.version,
  }));

  const configColumn: ConfigCell<TechResult>[] = [
    {
      id: 'technology',
      disablePadding: true,
      label: 'Technology Results',
      cell: (row: TechResult) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.name || EmptyText}
          </Typography>
        );
      },
    },
    {
      id: 'version',
      disablePadding: true,
      label: 'Version',
      cell: (row: TechResult) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {row.version || EmptyText}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box padding={1}>
      <Table data={listData} configColumn={configColumn} dataKey="name" isPagination={false} />
    </Box>
  );
};

export default TechnologyResults;
