import { Container, Stack, styled } from '@mui/material';

// Components
import BreadcrumbWithBtnBack from 'src/components/BreadcrumbWithBtnBack';
import Buyer from 'src/features/TradeDataDetail/Buyer';
import TradeDataInformation from 'src/features/TradeDataDetail/Common/Information';
import Description from 'src/features/TradeDataDetail/Description';
import ExchangeRate from 'src/features/TradeDataDetail/ExchangeRate';
import MapAddress from 'src/features/TradeDataDetail/MapAddress';
import PackingInformation from 'src/features/TradeDataDetail/PackingInformation';
import Port from 'src/features/TradeDataDetail/Port';
import Shipper from 'src/features/TradeDataDetail/Shipper';
import Supplier from 'src/features/TradeDataDetail/Supplier';

// Utilities

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(7),
  marginTop: theme.spacing(1),
  textAlign: 'center',
}));

const TradeDataDetail = () => {
  const MOCKUP_BILL_OF_LANDING_DETAIL = {
    identification_number: 'MAEU720035747',
    hs_code: '8421390500',
    description:
      'Dairy produce; milk and cream, concentrated or containing added sugar or other sweetening matter, in powder, granules or other solid forms, of a fat content not exceeding 1.5% (by weight)',
    date_of_transaction: '02/02/2022',
    supplier: 'HWA HSIA GLASS CO LTD',
    buyer: 'VERITIV',
    supplier_address: 'NO. 49, PUU CHIEN ROAD, HSINCHU, TAIWAN',
    buyer_address: ' 9330 NW 110TH AVENUE, MIAMI, FL 33178, USA',
    exporting_country: 'Taiwan',
    importing_country: 'USA',
    country_of_origin: 'Taiwan',
    destination_country: 'USA',
    export_port: 'ENSENADA ENSENADA BAJA CALIFORNIA',
    import_port: 'ENSENADA ENSENADA BAJA CALIFORNIA',
    currency_transaction: 'LCU or USD',
    exchange_rate: '17.23',
    exchange_date: '02-02-2022T00:00:00Z',
    vessel_name: 'GUNDE MAERSK',
    shipper_company_name: 'MAERSK',
    shipper_address: 'NO. 49, PUU CHIEN ROAD, HSINCHU, TAIWAN',
    quantity: '60,480',
    unit_price: '60,480',
    pkg: '60,480',
    wgt: '15,582.0',
    value_usd: '39,453.63',
    freight_value: '39,453.63',
    insurance_value: '982.32',
    applied_tariffs: undefined,
    invoice_value: undefined,
    total_cost: '1,965,145.71',
    original_weight: 50000,
    original_unit: 20000,
  } as IBillOfLandingDetail;

  return (
    <StyledContainer maxWidth="xl">
      <Stack py={3} spacing={3}>
        <BreadcrumbWithBtnBack
          listItem={[
            {
              text: 'Trade Data',
            },
            {
              text: 'United State',
            },
            {
              text: 'MAEU720035747',
            },
          ]}
          updatedAt="03-08-2009T00:00:00Z"
        />
        <TradeDataInformation />
        <Description tradeDataDetail={MOCKUP_BILL_OF_LANDING_DETAIL} />
        <Buyer tradeDataDetail={MOCKUP_BILL_OF_LANDING_DETAIL} />
        <Supplier tradeDataDetail={MOCKUP_BILL_OF_LANDING_DETAIL} />
        <Shipper tradeDataDetail={MOCKUP_BILL_OF_LANDING_DETAIL} />
        <ExchangeRate tradeDataDetail={MOCKUP_BILL_OF_LANDING_DETAIL} />
        <PackingInformation tradeDataDetail={MOCKUP_BILL_OF_LANDING_DETAIL} />
        <Port tradeDataDetail={MOCKUP_BILL_OF_LANDING_DETAIL} />
        <MapAddress />
      </Stack>
    </StyledContainer>
  );
};

export default TradeDataDetail;
