import { Box, Button, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import ModalComponent from 'src/components/Modal/ModalComponent';

// Utilities
import DetectImage from 'src/asset/images/detect-image.svg';
import { USER_POLICY } from 'src/utils/constants/config';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const WarningSensitiveImage: React.FC<IProps> = ({ open, handleClose }) => {
  return (
    <ModalComponent closeOnBackdropClick={false} padding={4} open={open} handleClose={handleClose}>
      <Stack direction="column" spacing={2} width={400} alignItems="center">
        <Box src={DetectImage} component="img" width={200} />
        <Typography textAlign="center" variant="h5">
          Detect sensitive images
        </Typography>
        <Typography textAlign="center" variant="body2">
          Your posted images may contain sensitive content and appears to be in violation of our{' '}
          <Link sx={{ margin: 0 }} target="_blank" href={USER_POLICY}>
            user policies
          </Link>
          . Please choose another image to upload. Please note that repeatedly attempting to upload sensitive content
          will cause your account to be locked after the third attempt.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClose}>
          TRY AGAIN
        </Button>
      </Stack>
    </ModalComponent>
  );
};

export default WarningSensitiveImage;
