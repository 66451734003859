import { Stack, Typography } from '@mui/material';
import { get } from 'lodash';

// Components

import DataTable from 'src/features/CompanyDetail/Common/DataTable';
import { EmptyText, FORMAT_DATE_TIME } from 'src/utils/constants/config';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { formatUrlNonProtocol } from 'src/utils/helpers/stringHelpers';

export default function DataSources({ dataCompany }: { dataCompany: ICompanyDetail }) {
  return (
    <Stack spacing={1}>
      <Typography variant="h5" align="left">
        Data Sources
      </Typography>
      <DataTable
        listData={[
          {
            key: 'Data_Sources',
            title: 'Data Sources',
            value: get(dataCompany, 'data_source.data_source', EmptyText),
          },
          {
            key: 'Last_Retrieved',
            title: 'Last Retrieved',
            value: formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, dataCompany.data_source?.last_retrieved || ''),
          },
          {
            key: 'Link',
            title: 'Link',
            value: (
              <RouterLinkNoUnderline
                to={formatUrlNonProtocol(get(dataCompany, 'data_source.link', ''))}
                target="_blank"
              >
                {get(dataCompany, 'data_source.link', EmptyText)}
              </RouterLinkNoUnderline>
            ),
          },
        ]}
      />
    </Stack>
  );
}
