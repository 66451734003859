import { useRef, useState } from 'react';
import { useMutateCheckImageNudity, useMutateGetImageUrl, useMutateUploadAvatarUser } from 'src/services/mutate';
import { MAX_TIMES_RETRY_CHECK_IMAGES } from 'src/utils/constants/config';
import { ERROR_CODE, SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';

const useUploadImage = (props?: { imageDefault?: string }) => {
  const [isPending, setIsPending] = useState(false)
  const [isError, setIsError] = useState(false)
  const uploadImageMutation = useMutateUploadAvatarUser();
  const checkImageNudityMutation = useMutateCheckImageNudity();
  const { handleOpenSearch } = useFormSearch((state) => state);
  const getImageUrlMutation = useMutateGetImageUrl();
  const abortControllerRef = useRef<AbortController | null>(null);

  const onUploadImage = async (image?: File) => {
    abortControllerRef.current = new AbortController()
    if (!image) return
    setIsError(false)
    setIsPending(true)
    let imageURL: string | undefined = ""
    const resUploadImage = await uploadImageMutation.mutateAsync(image);
    const requestIdUploadImage = resUploadImage.data.request_id;
    const requestIdCheckNudity = await getRequestIdCheckNudity(requestIdUploadImage);
    if (requestIdCheckNudity) {
      imageURL = await getImageURL(requestIdCheckNudity);
      setIsPending(false)
      if (!imageURL) setIsError(true)
      if (abortControllerRef.current?.signal.aborted) return props?.imageDefault;
      return imageURL
    }
    setIsPending(false)
    if (!imageURL) setIsError(true)
    if (abortControllerRef.current?.signal.aborted) return props?.imageDefault;
    return imageURL
  };
  const getRequestIdCheckNudity = async (requestIdUploadImage: string) => {
    for (let attempt = 1; attempt <= MAX_TIMES_RETRY_CHECK_IMAGES; attempt++) {
      if (abortControllerRef.current?.signal.aborted) return;
      const responseCheckImageNudity = await checkImageNudityMutation.mutateAsync(requestIdUploadImage);

      if (responseCheckImageNudity.status_code === SUCCESS_CODE.GET) {
        return responseCheckImageNudity.data.request_id;
      } else if (responseCheckImageNudity.status_code === ERROR_CODE.FORBIDDEN) {
        handleOpenSearch(FormType.WarningSensitiveImage);
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds before retrying
    }
  };

  const getImageURL = async (requestIdCheckNudity: string) => {
    for (let attempt = 1; attempt <= MAX_TIMES_RETRY_CHECK_IMAGES; attempt++) {
      if (abortControllerRef.current?.signal.aborted) return;
      const responseImageUrl = await getImageUrlMutation.mutateAsync(requestIdCheckNudity);

      if (responseImageUrl.status_code === SUCCESS_CODE.GET) {
        return responseImageUrl.public_link;
      }

      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds before retrying
    }
  };

  const onCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Cancel all pending requests
      setIsPending(false);
    }
  };

  return {
    isPending, onUploadImage, onCancel, isError
  };
};

export default useUploadImage;
