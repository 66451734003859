import { IAccountType } from 'src/zustand/useOnboardingStore';

export const ACCOUNT_TYPES = [
  {
    label: 'Private Business includes self-employed',
    value: 'PrivateBussiness' as IAccountType,
  },
  {
    label: 'Government Agency',
    value: 'GovermentAgency' as IAccountType,
  },
  {
    label: 'Other',
    value: 'Other' as IAccountType,
  },
];

export const AGENCY_TYPES: { label: string; value: string }[] = [
  {
    label: 'Consulate or Embassy',
    value: '1',
  },
  {
    label: 'Export Promotion Agency',
    value: '2',
  },
  {
    label: 'Foreign Direct Investment Agency',
    value: '3',
  },
  {
    label: 'Ministry of Economy',
    value: '4',
  },
  {
    label: 'Ministry of Laboure',
    value: '5',
  },
  {
    label: 'Ministry (Other)',
    value: '6',
  },
  {
    label: 'Statistics Office',
    value: '7',
  },
];

export const ROLES: { label: string; value: string }[] = [
  {
    label: 'Chairman',
    value: 'Chairman',
  },
  {
    label: 'Export Promotion Agency',
    value: 'Export Promotion Agency',
  },
  {
    label: 'Director',
    value: 'Director',
  },
  {
    label: 'Manager',
    value: 'Manager',
  },
  {
    label: 'Team Leader',
    value: 'Team Leader',
  },
  {
    label: 'Team Member',
    value: 'Team Member',
  },
];
export const DEPARTMENTS: { label: string; value: string }[] = [
  {
    label: 'Human Resources (HR)',
    value: 'Human Resources (HR)',
  },
  {
    label: 'Finance',
    value: 'Finance',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Information Technology (IT)',
    value: 'Information Technology (IT)',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Customer Service',
    value: 'Customer Service',
  },
  {
    label: 'Operations',
    value: 'Operations',
  },
  {
    label: 'Research and Development (R&D)',
    value: 'Research and Development (R&D)',
  },
  {
    label: 'Administration',
    value: 'Administration',
  },
  {
    label: 'Legal',
    value: 'Legal',
  },
  {
    label: 'Procurement',
    value: 'Procurement',
  },
  {
    label: 'Quality Assurance',
    value: 'Quality Assurance',
  },
  {
    label: 'Public Relations (PR)',
    value: 'Public Relations (PR)',
  },
  {
    label: 'Supply Chain Management',
    value: 'Supply Chain Management',
  },
  {
    label: 'Project Management',
    value: 'Project Management',
  },
  {
    label: 'Facilities Management',
    value: 'Facilities Management',
  },
  {
    label: 'Compliance',
    value: 'Compliance',
  },
  {
    label: 'Business Development',
    value: 'Business Development',
  },
  {
    label: 'Communications',
    value: 'Communications',
  },
  {
    label: 'Training and Development',
    value: 'Training and Development',
  },
];

export const STEPS_LABEL = ['Get Started', 'Type Account', 'Information', 'Features'];

export const TYPE_FEATURE = {
  government: {
    identify_counterfeit_goods: {
      key: 'identify_counterfeit_goods',
      label: 'Identify counterfeit goods.',
    },
    investigate_market_trends: {
      key: 'investigate_market_trends',
      label: 'Investigate market trends',
    },
    map_a_supply_chain: {
      key: 'map_a_supply_chain',
      label: 'Map a supply chain',
    },
    source_new_suppliers: {
      key: 'source_new_suppliers',
      label: 'Source new suppliers',
    },
    predicting_future_demand: {
      key: 'predicting_future_demand',
      label: 'Predict future market demand for specific goods',
    },
    understand_taxes_and_tariffs_of_products: {
      key: 'understand_taxes_and_tariffs_of_products',
      label: 'Understand taxes and tariffs of products',
    },
    explore_supply_chain_disruptions: {
      key: 'explore_supply_chain_disruptions',
      label: 'Explore supply chain disruptions.',
    },
    ensure_compliance_with_trade_transparency_regulations: {
      key: 'ensure_compliance_with_trade_transparency_regulations',
      label: 'Ensure compliance with trade transparency regulations',
    },
    negative_media_monitoring: {
      key: 'negative_media_monitoring',
      label: 'Negative media monitoring',
    },
    obtain_company_contacts: {
      key: 'obtain_company_contacts',
      label: 'Obtain company contacts',
    },
  },
  company: {
    KYC_AML: {
      key: 'KYC_AML',
      label: 'KYC / AML',
    },
    find_new_customers: {
      key: 'find_new_customers',
      label: 'Find new customers',
    },
    source_new_suppliers: {
      key: 'source_new_suppliers',
      label: 'Source new suppliers',
    },
    understand_tariffs_and_taxes_for_my_product: {
      key: 'understand_tariffs_and_taxes_for_my_product(s)',
      label: 'Understand tariffs and taxes for my product(s)',
    },
    predicting_future_demand_for_my_product: {
      key: 'predicting_future_demand_for_my_product',
      label: 'Predict future demand for my product(s)',
    },
    mapping_my_supply_chain: {
      key: 'mapping_my_supply_chain',
      label: 'Mapping my supply chain',
    },
    ensuring_compliance_with_trade_transparency_regulations: {
      key: 'ensuring_compliance_with_trade_transparency_regulations',
      label: 'Ensuring compliance with trade transparency regulations',
    },
    negative_media_monitoring: {
      key: 'negative_media_monitoring',
      label: 'Negative Media Monitoring',
    },
    obtain_company_contacts: {
      key: 'obtain_company_contacts',
      label: 'Obtain company contacts',
    },
  },
};

export const LIST_TYPE_FOR_OTHERS = [
  'Association',
  'Chamber of Commerce / Industry',
  'Cooperative',
  'Commission',
  'Individual',
  'Foundation',
  'Non-Profit Organization',
  'Public Trust',
  'Public Utility',
  'Research Institute',
  'Social Enterprise',
  'Sole Trader',
  'Think Tank',
  'Union',
];

export const TYPE_STEP_INFORMATION = {
  government: {
    company_name: 'company_name',
    department: 'department',
    website_address: 'website_address',
    type_of_agency_working_for: 'type_of_agency_working_for',
    role: 'role',
    work_email: 'work_email',
    confirm_work_email: 'confirm_work_email',
  },
  company: {
    company_name: 'company_name',
    department: 'department',
    website_address: 'website_address',
    role: 'role',
    work_email: 'work_email',
    industry: 'industry',
    confirm_work_email: 'confirm_work_email',
  },
  other: {
    type_organization: 'type_organization',
  },
};
