import * as React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// Components
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import { styled, Typography, useTheme } from '@mui/material';

type TypeItem = {
  text: string;
  icon?: React.ReactNode;
  link?: string;
};

const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.text.disabled,
  },
}));

export default function Breadcrumbs({ listItem }: { listItem: TypeItem[] }) {
  const { palette } = useTheme();

  const renderLink = (item: TypeItem) => {
    return (
      <RouterLinkNoUnderline to={`${item.link}`}>
        {item.icon && item.icon}
        {item.text}
      </RouterLinkNoUnderline>
    );
  };

  const renderText = (item: TypeItem) => {
    return (
      <>
        {item.icon && item.icon}
        {item.text}
      </>
    );
  };

  const renderItem = (item: TypeItem) => {
    if (item.link) {
      return renderLink(item);
    } else {
      return renderText(item);
    }
  };

  return (
    <div role="presentation">
      <StyledMuiBreadcrumbs aria-label="breadcrumb">
        {listItem.map((item, i: number) => {
          const isLastItem = listItem.length - 1 === i;
          return (
            <Typography key={i} color={isLastItem ? palette.text.secondary : palette.text.disabled}>
              {renderItem(item)}
            </Typography>
          );
        })}
      </StyledMuiBreadcrumbs>
    </div>
  );
}
