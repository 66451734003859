import { Box, Container, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { isString } from 'lodash';

// Components
import TabComponent from 'src/components/Tab/TabComponent';
import { TypeTabBookmark, TABS_BOOKMARK } from 'src/features/Bookmark/config';
import CompanyProfileTab from 'src/features/Bookmark/CompanyProfile';
import PeopleProfileTab from 'src/features/Bookmark/PeopleProfile';
import CompareCompanyTab from 'src/features/Bookmark/CompareCompany';
import { useQueryParam } from 'src/hooks/useQueryParam';
import BreadcrumbWithBtnBack from 'src/components/BreadcrumbWithBtnBack';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(7),
}));

const BookmarkPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>(TypeTabBookmark.CompanyProfile);
  const tab = useQueryParam('tab');

  useEffect(() => {
    if (tab && isString(tab)) {
      setSelectedTab(tab);
    } else {
      setSelectedTab(TypeTabBookmark.CompanyProfile);
    }
  }, [tab]);

  const renderTab = () => {
    switch (selectedTab) {
      case TypeTabBookmark.CompanyProfile:
        return <CompanyProfileTab />;
      case TypeTabBookmark.PeopleProfile:
        return <PeopleProfileTab />;
      case TypeTabBookmark.CompareCompany:
        return <CompareCompanyTab />;
      default:
        return null;
    }
  };

  return (
    <StyledContainer maxWidth={'xl'}>
      <BreadcrumbWithBtnBack
        listItem={[
          {
            text: 'Home',
          },
          {
            text: 'Bookmark Page',
          },
        ]}
        updatedAt="14-01-2024T00:00:00Z"
        showButtonBack={false}
      />
      <Box mt={2} mb={3}>
        <TabComponent
          selected={selectedTab}
          handleChange={(_, e: string) => {
            setSelectedTab(e as TypeTabBookmark);
          }}
          tabs={TABS_BOOKMARK}
        />
      </Box>
      {renderTab()}
    </StyledContainer>
  );
};

export default BookmarkPage;
