import { Typography, Box, styled, Table, TableCell, TableRow, TableBody } from '@mui/material';

// Components
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import ExpandableBox from 'src/components/common/ExpandableBox';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatUrlNonProtocol } from 'src/utils/helpers/stringHelpers';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper2,
}));

const RegulatoryRequirement = ({ dataNtmMeasure }: { dataNtmMeasure: INtmMeasures }) => {
  return (
    <StyledWhiteBox spacing={3}>
      <Typography variant="h6">
        {dataNtmMeasure.MeasureSection}{' '}
        <Typography variant="h6" color="primary" component="span">
          ({dataNtmMeasure.MeasureTotalCount})
        </Typography>
      </Typography>
      {getArrayDefault(dataNtmMeasure.Measures).map((measure, index) => {
        const listDetailMeasure = dataNtmMeasure.AllMeasures.filter(
          (measureDetail) => measureDetail.Code === measure.MeasureCode,
        );
        return (
          <ExpandableBox
            key={`${index}-${measure.MeasureCode}`}
            title={`${measure.MeasureCode} - ${measure.MeasureTitle} (${measure.MeasureCount})`}
            openDefault={false}
            flex={1}
            gap={3}
          >
            {getArrayDefault(listDetailMeasure).map((measureDetail, index) => {
              return (
                <Box key={`${index}-${measureDetail.Code}`} padding={2} display="flex" flexDirection="column" gap={2}>
                  <Typography variant="subtitle1">
                    {index + 1}/ {measureDetail.Title}
                  </Typography>
                  <Table>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell component="th">
                          <Typography variant="subtitle2" color="text.secondary">
                            Legislation Title
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            {measureDetail.LegislationTitle}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th">
                          <Typography variant="subtitle2" color="text.secondary">
                            Legislation Summary
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            {measureDetail.LegislationSummary}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th">
                          <Typography variant="subtitle2" color="text.secondary">
                            Implementation Authority
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            {measureDetail.ImplementationAuthority}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th">
                          <Typography variant="subtitle2" color="text.secondary">
                            Validity
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            From {measureDetail.StartDate}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th">
                          <Typography variant="subtitle2" color="text.secondary">
                            Other Countries Affected
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            {measureDetail.AdditionalCommentCountry}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th">
                          <Typography variant="subtitle2" color="text.secondary">
                            Link
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <RouterLinkNoUnderline to={formatUrlNonProtocol(measureDetail.WebLink)} target="_blank">
                            <Typography variant="body2" color="secondary">
                              URL
                            </Typography>
                          </RouterLinkNoUnderline>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </Box>
              );
            })}
          </ExpandableBox>
        );
      })}
    </StyledWhiteBox>
  );
};

export default RegulatoryRequirement;
