import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { alpha, Stack, styled, Typography, useTheme } from '@mui/material';
import { startCase } from 'lodash';
import { UserIcon } from 'src/components/Icons';
import PlatformIcon from 'src/components/Icons/PlatformIcon';
import Table from 'src/components/Table';
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { formatUrlNonProtocol } from 'src/utils/helpers/stringHelpers';

const StyledIcon = styled(UserIcon)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.07),
  color: theme.palette.primary.light,
  padding: theme.spacing(0.5),
  borderRadius: '100%',
  fontSize: 22,
}));

const StyledIconCheck = styled(CheckOutlinedIcon)(({ theme }) => ({
  borderRadius: '100%',
  fontSize: 22,
  padding: theme.spacing(0.5),
  background: alpha(theme.palette.success.dark, 0.07),
  color: theme.palette.success.main,
}));

const StyledIconClose = styled(CloseOutlinedIcon)(({ theme }) => ({
  borderRadius: '100%',
  fontSize: 22,
  padding: theme.spacing(0.5),
  background: alpha(theme.palette.error.dark, 0.07),
  color: theme.palette.error.main,
}));

const StyledLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.secondary.main,
}));

const SocialMediaChat = ({ title, data }: { title: string; data: ISocialMediaItem[] }) => {
  const theme = useTheme();
  const LENGTH_ITEMS_PER_ROW = 1;

  let reformatData: Array<ISocialMediaData> = getArrayDefault(data);
  // Get total number item need to show
  reformatData = Array(reformatData.length < LENGTH_ITEMS_PER_ROW ? LENGTH_ITEMS_PER_ROW : reformatData.length)
    .fill('')
    .map((_, index) =>
      reformatData[index]
        ? { ...reformatData[index], available: true }
        : { name: null, type: null, url: null, available: false },
    );

  const configColumn: ConfigCell<ISocialMediaData>[] = [
    {
      disablePadding: false,
      id: 'name',
      label: 'Account',
      cell: (data: ISocialMediaData) => (
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          <StyledIcon />
          <Typography color={theme.palette.text.secondary} variant="body2">
            {data.name || EmptyText}
          </Typography>
        </Stack>
      ),
    },
    {
      disablePadding: false,
      id: 'type',
      label: 'Platform',
      cell: (data: ISocialMediaData) => (
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          <PlatformIcon id={data.type || ''} sx={{ fontSize: 'large' }} />
          <Typography color={theme.palette.text.secondary} variant="body2">
            {startCase(data?.type || '') || EmptyText}
          </Typography>
        </Stack>
      ),
    },
    {
      disablePadding: false,
      id: 'available',
      label: 'Available',
      cell: (data: ISocialMediaData) => (data.available ? <StyledIconCheck /> : <StyledIconClose />),
    },
    {
      disablePadding: false,
      id: 'url',
      label: 'URL Detail',
      cell: (data: ISocialMediaData) =>
        data.url ? (
          <StyledLink target="_blank" href={formatUrlNonProtocol(data.url)}>
            {formatUrlNonProtocol(data.url)}
          </StyledLink>
        ) : (
          <Typography variant="body2">{EmptyText}</Typography>
        ),
    },
  ];

  return (
    <Stack spacing={1}>
      <Typography variant="h5" align="left">
        {title}
      </Typography>
      <Table data={reformatData} configColumn={configColumn} dataKey="name" isPagination={false} />
    </Stack>
  );
};

export default SocialMediaChat;
