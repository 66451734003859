//SERVICE
export const USER_SERVICE = '/user-service/';
export const DATA_SERVICE = '/data-service/';

//ENDPOINT URL
export const URL_ENDPOINT = {
  user: {
    users: USER_SERVICE + 'users/',
    utils: USER_SERVICE + 'utils/',
  },
  company: {
    commons: DATA_SERVICE + 'commons/',
    companies: DATA_SERVICE + 'companies/',
    tariffs: DATA_SERVICE + 'tariffs/',
    utils: DATA_SERVICE + 'utils/',
  },
  people: {
    people: DATA_SERVICE + 'people/',
  },
};
