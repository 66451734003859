export const MOCKUP_BILL_OF_LANDING_DETAIL = {
  identification_number: 'MAEU720035747',
  hs_code: '8421390500',
  description:
    'Dairy produce; milk and cream, concentrated or containing added sugar or other sweetening matter, in powder, granules or other solid forms, of a fat content not exceeding 1.5% (by weight)',
  date_of_transaction: '02/02/2022',
  supplier: 'HWA HSIA GLASS CO LTD',
  buyer: 'VERITIV',
  supplier_address: 'NO. 49, PUU CHIEN ROAD, HSINCHU, TAIWAN',
  buyer_address: ' 9330 NW 110TH AVENUE, MIAMI, FL 33178, USA',
  exporting_country: 'Taiwan',
  importing_country: 'USA',
  country_of_origin: 'Taiwan',
  destination_country: 'USA',
  export_port: 'ENSENADA ENSENADA BAJA CALIFORNIA',
  import_port: 'ENSENADA ENSENADA BAJA CALIFORNIA',
  currency_transaction: 'LCU or USD',
  exchange_rate: '17.23',
  vessel_name: 'GUNDE MAERSK',
  shipper_company_name: 'MAERSK',
  quantity: '60,480',
  unit_price: '60,480',
  pkg: '60,480',
  wgt: '15,582.0',
  value_usd: '39,453.63',
  freight_value: '39,453.63',
  insurance_value: '982.32',
  applied_tariffs: undefined,
  invoice_value: undefined,
  total_cost: '1,965,145.71',
} as IBillOfLandingDetail;
