import { useState } from 'react';
import { Box, Chip, Grid, Pagination, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { AccessTimeOutlined, AddCircleOutlineOutlined } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

// Utilities
import LogoCompany from 'src/components/common/LogoCompany';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';
import { EmptyText, LIMIT_GET_LIST_PEOPLE } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { useQueryListRelatedCompanies } from 'src/services/queries';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import { CompanyStatusCode } from 'src/utils/constants/enum';
import { getColorCompanyStatus } from 'src/utils/helpers/stringHelpers';

export default function RelatedCorporate() {
  const { id } = useParams();
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const { data, isFetching } = useQueryListRelatedCompanies(id || '', {
    limit: LIMIT_GET_LIST_PEOPLE,
    offset: page - 1,
  });
  const { handleOpenContribute } = useCompanyContribute((state) => state);
  const listRelatedCompanies = getArrayDefault(data?.data);

  if (isFetching) {
    return (
      <StyledWhiteBox>
        <LoadingSkeleton count={5} height={50} />
      </StyledWhiteBox>
    );
  }

  if (isEmpty(listRelatedCompanies)) {
    return <></>;
  }

  return (
    <StyledWhiteBox>
      <Typography variant="h5" align="left">
        Related Corporates (Common Directorship)
      </Typography>
      <Grid container spacing={2} mt={3}>
        {listRelatedCompanies.map((company, index) => {
          if (Object.keys(company).length == 0) {
            return (
              <Grid key={`related-company-empty-${index}`} item xs={4} md={3}>
                <Tooltip title="Contribute data">
                  <Stack
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: '100%',
                      borderRadius: theme.shape.borderRadius,
                      border: '1px solid #D9D9F9',
                      background: theme.palette.background.paper1,
                      cursor: 'pointer',
                      height: '100%',
                      minHeight: 150,
                    }}
                    onClick={() => handleOpenContribute(TypeContribute.RelatedCorporate)}
                  >
                    <AddCircleOutlineOutlined color="primary" />
                  </Stack>
                </Tooltip>
              </Grid>
            );
          }
          return (
            <Grid key={`related-company-${company.id}`} item xs={4} md={3}>
              <Box
                p={2}
                borderRadius={theme.shape.borderRadius / 2}
                border={`1px solid ${theme.palette.divider}`}
                textAlign="left"
                height="100%"
                flexDirection="column"
                width="100%"
                display="flex"
                flex={1}
              >
                <LogoCompany logoURL={company?.logo_url || ''} width={64} height={64} />
                <Stack spacing={1} direction="row" alignItems="center" mb={1}>
                  <RouterLinkNoUnderline to={AuthRouteKeys.CompanyDetail.replace(':id?', company?.id || '')}>
                    <Typography color="secondary" variant="subtitle2" align="left">
                      {company?.name || EmptyText}
                    </Typography>
                  </RouterLinkNoUnderline>
                  <Chip
                    // color={company?.status === 'Active' ? 'success' : 'warning'}
                    color={getColorCompanyStatus(company?.status_code ?? CompanyStatusCode.Unknown)}
                    label={company?.status || 'Paused'}
                  />
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center" mb={1}>
                  <AccessTimeOutlined fontSize="small" />
                  <Typography variant="caption2" color="text.secondary">
                    12 years 9 months
                  </Typography>
                </Stack>
                <Stack spacing={1} maxHeight={115} height="inherit" overflow="auto">
                  {getArrayDefault(company?.staffs).map((staff, index) => (
                    <Typography
                      key={`staff-related-company-${index}-${staff.representative_id}`}
                      align="left"
                      variant="caption2"
                      component="p"
                    >
                      <Typography color="secondary.main" variant="caption2" component="span">
                        {staff?.representative_name || EmptyText}
                      </Typography>{' '}
                      is a {staff?.representative_role || EmptyText}
                    </Typography>
                  ))}
                </Stack>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Stack mt={2} alignItems="center">
        <Pagination
          page={page}
          count={data.total_pages}
          onChange={function (_, value: number): void {
            setPage(value);
          }}
          color="primary"
          shape="rounded"
        />
      </Stack>
    </StyledWhiteBox>
  );
}
