import { Box, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.palette.background.paper,
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
  borderRadius: theme.shape.borderRadius * 2,
}));

interface IProps {
  title: ReactNode;
  children: ReactNode;
}

const PeopleDetailCardContent: React.FC<IProps> = ({ title, children }) => {
  return (
    <StyledBox>
      <Typography variant="h6">{title}</Typography>
      <Box mt={2} />
      {children}
    </StyledBox>
  );
};

export default PeopleDetailCardContent;
