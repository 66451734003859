import { useTheme, Stack, Typography, Box } from '@mui/material';
import { Calendar } from 'react-feather';
import { OpenInNew } from '@mui/icons-material';
import { truncate } from 'lodash';
import React from 'react';

// Components
import ModalComponent from 'src/components/Modal/ModalComponent';
import IconFeather from 'src/components/common/Icon';

// Utilities
import { FORMAT_DATE_TIME } from 'src/utils/constants/config';
import { formatUrlNonProtocol, renderTextOrDefault } from 'src/utils/helpers/stringHelpers';
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';

interface IProps {
  story: INegativeMedia | null;
  open: boolean;
  handleClose: () => void;
}

const PopupNegativeStory: React.FC<IProps> = ({ story, open, handleClose }) => {
  const theme = useTheme();

  return (
    <ModalComponent padding={4} open={open} handleClose={handleClose}>
      <Stack direction="column" spacing={2} width={300}>
        {story?.logo_news ? (
          <Box component={'img'} width={70} height={24} src={story?.logo_news || ''} />
        ) : (
          <Typography variant="subtitle2" color="secondary">
            {(story?.site || '').toUpperCase()}
          </Typography>
        )}
        <Typography variant="subtitle1">{story?.title}</Typography>
        <Stack spacing={0.5} direction="row" alignItems="center">
          <IconFeather icon={<Calendar />} muiProps={{ sx: { color: theme.palette.text.secondary, fontSize: 16 } }} />
          <Typography variant="caption" align="left" color="text.secondary">
            {formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, story?.extracted_at)}
          </Typography>
        </Stack>
        <Typography variant="subtitle2" color="text.secondary" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {truncate(`${renderTextOrDefault(story?.text)}`, {
            length: 600,
          })}
        </Typography>
        <RouterLinkNoUnderline to={formatUrlNonProtocol(story?.url || '')} target="_blank">
          <Stack spacing={0.5} direction="row">
            <Typography ml={1} variant="body2" color="primary.main">
              SOURCE LINK
            </Typography>
            <OpenInNew fontSize="small" color="primary" />
          </Stack>
        </RouterLinkNoUnderline>
      </Stack>
    </ModalComponent>
  );
};

export default PopupNegativeStory;
