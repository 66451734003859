/* eslint-disable no-useless-escape */
import { TypeSocialProfile } from 'src/utils/constants/enum';
import EMAIL_DOMAIN_BLOCK from 'src/utils/constants/emailDomainBlock';
import WEBSITE_DOMAIN_VALID from 'src/utils/constants/websiteDomainValid';
import BAD_WORDS from 'src/utils/constants/badWord';
import {
  LIST_DOMAIN_FACEBOOK,
  LIST_DOMAIN_TWITTER,
  LIST_DOMAIN_LINKEDIN,
  LIST_DOMAIN_INSTAGRAM,
  LIST_DOMAIN_YOUTUBE,
} from 'src/utils/constants/domainList';

export const validateProfileURL = (url: string, type: TypeSocialProfile) => {
  if (url === '') {
    return true;
  }

  let pattern;

  // Facebook
  const domainPatternFacebook = LIST_DOMAIN_FACEBOOK.map((domain) => domain.replace('.', '\\.')).join('|');
  const facebookPattern = new RegExp(`^(https?:\\/\\/)?((www|m)\\.)?(${domainPatternFacebook})`, 'i');

  // Twitter
  const domainPatternTwitter = LIST_DOMAIN_TWITTER.map((domain) => domain.replace('.', '\\.')).join('|');
  const twitterPattern = new RegExp(`^(https?:\\/\\/)?((www|m)\\.)?(${domainPatternTwitter})`, 'i');

  // LinkedIn
  const domainPatternLinkedIn = LIST_DOMAIN_LINKEDIN.map((domain) => domain.replace('.', '\\.')).join('|');
  const linkedInPattern = new RegExp(`^(https?:\\/\\/)?((www|m)\\.)?(${domainPatternLinkedIn})`, 'i');

  // Instagram
  const domainPatternInstagram = LIST_DOMAIN_INSTAGRAM.map((domain) => domain.replace('.', '\\.')).join('|');
  const instagramPattern = new RegExp(`^(https?:\\/\\/)?((www|m)\\.)?(${domainPatternInstagram})`, 'i');

  // Youtube
  const domainPatternYoutube = LIST_DOMAIN_YOUTUBE.map((domain) => domain.replace('.', '\\.')).join('|');
  const youtubePattern = new RegExp(`^(https?:\\/\\/)?((www|m)\\.)?(${domainPatternYoutube})`, 'i');

  if (type === TypeSocialProfile.Facebook) {
    pattern = facebookPattern;
  } else if (type === TypeSocialProfile.Twitter) {
    pattern = twitterPattern;
  } else if (type === TypeSocialProfile.Linkedin) {
    pattern = linkedInPattern;
  } else if (type === TypeSocialProfile.Instagram) {
    pattern = instagramPattern;
  } else if (type === TypeSocialProfile.Youtube) {
    pattern = youtubePattern;
  }

  if (!pattern) {
    return false;
  }

  return pattern.test(url);
};

export const validateEmail = (email?: string) => {
  if (!email) return true;
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};

export const validateEmailBlocked = (email: string) => {
  if (!email) return true;
  let flag = true;
  EMAIL_DOMAIN_BLOCK.forEach((domain) => {
    if (email.includes(domain)) {
      flag = false;
    }
  });
  return flag;
};

export const validateURL = (domain = '') => {
  if (!domain) return true;
  const pattern = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)$/;
  return pattern.test(domain);
};

export const validateWebsiteDomainValid = (url: string) => {
  if (!url) return true;
  let flag = false;
  WEBSITE_DOMAIN_VALID.forEach((domain) => {
    if (url.indexOf('.' + domain) > -1) {
      flag = true;
    }
  });
  return flag;
};

export function countOccurrences(str: string, value: string) {
  const regExp = new RegExp(value, 'gi');
  return (str.match(regExp) || []).length;
}

export const validatePassword = (password: string) => {
  return [
    {
      title: 'At least 12 characters',
      valid: password.length >= 12,
    },
    {
      title: 'Uppercase',
      valid: /(?=.*?[A-Z]).*/.test(password),
    },
    {
      title: 'Lowercase',
      valid: /(?=.*?[a-z]).*/.test(password),
    },
    {
      title: 'Numbers',
      valid: /(?=.*?\d).*/.test(password),
    },
    {
      title: 'Symbols',
      valid: /(?=.*?\W).*/.test(password),
    },
  ];
};

export const checkBadWords = (text: string) => {
  const textLower = text.toLowerCase();

  for (const badWord of BAD_WORDS) {
    if (textLower.includes(badWord)) {
      return false;
    }
  }

  return true;
};
