import { useQuery } from '@tanstack/react-query';
import { DeviceManagers, User } from 'src/model/auth';
import { AuthService } from 'src/services/api';

export const ME_KEY = 'me';
const QUERY_KEY_CHECK_EMAIL_EXIST = 'QUERY_KEY_CHECK_EMAIL_EXIST';
const QUERY_KEY_DEVICE_MANGER = 'QUERY_KEY_DEVICE_MANGER';

export const useQueryUserInfo = () => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<User, Error>({
    queryKey: [ME_KEY],
    queryFn: AuthService.getUserInfo,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new User(),
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    refetch,
  };
};

export const useQueryCheckEmailExist = (email?: string) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_CHECK_EMAIL_EXIST, email ?? ''],
    queryFn: () => AuthService.checkEmailExists({ email: email ?? '' }),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!email,
    initialData: {
      message: '',
      status_code: 0,
      warning: null,
    },
  });
  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryDeviceManger = (params: Record<string, string | number>) => {
  const { isFetching, data, refetch } = useQuery({
    queryKey: [QUERY_KEY_DEVICE_MANGER, params],
    queryFn: () => AuthService.getDeviceMangers(params),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new DeviceManagers(),
  });
  return {
    isFetching,
    data,
    refetch,
  };
};
