import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WhatsappIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 19.3749C14.8325 19.3749 18.75 15.4574 18.75 10.6249C18.75 5.79245 14.8325 1.87494 10 1.87494C5.16751 1.87494 1.25 5.79245 1.25 10.6249C1.25 12.1942 1.66312 13.667 2.38655 14.9405L1.25 19.3749L5.82179 18.3148C7.06336 18.9908 8.48682 19.3749 10 19.3749ZM10 18.0288C14.089 18.0288 17.4038 14.714 17.4038 10.6249C17.4038 6.53591 14.089 3.22109 10 3.22109C5.91097 3.22109 2.59615 6.53591 2.59615 10.6249C2.59615 12.2037 3.09031 13.6671 3.9324 14.8688L3.26923 17.3557L5.79996 16.723C6.99335 17.5465 8.44036 18.0288 10 18.0288Z"
          fill="#BFC8D0"
        />
        <path
          d="M17.5 9.99994C17.5 14.1421 14.1421 17.4999 10 17.4999C8.4201 17.4999 6.9543 17.0114 5.74541 16.1772L3.18182 16.8181L3.8536 14.2989C3.00058 13.0816 2.5 11.5992 2.5 9.99994C2.5 5.8578 5.85786 2.49994 10 2.49994C14.1421 2.49994 17.5 5.8578 17.5 9.99994Z"
          fill="url(#paint0_linear_434_17389)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18.7499C14.8325 18.7499 18.75 14.8324 18.75 9.99994C18.75 5.16745 14.8325 1.24994 10 1.24994C5.16751 1.24994 1.25 5.16745 1.25 9.99994C1.25 11.5692 1.66312 13.042 2.38655 14.3155L1.25 18.7499L5.82179 17.6898C7.06336 18.3658 8.48682 18.7499 10 18.7499ZM10 17.4038C14.089 17.4038 17.4038 14.089 17.4038 9.99994C17.4038 5.91091 14.089 2.59609 10 2.59609C5.91097 2.59609 2.59615 5.91091 2.59615 9.99994C2.59615 11.5787 3.09031 13.0421 3.9324 14.2438L3.26923 16.7307L5.79996 16.098C6.99335 16.9215 8.44036 17.4038 10 17.4038Z"
          fill="white"
        />
        <path
          d="M7.81251 5.93749C7.60447 5.51963 7.28533 5.55662 6.96292 5.55662C6.38673 5.55662 5.48828 6.2468 5.48828 7.53128C5.48828 8.58399 5.95216 9.73633 7.51526 11.4601C9.02378 13.1237 11.0059 13.9843 12.6514 13.955C14.2969 13.9257 14.6354 12.5097 14.6354 12.0315C14.6354 11.8196 14.5039 11.7138 14.4133 11.6851C13.8525 11.416 12.8183 10.9145 12.583 10.8203C12.3477 10.7261 12.2248 10.8535 12.1484 10.9229C11.935 11.1262 11.512 11.7255 11.3672 11.8604C11.2224 11.9952 11.0064 11.9269 10.9166 11.876C10.5859 11.7433 9.68933 11.3445 8.97467 10.6517C8.09083 9.79492 8.03896 9.50015 7.87244 9.23775C7.73922 9.02784 7.83698 8.89905 7.88576 8.84276C8.07619 8.62304 8.33913 8.2838 8.45705 8.11523C8.57496 7.94665 8.48135 7.6907 8.42518 7.53128C8.18361 6.84569 7.97895 6.27178 7.81251 5.93749Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_434_17389"
            x1="16.5625"
            y1="4.37494"
            x2="2.5"
            y2="17.4999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5BD066" />
            <stop offset="1" stopColor="#27B43E" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default WhatsappIcon;
