import { Box, ClickAwayListener, Popper, useTheme } from '@mui/material';
import FormItemPhoneNumber from 'src/components/Form/FormItemPhoneNumber';

const PopupSelectPhoneCode = ({
  open,
  handleClose,
  id,
  disablePortal = false,
  anchorEl,
}: {
  open: boolean;
  handleClose: () => void;
  id: string;
  disablePortal?: boolean;
  anchorEl: HTMLElement | null;
}) => {
  const theme = useTheme();

  return (
    <Popper anchorEl={anchorEl} id="placement-popper" open={open} placement={'bottom'} sx={{ zIndex: 9999 }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box
          sx={{
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius / 2,
          }}
          width={400}
        >
          <FormItemPhoneNumber autoFocus id={id} callback={handleClose} disablePortal={disablePortal} />
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};

export default PopupSelectPhoneCode;
