import { Avatar, Box, Button, Chip, Container, Grid, Stack, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// Components
import { get } from 'lodash';
import AIIcon from 'src/asset/lottie/AI.json';
import DashboardTradeIcon from 'src/asset/lottie/dashboard-trade.json';
import LogisticIcon from 'src/asset/lottie/logistics.json';
import MediaSearch from 'src/asset/lottie/media-search.json';
import ThinkingIcon from 'src/asset/lottie/thinking.json';
import Tracking from 'src/asset/lottie/tracking.json';
import CardSearchItem from 'src/features/Home/Common/CardSearchItem';

// Utilities
import { Download } from 'react-feather';
import { useTranslation } from 'react-i18next';
import NetImage from 'src/asset/images/net-border.png';
import IconFeather from 'src/components/common/Icon';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import WavingHandIcon from 'src/components/Icons/WavingHand';
import { useQueryUserInfo } from 'src/services/queries';
import { LINK_DOWNLOAD_BROCHURE, LINK_SUPPORT, UnknownText } from 'src/utils/constants/config';
import { BANNER_NOTIFY_HOME_PAGE, ICON_BANNER_HOME_PAGE } from 'src/utils/constants/imagesUrl';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';
import PopupVideo from 'src/features/Home/Common/PopupVideo';
import { RULES_AND_REGULATION, COMPANY_INFORMATION } from 'src/utils/constants/videosUrl';

const Blur = styled(Box)(() => ({
  width: 400,
  height: 268,
  position: 'absolute',
  background: 'linear-gradient(131deg, rgba(255, 106, 196, 0.30) 15.21%, rgba(0, 55, 251, 0.09) 85.28%)',
  borderRadius: '50%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  filter: 'blur(93.60465240478516px)',
}));

const Banner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  background: ' linear-gradient(91deg, #422AFB 2.03%, #5451F8 98.7%)',
  borderRadius: theme.shape.borderRadius * 2,
  zIndex: 1000,
  position: 'relative',
  padding: theme.spacing(1.5),
  gap: theme.spacing(4),
}));

const Home = () => {
  const { handleOpenSearch } = useFormSearch((state) => state);
  const [openVideo, setOpenVideo] = useState({ isOpen: false, videoUrl: '', titleVideo: '' });
  const { data } = useQueryUserInfo();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenLearnMore = ({ videoUrl, titleVideo }: { videoUrl: string; titleVideo: string }) => {
    setOpenVideo({
      isOpen: true,
      videoUrl: videoUrl,
      titleVideo: titleVideo,
    });
  };

  return (
    <Container maxWidth="xl" sx={{ textAlign: 'center', py: 5, position: 'relative' }}>
      <PopupVideo
        open={openVideo.isOpen}
        handleClose={() => setOpenVideo({ isOpen: false, videoUrl: '', titleVideo: '' })}
        videoUrl={openVideo.videoUrl || ''}
        title={openVideo.titleVideo || ''}
      />
      <Box mx={'auto'} mb={4} height={150} position={'relative'} maxWidth={600}>
        <Blur />
        <Box width={'100%'} component={'img'} src={NetImage} />
        <Stack left={'20%'} top={'10%'} zIndex={20} position={'absolute'} alignItems={'center'} gap={2}>
          <Avatar
            alt={data.data?.alt}
            src={data?.data?.avatar_url}
            sizes="100"
            sx={{ width: 100, height: 100, border: '2px solid #fff' }}
          >
            {(data?.data?.first_name || UnknownText).charAt(0).toUpperCase()}
          </Avatar>

          <Typography variant="h6">
            {t('home.welcome.message')}, {`${get(data, 'data.first_name', '')} ${get(data, 'data.last_name', '')}`}{' '}
            <WavingHandIcon />
          </Typography>
        </Stack>
      </Box>
      <Banner>
        <Box
          component="img"
          height="150px"
          src={BANNER_NOTIFY_HOME_PAGE}
          sx={{ objectFit: 'cover', display: 'inline-block' }}
        />
        <Box
          component="img"
          height="100%"
          src={ICON_BANNER_HOME_PAGE}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        />
        <Stack justifyContent="center" flex={4} gap={1}>
          <Typography variant="h5" color="white" align="left">
            Limited Company Data Jurisdictions - More Coming Weekly
          </Typography>
          <Typography variant="body1" color="white" align="left">
            Please note that during our initial roll-out plan we are offering company and people data for only limited
            set of jurisdictions. Please download our brochure for a complete list.
          </Typography>
        </Stack>
        <Stack justifyContent="center" flex={1}>
          <RouterLinkNoUnderline to={LINK_DOWNLOAD_BROCHURE} target="_blank">
            <Button variant="outlined" color="inheritWhite" startIcon={<IconFeather icon={<Download />} />}>
              Download BROCHURE
            </Button>
          </RouterLinkNoUnderline>
        </Stack>
      </Banner>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={4}>
          <CardSearchItem
            tittle={
              <Stack direction={'row'} gap={1}>
                <Typography variant="subtitle1" align="left" fontWeight={700}>
                  {t('home.app.tariffs_and_rules.title')}
                </Typography>
                <Chip color="success" label="Available Now" size="small" />
              </Stack>
            }
            content={t('home.app.tariffs_and_rules.description')}
            textButton={t('home.app.tariffs_and_rules.button.title')}
            iconLottie={Tracking}
            onClickLearnMore={() =>
              handleOpenLearnMore({
                videoUrl: RULES_AND_REGULATION,
                titleVideo: 'ADAMftd : Rules and Regulations Features',
              })
            }
            onClick={() => navigate(AuthRouteKeys.TariffsAndRules)}
          />
        </Grid>
        <Grid item xs={4}>
          <CardSearchItem
            tittle={
              <Stack direction={'row'} gap={1}>
                <Typography variant="subtitle1" align="left" fontWeight={700}>
                  {t('home.app.company.title')}
                </Typography>
                <Chip color="success" label="Beta Version" size="small" />
              </Stack>
            }
            content={t('home.app.company.description')}
            textButton={t('home.app.company.button.title')}
            iconLottie={LogisticIcon}
            onClickLearnMore={() =>
              handleOpenLearnMore({
                videoUrl: COMPANY_INFORMATION,
                titleVideo: 'ADAMftd Company KYC / Company Due Diligence Features',
              })
            }
            onClick={() => {
              handleOpenSearch(FormType.SearchCompany);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CardSearchItem
            tittle={
              <Stack direction={'row'} gap={1}>
                <Typography variant="subtitle1" align="left" fontWeight={700}>
                  {t('home.app.people.title')}
                  <Chip color="success" label="Beta Version" size="small" />
                </Typography>
              </Stack>
            }
            content={t('home.app.people.description')}
            textButton={t('home.app.people.button.title')}
            iconLottie={ThinkingIcon}
            onClick={() => {
              handleOpenSearch(FormType.SearchPeople);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CardSearchItem
            tittle={
              <Stack direction={'row'} gap={1}>
                <Typography variant="subtitle1" align="left" fontWeight={700}>
                  {t('home.app.compare_companies.title')}
                </Typography>
                <Chip color="success" label="Available Now" size="small" />
              </Stack>
            }
            content={t('home.app.compare_companies.description')}
            textButton={t('home.app.compare_companies.button.title')}
            iconLottie={AIIcon}
            onClick={() => handleOpenSearch(FormType.CompareCompany)}
          />
        </Grid>
        <Grid item xs={4}>
          <CardSearchItem
            tittle={
              <Stack direction={'row'} gap={1}>
                <Typography variant="subtitle1" align="left" fontWeight={700}>
                  {t('home.app.trade_data.title')}
                </Typography>
                <Chip color="info" label="Next Releases" size="small" />
              </Stack>
            }
            content={t('home.app.trade_data.description')}
            textButton={t('home.app.trade_data.button.title')}
            iconLottie={DashboardTradeIcon}
            onClick={() => navigate(AuthRouteKeys.TradeData)}
            // onClick={() => handleOpenSearch(FormType.SearchTradeData)}
          />
        </Grid>
        <Grid item xs={4}>
          <CardSearchItem
            tittle={
              <Typography variant="subtitle1" align="left" fontWeight={700}>
                {t('home.app.support.title')}
              </Typography>
            }
            content={t('home.app.support.description')}
            textButton={t('home.app.support.button.title')}
            iconLottie={MediaSearch}
            // onClickLearnMore={() => null}
            onClick={() => window.open(LINK_SUPPORT, '_blank')}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
