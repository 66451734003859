import { Box, Container, styled, Typography, Stack, Skeleton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import { get, isString } from 'lodash';

// Components
import FormItemCountryTariffs from 'src/components/Form/FormItemCountryTariffs';
import FormItemHsCode from 'src/components/Form/FormItemHsCode';
import FormItemNationalTariffCode from 'src/components/Form/FormItemNationalTariffCode';
import FormikForm from 'src/components/common/FormikForm';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import Overview from 'src/features/TariffsAndRules/Overview';
import CustomsTariff from 'src/features/TariffsAndRules/CustomsTariff';
import TradeRemedies from 'src/features/TariffsAndRules/TradeRemedies';
import RegulatoryRequirement from 'src/features/TariffsAndRules/RegulatoryRequirements';
import ImportDuty from 'src/features/TariffsAndRules/ImportDuty';

// Utilities
import TradeImg from 'src/asset/images/tariff-and-rule/saving-money-bank.svg';
import RequirementImg from 'src/asset/images/tariff-and-rule/supply-chain-distribution.svg';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { useQueryGetNtmMeasures, useQueryGetCustomDuties, useQueryGetTradeRemedy } from 'src/services/queries';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { EmptyText } from 'src/utils/constants/config';
import SearchIcon from 'src/asset/lottie/searches.json';
import React, { useMemo } from 'react';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(7),
}));

const TariffRules = () => {
  const [, setSearchParam] = useSearchParams();
  const { partner, reporter, hs_code, product } = useQueryParams();
  const GetNtmMeasuresQuery = useQueryGetNtmMeasures({
    reporter: reporter && isString(reporter) ? reporter : '',
    partner: partner && isString(partner) ? partner : '',
    product: product && isString(product) ? product : '',
  });
  const GetCustomDutiesQuery = useQueryGetCustomDuties({
    reporter: reporter && isString(reporter) ? reporter : '',
    partner: partner && isString(partner) ? partner : '',
    product: product && isString(product) ? product : '',
  });
  const GetTradeRemedyQuery = useQueryGetTradeRemedy({
    reporter: reporter && isString(reporter) ? reporter : '',
    partner: partner && isString(partner) ? partner : '',
    product: product && isString(product) ? product : '',
  });
  const dataNtmMeasures = GetNtmMeasuresQuery.data?.data;
  const dataCustomDuties = GetCustomDutiesQuery.data?.data;
  const dataTradeRemedy = GetTradeRemedyQuery.data?.data;

  const isEmptyData = useMemo(() => {
    const isDoneFetching =
      !GetNtmMeasuresQuery.isFetching && !GetCustomDutiesQuery.isFetching && !GetTradeRemedyQuery.isFetching;
    const isDataEmpty = !dataNtmMeasures && !dataCustomDuties && !dataTradeRemedy;
    return isDoneFetching && isDataEmpty;
  }, [GetNtmMeasuresQuery, GetCustomDutiesQuery, GetTradeRemedyQuery]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country_partner: partner && isString(partner) ? partner : '',
      country_reporter: reporter && isString(reporter) ? reporter : '',
      hs_code: hs_code && isString(hs_code) ? hs_code : '',
      national_code: product && isString(product) ? product : '',
    },
    validationSchema: Yup.object().shape({
      country_partner: Yup.string().required('This field is required').max(255),
      country_reporter: Yup.string().required('This field is required').max(255),
      hs_code: Yup.string().required('This field is required').max(255),
      national_code: Yup.string().required('This field is required').max(255),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSearchParam({
        partner: values.country_partner,
        reporter: values.country_reporter,
        hs_code: values.hs_code,
        product: values.national_code,
      });
      setSubmitting(false);
    },
  });

  return (
    <StyledContainer maxWidth={'xl'}>
      <Stack spacing={3}>
        <Box>
          <Typography variant="h5">Tariffs and Rules Search</Typography>
          <Typography variant="body2" color="text.secondary" mt={1}>
            Identify import and export-related regulatory requirements applicable to a product by importer, exporter and
            year.
          </Typography>
        </Box>
        <FormikForm formik={formik}>
          <StyledWhiteBox spacing={2} direction="row">
            <Box display="flex" flex={1} maxWidth="20%">
              <FormItemCountryTariffs required={true} id="country_partner" label="Exporting Country" />
            </Box>
            <Box display="flex" flex={1} maxWidth="20%">
              <FormItemCountryTariffs required={true} id="country_reporter" label="Destination Country" />
            </Box>
            <Box display="flex" flex={1} maxWidth="25%">
              <FormItemHsCode
                countryCode={formik.values.country_reporter}
                defaultKeyword={formik.values.hs_code}
                required={true}
                id="hs_code"
                disabled={!formik.values.country_reporter}
              />
            </Box>
            <Box display="flex" flex={1} maxWidth="25%">
              <FormItemNationalTariffCode
                countryCode={formik.values.country_reporter}
                required={true}
                hsCode={formik.values.hs_code}
                id="national_code"
                disabled={!formik.values.hs_code}
              />
            </Box>
            <Box display="flex" maxWidth="20%">
              <ButtonWithLoading
                size="small"
                variant="contained"
                endIcon={<Search />}
                type="submit"
                sx={{ paddingLeft: '22px', paddingRight: '22px' }}
              >
                Search
              </ButtonWithLoading>
            </Box>
          </StyledWhiteBox>
        </FormikForm>
        {partner && reporter && product && (
          <React.Fragment>
            {isEmptyData ? (
              <EmptyData />
            ) : (
              <Stack direction="row" spacing={3}>
                <Stack spacing={3} flex={1}>
                  <Overview dataNtmMeasures={dataNtmMeasures} isFetching={GetNtmMeasuresQuery.isFetching} />
                  <ImportDuty dataCustomDuties={dataCustomDuties} isFetching={GetCustomDutiesQuery.isFetching} />
                  <StyledWhiteBox spacing={4} direction="row" alignItems="center">
                    <Box width="100%" src={TradeImg} component="img" flex={1} />
                    <Box flex={1}>
                      <Typography variant="h6" color="text.secondary" align="center">
                        Trade Remedies
                      </Typography>
                      <Typography variant="h4" align="center">
                        {getArrayDefault(dataTradeRemedy?.TradeRemedyData).length}
                      </Typography>
                    </Box>
                  </StyledWhiteBox>
                  <StyledWhiteBox spacing={4} direction="row" alignItems="center">
                    <Box width="100%" src={RequirementImg} component="img" flex={1} />
                    <Box flex={1}>
                      <Typography variant="h6" color="text.secondary" align="center">
                        Requirements
                      </Typography>
                      <Typography variant="h4" align="center">
                        {get(dataNtmMeasures, '[0].MeasureTotalCount', EmptyText)}
                      </Typography>
                    </Box>
                  </StyledWhiteBox>
                </Stack>
                <Stack spacing={3} flex={2}>
                  <CustomsTariff dataCustomDuties={dataCustomDuties} isFetching={GetCustomDutiesQuery.isFetching} />
                  <TradeRemedies dataTradeRemedy={dataTradeRemedy} isFetching={GetCustomDutiesQuery.isFetching} />
                  {GetNtmMeasuresQuery.isFetching ? (
                    <Skeleton animation="wave" variant="rectangular" height={400} />
                  ) : (
                    getArrayDefault(dataNtmMeasures).map((measure, index) => {
                      return (
                        <RegulatoryRequirement key={`${index}-${measure.MeasureDirection}`} dataNtmMeasure={measure} />
                      );
                    })
                  )}
                </Stack>
              </Stack>
            )}
          </React.Fragment>
        )}
      </Stack>
    </StyledContainer>
  );
};

export default TariffRules;

const EmptyData = () => {
  return (
    <Stack>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: SearchIcon,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={600}
        width={540}
      />
      <Typography variant="h2" sx={{ color: '#3658C1' }} align="center">
        No Results Found
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        Sorry, we did not find any results matching your search criteria. Please search again.
      </Typography>
    </Stack>
  );
};
