import { Avatar, AvatarProps, Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface IProps {
  onClick: () => void;
  imgSrc: string;
  textOverlay: ReactNode;
  avatarProps?: AvatarProps;
  hasBackdrop: boolean;
}

const ImageWithOverlay = ({ onClick, imgSrc, avatarProps, textOverlay, hasBackdrop }: IProps) => {
  return (
    <Box
      component={'div'}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
      position={'relative'}
      width={'inherit'}
      height={'inherit'}
    >
      <Avatar
        sx={{ height: 'inherit', width: '100%', filter: hasBackdrop ? 'brightness(0.6)' : undefined }}
        variant="rounded"
        {...avatarProps}
        src={imgSrc}
      />
      {textOverlay && (
        <Typography
          variant="subtitle1"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 10,
          }}
          color={'white'}
        >
          {textOverlay}
        </Typography>
      )}
    </Box>
  );
};

export default ImageWithOverlay;
