import { Backdrop, Box } from '@mui/material';
import * as React from 'react';

// Components
import Header from './Header';
import SidebarMenu from './SidebarMenu';

export default function Drawer({ hideSideBar }: { hideSideBar?: boolean }) {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={open} />
      <Header />
      {!hideSideBar && (
        <SidebarMenu open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
      )}
    </Box>
  );
}
