import { create } from 'zustand';

export enum FormType {
  SearchCompany = 'search_company_profile',
  SearchPeople = 'search_people_profile',
  CompareCompany = 'compare_company',
  WarningSensitiveImage = 'warning_sensitive_image',
  SearchTradeData = 'search_trade_data',
}

export interface IFormSearchState {
  type: FormType | undefined;
  visible: boolean;
  selectedCompanyCompare: ICompareCompany | null;
}

export interface IFormSearchAction {
  handleCloseSearch: () => void;
  handleOpenSearch: (type: FormType, selectedCompanyCompare?: ICompareCompany) => void;
  resetFormCompare: () => void;
}

export const useFormSearch = create<IFormSearchState & IFormSearchAction>((set) => ({
  type: undefined,
  visible: false,
  selectedCompanyCompare: null,
  handleCloseSearch: () => set(() => ({ type: undefined, visible: false })),
  handleOpenSearch: (type: FormType, selectedCompanyCompare?: ICompareCompany) => {
    if (selectedCompanyCompare) {
      set(() => ({ type: type, visible: true, selectedCompanyCompare: selectedCompanyCompare }));
    } else {
      set(() => ({ type: type, visible: true }));
    }
  },
  resetFormCompare: () => {
    set(() => ({ selectedCompanyCompare: null }));
  },
}));
