export default [
  'fuck',
  'shit',
  'bitch',
  'dick',
  'asshole',
  'bastard',
  'damn',
  'crap',
  'piss',
  'douche',
  'cunt',
  'fag',
  'faggot',
  'whore',
  'slut',
  'prick',
  'twat',
  'wanker',
  'motherfucker',
  'mother fucker',
  'nigger',
  'chink',
  'spic',
  'kike',
  'gook',
  'retard',
  'dyke',
  'queer',
  'homo',
];
