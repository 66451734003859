import { Box, Grid } from '@mui/material';

// Components
import FormLogin from 'src/features/Login/FormLogin';
import RightSight from 'src/features/Login/Common/RightSight';

const Login = () => {
  const renderForm = <FormLogin />;

  return (
    <Grid sx={{ minHeight: '100vh' }} container>
      <Grid item xs={6}>
        <Box display={'grid'} sx={{ height: '100%', placeItems: 'center' }}>
          {renderForm}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <RightSight />
      </Grid>
    </Grid>
  );
};

export default Login;
