import { Box, Container, styled } from '@mui/material';
import { isString } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import TabComponent from 'src/components/Tab/TabComponent';
import AddressesTab from 'src/features/CompanyDetail/Addresses';
import ComingSoon from 'src/features/CompanyDetail/Common/ComingSoon';
import AlertsTab from 'src/features/CompanyDetail/Alerts';
import CompanyInformation from 'src/features/CompanyDetail/Common/CompanyInformation';
import FormContribute from 'src/features/CompanyDetail/Common/FormContribute';
import FormReportWrong from 'src/features/CompanyDetail/Common/FormReportWrong';
import FormReview from 'src/features/CompanyDetail/Common/FormReview';
import ContactsTab from 'src/features/CompanyDetail/Contacts';
import NegativeMediaTab from 'src/features/CompanyDetail/NegativeMedia';
import OverViewTab from 'src/features/CompanyDetail/OverView';
import Reviews from 'src/features/CompanyDetail/Reviews';
import SocialMediaTab from 'src/features/CompanyDetail/SocialMedia';
import StockPlaceTab from 'src/features/CompanyDetail/StockPlace';
import TradeMarkTab from 'src/features/CompanyDetail/TradeMark';
// import TradingArea from 'src/features/CompanyDetail/TradingArea';
// import BillOfLanding from 'src/features/CompanyDetail/BillOfLanding';
// import SupplyChainMap from 'src/features/CompanyDetail/SupplyChainMap';
// import PortOfDelivery from 'src/features/CompanyDetail/PortOfDelivery_PortOfOrigin';
// import CustomerTab from 'src/features/CompanyDetail/Customer_Supplier_Competitor';
// import DataAnalytic from 'src/features/CompanyDetail/DataAnalytic';
// import HSCode from 'src/features/CompanyDetail/HSCode';
import WebDetailTab from 'src/features/CompanyDetail/WebDetail';

// Utilities
import BillOfLadingPlaceHolder from 'src/asset/images/company/BillOfLading.png';
import CompetitorsPlaceHolder from 'src/asset/images/company/Competitors.png';
import CustomersPlaceHolder from 'src/asset/images/company/Customers.png';
import DataAnalyticPlaceHolder from 'src/asset/images/company/DataAnalytics.png';
import HSCodePlaceHolder from 'src/asset/images/company/HSCode.png';
import PortOfDeliveryPlaceHolder from 'src/asset/images/company/PortOfDelivery.png';
import PortOfOriginPlaceHolder from 'src/asset/images/company/PortOfOrigin.png';
import SupplierPlaceHolder from 'src/asset/images/company/Supplier.png';
import SupplyChainMapPlaceHolder from 'src/asset/images/company/SupplyChainMap.png';
import TradingAreaPlaceHolder from 'src/asset/images/company/TradingArea.png';
import BreadcrumbWithBtnBack from 'src/components/BreadcrumbWithBtnBack';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { TABS_COMPANY, TypeTabCompany } from 'src/features/CompanyDetail/config';
import { useQueryParam } from 'src/hooks/useQueryParam';
import useRefetch from 'src/hooks/useRefetch';
import { QUERY_KEY_COMPANY_REVIEW, QUERY_KEY_COMPANY_REVIEW_DETAIL, useQueryCompanyDetail } from 'src/services/queries';
import { EmptyText } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';
import analytics from 'src/analytics';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(7),
  marginTop: theme.spacing(1),
  textAlign: 'center',
}));

const CompanyDetail = () => {
  const [selectedTab, setSelectedTab] = useState<string>(TypeTabCompany.Overview);
  const { id } = useParams();
  const { data } = useQueryCompanyDetail(id || '');

  const { oldData, open, type, handleCloseContribute } = useCompanyContribute((state) => state);
  const navigate = useNavigate();
  const tab = useQueryParam('tab');
  const { refetchQuery } = useRefetch();

  useEffect(() => {
    if(!data?.data?.name) return;
    analytics.logEvent('page_view', {
      'page_title': `Company Detail - ${data?.data?.name}`,
      'page_location': window.location.href,
      'page_path': window.location.pathname
    })
  }, [data?.data?.name]);

  useEffect(() => {
    if (tab && isString(tab)) {
      setSelectedTab(tab);
    } else {
      setSelectedTab(TypeTabCompany.Overview);
    }
  }, [tab]);

  useEffect(() => {
    if (!id) {
      navigate(AuthRouteKeys.Home);
    }
  }, []);

  const renderForm = () => {
    switch (selectedTab) {
      case TypeTabCompany.Overview:
        return <OverViewTab />;
      case TypeTabCompany.Contacts:
        return <ContactsTab />;
      case TypeTabCompany.SocialMedia:
        return <SocialMediaTab />;
      case TypeTabCompany.Reviews:
        return <Reviews />;
      case TypeTabCompany.NegativeMedia:
        return <NegativeMediaTab company={data?.data} />;
      case TypeTabCompany.Trademark:
        return <TradeMarkTab company={data} />;
      case TypeTabCompany.Addresses:
        return <AddressesTab />;
      case TypeTabCompany.WebDetails:
        return <WebDetailTab />;
      case TypeTabCompany.DataAnalytics:
        return <ComingSoon image={DataAnalyticPlaceHolder} />;
      case TypeTabCompany.SupplyChainMap:
        return <ComingSoon image={SupplyChainMapPlaceHolder} />;
      case TypeTabCompany.BillsOfLading:
        return <ComingSoon image={BillOfLadingPlaceHolder} />;
      case TypeTabCompany.HSCode:
        return <ComingSoon image={HSCodePlaceHolder} />;
      case TypeTabCompany.Customers:
        return <ComingSoon image={CustomersPlaceHolder} />;
      case TypeTabCompany.Suppliers:
        return <ComingSoon image={SupplierPlaceHolder} />;
      case TypeTabCompany.Competitors:
        return <ComingSoon image={CompetitorsPlaceHolder} />;
      case TypeTabCompany.PortsOfDelivery:
        return <ComingSoon image={PortOfDeliveryPlaceHolder} />;
      case TypeTabCompany.PortsOfOrigin:
        return <ComingSoon image={PortOfOriginPlaceHolder} />;
      case TypeTabCompany.TradingArea:
        return <ComingSoon image={TradingAreaPlaceHolder} />;
      // case TypeTabCompany.DataAnalytics:
      //   return <DataAnalytic />;
      // case TypeTabCompany.SupplyChainMap:
      //   return <SupplyChainMap />;
      // case TypeTabCompany.BillsOfLading:
      //   return <BillOfLanding />;
      // case TypeTabCompany.HSCode:
      //   return <HSCode />;
      // case TypeTabCompany.Customers:
      //   return <CustomerTab tab={selectedTab} />;
      // case TypeTabCompany.Suppliers:
      //   return <CustomerTab tab={selectedTab} />;
      // case TypeTabCompany.Competitors:
      //   return <CustomerTab tab={selectedTab} />;
      // case TypeTabCompany.PortsOfDelivery:
      //   return <PortOfDelivery tab={selectedTab} />;
      // case TypeTabCompany.PortsOfOrigin:
      //   return <PortOfDelivery tab={selectedTab} />;
      // case TypeTabCompany.TradingArea:
      //   return <TradingArea />;
      case TypeTabCompany.StockPlace:
        return <StockPlaceTab id={id} />;
      case TypeTabCompany.Alerts:
        return <AlertsTab />;
      default:
        return <OverViewTab />;
    }
  };

  const renderFormContribute = () => {
    switch (type) {
      case TypeContribute.Review:
        return (
          <FormReview
            callback={() => {
              refetchQuery([QUERY_KEY_COMPANY_REVIEW]);
              refetchQuery([QUERY_KEY_COMPANY_REVIEW_DETAIL]);
            }}
            companyData={data?.data}
            open={true}
            handleClose={handleCloseContribute}
          />
        );
      case TypeContribute.ReportWrongOtherContact:
        return (
          <FormReportWrong oldData={oldData} companyData={data?.data} open={true} handleClose={handleCloseContribute} />
        );
      default:
        return <FormContribute companyData={data?.data} open={true} type={type} handleClose={handleCloseContribute} />;
    }
  };

  return (
    <StyledContainer maxWidth="xl">
      {open && renderFormContribute()}
      <BreadcrumbWithBtnBack
        listItem={[
          {
            text: 'Companies',
          },
          {
            text: data?.data?.country ?? EmptyText,
          },
          {
            text: data?.data?.jurisdiction ?? EmptyText,
          },
          {
            text: data?.data?.name ?? EmptyText,
          },
        ]}
        updatedAt={data?.data?.data_source?.last_retrieved || ''}
      />
      <CompanyInformation />
      <TabComponent
        tabs={TABS_COMPANY}
        selected={selectedTab}
        handleChange={(e, value) => {
          setSelectedTab(value);
        }}
      />
      <Box mt={3.75} />
      {renderForm()}
    </StyledContainer>
  );
};

export default CompanyDetail;
