import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Rating,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import ModalComponent from 'src/components/Modal/ModalComponent';
import {
  IPropsDataReviewCompany,
  RELATIONSHIP_DATA,
  TypeContribute,
  TYPE_CONTRIBUTE,
} from 'src/features/CompanyDetail/Common/config';

// Utilities
import GuardContributeImg from 'src/asset/images/guard-contribute.png';
import { useMutateCreateReview } from 'src/services/mutate';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { getCurrentUrl } from 'src/utils/helpers/stringHelpers';

interface IProps {
  companyData: ICompanyDetail;
  open: boolean;
  handleClose: () => void;
  callback: () => void;
}

const StyledHeaderAlert = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  padding: '8px 32px',
}));

const FormReview: React.FC<IProps> = ({ companyData, open, handleClose, callback }) => {
  const theme = useTheme();
  const { mutateAsync } = useMutateCreateReview();

  const formik = useFormik<IPropsDataReviewCompany>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      type_contribute: TypeContribute.Review,
      relationship_to_data: '',
      is_confirm: false,
      rating_value: 0,
      review_text: '',
    },
    validationSchema: Yup.object().shape({
      type_contribute: Yup.string().max(255).required('Email is required'),
      relationship_to_data: Yup.string().max(255).required('Relationship Data is required'),
      is_confirm: Yup.bool().oneOf([true], 'Please checked this checkbox'),
      rating_value: Yup.number().min(1),
      review_text: Yup.string().required('Feedback is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const params: TypeDTOReviewCompany = {
        company_id: companyData.id,
        relationship_to_data: values.relationship_to_data,
        review_text: values.review_text,
        rating_value: values.rating_value,
      };
      const res = await mutateAsync(params);
      if (res.status_code === SUCCESS_CODE.CREATE) {
        callback();
        handleClose();
      }
      setSubmitting(false);
    },
  });

  return (
    <ModalComponent
      padding={4}
      pt={10}
      handleClose={handleClose}
      open={open}
      closeOnBackdropClick={false}
      disabledCloseIcon={formik.isSubmitting}
      showCloseIcon={true}
      headerAlert={
        <StyledHeaderAlert id="form-contribute-header-alert" direction="row" alignItems="center">
          <Box src={GuardContributeImg} component="img" width={24} mr={1} />
          <Typography variant="caption"> Your personal information will remain </Typography>
          <Button variant="text" color="success">
            100% confidential
          </Button>
        </StyledHeaderAlert>
      }
    >
      <FormikForm formik={formik}>
        <Box width={540}>
          <Typography textAlign={'center'} variant="h5">
            Rate This Company
          </Typography>
          <Typography textAlign={'center'} color={theme.palette.text.secondary} variant="body2">
            Your voice matters, make it heard.
          </Typography>
          <Box mt={4} />
          <Stack direction={'column'} spacing={2.5}>
            <TextField
              type="select"
              id="type_contribute"
              name="type_contribute"
              value={formik.values.type_contribute}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              select
              fullWidth
              disabled
              label="Type Contribute"
              error={Boolean(formik.touched.type_contribute && formik.errors.type_contribute)}
              helperText={Boolean(formik.touched.type_contribute) && formik.errors.type_contribute}
            >
              {TYPE_CONTRIBUTE.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControl>
              <Autocomplete
                clearIcon={false}
                disabled
                options={[]}
                freeSolo
                multiple
                renderTags={(value) => value.map((option, index) => <Chip key={option + index} label={option} />)}
                value={[`${getCurrentUrl()}`]}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    size="small"
                    fullWidth
                    label={
                      <span>
                        Profile Contribute (URL) <TooltipFormLabel title="Profile Contribute" />
                      </span>
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                clearIcon={false}
                disabled
                options={[]}
                freeSolo
                multiple
                renderTags={(value) => value.map((option, index) => <Chip key={option + index} label={option} />)}
                value={[companyData?.name || '']}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    disabled
                    size="small"
                    fullWidth
                    label={
                      <span>
                        Company <TooltipFormLabel title="Company" />
                      </span>
                    }
                  />
                )}
              />
            </FormControl>
            <Stack spacing={1}>
              <Typography variant="subtitle2" component="p">
                Rate this Company
              </Typography>
              <Rating
                name="simple-controlled"
                value={formik.values.rating_value || 0}
                onChange={(event, value) => {
                  formik.setFieldValue('rating_value', value);
                }}
              />
              {formik.touched.rating_value && formik.errors.rating_value && (
                <FormHelperText error>{formik.errors.rating_value}</FormHelperText>
              )}
            </Stack>
            <TextField
              type="select"
              id="relationship_to_data"
              name="relationship_to_data"
              value={formik.values.relationship_to_data}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              select
              fullWidth
              required
              placeholder="Select option"
              label={
                <span>
                  Relationship to Data
                  <TooltipFormLabel title="Relationship to Data" />
                </span>
              }
              error={Boolean(formik.touched.relationship_to_data && formik.errors.relationship_to_data)}
              helperText={Boolean(formik.touched.relationship_to_data) && formik.errors.relationship_to_data}
            >
              {RELATIONSHIP_DATA.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="text"
              id="review_text"
              name="review_text"
              value={formik.values.review_text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
              fullWidth
              required
              placeholder="Your Feedback"
              label={<span>Your Feedback</span>}
              multiline
              rows={4}
              error={Boolean(formik.touched.review_text && formik.errors.review_text)}
              helperText={Boolean(formik.touched.review_text) && formik.errors.review_text}
            />
            <Box>
              <FormControlLabel
                id="is_confirm"
                name="is_confirm"
                onChange={(e, value) => {
                  formik.setFieldValue('is_confirm', value);
                }}
                value={formik.values.is_confirm}
                control={<Checkbox checked={formik.values.is_confirm} />}
                label={
                  <Typography color={theme.palette.text.secondary} variant="body1">
                    I certify that I am authorized to prove the above information
                  </Typography>
                }
              />
              {formik.touched.is_confirm && formik.errors.is_confirm && (
                <FormHelperText error>{formik.errors.is_confirm}</FormHelperText>
              )}
            </Box>
            <ButtonWithLoading
              fullWidth
              size="large"
              variant="contained"
              isRounded
              type="submit"
              isLoading={formik.isSubmitting}
              disabled={!formik.isValid}
            >
              Submit
            </ButtonWithLoading>
          </Stack>
        </Box>
      </FormikForm>
    </ModalComponent>
  );
};
export default FormReview;
