import { useMutation } from '@tanstack/react-query';
import { SettingService } from 'src/services/api';

export const useMutateUpdateUserName = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOUpdateUsername) => SettingService.handleUpdateUsername(body),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateUserInfo = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOSettingInformation) => SettingService.handleInformation(body),

  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateUserNoti = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOSettingNotification) => SettingService.handleNotification(body),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateUserEmail = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOCreateOTP) => SettingService.handleUpdateEmail(body),
  });
  return {
    isPending,
    mutateAsync,
  };
};

export const useMutateUserPassword = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (body: TypeDTOUpdatePassword) => SettingService.handleUpdatePassword(body),
  });
  return {
    isPending,
    mutateAsync,
  };
};
