import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { EmptyText, FORMAT_DATE_TIME } from 'src/utils/constants/config';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const formatTimeUTCToFromNow = (time: string) => {
  return dayjs.utc(time).local().fromNow();
};

export const formatUTCTime = (format: string, time?: string) => {
  if (!time) {
    return EmptyText;
  }
  const formattedDate = dayjs(time, FORMAT_DATE_TIME.UTC).format(format);
  return formattedDate;
};

export const formatTimeToUTC = (time: Dayjs | null) => {
  return dayjs(time).utc().format(FORMAT_DATE_TIME.UTC);
};
