import { isArray } from 'lodash';

export const getArrayDefault = <T>(array: T) => {
  if (isArray(array)) return array;
  return [];
};
export const sortObjectsArrayByKey = <T>(array: T[], key: keyof T) => {
  if (!isArray(array)) return [];
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};
