import { Box, Grid } from '@mui/material';
import RightSight from 'src/features/Login/Common/RightSight';
import FormForgotPassword from './FormForgotPassword';

const ForgotPassword = () => {
  const renderForm = <FormForgotPassword />;

  return (
    <Grid sx={{ minHeight: '100vh' }} container>
      <Grid item xs={6}>
        <Box display={'grid'} sx={{ height: '100%', placeItems: 'center' }}>
          {renderForm}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <RightSight />
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
