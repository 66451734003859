import { Box, BoxProps, Collapse, Stack, styled, Theme, Typography, useTheme } from '@mui/material';
import { ChevronDown, ChevronUp } from 'react-feather';

// Components
import { useState } from 'react';
import IconFeather from 'src/components/common/Icon';

const StyledBox = styled(Box)<{ theme: Theme; isOpen?: boolean }>(({ theme, isOpen }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  border: '1px solid' + theme.palette.divider,
  borderRadius: isOpen
    ? `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0px 0px`
    : theme.shape.borderRadius * 2,
  cursor: 'pointer',
  alignItems: 'center',
  background: isOpen ? theme.palette.primary.dark : theme.palette.background.default,
  color: isOpen ? theme.palette.common.white : theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    color: isOpen ? theme.palette.common.white : theme.palette.text.secondary,
  },
}));

const BoxCollapse = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  borderTop: 'none',
  borderRadius: `0px 0px ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
}));

interface IProps extends BoxProps {
  title: string;
  openDefault?: boolean;
}

const ExpandableBox = ({ title, children, openDefault = true, ...params }: IProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(openDefault);

  return (
    <Box {...params}>
      <StyledBox onClick={() => setOpen(!open)} component={'div'} isOpen={open} theme={theme}>
        <Stack direction={'row'} alignItems="center" gap={2}>
          <Typography sx={{ color: 'inherit' }} typography={'subtitle1'}>
            {title}
          </Typography>
        </Stack>
        <IconFeather muiProps={{ color: 'inherit' }} icon={open ? <ChevronUp /> : <ChevronDown />} />
      </StyledBox>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <BoxCollapse>{children}</BoxCollapse>
      </Collapse>
    </Box>
  );
};

export default ExpandableBox;
