export const TRADEMARK_JSON = {
  AF: {
    value: 'AF',
    label: '(AF) Afghanistan',
    score: 194,
    highlighted: '(<em>AF<%2Fem>) <em>Af<%2Fem>ghanistan',
  },
  AL: {
    value: 'AL',
    label: '(AL) Albania',
    score: 194,
    highlighted: '(<em>AL<%2Fem>) <em>Al<%2Fem>bania',
  },
  AQ: {
    value: 'AQ',
    label: '(AQ) Antarctica',
    score: 99,
    highlighted: '(<em>AQ<%2Fem>) Antarctica',
  },
  DZ: {
    value: 'DZ',
    label: '(DZ) Algeria',
    score: 99,
    highlighted: '(<em>DZ<%2Fem>) Algeria',
  },
  AS: {
    value: 'AS',
    label: '(AS) American Samoa',
    score: 99,
    highlighted: '(<em>AS<%2Fem>) American Samoa',
  },
  AD: {
    value: 'AD',
    label: '(AD) Andorra',
    score: 99,
    highlighted: '(<em>AD<%2Fem>) Andorra',
  },
  AO: {
    value: 'AO',
    label: '(AO) Angola',
    score: 99,
    highlighted: '(<em>AO<%2Fem>) Angola',
  },
  AG: {
    value: 'AG',
    label: '(AG) Antigua and Barbuda',
    score: 529,
    highlighted: '(<em>A<%2Fem>G) <em>A<%2Fem>ntigu<em>a<%2Fem> <em>a<%2Fem>nd B<em>a<%2Fem>rbud<em>a<%2Fem>',
  },
  AZ: {
    value: 'AZ',
    label: '(AZ) Azerbaijan',
    score: 194,
    highlighted: '(<em>AZ<%2Fem>) <em>Az<%2Fem>erbaijan',
  },
  AR: {
    value: 'AR',
    label: '(AR) Argentina',
    score: 194,
    highlighted: '(<em>AR<%2Fem>) <em>Ar<%2Fem>gentina',
  },
  AU: {
    value: 'AU',
    label: '(AU) Australia',
    score: 194,
    highlighted: '(<em>AU<%2Fem>) <em>Au<%2Fem>stralia',
  },
  AT: {
    value: 'AT',
    label: '(AT) Austria',
    score: 99,
    highlighted: '(<em>AT<%2Fem>) Austria',
  },
  BS: {
    value: 'BS',
    label: '(BS) Bahamas',
    score: 99,
    highlighted: '(<em>BS<%2Fem>) Bahamas',
  },
  BH: {
    value: 'BH',
    label: '(BH) Bahrain',
    score: 99,
    highlighted: '(<em>BH<%2Fem>) Bahrain',
  },
  BD: {
    value: 'BD',
    label: '(BD) Bangladesh',
    score: 99,
    highlighted: '(<em>BD<%2Fem>) Bangladesh',
  },
  AM: {
    value: 'AM',
    label: '(AM) Armenia',
    score: 99,
    highlighted: '(<em>AM<%2Fem>) Armenia',
  },
  BB: {
    value: 'BB',
    label: '(BB) Barbados',
    score: 99,
    highlighted: '(<em>BB<%2Fem>) Barbados',
  },
  BE: {
    value: 'BE',
    label: '(BE) Belgium',
    score: 194,
    highlighted: '(<em>BE<%2Fem>) <em>Be<%2Fem>lgium',
  },
  BM: {
    value: 'BM',
    label: '(BM) Bermuda',
    score: 99,
    highlighted: '(<em>BM<%2Fem>) Bermuda',
  },
  BT: {
    value: 'BT',
    label: '(BT) Bhutan',
    score: 99,
    highlighted: '(<em>BT<%2Fem>) Bhutan',
  },
  BO: {
    value: 'BO',
    label: '(BO) Bolivia (Plurinational State of)',
    score: 194,
    highlighted: '(<em>BO<%2Fem>) <em>Bo<%2Fem>livia (Plurinational State of)',
  },
  BA: {
    value: 'BA',
    label: '(BA) Bosnia and Herzegovina',
    score: 99,
    highlighted: '(<em>BA<%2Fem>) Bosnia and Herzegovina',
  },
  BW: {
    value: 'BW',
    label: '(BW) Botswana',
    score: 99,
    highlighted: '(<em>BW<%2Fem>) Botswana',
  },
  BV: {
    value: 'BV',
    label: '(BV) Bouvet Island',
    score: 99,
    highlighted: '(<em>BV<%2Fem>) Bouvet Island',
  },
  BR: {
    value: 'BR',
    label: '(BR) Brazil',
    score: 194,
    highlighted: '(<em>BR<%2Fem>) <em>Br<%2Fem>azil',
  },
  BZ: {
    value: 'BZ',
    label: '(BZ) Belize',
    score: 99,
    highlighted: '(<em>BZ<%2Fem>) Belize',
  },
  IO: {
    value: 'IO',
    label: '(IO) British Indian Ocean Territory',
    score: 99,
    highlighted: '(<em>IO<%2Fem>) British Indian Ocean Territory',
  },
  SB: {
    value: 'SB',
    label: '(SB) Solomon Islands',
    score: 99,
    highlighted: '(<em>SB<%2Fem>) Solomon Islands',
  },
  VG: {
    value: 'VG',
    label: '(VG) Virgin Islands (British)',
    score: 99,
    highlighted: '(<em>VG<%2Fem>) Virgin Islands (British)',
  },
  BN: {
    value: 'BN',
    label: '(BN) Brunei Darussalam',
    score: 99,
    highlighted: '(<em>BN<%2Fem>) Brunei Darussalam',
  },
  BG: {
    value: 'BG',
    label: '(BG) Bulgaria',
    score: 99,
    highlighted: '(<em>BG<%2Fem>) Bulgaria',
  },
  MM: {
    value: 'MM',
    label: '(MM) Myanmar',
    score: 99,
    highlighted: '(<em>MM<%2Fem>) Myanmar',
  },
  BI: {
    value: 'BI',
    label: '(BI) Burundi',
    score: 99,
    highlighted: '(<em>BI<%2Fem>) Burundi',
  },
  KH: {
    value: 'KH',
    label: '(KH) Cambodia',
    score: 99,
    highlighted: '(<em>KH<%2Fem>) Cambodia',
  },
  CM: {
    value: 'CM',
    label: '(CM) Cameroon',
    score: 99,
    highlighted: '(<em>CM<%2Fem>) Cameroon',
  },
  CA: {
    value: 'CA',
    label: '(CA) Canada',
    score: 194,
    highlighted: '(<em>CA<%2Fem>) <em>Ca<%2Fem>nada',
  },
  CV: {
    value: 'CV',
    label: '(CV) Cabo Verde',
    score: 99,
    highlighted: '(<em>CV<%2Fem>) Cabo Verde',
  },
  KY: {
    value: 'KY',
    label: '(KY) Cayman Islands',
    score: 99,
    highlighted: '(<em>KY<%2Fem>) Cayman Islands',
  },
  CF: {
    value: 'CF',
    label: '(CF) Central African Republic',
    score: 99,
    highlighted: '(<em>CF<%2Fem>) Central African Republic',
  },
  LK: {
    value: 'LK',
    label: '(LK) Sri Lanka',
    score: 99,
    highlighted: '(<em>LK<%2Fem>) Sri Lanka',
  },
  TD: {
    value: 'TD',
    label: '(TD) Chad',
    score: 99,
    highlighted: '(<em>TD<%2Fem>) Chad',
  },
  CL: {
    value: 'CL',
    label: '(CL) Chile',
    score: 99,
    highlighted: '(<em>CL<%2Fem>) Chile',
  },
  CN: {
    value: 'CN',
    label: '(CN) China',
    score: 99,
    highlighted: '(<em>CN<%2Fem>) China',
  },
  TW: {
    value: 'TW',
    label: '(TW) Taiwan (Province of China)',
    score: 99,
    highlighted: '(<em>TW<%2Fem>) Taiwan (Province of China)',
  },
  CX: {
    value: 'CX',
    label: '(CX) Christmas Island',
    score: 99,
    highlighted: '(<em>CX<%2Fem>) Christmas Island',
  },
  CC: {
    value: 'CC',
    label: '(CC) Cocos (Keeling) Islands',
    score: 99,
    highlighted: '(<em>CC<%2Fem>) Cocos (Keeling) Islands',
  },
  CO: {
    value: 'CO',
    label: '(CO) Colombia',
    score: 194,
    highlighted: '(<em>CO<%2Fem>) <em>Co<%2Fem>lombia',
  },
  KM: {
    value: 'KM',
    label: '(KM) Comoros',
    score: 99,
    highlighted: '(<em>KM<%2Fem>) Comoros',
  },
  YT: {
    value: 'YT',
    label: '(YT) Mayotte',
    score: 99,
    highlighted: '(<em>YT<%2Fem>) Mayotte',
  },
  CG: {
    value: 'CG',
    label: '(CG) Congo',
    score: 99,
    highlighted: '(<em>CG<%2Fem>) Congo',
  },
  CD: {
    value: 'CD',
    label: '(CD) Congo (Democratic Republic of the)',
    score: 99,
    highlighted: '(<em>CD<%2Fem>) Congo (Democratic Republic of the)',
  },
  CK: {
    value: 'CK',
    label: '(CK) Cook Islands',
    score: 99,
    highlighted: '(<em>CK<%2Fem>) Cook Islands',
  },
  CR: {
    value: 'CR',
    label: '(CR) Costa Rica',
    score: 99,
    highlighted: '(<em>CR<%2Fem>) Costa Rica',
  },
  HR: {
    value: 'HR',
    label: '(HR) Croatia',
    score: 99,
    highlighted: '(<em>HR<%2Fem>) Croatia',
  },
  CY: {
    value: 'CY',
    label: '(CY) Cyprus',
    score: 194,
    highlighted: '(<em>CY<%2Fem>) <em>Cy<%2Fem>prus',
  },
  CZ: {
    value: 'CZ',
    label: '(CZ) Czech Republic',
    score: 194,
    highlighted: '(<em>CZ<%2Fem>) <em>Cz<%2Fem>ech Republic',
  },
  BJ: {
    value: 'BJ',
    label: '(BJ) Benin',
    score: 99,
    highlighted: '(<em>BJ<%2Fem>) Benin',
  },
  DK: {
    value: 'DK',
    label: '(DK) Denmark',
    score: 99,
    highlighted: '(<em>DK<%2Fem>) Denmark',
  },
  DM: {
    value: 'DM',
    label: '(DM) Dominica',
    score: 99,
    highlighted: '(<em>DM<%2Fem>) Dominica',
  },
  DO: {
    value: 'DO',
    label: '(DO) Dominican Republic',
    score: 194,
    highlighted: '(<em>DO<%2Fem>) <em>Do<%2Fem>minican Republic',
  },
  EC: {
    value: 'EC',
    label: '(EC) Ecuador',
    score: 194,
    highlighted: '(<em>EC<%2Fem>) <em>Ec<%2Fem>uador',
  },
  SV: {
    value: 'SV',
    label: '(SV) El Salvador',
    score: 99,
    highlighted: '(<em>SV<%2Fem>) El Salvador',
  },
  GQ: {
    value: 'GQ',
    label: '(GQ) Equatorial Guinea',
    score: 99,
    highlighted: '(<em>GQ<%2Fem>) Equatorial Guinea',
  },
  ET: {
    value: 'ET',
    label: '(ET) Ethiopia',
    score: 194,
    highlighted: '(<em>ET<%2Fem>) <em>Et<%2Fem>hiopia',
  },
  ER: {
    value: 'ER',
    label: '(ER) Eritrea',
    score: 194,
    highlighted: '(<em>ER<%2Fem>) <em>Er<%2Fem>itrea',
  },
  EE: {
    value: 'EE',
    label: '(EE) Estonia',
    score: 99,
    highlighted: '(<em>EE<%2Fem>) Estonia',
  },
  FO: {
    value: 'FO',
    label: '(FO) Faroe Islands',
    score: 99,
    highlighted: '(<em>FO<%2Fem>) Faroe Islands',
  },
  FK: {
    value: 'FK',
    label: '(FK) Falkland Islands (Malvinas)',
    score: 99,
    highlighted: '(<em>FK<%2Fem>) Falkland Islands (Malvinas)',
  },
  GS: {
    value: 'FK',
    label: '(FK) Falkland Islands (Malvinas)',
    score: 99,
    highlighted: '(<em>FK<%2Fem>) Falkland Islands (Malvinas)',
  },
  FJ: {
    value: 'FJ',
    label: '(FJ) Fiji',
    score: 99,
    highlighted: '(<em>FJ<%2Fem>) Fiji',
  },
  FI: {
    value: 'FI',
    label: '(FI) Finland',
    score: 194,
    highlighted: '(<em>FI<%2Fem>) <em>Fi<%2Fem>nland',
  },
  FR: {
    value: 'FR',
    label: '(FR) France',
    score: 194,
    highlighted: '(<em>FR<%2Fem>) <em>Fr<%2Fem>ance',
  },
  GF: {
    value: 'GF',
    label: '(GF) French Guiana',
    score: 99,
    highlighted: '(<em>GF<%2Fem>) French Guiana',
  },
  PF: {
    value: 'PF',
    label: '(PF) French Polynesia',
    score: 99,
    highlighted: '(<em>PF<%2Fem>) French Polynesia',
  },
  TF: {
    value: 'TF',
    label: '(TF) French Southern Territories',
    score: 99,
    highlighted: '(<em>TF<%2Fem>) French Southern Territories',
  },
  DJ: {
    value: 'DJ',
    label: '(DJ) Djibouti',
    score: 194,
    highlighted: '(<em>DJ<%2Fem>) <em>Dj<%2Fem>ibouti',
  },
  GA: {
    value: 'GA',
    label: '(GA) Gabon',
    score: 194,
    highlighted: '(<em>GA<%2Fem>) <em>Ga<%2Fem>bon',
  },
  GE: {
    value: 'GE',
    label: '(GE) Georgia',
    score: 194,
    highlighted: '(<em>GE<%2Fem>) <em>Ge<%2Fem>orgia',
  },
  GM: {
    value: 'GM',
    label: '(GM) Gambia (the)',
    score: 99,
    highlighted: '(<em>GM<%2Fem>) Gambia (the)',
  },
  PS: {
    value: 'PS',
    label: '(PS) Palestine',
    score: 99,
    highlighted: '(<em>PS<%2Fem>) Palestine',
  },
  DE: {
    value: 'DE',
    label: '(DE) Germany',
    score: 99,
    highlighted: '(<em>DE<%2Fem>) Germany',
  },
  GH: {
    value: 'GH',
    label: '(GH) Ghana',
    score: 194,
    highlighted: '(<em>GH<%2Fem>) <em>Gh<%2Fem>ana',
  },
  GI: {
    value: 'GI',
    label: '(GI) Gibraltar',
    score: 194,
    highlighted: '(<em>GI<%2Fem>) <em>Gi<%2Fem>braltar',
  },
  KI: {
    value: 'KI',
    label: '(KI) Kiribati',
    score: 194,
    highlighted: '(<em>KI<%2Fem>) <em>Ki<%2Fem>ribati',
  },
  GR: {
    value: 'GR',
    label: '(GR) Greece',
    score: 194,
    highlighted: '(<em>GR<%2Fem>) <em>Gr<%2Fem>eece',
  },
  GL: {
    value: 'GL',
    label: '(GL) Greenland',
    score: 99,
    highlighted: '(<em>GL<%2Fem>) Greenland',
  },
  GD: {
    value: 'GD',
    label: '(GD) Grenada',
    score: 99,
    highlighted: '(<em>GD<%2Fem>) Grenada',
  },
  GP: {
    value: 'GP',
    label: '(GP) Guadeloupe',
    score: 99,
    highlighted: '(<em>GP<%2Fem>) Guadeloupe',
  },
  GU: {
    value: 'GU',
    label: '(GU) Guam',
    score: 194,
    highlighted: '(<em>GU<%2Fem>) <em>Gu<%2Fem>am',
  },
  GT: {
    value: 'GT',
    label: '(GT) Guatemala',
    score: 99,
    highlighted: '(<em>GT<%2Fem>) Guatemala',
  },
  GN: {
    value: 'GN',
    label: '(GN) Guinea',
    score: 99,
    highlighted: '(<em>GN<%2Fem>) Guinea',
  },
  GY: {
    value: 'GY',
    label: '(GY) Guyana',
    score: 99,
    highlighted: '(<em>GY<%2Fem>) Guyana',
  },
  HT: {
    value: 'HT',
    label: '(HT) Haiti',
    score: 99,
    highlighted: '(<em>HT<%2Fem>) Haiti',
  },
  HM: {
    value: 'HM',
    label: '(HM) Heard Island and McDonald Islands',
    score: 99,
    highlighted: '(<em>HM<%2Fem>) Heard Island and McDonald Islands',
  },
  VA: {
    value: 'VA',
    label: '(VA) Holy See',
    score: 99,
    highlighted: '(<em>VA<%2Fem>) Holy See',
  },
  HN: {
    value: 'HN',
    label: '(HN) Honduras',
    score: 99,
    highlighted: '(<em>HN<%2Fem>) Honduras',
  },
  HK: {
    value: 'HK',
    label: '(HK) Hong Kong',
    score: 99,
    highlighted: '(<em>HK<%2Fem>) Hong Kong',
  },
  HU: {
    value: 'HU',
    label: '(HU) Hungary',
    score: 194,
    highlighted: '(<em>HU<%2Fem>) <em>Hu<%2Fem>ngary',
  },
  IS: {
    value: 'IS',
    label: '(IS) Iceland',
    score: 99,
    highlighted: '(<em>IS<%2Fem>) Iceland',
  },
  IN: {
    value: 'IN',
    label: '(IN) India',
    score: 194,
    highlighted: '(<em>IN<%2Fem>) <em>In<%2Fem>dia',
  },
  ID: {
    value: 'ID',
    label: '(ID) Indonesia',
    score: 99,
    highlighted: '(<em>ID<%2Fem>) Indonesia',
  },
  IQ: {
    value: 'IQ',
    label: '(IQ) Iraq',
    score: 99,
    highlighted: '(<em>IQ<%2Fem>) Iraq',
  },
  IE: {
    value: 'IE',
    label: '(IE) Ireland',
    score: 99,
    highlighted: '(<em>IE<%2Fem>) Ireland',
  },
  IL: {
    value: 'IL',
    label: '(IL) Israel',
    score: 99,
    highlighted: '(<em>IL<%2Fem>) Israel',
  },
  IT: {
    value: 'IT',
    label: '(IT) Italy',
    score: 194,
    highlighted: '(<em>IT<%2Fem>) <em>It<%2Fem>aly',
  },
  CI: {
    value: 'CI',
    label: "(CI) Côte d'Ivoire",
    score: 99,
    highlighted: "(<em>CI<%2Fem>) Côte d'Ivoire",
  },
  JM: {
    value: 'JM',
    label: '(JM) Jamaica',
    score: 99,
    highlighted: '(<em>JM<%2Fem>) Jamaica',
  },
  JP: {
    value: 'JP',
    label: '(JP) Japan',
    score: 99,
    highlighted: '(<em>JP<%2Fem>) Japan',
  },
  KZ: {
    value: 'KZ',
    label: '(KZ) Kazakhstan',
    score: 99,
    highlighted: '(<em>KZ<%2Fem>) Kazakhstan',
  },
  JO: {
    value: 'JO',
    label: '(JO) Jordan',
    score: 194,
    highlighted: '(<em>JO<%2Fem>) <em>Jo<%2Fem>rdan',
  },
  KE: {
    value: 'KE',
    label: '(KE) Kenya',
    score: 194,
    highlighted: '(<em>KE<%2Fem>) <em>Ke<%2Fem>nya',
  },
  KR: {
    value: 'KR',
    label: '(KR) Korea (Republic of)',
    score: 99,
    highlighted: '(<em>KR<%2Fem>) Korea (Republic of)',
  },
  KW: {
    value: 'KW',
    label: '(KW) Kuwait',
    score: 99,
    highlighted: '(<em>KW<%2Fem>) Kuwait',
  },
  KG: {
    value: 'KG',
    label: '(KG) Kyrgyzstan',
    score: 99,
    highlighted: '(<em>KG<%2Fem>) Kyrgyzstan',
  },
  LA: {
    value: 'LA',
    label: "(LA) Lao People's Democratic Republic",
    score: 194,
    highlighted: "(<em>LA<%2Fem>) <em>La<%2Fem>o People's Democratic Republic",
  },
  LB: {
    value: 'LB',
    label: '(LB) Lebanon',
    score: 99,
    highlighted: '(<em>LB<%2Fem>) Lebanon',
  },
  LS: {
    value: 'LS',
    label: '(LS) Lesotho',
    score: 99,
    highlighted: '(<em>LS<%2Fem>) Lesotho',
  },
  LV: {
    value: 'LV',
    label: '(LV) Latvia',
    score: 99,
    highlighted: '(<em>LV<%2Fem>) Latvia',
  },
  LR: {
    value: 'LR',
    label: '(LR) Liberia',
    score: 99,
    highlighted: '(<em>LR<%2Fem>) Liberia',
  },
  LY: {
    value: 'LY',
    label: '(LY) Libya',
    score: 99,
    highlighted: '(<em>LY<%2Fem>) Libya',
  },
  LI: {
    value: 'LI',
    label: '(LI) Liechtenstein',
    score: 194,
    highlighted: '(<em>LI<%2Fem>) <em>Li<%2Fem>echtenstein',
  },
  LT: {
    value: 'LT',
    label: '(LT) Lithuania',
    score: 99,
    highlighted: '(<em>LT<%2Fem>) Lithuania',
  },
  LU: {
    value: 'LU',
    label: '(LU) Luxembourg',
    score: 194,
    highlighted: '(<em>LU<%2Fem>) <em>Lu<%2Fem>xembourg',
  },
  MO: {
    value: 'MO',
    label: '(MO) Macao',
    score: 99,
    highlighted: '(<em>MO<%2Fem>) Macao',
  },
  MG: {
    value: 'MG',
    label: '(MG) Madagascar',
    score: 99,
    highlighted: '(<em>MG<%2Fem>) Madagascar',
  },
  MW: {
    value: 'MW',
    label: '(MW) Malawi',
    score: 99,
    highlighted: '(<em>MW<%2Fem>) Malawi',
  },
  MY: {
    value: 'MY',
    label: '(MY) Malaysia',
    score: 99,
    highlighted: '(<em>MY<%2Fem>) Malaysia',
  },
  MV: {
    value: 'MV',
    label: '(MV) Maldives',
    score: 99,
    highlighted: '(<em>MV<%2Fem>) Maldives',
  },
  ML: {
    value: 'ML',
    label: '(ML) Mali',
    score: 99,
    highlighted: '(<em>ML<%2Fem>) Mali',
  },
  MT: {
    value: 'MT',
    label: '(MT) Malta',
    score: 99,
    highlighted: '(<em>MT<%2Fem>) Malta',
  },
  MQ: {
    value: 'MQ',
    label: '(MQ) Martinique',
    score: 99,
    highlighted: '(<em>MQ<%2Fem>) Martinique',
  },
  MR: {
    value: 'MR',
    label: '(MR) Mauritania',
    score: 99,
    highlighted: '(<em>MR<%2Fem>) Mauritania',
  },
  MU: {
    value: 'MU',
    label: '(MU) Mauritius',
    score: 99,
    highlighted: '(<em>MU<%2Fem>) Mauritius',
  },
  MX: {
    value: 'MX',
    label: '(MX) Mexico',
    score: 99,
    highlighted: '(<em>MX<%2Fem>) Mexico',
  },
  MC: {
    value: 'MC',
    label: '(MC) Monaco',
    score: 99,
    highlighted: '(<em>MC<%2Fem>) Monaco',
  },
  MN: {
    value: 'MN',
    label: '(MN) Mongolia',
    score: 99,
    highlighted: '(<em>MN<%2Fem>) Mongolia',
  },
  MD: {
    value: 'MD',
    label: '(MD) Moldova (Republic of)',
    score: 99,
    highlighted: '(<em>MD<%2Fem>) Moldova (Republic of)',
  },
  ME: {
    value: 'ME',
    label: '(ME) Montenegro',
    score: 99,
    highlighted: '(<em>ME<%2Fem>) Montenegro',
  },
  MS: {
    value: 'MS',
    label: '(MS) Montserrat',
    score: 99,
    highlighted: '(<em>MS<%2Fem>) Montserrat',
  },
  MA: {
    value: 'MA',
    label: '(MA) Morocco',
    score: 99,
    highlighted: '(<em>MA<%2Fem>) Morocco',
  },
  MZ: {
    value: 'MZ',
    label: '(MZ) Mozambique',
    score: 99,
    highlighted: '(<em>MZ<%2Fem>) Mozambique',
  },
  OM: {
    value: 'OM',
    label: '(OM) Oman',
    score: 194,
    highlighted: '(<em>OM<%2Fem>) <em>Om<%2Fem>an',
  },
  NA: {
    value: 'NA',
    label: '(NA) Namibia',
    score: 194,
    highlighted: '(<em>NA<%2Fem>) <em>Na<%2Fem>mibia',
  },
  NR: {
    value: 'NR',
    label: '(NR) Nauru',
    score: 99,
    highlighted: '(<em>NR<%2Fem>) Nauru',
  },
  NP: {
    value: 'NP',
    label: '(NP) Nepal',
    score: 99,
    highlighted: '(<em>NP<%2Fem>) Nepal',
  },
  NL: {
    value: 'NL',
    label: '(NL) Netherlands',
    score: 99,
    highlighted: '(<em>NL<%2Fem>) Netherlands',
  },
  CW: {
    value: 'CW',
    label: '(CW) Curaçao',
    score: 99,
    highlighted: '(<em>CW<%2Fem>) Curaçao',
  },
  AW: {
    value: 'AW',
    label: '(AW) Aruba',
    score: 99,
    highlighted: '(<em>AW<%2Fem>) Aruba',
  },
  SX: {
    value: 'SX',
    label: '(SX) Sint Maarten (Dutch part)',
    score: 99,
    highlighted: '(<em>SX<%2Fem>) Sint Maarten (Dutch part)',
  },
  BQ: {
    value: 'BQ',
    label: '(BQ) Bonaire, Sint Eustatius and Saba',
    score: 99,
    highlighted: '(<em>BQ<%2Fem>) Bonaire, Sint Eustatius and Saba',
  },
  NC: {
    value: 'NC',
    label: '(NC) New Caledonia',
    score: 99,
    highlighted: '(<em>NC<%2Fem>) New Caledonia',
  },
  VU: {
    value: 'VU',
    label: '(VU) Vanuatu',
    score: 99,
    highlighted: '(<em>VU<%2Fem>) Vanuatu',
  },
  NZ: {
    value: 'NZ',
    label: '(NZ) New Zealand',
    score: 99,
    highlighted: '(<em>NZ<%2Fem>) New Zealand',
  },
  NI: {
    value: 'NI',
    label: '(NI) Nicaragua',
    score: 194,
    highlighted: '(<em>NI<%2Fem>) <em>Ni<%2Fem>caragua',
  },
  NE: {
    value: 'NE',
    label: '(NE) Niger',
    score: 99,
    highlighted: '(<em>NE<%2Fem>) Niger',
  },
  NG: {
    value: 'NG',
    label: '(NG) Nigeria',
    score: 99,
    highlighted: '(<em>NG<%2Fem>) Nigeria',
  },
  NU: {
    value: 'NU',
    label: '(NU) Niue',
    score: 99,
    highlighted: '(<em>NU<%2Fem>) Niue',
  },
  NF: {
    value: 'NF',
    label: '(NF) Norfolk Island',
    score: 99,
    highlighted: '(<em>NF<%2Fem>) Norfolk Island',
  },
  NO: {
    value: 'NO',
    label: '(NO) Norway',
    score: 194,
    highlighted: '(<em>NO<%2Fem>) <em>No<%2Fem>rway',
  },
  MP: {
    value: 'MP',
    label: '(MP) Northern Mariana Islands',
    score: 99,
    highlighted: '(<em>MP<%2Fem>) Northern Mariana Islands',
  },
  UM: {
    value: 'UM',
    label: '(UM) United States Minor Outlying Islands',
    score: 99,
    highlighted: '(<em>UM<%2Fem>) United States Minor Outlying Islands',
  },
  FM: {
    value: 'FM',
    label: '(FM) Micronesia (Federated States of)',
    score: 99,
    highlighted: '(<em>FM<%2Fem>) Micronesia (Federated States of)',
  },
  MH: {
    value: 'MH',
    label: '(MH) Marshall Islands',
    score: 99,
    highlighted: '(<em>MH<%2Fem>) Marshall Islands',
  },
  PW: {
    value: 'PW',
    label: '(PW) Palau',
    score: 99,
    highlighted: '(<em>PW<%2Fem>) Palau',
  },
  PK: {
    value: 'PK',
    label: '(PK) Pakistan',
    score: 99,
    highlighted: '(<em>PK<%2Fem>) Pakistan',
  },
  PA: {
    value: 'PA',
    label: '(PA) Panama',
    score: 194,
    highlighted: '(<em>PA<%2Fem>) <em>Pa<%2Fem>nama',
  },
  PG: {
    value: 'PG',
    label: '(PG) Papua New Guinea',
    score: 99,
    highlighted: '(<em>PG<%2Fem>) Papua New Guinea',
  },
  PY: {
    value: 'PY',
    label: '(PY) Paraguay',
    score: 99,
    highlighted: '(<em>PY<%2Fem>) Paraguay',
  },
  PE: {
    value: 'PE',
    label: '(PE) Peru',
    score: 194,
    highlighted: '(<em>PE<%2Fem>) <em>Pe<%2Fem>ru',
  },
  PH: {
    value: 'PH',
    label: '(PH) Philippines',
    score: 194,
    highlighted: '(<em>PH<%2Fem>) <em>Ph<%2Fem>ilippines',
  },
  PN: {
    value: 'PN',
    label: '(PN) Pitcairn',
    score: 99,
    highlighted: '(<em>PN<%2Fem>) Pitcairn',
  },
  PL: {
    value: 'PL',
    label: '(PL) Poland',
    score: 99,
    highlighted: '(<em>PL<%2Fem>) Poland',
  },
  PT: {
    value: 'PT',
    label: '(PT) Portugal',
    score: 99,
    highlighted: '(<em>PT<%2Fem>) Portugal',
  },
  GW: {
    value: 'GW',
    label: '(GW) Guinea-Bissau',
    score: 99,
    highlighted: '(<em>GW<%2Fem>) Guinea-Bissau',
  },
  TL: {
    value: 'TL',
    label: '(TL) Timor-Leste',
    score: 99,
    highlighted: '(<em>TL<%2Fem>) Timor-Leste',
  },
  PR: {
    value: 'PR',
    label: '(PR) Puerto Rico',
    score: 99,
    highlighted: '(<em>PR<%2Fem>) Puerto Rico',
  },
  QA: {
    value: 'QA',
    label: '(QA) Qatar',
    score: 194,
    highlighted: '(<em>QA<%2Fem>) <em>Qa<%2Fem>tar',
  },
  RE: {
    value: 'RE',
    label: '(RE) Réunion',
    score: 99,
    highlighted: '(<em>RE<%2Fem>) Réunion',
  },
  RO: {
    value: 'RO',
    label: '(RO) Romania',
    score: 194,
    highlighted: '(<em>RO<%2Fem>) <em>Ro<%2Fem>mania',
  },
  RW: {
    value: 'RW',
    label: '(RW) Rwanda',
    score: 194,
    highlighted: '(<em>RW<%2Fem>) <em>Rw<%2Fem>anda',
  },
  SH: {
    value: 'SH',
    label: '(SH) Saint Helena, Ascension and Tristan da Cunha',
    score: 99,
    highlighted: '(<em>SH<%2Fem>) Saint Helena, Ascension and Tristan da Cunha',
  },
  KN: {
    value: 'KN',
    label: '(KN) Saint Kitts and Nevis',
    score: 99,
    highlighted: '(<em>KN<%2Fem>) Saint Kitts and Nevis',
  },
  AI: {
    value: 'AI',
    label: '(AI) Anguilla',
    score: 99,
    highlighted: '(<em>AI<%2Fem>) Anguilla',
  },
  LC: {
    value: 'LC',
    label: '(LC) Saint Lucia',
    score: 99,
    highlighted: '(<em>LC<%2Fem>) Saint Lucia',
  },
  PM: {
    value: 'PM',
    label: '(PM) Saint Pierre and Miquelon',
    score: 99,
    highlighted: '(<em>PM<%2Fem>) Saint Pierre and Miquelon',
  },
  VC: {
    value: 'VC',
    label: '(VC) Saint Vincent and the Grenadines',
    score: 99,
    highlighted: '(<em>VC<%2Fem>) Saint Vincent and the Grenadines',
  },
  SM: {
    value: 'SM',
    label: '(SM) San Marino',
    score: 99,
    highlighted: '(<em>SM<%2Fem>) San Marino',
  },
  ST: {
    value: 'ST',
    label: '(ST) Sao Tome and Principe',
    score: 99,
    highlighted: '(<em>ST<%2Fem>) Sao Tome and Principe',
  },
  SA: {
    value: 'SA',
    label: '(SA) Saudi Arabia',
    score: 194,
    highlighted: '(<em>SA<%2Fem>) <em>Sa<%2Fem>udi Arabia',
  },
  SN: {
    value: 'SN',
    label: '(SN) Senegal',
    score: 99,
    highlighted: '(<em>SN<%2Fem>) Senegal',
  },
  RS: {
    value: 'RS',
    label: '(RS) Serbia',
    score: 99,
    highlighted: '(<em>RS<%2Fem>) Serbia',
  },
  SC: {
    value: 'SC',
    label: '(SC) Seychelles',
    score: 99,
    highlighted: '(<em>SC<%2Fem>) Seychelles',
  },
  SL: {
    value: 'SL',
    label: '(SL) Sierra Leone',
    score: 99,
    highlighted: '(<em>SL<%2Fem>) Sierra Leone',
  },
  SG: {
    value: 'SG',
    label: '(SG) Singapore',
    score: 99,
    highlighted: '(<em>SG<%2Fem>) Singapore',
  },
  SK: {
    value: 'SK',
    label: '(SK) Slovakia',
    score: 99,
    highlighted: '(<em>SK<%2Fem>) Slovakia',
  },
  VN: {
    value: 'VN',
    label: '(VN) Viet Nam',
    score: 99,
    highlighted: '(<em>VN<%2Fem>) Viet Nam',
  },
  SI: {
    value: 'SI',
    label: '(SI) Slovenia',
    score: 99,
    highlighted: '(<em>SI<%2Fem>) Slovenia',
  },
  SO: {
    value: 'SO',
    label: '(SO) Somalia',
    score: 194,
    highlighted: '(<em>SO<%2Fem>) <em>So<%2Fem>malia',
  },
  ZA: {
    value: 'ZA',
    label: '(ZA) South Africa',
    score: 99,
    highlighted: '(<em>ZA<%2Fem>) South Africa',
  },
  ZW: {
    value: 'ZW',
    label: '(ZW) Zimbabwe',
    score: 99,
    highlighted: '(<em>ZW<%2Fem>) Zimbabwe',
  },
  ES: {
    value: 'ES',
    label: '(ES) Spain',
    score: 99,
    highlighted: '(<em>ES<%2Fem>) Spain',
  },
  EH: {
    value: 'EH',
    label: '(EH) Western Sahara',
    score: 99,
    highlighted: '(<em>EH<%2Fem>) Western Sahara',
  },
  SR: {
    value: 'SR',
    label: '(SR) Suriname',
    score: 99,
    highlighted: '(<em>SR<%2Fem>) Suriname',
  },
  SJ: {
    value: 'SJ',
    label: '(SJ) Svalbard and Jan Mayen',
    score: 99,
    highlighted: '(<em>SJ<%2Fem>) Svalbard and Jan Mayen',
  },
  SZ: {
    value: 'SZ',
    label: '(SZ) Eswatini',
    score: 99,
    highlighted: '(<em>SZ<%2Fem>) Eswatini',
  },
  SE: {
    value: 'SE',
    label: '(SE) Sweden',
    score: 99,
    highlighted: '(<em>SE<%2Fem>) Sweden',
  },
  CH: {
    value: 'CH',
    label: '(CH) Switzerland',
    score: 99,
    highlighted: '(<em>CH<%2Fem>) Switzerland',
  },
  TJ: {
    value: 'TJ',
    label: '(TJ) Tajikistan',
    score: 99,
    highlighted: '(<em>TJ<%2Fem>) Tajikistan',
  },
  TH: {
    value: 'TH',
    label: '(TH) Thailand',
    score: 194,
    highlighted: '(<em>TH<%2Fem>) <em>Th<%2Fem>ailand',
  },
  TG: {
    value: 'TG',
    label: '(TG) Togo',
    score: 99,
    highlighted: '(<em>TG<%2Fem>) Togo',
  },
  TK: {
    value: 'TK',
    label: '(TK) Tokelau',
    score: 99,
    highlighted: '(<em>TK<%2Fem>) Tokelau',
  },
  TO: {
    value: 'TO',
    label: '(TO) Tonga',
    score: 194,
    highlighted: '(<em>TO<%2Fem>) <em>To<%2Fem>nga',
  },
  AE: {
    value: 'AE',
    label: '(AE) UAE',
    score: 193,
    highlighted: '(<em>AE<%2Fem>) U<em>AE<%2Fem>',
  },
  TN: {
    value: 'TN',
    label: '(TN) Tunisia',
    score: 99,
    highlighted: '(<em>TN<%2Fem>) Tunisia',
  },
  TR: {
    value: 'TR',
    label: '(TR) Türkiye',
    score: 99,
    highlighted: '(<em>TR<%2Fem>) Türkiye',
  },
  TM: {
    value: 'TM',
    label: '(TM) Turkmenistan',
    score: 99,
    highlighted: '(<em>TM<%2Fem>) Turkmenistan',
  },
  TC: {
    value: 'TC',
    label: '(TC) Turks and Caicos Islands',
    score: 99,
    highlighted: '(<em>TC<%2Fem>) Turks and Caicos Islands',
  },
  TV: {
    value: 'TV',
    label: '(TV) Tuvalu',
    score: 99,
    highlighted: '(<em>TV<%2Fem>) Tuvalu',
  },
  UG: {
    value: 'UG',
    label: '(UG) Uganda',
    score: 194,
    highlighted: '(<em>UG<%2Fem>) <em>Ug<%2Fem>anda',
  },
  UA: {
    value: 'UA',
    label: '(UA) Ukraine',
    score: 99,
    highlighted: '(<em>UA<%2Fem>) Ukraine',
  },
  MK: {
    value: 'MK',
    label: '(MK) Republic of North Macedonia',
    score: 99,
    highlighted: '(<em>MK<%2Fem>) Republic of North Macedonia',
  },
  EG: {
    value: 'EG',
    label: '(EG) Egypt',
    score: 194,
    highlighted: '(<em>EG<%2Fem>) <em>Eg<%2Fem>ypt',
  },
  GB: {
    value: 'GB',
    label: '(GB) UK',
    score: 99,
    highlighted: '(<em>GB<%2Fem>) UK',
  },
  GG: {
    value: 'GG',
    label: '(GG) Guernsey',
    score: 99,
    highlighted: '(<em>GG<%2Fem>) Guernsey',
  },
  JE: {
    value: 'JE',
    label: '(JE) Jersey',
    score: 194,
    highlighted: '(<em>JE<%2Fem>) <em>Je<%2Fem>rsey',
  },
  IM: {
    value: 'IM',
    label: '(IM) Isle of Man',
    score: 99,
    highlighted: '(<em>IM<%2Fem>) Isle of Man',
  },
  TZ: {
    value: 'TZ',
    label: '(TZ) Tanzania, United Republic of',
    score: 99,
    highlighted: '(<em>TZ<%2Fem>) Tanzania, United Republic of',
  },
  US: {
    value: 'US',
    label: '(US) USA',
    score: 194,
    highlighted: '(<em>US<%2Fem>) <em>US<%2Fem>A',
  },
  VI: {
    value: 'VI',
    label: '(VI) Virgin Islands (U.S.)',
    score: 194,
    highlighted: '(<em>VI<%2Fem>) <em>Vi<%2Fem>rgin Islands (U.S.)',
  },
  BF: {
    value: 'BF',
    label: '(BF) Burkina Faso',
    score: 99,
    highlighted: '(<em>BF<%2Fem>) Burkina Faso',
  },
  UY: {
    value: 'UY',
    label: '(UY) Uruguay',
    score: 99,
    highlighted: '(<em>UY<%2Fem>) Uruguay',
  },
  UZ: {
    value: 'UZ',
    label: '(UZ) Uzbekistan',
    score: 194,
    highlighted: '(<em>UZ<%2Fem>) <em>Uz<%2Fem>bekistan',
  },
  VE: {
    value: 'VE',
    label: '(VE) Venezuela (Bolivarian Republic of)',
    score: 194,
    highlighted: '(<em>VE<%2Fem>) <em>Ve<%2Fem>nezuela (Bolivarian Republic of)',
  },
  WF: {
    value: 'WF',
    label: '(WF) Wallis and Futuna',
    score: 99,
    highlighted: '(<em>WF<%2Fem>) Wallis and Futuna',
  },
  WS: {
    value: 'WS',
    label: '(WS) Samoa',
    score: 99,
    highlighted: '(<em>WS<%2Fem>) Samoa',
  },
  YE: {
    value: 'YE',
    label: '(YE) Yemen',
    score: 194,
    highlighted: '(<em>YE<%2Fem>) <em>Ye<%2Fem>men',
  },
  ZM: {
    value: 'ZM',
    label: '(ZM) Zambia',
    score: 99,
    highlighted: '(<em>ZM<%2Fem>) Zambia',
  },
  XK: {
    value: 'XK',
    label: '(XK) Republic of Kosovo',
    score: 99,
    highlighted: '(<em>XK<%2Fem>) Republic of Kosovo',
  },
};
