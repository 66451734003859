import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import vi from './locales/vi.json';

const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
} as any;

export const LANGUAGE_PERSIST_KEY = 'APP_LOCALE';

export const languagePersistLayer = window.localStorage;

export const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng).then(() => {
    languagePersistLayer.setItem(LANGUAGE_PERSIST_KEY, lng);
  });
};

(window as any).changeLanguage = changeLanguage;

export default {
  init: () => {
    i18n.use(initReactI18next).init({
      resources,
      fallbackLng: 'en',
      lng: languagePersistLayer.getItem(LANGUAGE_PERSIST_KEY) || 'en',
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
      },
    });
  },
};
