import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { get, isEmpty, isFunction, slice } from 'lodash';
import { useMemo, useState } from 'react';
import { Bookmark, Calendar } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import AvatarUser from 'src/components/common/AvatarUser';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FlagCountry from 'src/components/common/FlagCountry';
// import { UserGroupIcon } from 'src/components/Icons';
import IconFeather from 'src/components/common/Icon';
import LogoCompany from 'src/components/common/LogoCompany';
import { useQueryQuickViewPeopleProfile } from 'src/services/queries';
import { UnknownText } from 'src/utils/constants/config';
import { NATIONALITIES } from 'src/utils/constants/nationality';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { convertToStartCase, getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';

// Utilities
interface IProps {
  id?: string;
  callback?: () => void;
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const PeopleProfileDetail = ({ id, callback }: IProps) => {
  const {
    data: { data },
    isFetching,
  } = useQueryQuickViewPeopleProfile(id || '');
  const DEFAULT_NUMBER_SKILLS_SHOW = 5;
  const DEFAULT_NUMBER_COMPANIES_SHOW = 3;
  const [expandSkill, setExpandSkill] = useState(false as boolean);
  const [expandCompany, setExpandCompany] = useState(false as boolean);
  const theme = useTheme();
  const navigate = useNavigate();

  const country = useMemo(() => {
    if (data?.country_code) return getCountryByCountryCode(data?.country_code || '');

    const countryCode = get(NATIONALITIES, data?.nationality || '', '');
    return getCountryByCountryCode(countryCode);
  }, [data]);

  const navigateToDetail = () => {
    if (id) {
      navigate(AuthRouteKeys.PeopleDetail.replace(':id?', id));
    }
    isFunction(callback) && callback();
  };

  const renderListSkills = () => {
    const listSkills = getArrayDefault(data?.skills);
    if (listSkills.length <= DEFAULT_NUMBER_SKILLS_SHOW) {
      return (
        <>
          {listSkills.length > 0 ? (
            listSkills.map((skill, index) => <Chip key={`skill-people-${index}-${skill}`} size="small" label={skill} />)
          ) : (
            <Chip size="small" label="##" />
          )}
        </>
      );
    }

    if (!expandSkill) {
      return (
        <>
          {slice(listSkills, 0, DEFAULT_NUMBER_SKILLS_SHOW).map((skill, index) => (
            <Chip key={`skill-people-${index}-${skill}`} size="small" label={skill} />
          ))}
          <Chip
            color="primary"
            size="small"
            label={`+${listSkills.length - DEFAULT_NUMBER_SKILLS_SHOW} Skills`}
            onClick={() => setExpandSkill(!expandSkill)}
          />
        </>
      );
    } else {
      return (
        <>
          {listSkills.map((skill, index) => (
            <Chip key={`skill-people-${index}-${skill}`} size="small" label={skill} />
          ))}
          <Chip color="primary" size="small" label="Show Less" onClick={() => setExpandSkill(!expandSkill)} />
        </>
      );
    }
  };

  const renderListCompanies = () => {
    const listCompanies = getArrayDefault(data?.companies);
    if (listCompanies.length <= DEFAULT_NUMBER_COMPANIES_SHOW) {
      return (
        <>
          {listCompanies.length > 0 ? (
            listCompanies.map((company, index) => (
              <Stack key={`company-people-${index}-${company.company_name}`} direction="row" spacing={1} flex={1}>
                <LogoCompany logoURL={company?.image_url || ''} width={32} height={32} />
                <Box flex={1}>
                  <Typography variant="subtitle2">
                    {convertToStartCase(company?.company_name || UnknownText)}
                  </Typography>
                  <Stack
                    divider={<Divider orientation="vertical" variant="middle" flexItem />}
                    direction="row"
                    spacing={1}
                  >
                    <Typography variant="caption2">{convertToStartCase(company?.position || UnknownText)}</Typography>
                  </Stack>
                </Box>
              </Stack>
            ))
          ) : (
            <Chip size="small" label="##" />
          )}
        </>
      );
    }

    if (!expandCompany) {
      return (
        <>
          {slice(listCompanies, 0, DEFAULT_NUMBER_COMPANIES_SHOW).map((company, index) => (
            <Stack key={`company-people-${index}-${company.company_name}`} direction="row" spacing={1} flex={1}>
              <LogoCompany logoURL={company?.image_url || ''} width={32} height={32} />
              <Box flex={1}>
                <Typography variant="subtitle2">{convertToStartCase(company?.company_name || UnknownText)}</Typography>
                <Stack
                  divider={<Divider orientation="vertical" variant="middle" flexItem />}
                  direction="row"
                  spacing={1}
                >
                  <Typography variant="caption2">{convertToStartCase(company?.position || UnknownText)}</Typography>
                </Stack>
              </Box>
            </Stack>
          ))}
          <Button variant="text" color="primary" onClick={() => setExpandCompany(!expandCompany)}>
            {`+${listCompanies.length - DEFAULT_NUMBER_COMPANIES_SHOW} The company worked`}
          </Button>
        </>
      );
    } else {
      return (
        <>
          {listCompanies.map((company, index) => (
            <Stack key={`company-people-${index}-${company.company_name}`} direction="row" spacing={1} flex={1}>
              <LogoCompany logoURL={company?.image_url || ''} width={32} height={32} />
              <Box flex={1}>
                <Typography variant="subtitle2">{convertToStartCase(company?.company_name || UnknownText)}</Typography>
                <Stack
                  divider={<Divider orientation="vertical" variant="middle" flexItem />}
                  direction="row"
                  spacing={1}
                >
                  <Typography variant="caption2">{convertToStartCase(company?.position || UnknownText)}</Typography>
                </Stack>
              </Box>
            </Stack>
          ))}
          <Button variant="text" color="primary" onClick={() => setExpandCompany(!expandCompany)}>
            Show Less
          </Button>
        </>
      );
    }
  };
  return (
    <Card>
      <CardHeader
        title="Quick View Panel"
        sx={{
          background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
          p: 2,
        }}
        titleTypographyProps={{
          color: 'white',
          align: 'center',
        }}
      />
      {isFetching ? (
        <SkeletonContent />
      ) : (
        <StyledCardContent sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                {/* <Avatar
                  alt={data?.full_name || UnknownText}
                  sizes="100"
                  variant="rounded"
                  sx={{ width: 64, height: 64, border: '2px solid #fff' }}
                >
                  {(data?.full_name || UnknownText).charAt(0).toUpperCase()}
                </Avatar> */}
                <AvatarUser
                  alt={data?.full_name || UnknownText}
                  sizes="100"
                  variant="rounded"
                  sx={{ width: 64, height: 64, backgroundColor: alpha(theme.palette.primary.main, 0.07) }}
                />
                <Tooltip title="Bookmarks Coming Soon!">
                  <IconButton sx={{ border: `1px solid ${theme.palette.divider}`, height: 'fit-content' }} size="small">
                    <IconFeather icon={<Bookmark />} muiProps={{ fontSize: 'small' }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="subtitle1">{convertToStartCase(data?.full_name || UnknownText)}</Typography>
              {data?.position && (
                <Typography variant="caption2" color="text.secondary">
                  {convertToStartCase(data?.position || UnknownText)}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1}>
              <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                <FlagCountry width={20} code={country?.code || ''} />
                <Typography variant="caption2" color="text.secondary">
                  {convertToStartCase(country?.label || UnknownText)}
                </Typography>
              </Box>
              {/* <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                <UserGroupIcon fontSize="small" color="disabled" />
                <Typography variant="caption2" color="text.secondary">
                  Male
                </Typography>
              </Box> */}
              {data?.birthday && (
                <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                  <IconFeather muiProps={{ fontSize: 'small', color: 'disabled' }} icon={<Calendar size={24} />} />{' '}
                  <Typography variant="caption2" color="text.secondary">
                    {data?.birthday || UnknownText}
                  </Typography>
                </Box>
              )}
              {data?.description && (
                <Typography variant="caption" color="text.secondary">
                  {data?.description || UnknownText}
                </Typography>
              )}
            </Stack>
            <Divider />
            <Box display="flex" flexDirection={'column'} rowGap={1} alignItems="flex-start">
              {renderListCompanies()}
            </Box>
            <Divider />
            {!isEmpty(data?.skills) && (
              <Stack spacing={1} direction="row" alignItems="center" flexWrap="wrap" rowGap={1}>
                <Typography variant="subtitle2">Skills</Typography>
                {renderListSkills()}
              </Stack>
            )}
            <ButtonWithLoading
              fullWidth
              size="large"
              variant="contained"
              isRounded
              endIcon={<ArrowForwardIcon />}
              onClick={navigateToDetail}
            >
              Full Profile
            </ButtonWithLoading>
          </Stack>
        </StyledCardContent>
      )}
    </Card>
  );
};

export default PeopleProfileDetail;

const SkeletonContent = () => {
  return (
    <StyledCardContent>
      <Box>
        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} mb={1}>
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={24} />
        </Stack>
        <Skeleton animation="wave" variant="rectangular" width={60} />
      </Box>
      <Stack spacing={1}>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Skeleton animation="wave" variant="rectangular" width={120} />
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={24} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
        <Stack gap={1} direction={'row'} alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={120} />
          <Skeleton animation="wave" variant="rectangular" width={120} />
        </Stack>
      </Stack>
      <Divider />
      <Stack gap={1} flexWrap={'wrap'} alignItems={'center'} direction={'row'}>
        <Skeleton animation="wave" variant="rectangular" width={60} />
        <Skeleton animation="wave" variant="rectangular" width={120} />
      </Stack>
      <Stack gap={1} flexWrap={'wrap'} alignItems={'center'} direction={'row'}>
        <Skeleton animation="wave" variant="rectangular" width={60} />
        <Skeleton animation="wave" variant="rectangular" width={120} />
      </Stack>
      <Skeleton animation="wave" height={56} />
    </StyledCardContent>
  );
};
