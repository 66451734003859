import React from 'react';
import { Stack, Skeleton, Grid } from '@mui/material';

// Components
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';

const SkeletonOverview = () => {
  return (
    <React.Fragment>
      <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <StyledWhiteBox flex={1}>
            <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </StyledWhiteBox>
          <StyledWhiteBox flex={1}>
            <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </StyledWhiteBox>
        </Stack>
        <StyledWhiteBox>
          <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </StyledWhiteBox>
        <StyledWhiteBox>
          <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
          <Grid container spacing={2} mt={3}>
            <Grid item xs={4} md={3}>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Grid>
            <Grid item xs={4} md={3}>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Grid>
            <Grid item xs={4} md={3}>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Grid>
            <Grid item xs={4} md={3}>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Grid>
          </Grid>
        </StyledWhiteBox>
      </Stack>
    </React.Fragment>
  );
};

export default SkeletonOverview;
