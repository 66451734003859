import React from 'react';
import ModalComponent from 'src/components/Modal/ModalComponent';

// Utilities

interface IProps {
  open: boolean;
  handleClose: () => void;
  videoUrl: string;
  title: string;
}

const PopupVideo: React.FC<IProps> = ({ open, handleClose, videoUrl, title }) => {
  return (
    <ModalComponent padding={4} open={open} handleClose={handleClose}>
      <iframe
        width="900"
        height="500"
        src={videoUrl}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </ModalComponent>
  );
};

export default PopupVideo;
