import { Box, CircularProgress, Stack, styled, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
//Components
import ButtonBack from 'src/components/common/ButtonBack';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import InputOtp from 'src/components/common/InputOtp';

//Utilities
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TIMER } from 'src/components/common/PopupOTP';
import useCountDown from 'src/hooks/useCountDown';
import { useNotification } from 'src/hooks/useNotification';
import { UserSignUp } from 'src/model/auth';
import { useMutateResendOTP, useMutateSignup, useMutateVerifyOTP } from 'src/services/mutate/useMutateAuth';
import { ACCESS_TOKEN_KEY, LENGTH_OTP_VALIDATE, REFRESH_TOKEN_KEY } from 'src/utils/constants/config';
import { AuthRouteKeys, NoAuthRouteKeys } from 'src/utils/constants/routes';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { useSignupStore } from 'src/zustand/useSignupStore';
import { useVerifyTypeStore } from 'src/zustand/useVerifyTypeStore';

const StyledButton = styled(ButtonWithLoading)(() => ({
  padding: 0,
  textTransform: 'none',
  bottom: 1,
  fontSize: '1rem',
  marginLeft: 4,
  textDecoration: 'underline',
}));

const FormTwoStepVerification = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const mutateResendOTP = useMutateResendOTP();
  const { email, password, first_name, last_name, reset } = useSignupStore();
  const { type } = useVerifyTypeStore();
  const signupMutation = useMutateSignup();
  const { notification } = useNotification();
  const { t } = useTranslation();

  const { timeLeft, resetimer } = useCountDown(DEFAULT_TIMER, true);

  const minute = Math.floor(timeLeft / 60);
  const second = numeral(timeLeft % 60).format('00');
  const percent = Math.floor((timeLeft * 100) / DEFAULT_TIMER);

  const { isPending, mutateAsync } = useMutateVerifyOTP();

  const handleVerify = async () => {
    const dto = {
      email: email,
      code: otp,
    } as TypeDTOVerifyOTP;
    const responseVerifyOTP = await mutateAsync(dto);
    if (responseVerifyOTP.status_code === SUCCESS_CODE.GET) {
      if (type === 'SIGNUP') {
        handleSignup();
      } else {
        handleForgotPass();
      }
    }
  };

  const handleSignup = async () => {
    const dtoSignUp = {
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
    } as TypeDTOSignup;

    const resSignup: UserSignUp = await signupMutation.mutateAsync(dtoSignUp);
    notification(
      resSignup,
      {
        title: t('signup.notification.success.title'),
        description: t('signup.notification.success.description'),
      },
      {
        title: t('signup.notification.error.title'),
        description: t('signup.notification.error.description'),
      },
    );
    if (resSignup?.status_code === SUCCESS_CODE.CREATE) {
      reset();
      localStorage.setItem(ACCESS_TOKEN_KEY, resSignup.data.access_token || '');
      localStorage.setItem(REFRESH_TOKEN_KEY, resSignup.data.access_token || '');
      navigate({ pathname: AuthRouteKeys.Onboarding });
    }
  };

  const handleForgotPass = () => {
    navigate({ pathname: NoAuthRouteKeys.ResetPassword }, { state: { otp: otp } });
  };

  const handleResendCode = async () => {
    resetimer();
    setOtp('');
    const dto = {
      email: email,
    } as TypeDTOCreateOTP;

    await mutateResendOTP.mutateAsync(dto);
  };

  return (
    <Box sx={{ width: 500 }}>
      {/* <ButtonBack href={NoAuthRouteKeys.Login} /> */}
      <ButtonBack sx={{ position: 'absolute', top: 40 }} />
      <Typography variant="h3">2-Step Verification</Typography>

      <Box sx={{ mt: 1 }} />

      <Typography color={theme.palette.text.secondary}>
        We have sent a six-digit number to your email address. Please check your spam folder as well, and then enter the
        code you received.{' '}
      </Typography>

      <Box sx={{ mt: 3 }} />

      <Typography variant="body1" color={theme.palette.text.secondary}>
        If you haven't received the email, please
        <StyledButton isLoading={mutateResendOTP.isPending} onClick={handleResendCode} color="secondary">
          click here to resend.
        </StyledButton>
      </Typography>

      <Box sx={{ mt: 4 }} />

      <Stack alignItems={'center'} justifyContent={'center'} flexDirection={'row'} gap={2}>
        <CircularProgress
          disableShrink
          thickness={22}
          color="primary"
          size={20}
          variant="determinate"
          value={percent}
        />
        <Typography variant="h5">
          {minute}:{second}
        </Typography>
      </Stack>

      <Box sx={{ mt: 4 }} display={'flex'} flexDirection={'column'}>
        <InputOtp setOtp={setOtp} otp={otp} />
      </Box>

      <Box sx={{ mt: 2 }} />

      <ButtonWithLoading
        isLoading={isPending}
        onClick={handleVerify}
        type="submit"
        sx={{ fontWeight: theme.typography.fontWeightMedium, height: 50 }}
        size="large"
        isRounded
        variant="contained"
        fullWidth
        disabled={otp.length < LENGTH_OTP_VALIDATE || isPending}
      >
        Verify
      </ButtonWithLoading>
    </Box>
  );
};

export default FormTwoStepVerification;
