import { Stack, Typography } from '@mui/material';
import { isEmpty, startCase } from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import Table from 'src/components/Table';
import ContributeForNoData from 'src/features/CompanyDetail/Common/ContributeForNoData';
// import PopupAlertsDetail from 'src/features/CompanyDetail/Alerts/PopupAlertsDetail';

// Utilities
import { useQueryListSanctionsCompanies } from 'src/services/queries';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { LIMIT_GET_LIST } from 'src/utils/constants/config';
import { formatUrlNonProtocol, renderTextOrDefault } from 'src/utils/helpers/stringHelpers';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { OpenInNew } from '@mui/icons-material';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';

const Sanctions = () => {
  const { id } = useParams();
  const [params, setParams] = useState({
    limit: LIMIT_GET_LIST,
    offset: 0,
  });
  // const [openAlertDetail, setOpenAlertDetail] = useState<{ open: boolean; alert: IAlertCompany | null }>({
  //   open: false,
  //   alert: null,
  // });
  const { data, isFetching } = useQueryListSanctionsCompanies({
    id: id || '',
    params,
  });

  // const handleOpenAlertDetail = (alert: IAlertCompany) => {
  //   setOpenAlertDetail({ open: true, alert: alert });
  // };

  // const handleCloseAlertDetail = () => {
  //   setOpenAlertDetail({ open: false, alert: null });
  // };

  const configColumn: ConfigCell<IAlertCompany>[] = [
    {
      id: 'authority',
      disablePadding: true,
      label: 'Authority',
      align: 'left',
      // width: 300,
      cell: (row: IAlertCompany) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(row.authority)}
          </Typography>
        );
      },
    },
    {
      id: 'program',
      disablePadding: false,
      label: 'Program',
      align: 'right',
      cell: (row: IAlertCompany) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(startCase(row.program || ''))}
          </Typography>
        );
      },
    },
    {
      id: 'start_date',
      disablePadding: false,
      // width: 200,
      label: 'Start Date',
      align: 'right',
      cell: (row: IAlertCompany) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(row?.start_date || '')}
          </Typography>
        );
      },
    },
    {
      id: 'end_date',
      disablePadding: false,
      // width: 200,
      label: 'End Date',
      align: 'right',
      cell: (row: IAlertCompany) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(row?.end_date || '')}
          </Typography>
        );
      },
    },
    {
      id: 'type',
      disablePadding: false,
      label: 'Source Link',
      align: 'right',
      cell: (row: IAlertCompany) => {
        return (
          <RouterLinkNoUnderline to={formatUrlNonProtocol(row?.source_link || '')} target="_blank">
            <Stack spacing={0.5} direction="row">
              <Typography ml={1} variant="body2" color="primary.main">
                Source
              </Typography>
              <OpenInNew fontSize="small" color="primary" />
            </Stack>
          </RouterLinkNoUnderline>
        );
      },
    },
  ];

  if (!isFetching && isEmpty(data.data)) {
    return (
      <StyledWhiteBox display="flex" flexDirection="column">
        <ContributeForNoData />
      </StyledWhiteBox>
    );
  }

  return (
    <React.Fragment>
      <StyledWhiteBox>
        <Stack spacing={3}>
          <Typography variant="h5" align="left">
            Alerts
          </Typography>
          <Table
            maxRow={5}
            // onRowClick={handleOpenAlertDetail}
            onChangePage={(e) => setParams({ ...params, ...{ offset: e } })}
            onChangePageSize={(e) => setParams({ ...params, ...{ offset: 0, limit: e } })}
            page={params.offset}
            pageSize={params.limit}
            loading={isFetching}
            data={getArrayDefault(data?.data)}
            totalRecords={data.total_docs_count}
            configColumn={configColumn}
            dataKey="id"
          />
        </Stack>
      </StyledWhiteBox>
      {/* <PopupAlertsDetail
        open={openAlertDetail.open}
        alert={openAlertDetail.alert}
        handleClose={handleCloseAlertDetail}
      /> */}
    </React.Fragment>
  );
};

export default Sanctions;
