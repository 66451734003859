import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  Chip,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FormikContextType } from 'formik';
import update from 'immutability-helper';
import { get } from 'lodash';
import { useId, useState } from 'react';
import { CheckCircle, Edit } from 'react-feather';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import IconFeather from 'src/components/common/Icon';
import { TypeChange } from 'src/features/Setting/Password';
import { useMutateValidatePassword } from 'src/services/mutate';
import { useQueryUserInfo } from 'src/services/queries';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { formatTimeUTCToFromNow } from 'src/utils/helpers/dayjs';
import { validatePassword } from 'src/utils/helpers/validate';
import useSnackbarStore from 'src/zustand/useSnackbarStore';
dayjs.extend(utc);

const FormItemPassword = ({
  formik,
  open2FA,
  isEdit,
  onClickEdit,
  onCancel,
}: {
  formik: FormikContextType<ExpectedAny>;
  open2FA: () => void;
  isEdit: { type: TypeChange; visible: boolean };
  onClickEdit: () => void;
  onCancel: () => void;
}) => {
  const [visible, setVisible] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });
  const { dispatch } = useSnackbarStore();

  const getError = (type: string) => {
    return get(formik.touched, type) && Boolean(get(formik.errors, type));
  };
  const id = useId();
  const { data } = useQueryUserInfo();
  const { mutateAsync, isPending } = useMutateValidatePassword();

  const theme = useTheme();

  const renderError = (type: string) => {
    return (
      get(formik.touched, type) &&
      get(formik.errors, type) && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {get(formik.errors, type, '').toString()}
        </FormHelperText>
      )
    );
  };

  const renderAdornment = (visible: boolean, onClick: () => void) => {
    return (
      <InputAdornment position="end">
        {visible ? (
          <IconButton onClick={onClick} size="small">
            <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
          </IconButton>
        ) : (
          <IconButton onClick={onClick} size="small">
            <VisibilityOffOutlinedIcon sx={{ fontSize: 16 }} />
          </IconButton>
        )}
      </InputAdornment>
    );
  };

  const onChangeVisible = (type: string) => {
    const state = update(visible, {
      [type]: {
        $set: !get(visible, type),
      },
    });
    setVisible(state);
  };

  const validateForm = async () => {
    const validatePassword = await mutateAsync(formik.values.password);
    if (validatePassword.status_code !== SUCCESS_CODE.GET) {
      dispatch({
        open: true,
        title: 'Error',
        description: validatePassword.message,
        variant: 'standard',
        color: 'error',
      });
      return;
    }

    const validate = await formik.validateForm();
    if (validate['password'] || validate['confirm_password'] || validate['new_password']) return;

    open2FA();
  };

  const getTypeInput = (visible: boolean) => {
    return visible ? 'text' : 'password';
  };

  const renderFormEdit = (
    <Box maxWidth={500}>
      <Typography variant="subtitle1">Password</Typography>
      <Typography color={theme.palette.text.secondary} variant="caption2">
        Please enter your current password to change your password
      </Typography>

      <Box mt={3} />

      <Stack gap={2}>
        <Box>
          <TextField
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="password"
            name="password"
            type={getTypeInput(visible.password)}
            error={getError('password')}
            InputProps={{
              endAdornment: renderAdornment(visible.password, () => onChangeVisible('password')),
            }}
            required
            size="small"
            label="Current Password"
          />
          {renderError('password')}
        </Box>

        <Box>
          <TextField
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="new_password"
            name="new_password"
            error={getError('new_password')}
            InputProps={{
              endAdornment: renderAdornment(visible.newPassword, () => onChangeVisible('newPassword')),
            }}
            type={getTypeInput(visible.newPassword)}
            required
            size="small"
            label="New Password"
          />
          {renderError('new_password')}
        </Box>

        <Box>
          <TextField
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="confirm_password"
            name="confirm_password"
            error={getError('confirm_password')}
            type={getTypeInput(visible.confirmPassword)}
            InputProps={{
              endAdornment: renderAdornment(visible.confirmPassword, () => onChangeVisible('confirmPassword')),
            }}
            required
            size="small"
            label="Confirm New Password"
          />
          {renderError('confirm_password')}
        </Box>
      </Stack>

      <Box mt={3} />

      {formik.values.new_password ? (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {validatePassword(formik.values.new_password).map((itemCheck) => {
            return (
              <Stack alignItems={'center'} key={id} direction="row" spacing={0.5}>
                <IconFeather
                  muiProps={{ color: itemCheck.valid ? 'success' : 'disabled', fontSize: 'small' }}
                  icon={<CheckCircle />}
                />
                <Typography variant="caption2" color={itemCheck.valid ? 'success.main' : 'text.disabled'}>
                  {itemCheck.title}
                </Typography>
              </Stack>
            );
          })}
        </Box>
      ) : null}

      <Box mt={3} />

      <ButtonWithLoading isLoading={isPending} type="submit" onClick={validateForm} variant="contained" size="large">
        CHANGE PASSWORD
      </ButtonWithLoading>
      <Button onClick={onCancel} sx={{ ml: 1 }} color="inherit" size="large">
        CANCEL
      </Button>
    </Box>
  );
  const renderFormNoEdit = (
    <Box>
      <Typography variant="subtitle1">Password</Typography>

      <Stack alignItems={'center'} direction={'row'} gap={2}>
        {data.data.password_change_at && (
          <Typography color={theme.palette.text.secondary} variant="body1">
            {formatTimeUTCToFromNow(data.data.password_change_at)}
          </Typography>
        )}

        <Chip size="small" color="success" label="VERIFIED" />

        <IconButton onClick={onClickEdit}>
          <IconFeather muiProps={{ color: 'primary' }} icon={<Edit size={24} />} />
        </IconButton>
      </Stack>
    </Box>
  );

  return isEdit.visible && isEdit.type === 'password' ? renderFormEdit : renderFormNoEdit;
};

export default FormItemPassword;
