import { Box, Typography, useTheme, styled, Stack, Divider, Tooltip, SvgIcon, IconButton } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  UserCheck,
  Bookmark,
  Briefcase,
  Edit,
  Columns,
  //  Printer
} from 'react-feather';
import { truncate } from 'lodash';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import IconFeather from 'src/components/common/Icon';
import FlagCountry from 'src/components/common/FlagCountry';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';

// Utilities
import { useQueryCompanyDetail } from 'src/services/queries';
import { EmptyText, MAX_LENGTH_CONTENT } from 'src/utils/constants/config';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';
import { getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';
import { FormType, useFormSearch } from 'src/zustand/useFormSearch';
import { LOGO_COMPANY_DEFAULT_URL } from 'src/utils/constants/imagesUrl';
// import { usePrinter } from 'src/hooks/usePrinter';

const TypographyWhite = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  overflow: 'hidden',
}));

const StyledButtonWithLoading = styled(ButtonWithLoading)(() => ({
  width: 'fit-content',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
}));

const StyledIconWhite = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 16,
}));

const IconButtonWhite = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 16,
  border: `1px solid ${theme.palette.common.white}`,
}));

const StyledButtonShowMore = styled(Typography)(() => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const CompanyInformation = () => {
  const [visibleMore, setVisibleMore] = useState(false as boolean);
  const theme = useTheme();
  const { id } = useParams();
  const { data, isFetching } = useQueryCompanyDetail(id || '');
  const { data: dataCompany } = data;
  const { handleOpenContribute } = useCompanyContribute((state) => state);
  const { handleOpenSearch } = useFormSearch((state) => state);
  // const { handlePrint } = usePrinter();

  const companyDescription = dataCompany.description || '';
  const countryCompany = useMemo(() => {
    return getCountryByCountryCode(dataCompany?.country || '');
  }, [dataCompany]);

  const renderDescription = () => {
    if (companyDescription.length <= MAX_LENGTH_CONTENT.COMPANY_INFORMATION) {
      return (
        <TypographyWhite align="left" variant="body2">
          {companyDescription}
        </TypographyWhite>
      );
    }

    if (!visibleMore) {
      return (
        <TypographyWhite align="left" variant="body2">
          {truncate(companyDescription, { length: MAX_LENGTH_CONTENT.COMPANY_INFORMATION })}{' '}
          <StyledButtonShowMore
            display={'inline'}
            onClick={() => setVisibleMore(!visibleMore)}
            variant="subtitle2"
            color={'inherit'}
          >
            Show More
          </StyledButtonShowMore>
        </TypographyWhite>
      );
    } else {
      return (
        <TypographyWhite align="left" variant="body2">
          {companyDescription}{' '}
          <StyledButtonShowMore
            display={'inline'}
            onClick={() => setVisibleMore(!visibleMore)}
            variant="subtitle2"
            color={'inherit'}
          >
            Show Less
          </StyledButtonShowMore>
        </TypographyWhite>
      );
    }
  };

  if (isFetching) {
    return (
      <StyledBox>
        <LoadingSkeleton count={5} height={30} />
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      <Stack direction="row" alignItems="center" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
        <Box flex={3}>
          <Box alignItems="center" mb={1} display="flex">
            <Box
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.shape.borderRadius / 4,
                p: 0.5,
              }}
              width={48}
              mr={1}
              src={dataCompany.logo_url || ''}
              component="img"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = LOGO_COMPANY_DEFAULT_URL;
              }}
            />
            <TypographyWhite align="left" variant="h5" gutterBottom>
              {(dataCompany?.name || '').toUpperCase() ?? EmptyText}
            </TypographyWhite>
          </Box>
          {renderDescription()}
          <Stack mt={4} direction={'row'} spacing={2}>
            <StyledButtonWithLoading
              variant="outlined"
              color="inheritWhite"
              isRounded
              endIcon={<IconFeather icon={<Columns />} muiProps={{ width: '16' }} />}
              size="medium"
              onClick={() => {
                handleOpenSearch(FormType.CompareCompany, dataCompany);
              }}
            >
              Compare Companies
            </StyledButtonWithLoading>
            <Tooltip title="Claim Company">
              <StyledButtonWithLoading
                variant="outlined"
                color="inheritWhite"
                isRounded
                endIcon={<IconFeather icon={<Edit />} muiProps={{ width: '16' }} />}
                size="medium"
                onClick={() => handleOpenContribute(TypeContribute.ClaimCompanyProfile)}
              >
                Contribute DATA
              </StyledButtonWithLoading>
            </Tooltip>
            <Tooltip title="Claim Company">
              <IconButtonWhite onClick={() => handleOpenContribute(TypeContribute.ClaimCompanyProfile)}>
                <IconFeather icon={<UserCheck />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip>
            {/* <Tooltip title="Print">
              <IconButtonWhite onClick={() => handlePrint()}>
                <IconFeather icon={<Printer />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip> */}
            <Tooltip title="Bookmarks Coming Soon!">
              <IconButtonWhite>
                <IconFeather icon={<Bookmark />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip>
          </Stack>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} minWidth={245} gap={2}>
          {dataCompany?.registered_address && (
            <Stack spacing={0.5} direction="row" alignItems="center">
              <StyledIconWhite>
                <RoomOutlinedIcon />
              </StyledIconWhite>
              <TypographyWhite variant="caption" align="left">
                {(dataCompany?.registered_address || '').toUpperCase() || EmptyText}
              </TypographyWhite>
            </Stack>
          )}
          {dataCompany?.company_code && (
            <Stack spacing={0.5} direction="row" alignItems="center">
              <IconFeather
                icon={<Briefcase />}
                muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }}
              />
              <TypographyWhite variant="caption" align="left">
                {dataCompany?.company_code ?? EmptyText}
              </TypographyWhite>
              <Tooltip arrow title="Company number">
                <StyledIconWhite>
                  <InfoOutlinedIcon />
                </StyledIconWhite>
              </Tooltip>
            </Stack>
          )}
          {dataCompany?.country && (
            <Stack spacing={0.5} direction="row" alignItems="center">
              <FlagCountry code={dataCompany?.country || ''} width={16} colorDefaultFlag={theme.palette.common.white} />
              <TypographyWhite variant="caption" align="left">
                {countryCompany?.label || EmptyText}
              </TypographyWhite>
              <Tooltip arrow title="Country">
                <StyledIconWhite>
                  <InfoOutlinedIcon />
                </StyledIconWhite>
              </Tooltip>
            </Stack>
          )}
          <Stack spacing={0.5} direction="row" alignItems="center">
            <StyledIconWhite>
              <TaskAltOutlinedIcon />
            </StyledIconWhite>
            <TypographyWhite variant="caption" align="left">
              {dataCompany?.status ?? EmptyText}
            </TypographyWhite>
            <Tooltip arrow title="Status">
              <StyledIconWhite>
                <InfoOutlinedIcon />
              </StyledIconWhite>
            </Tooltip>
          </Stack>
          <Stack spacing={0.5} direction="row" alignItems="center">
            <IconFeather icon={<UserCheck />} muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }} />
            <TypographyWhite variant="caption" align="left">
              {dataCompany?.is_sanctioned ? 'Claimed' : 'Unclaimed'}
            </TypographyWhite>
          </Stack>
        </Box>
      </Stack>
    </StyledBox>
  );
};

export default CompanyInformation;
