import { Box, Typography, Stack, Skeleton } from '@mui/material';

// Components
import { StyledWhiteBox } from 'src/features/TariffsAndRules/config';
import TextWithTooltip from 'src/components/common/TextWithTooltip';

// Utilities
import ImportDutyImg from 'src/asset/images/tariff-and-rule/international-money-transfer.svg';
import { EmptyText } from 'src/utils/constants/config';

const ImportDuty = ({ dataCustomDuties, isFetching }: { dataCustomDuties?: ICustomDuties; isFetching: boolean }) => {
  if (isFetching) {
    return <SkeletonContent />;
  }

  return (
    <StyledWhiteBox spacing={4} direction="row" alignItems="center">
      <Box width="100%" src={ImportDutyImg} component="img" flex={1} />
      <Box flex={1}>
        <Typography variant="h6" color="text.secondary" align="center">
          Import Duty
        </Typography>
        <Stack direction="row" spacing={4} mt={1}>
          <Box flex={1}>
            <Typography variant="h4" align="center">
              {dataCustomDuties?.MaxMFNDutiesApplied || EmptyText}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" align="center">
              MFN{' '}
              <TextWithTooltip title="Most favoured nation tariffs (MFN) are the tariffs applied by WTO members to goods imported from any other WTO member countries in respect of the Most Favoured Nation principle." />
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="h4" align="center">
              {dataCustomDuties?.MaxPrefTariffAve || EmptyText}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" align="center">
              Pref{' '}
              <TextWithTooltip title="Preferential tariffs are tariffs lower than the most favoured nation tariffs, levied on imports from a country that is being given favoured treatment through a preferential trading arrangement or under unilateral tariff preferences. Note that preferential rates agreed between countries may not cover all traded products." />
            </Typography>
          </Box>
        </Stack>
      </Box>
    </StyledWhiteBox>
  );
};

export default ImportDuty;

const SkeletonContent = () => {
  return (
    <StyledWhiteBox spacing={2}>
      <Skeleton variant="rectangular" animation="wave" width={150} height={30} />
      <Stack>
        <Skeleton animation="wave" variant="rectangular" height={200} />
      </Stack>
    </StyledWhiteBox>
  );
};
