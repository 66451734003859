import { Box, Radio, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import ButtonBack from 'src/features/Onboarding/ButtonBack';
import ButtonContinue from 'src/features/Onboarding/ButtonContinue';
import { ACCOUNT_TYPES } from 'src/features/Onboarding/config';
import { IAccountType, useOnboardingStore } from 'src/zustand/useOnboardingStore';

const StepTypeAccount = (props: IFormOnboardingProps) => {
  const { dispatch, accountType, avatar } = useOnboardingStore((state) => state);
  const [selectedType, setSelectedType] = useState(accountType);
  const theme = useTheme();

  const handleSelectType = (type: IAccountType) => {
    setSelectedType(type);
    dispatch({
      informations: undefined,
      features: undefined,
      accountType: type,
    });
  };

  const checkIsSelected = (type: string) => {
    return selectedType === type;
  };

  return (
    <>
      <Typography variant="h4">Do you work for a Government Agency or a Private Business?</Typography>

      <Box sx={{ mt: 4 }} />

      <Stack direction={'row'} gap={3}>
        {ACCOUNT_TYPES.map((type) => {
          return (
            <span key={type.value} onClick={() => handleSelectType(type.value)}>
              <Box
                display={'flex'}
                padding={1}
                paddingRight={2}
                alignItems={'center'}
                sx={{
                  color: theme.palette.text.secondary,
                  border: `1px solid ${
                    checkIsSelected(type.value) ? theme.palette.primary.main : theme.palette.divider
                  }`,
                  borderRadius: theme.shape.borderRadius / 2,
                  cursor: 'pointer',
                  height: '100%',
                  maxWidth: 234,
                }}
              >
                <Radio checked={checkIsSelected(type.value)} />
                <Typography
                  sx={{
                    textWrap: 'balance',
                    color: checkIsSelected(type.value) ? theme.palette.text.primary : theme.palette.text.secondary,
                  }}
                >
                  {type.label}
                </Typography>
              </Box>
            </span>
          );
        })}
      </Stack>

      <Box sx={{ mt: 4 }} />

      <Stack alignItems={'center'} direction={'row'} justifyContent={'end'} gap={3}>
        <ButtonBack onClick={props.onClickBack} />
        <ButtonContinue onClick={props.onClickContinue} />
      </Stack>
    </>
  );
};

export default StepTypeAccount;
