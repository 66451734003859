import { FormHelperText, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { get, isArray } from 'lodash';
import { useMemo } from 'react';

// Components
import { IPropsDataContribute } from 'src/features/CompanyDetail/Common/config';

const WebsiteProfile = ({ indexData }: { indexData: number }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<IPropsDataContribute>();
  const errorsObject = useMemo(() => {
    if (isArray(errors.contribution_website_company_profile)) {
      return { website: get(errors.contribution_website_company_profile?.[indexData], 'website', '') };
    }

    return {
      website: '',
    };
  }, [errors, touched]);
  return (
    <Stack spacing={0.5} flex={1}>
      <TextField
        required
        id={`contribution_website_company_profile[${indexData}].website`}
        name={`contribution_website_company_profile[${indexData}].website`}
        value={get(values.contribution_website_company_profile, `[${indexData}].website`, '')}
        onChange={handleChange}
        onBlur={handleBlur}
        size="small"
        fullWidth
        error={Boolean(touched.contribution_website_company_profile?.[indexData]?.website && errorsObject?.website)}
        label={<span>Website Company (URL)</span>}
      />
      {touched.contribution_website_company_profile?.[indexData]?.website && (
        <FormHelperText error>{errorsObject?.website}</FormHelperText>
      )}
    </Stack>
  );
};

export default WebsiteProfile;
