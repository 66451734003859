import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ButtonBack = ({ sx = {}, color = 'inherit' }: ButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button sx={{ cursor: 'pointer', ...sx }} variant="text" color={color} onClick={() => navigate(-1)}>
      <ArrowBackIosNewOutlined sx={{ mr: 1, fontSize: 14 }} />
      BACK
    </Button>
  );
};

export default ButtonBack;
