import { Divider, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import FormikForm from 'src/components/common/FormikForm';
import PopupOTP from 'src/components/common/PopupOTP';
import FormItemEmail from 'src/features/Setting/components/FormItemEmail';
import FormItemPassword from 'src/features/Setting/components/FormItemPassword';
import FormItemUserName from 'src/features/Setting/components/FormItemUserName';
import { useNotification } from 'src/hooks/useNotification';
import {
  useMutateUpdateUserName,
  useMutateUserEmail,
  useMutateUserPassword,
} from 'src/services/mutate/useMutateSetting';
import { useQueryUserInfo, useQueryUserNoti } from 'src/services/queries';
import { MIN_LENGTH_PASSWORD } from 'src/utils/constants/config';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { checkBadWords, validateEmail } from 'src/utils/helpers/validate';
import * as Yup from 'yup';

export enum TypeChange {
  Username = 'username',
  Email = 'email',
  Password = 'password',
  Null = '',
}

const Password = () => {
  const [otp, setOtp] = useState('');
  const [visible2FA, setVisible2FA] = useState(false as boolean);
  const [isEdit, setIsEdit] = useState({
    type: '' as TypeChange,
    visible: true,
  });
  const mutationUserName = useMutateUpdateUserName();
  const { data, refetch } = useQueryUserInfo();
  const emailMutation = useMutateUserEmail();
  const passwordMutation = useMutateUserPassword();
  const querySettingNoti = useQueryUserNoti();
  const { notification } = useNotification();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      two_fa_verification: querySettingNoti.data?.data?.password_2fa ?? true,
      username: data?.data?.username || '',
      email: data?.data?.email || '',
      password: '',
      new_password: '',
      confirm_password: '',
      confirm_agree_data_breaches: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .max(255)
        .required('Email is required')
        .test('email', 'Email is invalid', (text?: string) => validateEmail(text || '')),
      username: Yup.string()
        .min(6)
        .max(255)
        .matches(/^[a-zA-Z0-9_]+$/, 'Please enter valid name')
        .required('Username is required')
        .test('username-contain-bad-word', 'Username cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      password: Yup.string()
        .required('Password is required')
        .min(MIN_LENGTH_PASSWORD, `Your password must be at least ${MIN_LENGTH_PASSWORD} characters`),
      new_password: Yup.string()
        .required('Password is required')
        .min(MIN_LENGTH_PASSWORD, `Your password must be at least ${MIN_LENGTH_PASSWORD} characters`)
        .test(
          'has-enough-rule',
          'Your password must be included an uppercase letter, a lowercase letter, and a number and a symbol ',
          (value: string) =>
            /(?=.*?[A-Z]).*/.test(value) &&
            /(?=.*?[a-z]).*/.test(value) &&
            /(?=.*?\d).*/.test(value) &&
            /(?=.*?\W).*/.test(value),
        ),
      confirm_password: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('new_password')], 'No match, please re-enter'),
      confirm_agree_data_breaches: Yup.bool(),
    }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const onVerifySuccess = () => {
    switch (isEdit.type) {
      case TypeChange.Username:
        onUpdateUserName();
        break;
      case TypeChange.Email:
        onUpdateEmail();
        break;
      case TypeChange.Password:
        onUpdatePassword();
        break;
      default:
        break;
    }
  };

  const closeEdit = () => {
    setIsEdit({
      type: TypeChange.Null,
      visible: false,
    });
    setVisible2FA(false);
  };

  const onUpdateUserName = async () => {
    const dto = {
      username: formik.values.username,
    } as TypeDTOUpdateUsername;

    const res = await mutationUserName.mutateAsync(dto);
    notification(
      res,
      {
        title: t('setting.password.notification.update_username.success.title'),
        description: t('setting.password.notification.update_username.success.description'),
      },
      {
        title: t('setting.password.notification.update_username.failed.title'),
        description: t('setting.password.notification.update_username.failed.description'),
      },
    );
    if (res.status_code === SUCCESS_CODE.GET) {
      closeEdit();
      refetch();
    }
  };

  const onUpdateEmail = async () => {
    const dto = {
      email: formik.values.email,
    } as TypeDTOCreateOTP;

    const res = await emailMutation.mutateAsync(dto);
    notification(
      res,
      {
        title: t('setting.password.notification.update_email.success.title'),
        description: t('setting.password.notification.update_email.success.description'),
      },
      {
        title: t('setting.password.notification.update_email.failed.title'),
        description: t('setting.password.notification.update_email.failed.description'),
      },
    );
    if (res.status_code === SUCCESS_CODE.GET) {
      closeEdit();
      refetch();
    }
  };

  const onUpdatePassword = async () => {
    const dto = {
      old_password: formik.values.password,
      new_password: formik.values.new_password,
      confirm_password: formik.values.confirm_password,
      otp_code: otp || '',
    } as TypeDTOUpdatePassword;
    const res = await passwordMutation.mutateAsync(dto);
    notification(
      res,
      {
        title: t('setting.password.notification.update_password.success.title'),
        description: t('setting.password.notification.update_password.success.description'),
      },
      {
        title: t('setting.password.notification.update_password.failed.title'),
        description: t('setting.password.notification.update_password.failed.description'),
      },
    );
    if (res.status_code === SUCCESS_CODE.GET) {
      closeEdit();
      refetch();
    }
  };

  const renderPopupOTP = (
    <PopupOTP
      sendEmailTo={formik.values.email}
      onVerifySuccess={onVerifySuccess}
      otp={otp}
      setOtp={setOtp}
      open={visible2FA}
      handleClose={() => setVisible2FA(false)}
      isPending={mutationUserName.isPending || emailMutation.isPending || passwordMutation.isPending}
    />
  );

  const onCancel = () => {
    formik.resetForm();
    setIsEdit({
      type: TypeChange.Null,
      visible: false,
    });
  };

  const open2FA = async () => {
    setVisible2FA(true);
  };

  return (
    <FormikForm formik={formik}>
      <Stack gap={3.75} direction="column">
        {renderPopupOTP}

        <FormItemUserName
          open2FA={open2FA}
          onClickEdit={() => {
            formik.resetForm();
            setIsEdit({
              type: TypeChange.Username,
              visible: true,
            });
          }}
          onCancel={onCancel}
          isEdit={isEdit}
          formik={formik}
        />

        <Divider />

        <FormItemEmail
          open2FA={open2FA}
          onCancel={onCancel}
          onClickEdit={() => {
            formik.resetForm();
            setIsEdit({
              type: TypeChange.Email,
              visible: true,
            });
          }}
          isEdit={isEdit}
          formik={formik}
        />

        <Divider />

        <FormItemPassword
          onCancel={onCancel}
          onClickEdit={() => {
            formik.resetForm();
            setIsEdit({
              type: TypeChange.Password,
              visible: true,
            });
          }}
          isEdit={isEdit}
          open2FA={open2FA}
          formik={formik}
        />
      </Stack>
    </FormikForm>
  );
};

export default Password;
