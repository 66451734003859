import React from 'react';
import {
  ListItemText,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  IconButton,
  TypographyProps,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components

const PopoverAction = ({
  listAction,
  primaryTypographyProps = {},
  disabled = false,
  id = '',
}: {
  listAction: {
    icon: React.ReactNode;
    text: string;
    action: (params?: ExpectedAny) => void;
    disabled?: boolean;
  }[];
  disabled?: boolean;
  primaryTypographyProps?: TypographyProps;
  id?: string;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton color={disabled ? 'inherit' : 'suggestion'} onClick={handleClick} disabled={disabled}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={open ? `simple-popover-${id}` : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {listAction.map((action, index) => {
            return (
              <ListItem key={`${action.text}-${index}`} disablePadding>
                <ListItemButton
                  onClick={() => {
                    action.action();
                    handleClose();
                  }}
                  disabled={action?.disabled}
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText
                    primary={action.text}
                    primaryTypographyProps={{ variant: 'body2', ...primaryTypographyProps }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </React.Fragment>
  );
};

export default PopoverAction;
