// Routes
import {
  //  Activity, Briefcase, Radio, Users, User,
  Home,
  // Bookmark,
} from 'react-feather';
import { AuthRouteKeys } from 'src/utils/constants/routes';

// Icons
import IconFeather from 'src/components/common/Icon';

export const LIST_MENU_SIDEBAR = [
  {
    text: 'Homepage',
    icon: <IconFeather muiProps={{ fontSize: 'small' }} icon={<Home />} />,
    key: 'home_page',
    path: AuthRouteKeys.Home,
    disabled: false,
  },
  // 'PERSONAL',
  // {
  //   text: 'Bookmark Page',
  //   icon: <IconFeather muiProps={{ fontSize: 'small' }} icon={<Bookmark />} />,
  //   key: 'bookmark',
  //   path: AuthRouteKeys.Bookmark,
  //   disabled: true,
  // },
  // {
  //   text: 'My Profile',
  //   icon: <IconFeather muiProps={{ fontSize: 'small' }} icon={<User />} />,
  //   key: 'account_profile',
  //   path: AuthRouteKeys.AccountProfile,
  //   disabled: true,
  // },
  // 'COMMUNITY',
  // {
  //   text: 'News Feed',
  //   icon: <IconFeather muiProps={{ fontSize: 'small' }} icon={<Activity />} />,
  //   key: 'News_Feed',
  //   path: AuthRouteKeys.NewsFeed,
  //   disabled: true,
  // },
  // {
  //   text: 'My Company',
  //   icon: <IconFeather muiProps={{ fontSize: 'small' }} icon={<Briefcase />} />,
  //   key: 'my_company',
  //   path: AuthRouteKeys.MyCompany,
  //   disabled: true,
  // },
  // {
  //   text: 'My Groups',
  //   icon: <IconFeather muiProps={{ fontSize: 'small' }} icon={<Users />} />,
  //   key: 'my_groups',
  //   path: AuthRouteKeys.MyGroups,
  //   disabled: true,
  // },
  // {
  //   text: 'My Contacts',
  //   icon: <IconFeather muiProps={{ fontSize: 'small' }} icon={<Radio />} />,
  //   key: 'my_contacts',
  //   path: AuthRouteKeys.MyContacts,
  //   disabled: true,
  // },
];
