import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { useEffect, useState } from 'react';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { get, isString } from 'lodash';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

// Components
import iconSearch from 'src/asset/icons/icon-search.svg';
import PaperSelect from 'src/components/common/PaperSelect';
import { EmptyText } from 'src/utils/constants/config';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

interface IProp {
  id: string;
  countryCode?: string[] | null;
  size?: 'medium' | 'small';
  disabled?: boolean;
  required?: boolean;
}

const FormItemAddress = ({ id, countryCode = null, disabled, size = 'medium', required }: IProp) => {
  const [textSearch, setTextSearch] = useState('');
  const { values, handleChange, handleBlur, setFieldValue, errors, touched } = useFormikContext<ExpectedAny>();
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    debounce: 500,
    options: { input: textSearch, componentRestrictions: { country: countryCode } },
  });

  const renderHelperText = () => {
    if (!required) {
      return null;
    }

    if (touched[id] && errors[id]) {
      return errors[id] as string;
    }
  };

  useEffect(() => {
    getPlacePredictions({ input: textSearch, componentRestrictions: { country: countryCode } });
  }, [countryCode]);

  return (
    <Autocomplete
      id={id}
      defaultValue={{
        description: get(values, id, ''),
      }}
      loading={isPlacePredictionsLoading}
      onBlur={handleBlur}
      onChange={(_, value) => {
        if (isString(value)) {
          setFieldValue(id, value);
        } else {
          setFieldValue(id, value?.description);
        }
      }}
      isOptionEqualToValue={(option, value) => option.description === value.description}
      freeSolo={true}
      autoComplete
      disabled={disabled}
      fullWidth
      size={size}
      autoHighlight
      filterOptions={(option) => option}
      PaperComponent={PaperSelect}
      getOptionLabel={(option) => {
        if (isString(option)) {
          return option;
        } else {
          return option?.description ?? EmptyText;
        }
      }}
      popupIcon={<Box component={'img'} src={iconSearch} />}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={renderHelperText()}
          required={required}
          fullWidth
          error={Boolean(errors[id])}
          // defaultValue={get(values, id, '')}
          onChange={(event) => {
            handleChange(event);
            getPlacePredictions({ input: event.target.value, componentRestrictions: { country: countryCode } });
            setTextSearch(event.target.value);
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <FmdGoodOutlinedIcon color="action" fontSize="small" />
              </InputAdornment>
            ),
          }}
          label="Address"
        />
      )}
      options={getArrayDefault(placePredictions)}
    />
  );
};

export default FormItemAddress;
