import {
  Box,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { filter, find, get, isEmpty, map, size } from 'lodash';
import numeral from 'numeral';
import queryString from 'query-string';
import React, { ChangeEvent, useState } from 'react';
import { Briefcase, Info, MapPin } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Components
import ButtonBookmark from 'src/components/common/ButtonBookmark';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import Point from 'src/components/common/Point';
import CompanyProfileDetail from 'src/features/CompanyProfiles/CompanyProfileDetail';
import FilterCompanyProfile from 'src/features/CompanyProfiles/Filter';
import NoDataSearchCompany from 'src/features/CompanyProfiles/NoDataSearchCompany';
import StyledPagination from 'src/features/CompanyProfiles/StyledPagination';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { useQueryListCompany } from 'src/services/queries/useQueryCompany';
import { LIMIT_GET_LIST } from 'src/utils/constants/config';
import { CompanyStatusCode } from 'src/utils/constants/enum';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import {
  convertToStartCase,
  getColorCompanyStatus,
  getCountryByCountryCode,
  renderTextOrDefault,
} from 'src/utils/helpers/stringHelpers';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  position: 'relative',
  background: theme.palette.background.default,
}));

const CompanyProfilePage = () => {
  const query = useQueryParams();
  const getCompanyPayload = { ...query };

  if (getCompanyPayload.include_all_company_status === 'true') {
    // query string returns string type not boolean
    getCompanyPayload.statuses_code = [
      CompanyStatusCode.Success,
      CompanyStatusCode.Error,
      CompanyStatusCode.Warning,
      CompanyStatusCode.Unknown,
    ];
  }
  delete getCompanyPayload.include_all_company_status;

  if (!isEmpty(getCompanyPayload.company_name)) {
    delete getCompanyPayload.sort_by;
  }

  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data, isFetching } = useQueryListCompany(queryString.stringify(getCompanyPayload));
  const { data: listCompany = [] } = data;
  const [selected, setSelected] = useState(undefined as undefined | ICompanyProfile);
  const [selectedCompanies, setSelectedCompanies] = useState([] as ICompanyProfile[]);

  const theme = useTheme();
  const { palette } = theme;

  const renderLoading = () => (
    <Grid container spacing={2}>
      {Array(Number(query?.limit || LIMIT_GET_LIST))
        .fill('')
        .map((_, index) => (
          <Grid key={`skeleton-profile-company-${index}`} sm={12} md={12} lg={12} xl={6} item>
            <SkeletonProfile />
          </Grid>
        ))}
    </Grid>
  );

  const onChangeCheckbox = (company: ICompanyProfile, checked: boolean) => {
    if (size(selectedCompanies) === 3 && checked) return;
    if (checked) {
      setSelectedCompanies((prev) => [...prev, company]);
    } else {
      const state = filter(selectedCompanies, (item: ICompanyProfile) => item.id !== company.id);
      setSelectedCompanies(state);
    }
  };

  const renderCompanyComparation = () => {
    if (isEmpty(selectedCompanies)) return null;

    const onDelete = (company: ICompanyProfile) => {
      const state = filter(selectedCompanies, (item: ICompanyProfile) => item.id !== company.id);
      setSelectedCompanies(state);
    };

    const onClickCompare = () => {
      let search = '';
      selectedCompanies.forEach((company) => (search += `companyIds=${company.id}&`));
      navigate({
        pathname: AuthRouteKeys.CompareCompany,
        search: search,
      });
    };

    return (
      <Box
        alignItems={'center'}
        display={'flex'}
        justifyContent="space-between"
        px={2}
        py={1}
        bgcolor={palette.background.default}
      >
        <Stack flexWrap={'wrap'} direction={'row'} spacing={1}>
          {map(selectedCompanies, (company: ICompanyProfile) => (
            <Chip onDelete={() => onDelete(company)} color="secondary" key={company.id} label={company.name} />
          ))}
        </Stack>

        <Stack flexWrap={'wrap'} direction={'row'} spacing={1}>
          <ButtonWithLoading onClick={() => setSelectedCompanies([])} variant="outlined" isRounded>
            reset all
          </ButtonWithLoading>
          <ButtonWithLoading onClick={onClickCompare} variant="contained" isRounded>
            compare
          </ButtonWithLoading>
        </Stack>
      </Box>
    );
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: '0px auto',
        py: 2,
      }}
    >
      <Stack gap={3}>
        <Stack direction={'row'} flexWrap="wrap" justifyContent={'space-between'}>
          <Typography variant="h5">Companies Profile Search Result</Typography>
          <Typography color={palette.text.secondary} display={'flex'} variant="body2">
            We found
            <Typography color={'inherit'} sx={{ mx: 1 }} variant="subtitle2">
              {numeral(get(data, 'total_docs_count', 0)).format('0,0')} of{' '}
              {numeral(get(data, 'total_companies_count', 0) || 0).format('0,0')} company profiles matching
            </Typography>
            your search conditions
          </Typography>
        </Stack>

        <Box>
          <FilterCompanyProfile />
        </Box>

        {renderCompanyComparation()}

        {isFetching ? (
          renderLoading()
        ) : (
          <React.Fragment>
            {listCompany.length === 0 ? (
              <NoDataSearchCompany />
            ) : (
              <Grid spacing={3} container>
                <Grid sm={12} md={8} lg={8} xl={9} item>
                  <Grid container spacing={2}>
                    {listCompany.map((company: ICompanyProfile) => {
                      const isChecked = find(selectedCompanies, (o) => o.id === company.id) ? true : false;
                      return (
                        <Grid sm={12} md={12} lg={12} xl={6} key={company.id} item>
                          <Profile
                            isChecked={isChecked}
                            onChangeCheckbox={onChangeCheckbox}
                            onSelect={() => {
                              setSelected(company);
                            }}
                            selected={selected}
                            company={company}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                {selected && (
                  <Grid sm={12} md={4} lg={4} xl={3} item>
                    <CompanyProfileDetail selected={selected} />
                  </Grid>
                )}
              </Grid>
            )}
          </React.Fragment>
        )}
        <Grid spacing={3} container>
          {listCompany.length > 0 && (
            <Grid sm={12} md={8} lg={8} xl={9} item>
              <StyledPagination
                total_pages={data.total_pages}
                page={Number(query.offset) + 1}
                onChange={function (_: ChangeEvent<unknown>, value: number): void {
                  setSearchParams((searchParams) => {
                    searchParams.set('offset', String(value - 1));
                    return searchParams;
                  });
                }}
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    </Container>
  );
};

export default CompanyProfilePage;

const Profile = ({
  company,
  selected,
  onSelect,
  onChangeCheckbox,
  isChecked,
}: {
  company: ICompanyProfile;
  selected?: ICompanyProfile;
  onSelect: () => void;
  onChangeCheckbox: (company: ICompanyProfile, checked: boolean) => void;
  isChecked: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSelected = selected?.id === company.id ? true : false;
  const country = getCountryByCountryCode(company?.country || '');

  const renderCountryInfo = () => {
    if (!country.code) return null;

    return (
      <>
        <FlagCountry code={country.code} />

        <Typography variant="caption" color={'text.secondary'}>
          ({country.code})
        </Typography>

        <Point />
      </>
    );
  };

  const renderInformationAvailable = () => {
    const information_available: string[] = [];

    if (company.is_social_media) {
      information_available.push(t('company_profile.available_info.social'));
    }
    if (company.is_stock) {
      information_available.push(t('company_profile.available_info.stock'));
    }
    if (company.is_websites) {
      information_available.push(t('company_profile.available_info.website'));
    }
    if (company.is_contacts) {
      information_available.push(t('company_profile.available_info.contact'));
    }
    if (isEmpty(information_available))
      return <Chip size="small" color="default" label={t('company_profile.available_info.empty_text')} />;

    return getArrayDefault(information_available).map((info) => (
      <Chip key={info} size="small" color="default" label={convertToStartCase(info)} />
    ));
  };

  return (
    <StyledBox
      onClick={onSelect}
      sx={{
        borderColor: isSelected ? theme.palette.primary.main : theme.palette.divider,
        cursor: 'pointer',
      }}
      display={'flex'}
      flexDirection="column"
      gap={1}
      padding={1.5}
      height="100%"
      position={'relative'}
    >
      <Box zIndex={20} right={theme.spacing(1.5)} position={'absolute'}>
        <ButtonBookmark
          iconProps={{ sx: { fontSize: 12 } }}
          buttonProps={{ size: 'medium' }}
          isMarked={company.is_marked}
        />
      </Box>

      <Tooltip placement="left-start" title={company.name}>
        <FormControlLabel
          sx={{ ml: -0.7, maxWidth: 'fit-content' }}
          label={
            <Typography noWrap pr={1} color={'primary.main'} variant="subtitle1">
              {renderTextOrDefault(company.name)}
            </Typography>
          }
          control={
            <Checkbox checked={isChecked} onChange={(_, checked) => onChangeCheckbox(company, checked)} size="small" />
          }
        />
      </Tooltip>

      <Stack alignItems={'center'} direction={'row'} spacing={1}>
        {renderCountryInfo()}

        <IconFeather muiProps={{ fontSize: 'small', color: 'action' }} icon={<Briefcase />} />

        <Typography display={'flex'} color={'text.secondary'} variant="caption" alignItems="center">
          {renderTextOrDefault(company.company_code)}
        </Typography>

        {company?.status && (
          <Chip
            label={convertToStartCase(company.status)}
            size="small"
            color={getColorCompanyStatus(company?.status_code ?? CompanyStatusCode.Unknown)}
          />
        )}
      </Stack>

      <Typography display={'flex'} gap={1} color={'text.secondary'} variant="caption">
        <IconFeather muiProps={{ fontSize: 'small' }} icon={<MapPin />} />
        {renderTextOrDefault(company.full_address).toLocaleString().toUpperCase()}
      </Typography>

      <Typography alignItems={'center'} display={'flex'} gap={1} color={'text.secondary'} variant="caption">
        <Tooltip placement="top" title={t('company_profile.available_info.tooltip')}>
          <Box>
            <IconFeather muiProps={{ fontSize: 'small' }} icon={<Info />} />
          </Box>
        </Tooltip>
        {renderInformationAvailable()}
      </Typography>
    </StyledBox>
  );
};

const SkeletonProfile = () => {
  return (
    <StyledBox display={'flex'} flexDirection="column" gap={1} padding={1.5}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
      <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
      <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
    </StyledBox>
  );
};
