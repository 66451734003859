import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';

const ButtonContinue = ({
  onClick,
  text,
  disabled,
  isLoading,
}: {
  onClick: () => void;
  text?: string;
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <ButtonWithLoading
      isLoading={isLoading}
      disabled={disabled}
      type="submit"
      onClick={onClick}
      size="large"
      sx={{ float: 'right' }}
      isRounded
      variant="contained"
    >
      {text ? text : 'Continue'}
      <ArrowForwardIosOutlinedIcon fontSize="small" sx={{ marginLeft: 1 }} />
    </ButtonWithLoading>
  );
};

export default ButtonContinue;
