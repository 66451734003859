import { ReactNode } from 'react';

import MainLayout from 'src/layouts';

interface IProps {
  children: ReactNode;
  hideSideBar?: boolean;
}

const PeopleLayout = ({ children, hideSideBar }: IProps) => {
  return <MainLayout hideSideBar={hideSideBar}>{children}</MainLayout>;
};

export default PeopleLayout;
