import { Stack, Typography, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import ExpandableBox from 'src/components/common/ExpandableBox';
import TextWithTooltip from 'src/components/common/TextWithTooltip';
import NoDataWebDetail from 'src/features/CompanyDetail/WebDetail/NoDataWebDetail';
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import BackLinks from 'src/features/CompanyDetail/WebDetail/Backlinks';
import Keywords from 'src/features/CompanyDetail/WebDetail/Keywords';
import ListWebsite from 'src/features/CompanyDetail/WebDetail/ListWebsite';
import Performance from 'src/features/CompanyDetail/WebDetail/Performance';
import RecordDomain from 'src/features/CompanyDetail/WebDetail/RecordDomain';
import SkeletonWebDetail from 'src/features/CompanyDetail/WebDetail/SkeletonWebDetail';
import TechnologyResults from 'src/features/CompanyDetail/WebDetail/TechnologyResults';

// Utilities
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { useQueryCompanyWebDetail } from 'src/services/queries/useQueryCompany';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { checkDataBeforeRendering } from 'src/utils/helpers/ui';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';

const WebDetailTab = () => {
  const { id } = useParams();
  const theme = useTheme();
  const { data: dataCompanyWebDetail, isFetching } = useQueryCompanyWebDetail(id || '');
  const [selectedIndexDetail, setSelectedIndexDetail] = useState(0);
  const { handleOpenContribute } = useCompanyContribute((state) => state);

  const selectedWebDetail = useMemo(
    () => getArrayDefault(dataCompanyWebDetail?.data)[selectedIndexDetail],
    [dataCompanyWebDetail, selectedIndexDetail],
  );

  if (isFetching) {
    return <SkeletonWebDetail />;
  }

  if (!selectedWebDetail) {
    return (
      <Stack direction="column" alignItems="center">
        <StyledWhiteBox display="flex" flexDirection="column" width="70%" border={`1px solid ${theme.palette.divider}`}>
          <NoDataWebDetail action={() => handleOpenContribute(TypeContribute.WebsiteCompanyProfile)} />
        </StyledWhiteBox>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} direction="column">
      <ListWebsite
        companyWebDetail={dataCompanyWebDetail.data}
        selectedIndexDetail={selectedIndexDetail}
        setSelectedIndexDetail={setSelectedIndexDetail}
      />
      <StyledWhiteBox padding={4} display="flex" flexDirection="column" gap={3} position="relative">
        <Typography variant="h5" align="left">
          Web Details
          <TextWithTooltip title="Web Details" />
        </Typography>
        <Stack spacing={2} direction="row">
          <ExpandableBox title="WHOIS Record Domain" flex={1} maxWidth="50%">
            <RecordDomain selectedWebDetail={selectedWebDetail} />
          </ExpandableBox>
          <Stack flex={1} spacing={3} maxWidth="50%">
            <ExpandableBox title="Performance Results">
              <Performance selectedWebDetail={selectedWebDetail} />
            </ExpandableBox>
            {checkDataBeforeRendering(
              selectedWebDetail.keywords,
              <ExpandableBox title="Keywords">
                <Keywords selectedWebDetail={selectedWebDetail} />
              </ExpandableBox>,
            )}
            {checkDataBeforeRendering(
              selectedWebDetail.backlinks,
              <ExpandableBox title="Backlinks">
                <BackLinks selectedWebDetail={selectedWebDetail} />
              </ExpandableBox>,
            )}
            {checkDataBeforeRendering(
              selectedWebDetail.technical_results,
              <ExpandableBox title="Technology Results">
                <TechnologyResults selectedWebDetail={selectedWebDetail} />
              </ExpandableBox>,
            )}
          </Stack>
        </Stack>
      </StyledWhiteBox>
    </Stack>
  );
};

export default WebDetailTab;
