import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import { debounce, get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// Components
import ButtonBack from 'src/components/common/ButtonBack';
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import IconFeather from 'src/components/common/Icon';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';

// Utilities
import { useMutateCreateOTP } from 'src/services/mutate/useMutateAuth';
import { useQueryCheckEmailExist } from 'src/services/queries';
import { LINK_DATA_BREACHES, MIN_LENGTH_PASSWORD } from 'src/utils/constants/config';
import { NoAuthRouteKeys } from 'src/utils/constants/routes';
import { ERROR_CODE, SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { checkBadWords, validateEmail, validatePassword } from 'src/utils/helpers/validate';
import { useSignupStore } from 'src/zustand/useSignupStore';
import { useVerifyTypeStore } from 'src/zustand/useVerifyTypeStore';

const Link = styled('a')(() => ({
  color: 'inherit',
  margin: '0px 3px',
  textDecoration: 'underline',
}));

const FormSignUp = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [visibility, setVisibility] = useState({
    password: false,
    passwordRepeat: false,
  });
  const [emailCheck, setEmailCheck] = useState<string>('');
  const { mutateAsync, isPending } = useMutateCreateOTP();
  const { data: dataCheckEmail, isFetching: isFetchingCheckEmail } = useQueryCheckEmailExist(emailCheck);
  const { dispatch, first_name, last_name, email, password, password_repeat } = useSignupStore((state) => state);
  const verifyStore = useVerifyTypeStore();

  const isAccountExisted = useMemo(
    () => dataCheckEmail && dataCheckEmail.status_code === ERROR_CODE.USER_EXISTED,
    [dataCheckEmail],
  );

  const toggleVisiblePassword = (type: 'password' | 'passwordRepeat') => {
    const state = update(visibility, {
      [type]: {
        $set: !visibility[type],
      },
    });
    setVisibility(state);
  };

  const renderIconHidePassword = (type: 'password' | 'passwordRepeat') => {
    return visibility[type] ? (
      <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
    ) : (
      <VisibilityOffOutlinedIcon sx={{ fontSize: 16 }} />
    );
  };

  const formik = useFormik({
    // enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      email: email,
      password: password,
      password_repeat: password_repeat,
      first_name: first_name,
      last_name: last_name,
      confirm_agree_term: false,
      confirm_agree_data_breaches: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .max(255)
        .required('Email is required')
        .test('email', 'Email is invalid', (text?: string) => validateEmail(text || '')),
      password: Yup.string()
        .required('Password is required')
        .min(MIN_LENGTH_PASSWORD, `Your password must be at least ${MIN_LENGTH_PASSWORD} characters`)
        .test(
          'has-enough-rule',
          'Your password must be included an uppercase letter, a lowercase letter, and a number and a symbol ',
          (value: string) =>
            /(?=.*?[A-Z]).*/.test(value) &&
            /(?=.*?[a-z]).*/.test(value) &&
            /(?=.*?\d).*/.test(value) &&
            /(?=.*?\W).*/.test(value),
        ),
      first_name: Yup.string()
        .required()
        .max(255)
        .required('First Name is required')
        .test('first-name-contain-bad-word', 'First name cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      last_name: Yup.string()
        .required()
        .max(255)
        .required('Last Name is required')
        .test('last-name-contain-bad-word', 'Last name cannot contain sensitive words', (text?: string) =>
          checkBadWords(text || ''),
        ),
      password_repeat: Yup.string()
        .oneOf([Yup.ref('password')], 'No match, please re-enter')
        .required()
        .required('Password is required'),
      confirm_agree_term: Yup.bool().oneOf([true]),
      confirm_agree_data_breaches: Yup.bool().test('data-breaches', 'pls confirm to continue', (value?: boolean) => {
        if (!dataCheckEmail.warning) {
          return true;
        }
        return !!value;
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const { email } = values;
      const dto: TypeDTOCreateOTP = {
        email: email,
      };
      const responseCreateOTP = await mutateAsync(dto);
      if (responseCreateOTP.status_code === SUCCESS_CODE.GET) {
        navigate({ pathname: NoAuthRouteKeys.TwoStepVerification });
      }
      verifyStore.dispatch('SIGNUP' as TypeVerify);
      setSubmitting(false);
    },
  });

  const renderError = (type: string) => {
    return (
      get(formik.touched, type) &&
      get(formik.errors, type) && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {get(formik.errors, type)}
        </FormHelperText>
      )
    );
  };

  const getError = (type: string) => {
    return get(formik.touched, type) && Boolean(get(formik.errors, type));
  };

  const checkEmail = useCallback(
    debounce((e) => {
      if (validateEmail(String(e.target.value).toLowerCase())) {
        setEmailCheck(e.target.value);
      } else {
        setEmailCheck('');
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    dispatch(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <FormikForm formik={formik}>
      <ButtonBack sx={{ position: 'absolute', top: 40 }} />
      <Box width={500} position="relative">
        <Typography variant="h3">Sign Up</Typography>

        <Box sx={{ mt: 1 }} />

        <Typography variant="body1" color={theme.palette.text.secondary}>
          Please enter your email and password to sign up!
        </Typography>

        <Box sx={{ mt: 4 }} />

        <Box display={'flex'} gap={2} flexDirection={'column'}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextField
                size="small"
                color="warning"
                fullWidth
                placeholder="First Name"
                required
                label={<span>First Name</span>}
                id="first_name"
                name="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                error={getError('first_name')}
              />
              {renderError('first_name')}
            </Grid>
            <Grid xs={6} item>
              <TextField
                size="small"
                id="last_name"
                name="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                fullWidth
                placeholder="Last Name"
                required
                error={getError('last_name')}
                label={<span>Last Name</span>}
              />
              {renderError('last_name')}
            </Grid>
          </Grid>

          <FormControl>
            <TextField
              size="small"
              id="email"
              name="email"
              onChange={(e) => {
                formik.handleChange(e);
                checkEmail(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="john@gmail.com"
              error={getError('email')}
              required
              InputProps={{
                endAdornment: isFetchingCheckEmail ? (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={16} />
                  </InputAdornment>
                ) : null,
              }}
              label="Email Address"
            />
            {renderError('email')}
            {isAccountExisted && <FormHelperText error>This email has been registered</FormHelperText>}
          </FormControl>
          <FormControl>
            <TextField
              size="small"
              id="password"
              error={getError('password')}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisiblePassword('password')} size="small">
                      {renderIconHidePassword('password')}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={visibility.password ? 'text' : 'password'}
              placeholder="********"
              required
              label={<span>Password</span>}
            />
            {renderError('password')}
          </FormControl>
          <FormControl>
            <TextField
              size="small"
              id="password_repeat"
              name="password_repeat"
              error={getError('password_repeat')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password_repeat}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisiblePassword('passwordRepeat')} size="small">
                      {renderIconHidePassword('passwordRepeat')}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={visibility.passwordRepeat ? 'text' : 'password'}
              placeholder="********"
              required
              label={<span>Repeat Password</span>}
            />
            {renderError('password_repeat')}
          </FormControl>
        </Box>

        {formik.values.password ? (
          <Box mt={2} mb={2} display="flex" flexDirection="row" justifyContent="space-between">
            {validatePassword(formik.values.password).map((itemCheck, index) => {
              return (
                <Stack key={`register-strong-password-${index}`} direction="row" spacing={0.5}>
                  <IconFeather
                    muiProps={{ color: itemCheck.valid ? 'success' : 'disabled', fontSize: 'small' }}
                    icon={<CheckCircle />}
                  />
                  <Typography variant="caption2" color={itemCheck.valid ? 'success.main' : 'text.disabled'}>
                    {itemCheck.title}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        ) : (
          <Box mt={2} />
        )}

        <FormControlLabel
          control={
            <Checkbox
              sx={{ top: -10 }}
              size="small"
              onChange={(e, value) => {
                formik.setFieldValue('confirm_agree_term', value);
              }}
              checked={formik.values.confirm_agree_term}
              color="primary"
            />
          }
          label={
            <Typography color={theme.palette.text.secondary} variant="body2">
              By creating this account, I acknowledge and agree to the{' '}
              <Link
                href={NoAuthRouteKeys.TermAndCondition}
                target="_blank"
                sx={{ color: theme.palette.secondary.main }}
              >
                Terms and Conditions
              </Link>
              and{' '}
              <Link
                href={NoAuthRouteKeys.GeneralDisclaimer}
                target="_blank"
                sx={{ color: theme.palette.secondary.main }}
              >
                General Disclaimer
              </Link>{' '}
              of ADAMftd.
            </Typography>
          }
        />

        <Box sx={{ mt: 2 }} />

        <ButtonWithLoading
          sx={{ fontWeight: theme.typography.fontWeightMedium, height: 50 }}
          size="large"
          isRounded
          variant="contained"
          fullWidth
          type="submit"
          isLoading={isPending}
          disabled={isPending || !formik.isValid || isAccountExisted}
        >
          Create My Account
        </ButtonWithLoading>

        <Box sx={{ mt: 4 }} />

        <Stack gap={1} direction={'row'} justifyContent={'center'}>
          <Typography color={theme.palette.text.secondary} variant="body2">
            Already a member?
          </Typography>

          <RouterLinkNoUnderline to={NoAuthRouteKeys.Login}>
            <Typography color={theme.palette.secondary.main} variant="body2">
              Sign in
            </Typography>
          </RouterLinkNoUnderline>
        </Stack>
        {dataCheckEmail.warning && (
          <Box mt={3} position="absolute" top="90%" width="100%">
            <Alert severity="warning">
              <AlertTitle>Security Warning</AlertTitle>
              Your email address has been identified in a data breach from an unrelated third party. This means that
              your password may have been exposed in a data breach not associated with our systems. To ensure the
              security of your account, we strongly recommend that you do not use the same password here that you use on
              other websites.{' '}
              <Link sx={{ margin: 0 }} target="_blank" href={LINK_DATA_BREACHES}>
                Click here
              </Link>{' '}
              to learn more about data-breaches
              <Box mt={1} mb={1}>
                Please check the agree checkbox below to continue signing up.
              </Box>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Checkbox
                    size="small"
                    onChange={(e, value) => {
                      formik.setFieldValue('confirm_agree_data_breaches', value);
                    }}
                    checked={formik.values.confirm_agree_data_breaches}
                    color="secondary"
                  />
                }
                label={
                  <Typography color={theme.palette.secondary.main} variant="body2">
                    I agree not to use an old password.
                  </Typography>
                }
              />
            </Alert>
          </Box>
        )}
      </Box>
    </FormikForm>
  );
};

export default FormSignUp;
