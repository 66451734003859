import React from 'react';
import { Helmet } from 'react-helmet';
import ForgotPassword from 'src/features/ForgotPassword';

const ForgotPasswordPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Forget Password</title>
      </Helmet>
      <ForgotPassword />
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
