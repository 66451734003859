import { useTheme, Box, Typography, styled, Stack, Tooltip, IconButton, Divider, SvgIcon } from '@mui/material';

import {
  AlertTriangle,
  Bookmark,
  Box as BoxIcon,
  Calendar,
  ChevronsRight,
  HelpCircle,
  Printer,
  //  Printer
} from 'react-feather';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';

// Utilities
// import { usePrinter } from 'src/hooks/usePrinter';

const TypographyWhite = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  overflow: 'hidden',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
}));

const IconButtonWhite = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 16,
  border: `1px solid ${theme.palette.common.white}`,
}));

const StyledIconWhite = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 16,
}));

const TradeDataInformation = () => {
  const theme = useTheme();
  // const { handlePrint } = usePrinter();

  return (
    <StyledBox position="relative">
      <Stack direction="row" alignItems="center" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
        <Box display="flex" flexDirection="column" flex={2}>
          <TypographyWhite align="left" variant="h5" gutterBottom>
            MAEU720035747
          </TypographyWhite>
          <TypographyWhite align="left" variant="body2" gutterBottom>
            Dairy produce; milk and cream, concentrated or containing added sugar or other sweetening matter, in powder,
            granules or other solid forms, of a fat content not exceeding 1.5% (by weight)
          </TypographyWhite>
          <Stack mt={4} spacing={2} direction="row" alignItems="center">
            <Tooltip title="Bookmarks Coming Soon!">
              <IconButtonWhite>
                <IconFeather icon={<AlertTriangle />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip>
            <Tooltip title=" Coming Soon!">
              <IconButtonWhite>
                <IconFeather icon={<Printer />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip>
            <Tooltip title="Coming Soon!">
              <IconButtonWhite>
                <IconFeather icon={<Bookmark />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip>
            <Tooltip title="Coming Soon!">
              <IconButtonWhite>
                <IconFeather icon={<HelpCircle />} muiProps={{ fontSize: 'small' }} />
              </IconButtonWhite>
            </Tooltip>
          </Stack>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} minWidth={245} gap={2}>
          <Stack spacing={1} direction="row" alignItems="center">
            <StyledIconWhite>
              <Calendar />
            </StyledIconWhite>
            <TypographyWhite variant="caption" align="left">
              12/04/2022
            </TypographyWhite>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <StyledIconWhite>
              <BoxIcon />
            </StyledIconWhite>
            <TypographyWhite variant="caption" align="left">
              8421390500
            </TypographyWhite>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <FlagCountry code="CN" />
            <TypographyWhite variant="caption" align="left">
              China
            </TypographyWhite>
            <IconFeather
              icon={<ChevronsRight />}
              muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }}
            />
            <FlagCountry code="US" />
            <TypographyWhite variant="caption" align="left">
              USA
            </TypographyWhite>
          </Stack>
          <Stack spacing={0.5} direction="row" alignItems="center">
            <TypographyWhite variant="caption" align="left">
              HWA HSIA GLASS CO LTD
            </TypographyWhite>
            <IconFeather
              icon={<ChevronsRight />}
              muiProps={{ sx: { color: theme.palette.common.white, fontSize: 16 } }}
            />
            <TypographyWhite variant="caption" align="left">
              9330 NW 110TH AVENUE, MIAMI, FL 33178, USA
            </TypographyWhite>
          </Stack>
        </Box>
      </Stack>
    </StyledBox>
  );
};

export default TradeDataInformation;
