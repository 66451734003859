import { Box, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Search } from 'react-feather';
import FormItemCountry from 'src/components/Form/FormItemCountry';
import * as Yup from 'yup';

// Components
import FormikForm from 'src/components/common/FormikForm';
import IconFeather from 'src/components/common/Icon';
import { CONTACT_FILTER, StyledWhiteBox } from 'src/features/Bookmark/config';
import ListPeople from 'src/features/Bookmark/PeopleProfile/ListPeople';

const PeopleProfileTab = () => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country_code: '',
      contact: CONTACT_FILTER[0].value || '',
    },
    validationSchema: Yup.object().shape({
      country_code: Yup.string().max(255),
      contact: Yup.string().max(255),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });
  return (
    <Stack gap={3} direction="column">
      <StyledWhiteBox>
        <Typography variant="h6">People Profile Bookmark</Typography>
        <FormikForm formik={formik}>
          <Stack direction="row" spacing={2} mt={2} mb={3}>
            <Box display="flex" flex={3}>
              <TextField
                sx={{ flex: 1 }}
                placeholder="Search People Bookmark"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconFeather icon={<Search />} muiProps={{ color: 'disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex" flex={1}>
              <FormItemCountry id="country_code" label="Filter By Country" />
            </Box>
            <Box display="flex" flex={1}>
              <TextField
                type="select"
                id="contact"
                name="contact"
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size="small"
                select
                fullWidth
                label="Filter By Contact"
              >
                {CONTACT_FILTER.map((type) => (
                  <MenuItem value={type.value} key={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Stack>
          <ListPeople />
        </FormikForm>
      </StyledWhiteBox>
    </Stack>
  );
};

export default PeopleProfileTab;
