import { Box, Chip, Stack, styled, Typography, Grid, useTheme } from '@mui/material';
import { useState } from 'react';

// Components
import FlagCountry from 'src/components/common/FlagCountry';
import { EmptyText, FORMAT_DATE_TIME } from 'src/utils/constants/config';
import { CompanyStatusCode } from 'src/utils/constants/enum';
import LogoCompany from 'src/components/common/LogoCompany';
import PopupTrademark from 'src/features/CompanyDetail/TradeMark/PopupTrademark';

// Utilities
import { formatUTCTime } from 'src/utils/helpers/dayjs';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { getColorCompanyStatus } from 'src/utils/helpers/stringHelpers';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  position: 'relative',
  background: theme.palette.background.default,
  cursor: 'pointer',
}));

const ListCompanyTrademark = ({
  listCompany,
  onSelect,
}: {
  listCompany: ICompanyTrademark[];
  onSelect: (company: ICompanyTrademark) => void;
}) => {
  const theme = useTheme();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  return (
    <Grid spacing={3} container>
      {listCompany.map((company, index) => {
        return (
          <Grid key={company.id + index} sm={12} md={12} lg={6} xl={4} item onClick={() => handleOpenDetail()}>
            <StyledBox
              onClick={() => onSelect(company)}
              display={'flex'}
              flexDirection="column"
              gap={1}
              padding={1.5}
              height="100%"
            >
              <Box mb={1} display="flex">
                <LogoCompany logoURL={company?.logo_url || ''} padding={1} borderRadius={1} width={48} />
                <Stack flex={1} direction="column" justifyContent="space-between" marginLeft={theme.spacing(1)}>
                  <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Typography color={theme.palette.primary.main} variant="subtitle1" align="left">
                      {(company?.name || '').toUpperCase() || EmptyText}
                    </Typography>
                    <Stack spacing={0.5} direction="row">
                      {getArrayDefault(company.classes).map((classItem) => (
                        <Chip key={classItem + company.id} label={classItem} size="small" color="info" />
                      ))}
                    </Stack>
                  </Stack>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Chip
                      label={company?.status || EmptyText}
                      size="small"
                      color={getColorCompanyStatus(company?.status_code ?? CompanyStatusCode.Unknown)}
                    />
                    <Typography variant="caption" color={'text.secondary'}>
                      {company?.date_incorporated
                        ? formatUTCTime(FORMAT_DATE_TIME.FULL_ONLY_DATE, company?.date_incorporated)
                        : EmptyText}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography color={'text.secondary'} variant="caption">
                  Owner
                </Typography>
                <Typography variant="caption">{company?.owner || EmptyText}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography color={'text.secondary'} variant="caption">
                  IPR
                </Typography>
                <Typography variant="caption">{company?.ipr || EmptyText}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography color={'text.secondary'} variant="caption">
                  Country of filling
                </Typography>
                <Stack alignItems={'center'} spacing={1} direction="row">
                  <FlagCountry width={20} code={company.country} />
                  <Typography color={'text.secondary'} variant="caption">
                    {company.country || EmptyText}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography color={'text.secondary'} variant="caption">
                  Registration number
                </Typography>
                <Typography variant="caption">{company?.registration_number || EmptyText}</Typography>
              </Stack>
            </StyledBox>
          </Grid>
        );
      })}
      <PopupTrademark open={openDetail} handleClose={handleCloseDetail} />
    </Grid>
  );
};

export default ListCompanyTrademark;

// const SkeletonProfile = () => {
//   return (
//     <StyledBox display={'flex'} flexDirection="column" gap={1} padding={1.5}>
//       <Skeleton animation="wave" />
//       <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
//       <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
//       <Skeleton animation="wave" variant="rectangular" height={24} width={60} />
//     </StyledBox>
//   );
// };
