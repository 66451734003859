import {
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useCallback, useEffect } from 'react';
import * as Yup from 'yup';

//Components
import FormikForm from 'src/components/common/FormikForm';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import FormItemCompanyName from 'src/components/Form/FormItemCompanyName';
import FormItemConstantSetting from 'src/components/Form/FormItemConstantSetting';
import ButtonBack from 'src/features/Onboarding/ButtonBack';
import ButtonContinue from 'src/features/Onboarding/ButtonContinue';
import { TYPE_STEP_INFORMATION } from 'src/features/Onboarding/config';
import { CONSTANT_SETTING_TYPES } from 'src/services/api/config';
import {
  validateEmailBlocked,
  validateURL,
  validateEmail,
  validateWebsiteDomainValid,
} from 'src/utils/helpers/validate';
import { useOnboardingStore } from 'src/zustand/useOnboardingStore';
import { useMutateCheckWorkEmail } from 'src/services/mutate';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';

const StepInformation = (props: IFormOnboardingProps) => {
  const { dispatch, informations } = useOnboardingStore((state) => state);
  const { mutateAsync, isPending: isPendingCheckWorkEmail } = useMutateCheckWorkEmail();

  const formik = useFormik({
    initialValues: {
      [TYPE_STEP_INFORMATION.government.company_name]: informations?.company_name,
      [TYPE_STEP_INFORMATION.government.department]: informations?.department,
      [TYPE_STEP_INFORMATION.government.website_address]: informations?.website_address,
      [TYPE_STEP_INFORMATION.government.type_of_agency_working_for]: informations?.type_of_agency_working_for,
      [TYPE_STEP_INFORMATION.government.role]: informations?.role,
      [TYPE_STEP_INFORMATION.government.work_email]: informations?.work_email || '',
      [TYPE_STEP_INFORMATION.government.confirm_work_email]: informations?.confirm_work_email || false,
    },
    validationSchema: Yup.object().shape({
      company_name: Yup.string().required('Government Agency Name is required'),
      department: Yup.string().required('Department is required'),
      type_of_agency_working_for: Yup.string().required('Type of agency is required'),
      role: Yup.string().required('Role is required'),
      work_email: Yup.string()
        .max(255)
        .test('email', 'Email Invalid', (text?: string) => validateEmail(text || '')),
      website_address: Yup.string()
        .max(255)
        .test(
          'website_address',
          'Does not match website syntax',
          (text?: string) => validateURL(text || '') && validateWebsiteDomainValid(text || ''),
        ),
      confirm_work_email: Yup.bool().test(
        'email',
        'Please use your work-provided email address',
        (confirm?: boolean, context?: ExpectedAny) => {
          return Boolean(confirm) || validateEmailBlocked(context?.parent?.work_email);
        },
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
    validateOnMount: true,
  });

  const checkEmail = useCallback(async () => {
    if (formik.values.work_email && validateEmail(String(formik.values.work_email))) {
      const resCheckEmail = await mutateAsync({ work_email: String(formik.values.work_email) });
      if (resCheckEmail.status_code !== SUCCESS_CODE.GET) {
        formik.setFieldError('work_email', resCheckEmail.message);
        return;
      }
    }
  }, [formik.values.work_email]);

  const renderError = (type: string) => {
    return (
      get(formik.touched, type) &&
      get(formik.errors, type) && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {get(formik.errors, type)}
        </FormHelperText>
      )
    );
  };

  const renderErrorWorkingEmail = () => {
    if (isPendingCheckWorkEmail) return;
    if (get(formik.errors, TYPE_STEP_INFORMATION.government.work_email)) {
      return renderError('work_email');
    }

    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {get(formik.errors, TYPE_STEP_INFORMATION.government.confirm_work_email) && (
          <FormHelperText error id="standard-weight-helper-text-email-login">
            {get(formik.errors, TYPE_STEP_INFORMATION.government.confirm_work_email)}
          </FormHelperText>
        )}
        {!validateEmailBlocked(String(formik.values.work_email)) && (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Checkbox
                size="small"
                onChange={(e, value) => {
                  formik.setFieldValue('confirm_work_email', value);
                }}
                checked={Boolean(formik.values.confirm_work_email)}
                color="primary"
              />
            }
            label={<Typography variant="body2">I promise this is my work email</Typography>}
          />
        )}
      </Stack>
    );
  };

  const getError = (type: string) => {
    return get(formik.touched, type) && Boolean(get(formik.errors, type));
  };

  //Update Data form onboarding global state
  useEffect(() => {
    dispatch({
      informations: formik.values,
    });
  }, [formik.values]);

  return (
    <FormikForm formik={formik}>
      <Typography variant="h4">Tell us about yourself</Typography>

      <Box sx={{ mt: 4 }} />

      <Stack gap={2} direction={'column'}>
        <FormControl>
          <FormItemCompanyName
            id={TYPE_STEP_INFORMATION.government.company_name}
            required
            label="Government Agency Name"
            error={getError(TYPE_STEP_INFORMATION.government.company_name)}
            freeSolo={true}
          />
          {renderError(TYPE_STEP_INFORMATION.government.company_name)}
        </FormControl>
        <FormControl>
          <FormItemConstantSetting
            id={TYPE_STEP_INFORMATION.government.department}
            required
            error={getError(TYPE_STEP_INFORMATION.government.department)}
            label={
              <>
                Department
                <TooltipFormLabel title="Please select from the list, please choose as close as possible" />
              </>
            }
            type={CONSTANT_SETTING_TYPES.information_department}
          />
          {renderError(TYPE_STEP_INFORMATION.government.department)}
        </FormControl>
        <FormControl>
          <FormItemConstantSetting
            id={TYPE_STEP_INFORMATION.government.type_of_agency_working_for}
            required
            error={getError(TYPE_STEP_INFORMATION.government.type_of_agency_working_for)}
            label={
              <>
                Please Select The Type of Agency or Ministry For Which You Work
                <TooltipFormLabel title="Please select from the list, please choose as close as possible" />
              </>
            }
            type={CONSTANT_SETTING_TYPES.information_type_of_agency_working_for}
          />
          {renderError(TYPE_STEP_INFORMATION.government.type_of_agency_working_for)}
        </FormControl>
        <FormControl>
          <FormItemConstantSetting
            id={TYPE_STEP_INFORMATION.government.role}
            required
            error={getError(TYPE_STEP_INFORMATION.government.role)}
            label={
              <>
                Which function best describes your role?
                <TooltipFormLabel title="Please select from the list, please choose as close as possible" />
              </>
            }
            type={CONSTANT_SETTING_TYPES.information_role}
          />
          {renderError(TYPE_STEP_INFORMATION.government.role)}
        </FormControl>
        <FormControl>
          <TextField
            size="small"
            name={TYPE_STEP_INFORMATION.government.work_email}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              checkEmail();
            }}
            value={formik.values.work_email}
            id={TYPE_STEP_INFORMATION.government.work_email}
            label={
              <>
                What Is Your Work Email Address? <TooltipFormLabel title="" />
              </>
            }
            InputProps={{
              endAdornment: isPendingCheckWorkEmail ? (
                <InputAdornment position="end">
                  <CircularProgress color="inherit" size={16} />
                </InputAdornment>
              ) : null,
            }}
            error={getError(TYPE_STEP_INFORMATION.government.work_email)}
          />
          {renderErrorWorkingEmail()}
        </FormControl>
        <FormControl>
          <TextField
            error={getError(TYPE_STEP_INFORMATION.government.website_address)}
            size="small"
            label={
              <>
                Website Address <TooltipFormLabel title="" />
              </>
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.website_address}
            id={TYPE_STEP_INFORMATION.government.website_address}
          />
          {renderError(TYPE_STEP_INFORMATION.government.website_address)}
        </FormControl>
      </Stack>

      <Box sx={{ mt: 4 }} />

      <Stack alignItems={'center'} direction={'row'} justifyContent={'end'} gap={3}>
        <ButtonBack onClick={props.onClickBack} />
        <ButtonContinue
          disabled={!formik.isValid}
          onClick={() => {
            if (formik.isValid) {
              props.onClickContinue();
            }
          }}
        />
      </Stack>
    </FormikForm>
  );
};

export default StepInformation;
