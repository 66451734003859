import { Autocomplete, Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { debounce, get } from 'lodash';
import React from 'react';
import iconSearch from 'src/asset/icons/icon-search.svg';

// Components
import PaperSelect from 'src/components/common/PaperSelect';

// Utilities
import { useQueryPeopleInformationSuggestion } from 'src/services/queries';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

interface IProps {
  id: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  freeSolo?: boolean;
  countryCode?: string[];
}

const FormItemSuggestAddress = ({ disabled, id, required, freeSolo, error, countryCode = [] }: IProps) => {
  const { values, handleBlur, setFieldValue } = useFormikContext<TypeDTOSettingInformation>();
  const [query, setQuery] = React.useState('');

  const { data, isFetching } = useQueryPeopleInformationSuggestion(
    {
      company_address: query,
    },
    countryCode,
  );

  const onChange = (e: string) => {
    if (freeSolo) {
      setFieldValue(id, e);
    }
    setQuery(e);
  };

  const debounceOnchange = React.useCallback(debounce(onChange, 500), []);

  return (
    <Autocomplete
      id={id}
      defaultValue={get(values, id, '')}
      loading={isFetching}
      onBlur={handleBlur}
      onChange={(_, value) => {
        setFieldValue(id, value);
      }}
      freeSolo={freeSolo}
      autoComplete
      disabled={disabled}
      fullWidth
      size="small"
      autoHighlight
      options={getArrayDefault(data.data)}
      PaperComponent={PaperSelect}
      getOptionLabel={(option) => option}
      popupIcon={<Box component={'img'} src={iconSearch} />}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: freeSolo ? <Box component={'img'} src={iconSearch} /> : <></>,
          }}
          onChange={(e) => debounceOnchange(e.target.value)}
          required={required}
          label="Address"
        />
      )}
    />
  );
};

export default FormItemSuggestAddress;
