import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps, Theme, Tooltip } from '@mui/material';

const TextWithTooltip = ({
  title,
  placement,
  sx = {},
}: {
  title: string;
  sx?: SxProps<Theme>;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}) => {
  return (
    <Tooltip arrow placement={placement || 'top'} title={title} sx={{ ...sx }}>
      <InfoOutlinedIcon color="action" sx={{ fontSize: 16, mb: 0.5, ml: 0.5 }} />
    </Tooltip>
  );
};

export default TextWithTooltip;
