import { AvatarProps, Avatar, useTheme } from '@mui/material';
import { User } from 'react-feather';
import IconFeather from 'src/components/common/Icon';

interface IProps extends AvatarProps {
  alt?: string;
  colorIcon?: string;
}

const AvatarUser = ({ alt, colorIcon, sizes, variant, src, ...props }: IProps) => {
  const theme = useTheme();

  return (
    <Avatar alt={alt || ''} sizes={sizes} variant={variant} src={src} {...props}>
      <IconFeather
        icon={<User />}
        muiProps={{
          sx: {
            color: colorIcon || theme.palette.primary.light,
          },
        }}
      />
    </Avatar>
  );
};

export default AvatarUser;
