import { useQuery } from '@tanstack/react-query';
import {
  CompanyAddresses,
  CompanyContacts,
  CompanyDetail,
  CompanyIndustry,
  CompanyMarketPlaces,
  CompanyNames,
  CompanyProfileQuickView,
  CompanyProfiles,
  CompanyReviewDetail,
  CompanyReviews,
  CompanySocialReviews,
  CompanyType,
  CompanyWebDetail,
  CompareCompanies,
  CompareSocialMedia,
  Jurisdictions,
  NegativeMediaList,
  RelatedCompanies,
  SanctionCompanies,
} from 'src/model/company';
import { CompanyService } from 'src/services/api';

const QUERY_KEY_SEARCH_COMPANY_JURISDICTION = 'QUERY_KEY_SEARCH_COMPANY_JURISDICTION';
const QUERY_KEY_SEARCH_INDUSTRY = 'QUERY_KEY_SEARCH_INDUSTRY';
const QUERY_KEY_SEARCH_COMPANY_TYPES = 'QUERY_KEY_SEARCH_COMPANY_TYPES';
const QUERY_KEY_SEARCH_LIST_COMPANY = 'QUERY_KEY_SEARCH_LIST_COMPANY';
const QUERY_KEY_SEARCH_LIST_COMPANY_DETAIL = 'QUERY_KEY_SEARCH_LIST_COMPANY_DETAIL';
const QUERY_KEY_SEARCH_COMPANY_NAMES = 'QUERY_KEY_SEARCH_COMPANY_NAMES';
export const QUERY_KEY_COMPANY_DETAIL = 'QUERY_KEY_COMPANY_DETAIL';
const QUERY_KEY_COMPANY_WEB_DETAIL = 'QUERY_KEY_COMPANY_WEB_DETAIL';
const QUERY_KEY_COMPANY_SOCIAL_REVIEW = 'QUERY_KEY_COMPANY_SOCIAL_REVIEW';
export const QUERY_KEY_COMPANY_REVIEW_DETAIL = 'QUERY_KEY_COMPANY_REVIEW_DETAIL';
export const QUERY_KEY_COMPANY_REVIEW = 'QUERY_KEY_COMPANY_REVIEW';
const QUERY_KEY_COMPARE_COMPANIES = 'QUERY_KEY_COMPARE_COMPANIES';
const QUERY_KEY_COMPANY_MARKET_PLACE = 'QUERY_KEY_COMPANY_MARKET_PLACE';
const QUERY_KEY_COMPANY_CONTACTS = 'QUERY_KEY_COMPANY_CONTACTS';
const QUERY_KEY_COMPANY_ADDRESS = 'QUERY_KEY_COMPANY_ADDRESS';
const QUERY_KEY_COMPANY_SOCIAL_MEDIA = 'QUERY_KEY_COMPANY_SOCIAL_MEDIA';
const QUERY_KEY_COMPANY_NEGATIVE_MEDIA_LIST = 'QUERY_KEY_COMPANY_NEGATIVE_MEDIA_LIST';
const QUERY_KEY_COMPANY_EXACT_NEGATIVE_MEDIA_LIST = 'QUERY_KEY_COMPANY_EXACT_NEGATIVE_MEDIA_LIST';
const QUERY_KEY_RELATED_COMPANIES = 'QUERY_KEY_RELATED_COMPANIES';

export const useQueryJurisdictions = () => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_COMPANY_JURISDICTION],
    queryFn: CompanyService.getJurisdictions,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new Jurisdictions(),
  });
  return {
    data,
    isFetching,
  };
};

export const useQueryIndustry = (countryCode: string[]) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_INDUSTRY, countryCode],
    queryFn: () => CompanyService.getCompanyIndustry(countryCode),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyIndustry(),
  });
  return {
    data,
    isFetching,
  };
};

export const useQueryCompanyTypes = (jurisdiction: string[]) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_COMPANY_TYPES, jurisdiction],
    queryFn: () => CompanyService.getCompanyTypes(jurisdiction),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyType(),
    enabled: jurisdiction && jurisdiction.length > 0,
  });
  return {
    data,
    isFetching,
  };
};
export const useQueryCompanyNames = (params: Record<string, string | number>, jurisdiction?: string[]) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_COMPANY_NAMES, params, jurisdiction],
    queryFn: () => CompanyService.getCompanyNames(params, jurisdiction || []),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyNames(),
  });
  return {
    data,
    isFetching,
  };
};

export const useQueryListCompany = (query: string) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_LIST_COMPANY, query],
    queryFn: () => CompanyService.getListCompany(query),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyProfiles(),
    placeholderData: (previousData) => previousData,
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryQuickViewCompanyProfile = (id: string) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_SEARCH_LIST_COMPANY_DETAIL, id],
    queryFn: () => CompanyService.getQuickViewCompanyProfile(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyProfileQuickView(),
    placeholderData: (previousData) => previousData,
    enabled: Boolean(id),
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryCompanyDetail = (id: string) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_DETAIL, id],
    queryFn: () => CompanyService.getCompanyDetail(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyDetail(),
    placeholderData: (previousData) => previousData,
  });
  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryCompanyWebDetail = (id: string) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_WEB_DETAIL, id],
    queryFn: () => CompanyService.getCompanyWebDetail(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyWebDetail(),
  });
  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryCompanySocialReview = (props: { id?: string }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_SOCIAL_REVIEW, props.id],
    queryFn: () => CompanyService.getCompanyReviewFromSocial(props.id ?? ''),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanySocialReviews(),
    enabled: Boolean(props.id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};

export const useQueryCompanyReviewDetail = (props: { id?: string }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_REVIEW_DETAIL, props.id],
    queryFn: () => CompanyService.getCompanyReviewDetail(props.id ?? ''),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyReviewDetail(),
    enabled: Boolean(props.id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};
export const useQueryCompanyReviews = (props: { id?: string; params: Record<string, string | number> }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_REVIEW, props.id, props.params],
    queryFn: () => CompanyService.getCompanyReviews(props.id ?? '', props.params),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyReviews(),
    enabled: Boolean(props.id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};

export const useQueryCompareCompanies = (companyIds: string[]) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEY_COMPARE_COMPANIES, companyIds],
    queryFn: () => CompanyService.getCompareCompanies(companyIds),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompareCompanies(),
  });
  return {
    data,
    isFetching,
  };
};

export const useQueryCompanyMarketPlace = (props: { id: string; params: Record<string, string | number> }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_MARKET_PLACE, props.id, props.params],
    queryFn: () => CompanyService.getCompanyMarketplace(props.id, props.params),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyMarketPlaces(),
    enabled: Boolean(props.id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};

export const useQueryCompanyContacts = ({ id }: { id: string }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_CONTACTS, id],
    queryFn: () => CompanyService.getCompanyContacts(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyContacts(),
    enabled: Boolean(id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};
export const useQueryCompanyAddress = ({ id }: { id: string }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_ADDRESS, id],
    queryFn: () => CompanyService.getCompanyAddress(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompanyAddresses(),
  });
  return {
    isFetching,
    data,
    refetch,
  };
};

export const useQueryCompanySocialMedia = ({ id }: { id: string }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_SOCIAL_MEDIA, id],
    queryFn: () => CompanyService.getCompanySocialMedia(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CompareSocialMedia(),
    enabled: Boolean(id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};
export const useQueryCompanyNegativeMedia = ({ id, params }: { id?: string; params: Record<string, number> }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_NEGATIVE_MEDIA_LIST, id, params],
    queryFn: () => CompanyService.getListNegativeMedia(params, id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new NegativeMediaList(),
    enabled: Boolean(id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};
export const useQueryCompanyExactNegativeMedia = ({ id, params }: { id?: string; params: Record<string, number> }) => {
  const { isFetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_COMPANY_EXACT_NEGATIVE_MEDIA_LIST, id, params],
    queryFn: () => CompanyService.getListExactNegativeMedia(params, id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new NegativeMediaList(),
    enabled: Boolean(id),
  });
  return {
    isFetching,
    refetch,
    data,
  };
};

export const useQueryListRelatedCompanies = (id: string, query: Record<string, string | number>) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_RELATED_COMPANIES, id, query],
    queryFn: () => CompanyService.getListRelatedCompanies(id, query),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new RelatedCompanies(),
    placeholderData: (previousData) => previousData,
  });
  return {
    data,
    isFetching,
    refetch,
  };
};

export const useQueryListSanctionsCompanies = ({ id, params }: { id: string; params: Record<string, number> }) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEY_RELATED_COMPANIES, id, params],
    queryFn: () => CompanyService.getSanctionsCompanies(id, params),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new SanctionCompanies(),
    placeholderData: (previousData) => previousData,
  });
  return {
    data,
    isFetching,
    refetch,
  };
};
