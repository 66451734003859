import StarIcon from '@mui/icons-material/Star';
import { Box, Rating, Slider, Stack, styled, Typography, useTheme } from '@mui/material';
import { find, isNumber } from 'lodash';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { useQueryCompanyReviewDetail } from 'src/services/queries/useQueryCompany';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';

// Utilities
import { EmptyText } from 'src/utils/constants/config';
import ContributeForNoData from 'src/features/CompanyDetail/Common/ContributeForNoData';

const StyledSlider = styled(Slider)(({ theme }) => ({
  width: '80%',
  '&.Mui-disabled': {
    color: theme.palette.rating.main,
  },
  height: 16,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: 'transparent',
    border: 'none',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: theme.palette.background.paper1,
  },
}));

interface IProps {
  id?: string;
}

const ReviewOverview = ({ id }: IProps) => {
  const theme = useTheme();
  const { handleOpenContribute } = useCompanyContribute((state) => state);
  const {
    data: { data },
    isFetching,
  } = useQueryCompanyReviewDetail({ id: id });

  if (isFetching) {
    return <LoadingSkeleton count={5} height={50} />;
  }

  const fiveStarReview = find(data?.rating_details, (o) => o.rate_name === '5 Stars')?.votes_count ?? 0;
  const fourStarReview = find(data?.rating_details, (o) => o.rate_name === '4 Stars')?.votes_count ?? 0;
  const threeStarReview = find(data?.rating_details, (o) => o.rate_name === '3 Stars')?.votes_count ?? 0;
  const twoStarReview = find(data?.rating_details, (o) => o.rate_name === '2 Stars')?.votes_count ?? 0;
  const oneStarReview = find(data?.rating_details, (o) => o.rate_name === '1 Stars')?.votes_count ?? 0;

  const renderPopoverNoReviews = () => {
    if ([oneStarReview, twoStarReview, threeStarReview, fourStarReview, fiveStarReview].every((item) => item === 0)) {
      return (
        <>
          <Box
            position="absolute"
            bgcolor="rgba(255, 255, 255, 0.80)"
            left={0}
            right={0}
            top={0}
            bottom={0}
            sx={{
              backdropFilter: 'blur(2px)',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%);',
            }}
          >
            <ContributeForNoData />
          </Box>
        </>
      );
    }
  };

  return (
    <Stack direction="row" spacing={4}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h2">
          {isNumber(data?.rating_value) ? renderTextOrDefault(data?.rating_value) + '/5' : EmptyText}
        </Typography>
        <Box>
          <Rating
            name="text-feedback"
            value={data?.rating_value}
            readOnly
            precision={0.5}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          />
        </Box>
        <Typography variant="subtitle1" color="secondary">
          {data?.total_reviews ?? 0} reviews
        </Typography>
        <ButtonWithLoading
          isRounded
          variant="contained"
          size="large"
          onClick={() => handleOpenContribute(TypeContribute.Review)}
        >
          Write your review
        </ButtonWithLoading>
      </Box>
      <Box flex={1} position="relative">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">5</Typography>
          <StarIcon sx={{ color: theme.palette.rating.main }} />
          <StyledSlider defaultValue={fiveStarReview} min={0} max={data?.total_reviews} disabled />
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(fiveStarReview)} reviews
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">4</Typography>
          <StarIcon sx={{ color: theme.palette.rating.main }} />
          <StyledSlider defaultValue={fourStarReview} min={0} max={data?.total_reviews} disabled />
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(fourStarReview)} reviews
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">3</Typography>
          <StarIcon sx={{ color: theme.palette.rating.main }} />
          <StyledSlider defaultValue={threeStarReview} min={0} max={data?.total_reviews} disabled />
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(threeStarReview)} reviews
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">2</Typography>
          <StarIcon sx={{ color: theme.palette.rating.main }} />
          <StyledSlider defaultValue={twoStarReview} min={0} max={data?.total_reviews} disabled />
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(twoStarReview)} reviews
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">1</Typography>
          <StarIcon sx={{ color: theme.palette.rating.main }} />
          <StyledSlider defaultValue={oneStarReview} min={0} max={data?.total_reviews} disabled />
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(oneStarReview)} reviews
          </Typography>
        </Stack>
        {renderPopoverNoReviews()}
      </Box>
    </Stack>
  );
};

export default ReviewOverview;
