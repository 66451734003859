export class CountryTariffs implements IResponse<ICountryTariffs[]> {
  status_code: number;
  data: ICountryTariffs[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}

export class ProductTariffs implements IResponse<IProductTariffs[]> {
  status_code: number;
  data: IProductTariffs[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}

export class NtmMeasures implements IResponse<INtmMeasures[]> {
  status_code: number;
  data: INtmMeasures[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}

export class CustomDuties implements IResponse<ICustomDuties> {
  status_code: number;
  data: ICustomDuties;
  constructor() {
    this.status_code = 200;
    this.data = {
      CustomDuty: [],
    };
  }
}

export class TradeRemedy implements IResponse<ITradeRemedy> {
  status_code: number;
  data: ITradeRemedy;
  constructor() {
    this.status_code = 200;
    this.data = { TradeRemedyData: [] };
  }
}

export class Fta implements IResponse<IFta> {
  status_code: number;
  data: IFta;
  constructor() {
    this.status_code = 200;
    this.data = {};
  }
}

export class RooFta implements IResponse<IRooFta> {
  status_code: number;
  data: IRooFta;
  constructor() {
    this.status_code = 200;
    this.data = {};
  }
}
