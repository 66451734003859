import { LINK_SUPPORT, PRIVACY, TERMS } from 'src/utils/constants/config';
export const MENUS_LOGIN_PAGE = [
  {
    text: 'Support',
    path: LINK_SUPPORT,
    target: '_blank',
  },
  {
    text: 'Privacy',
    path: PRIVACY,
    target: '_blank',
  },
  {
    text: 'Terms of Use',
    path: TERMS,
    target: '_blank',
  },
];
