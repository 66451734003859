import { Box, Chip, Stack, Typography } from '@mui/material';
import { first, isEmpty } from 'lodash';
import React from 'react';
import { Check, X } from 'react-feather';
import { useParams } from 'react-router-dom';

// Components
import IconFeather from 'src/components/common/Icon';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import RouterLinkNoUnderline from 'src/components/common/RouterLinkNoUnderline';
import Table from 'src/components/Table';
import AlternativeAddresses from 'src/features/CompanyDetail/Addresses/AlternativeAddresses';
import PrimaryAddress from 'src/features/CompanyDetail/Addresses/PrimaryAddress';
import RegisteredAddress from 'src/features/CompanyDetail/Addresses/RegisteredAddress';
import { renderTextOrDefault } from 'src/utils/helpers/stringHelpers';

// Utilities
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import { useQueryCompanyAddress } from 'src/services/queries';
import { EmptyText } from 'src/utils/constants/config';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { AuthRouteKeys } from 'src/utils/constants/routes';

const Addresses = () => {
  const { id } = useParams();
  const { placesService } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const {
    data: { data },
    isFetching,
  } = useQueryCompanyAddress({ id: id || '' });
  const configColumn: ConfigCell<ILocatedCompanyAddress>[] = [
    {
      id: 'company_name',
      disablePadding: true,
      label: 'Company Name',
      cell: (row: ILocatedCompanyAddress) => {
        return (
          <RouterLinkNoUnderline to={AuthRouteKeys.CompanyDetail.replace(':id?', row?.id || '')}>
            <Typography variant="body2" color="secondary">
              {renderTextOrDefault(row.name)}
            </Typography>
          </RouterLinkNoUnderline>
        );
      },
    },
    {
      id: 'company_number',
      disablePadding: false,
      label: 'Company Number',
      cell: (row: ILocatedCompanyAddress) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(row.registration_number)}
          </Typography>
        );
      },
    },
    {
      id: 'company_type',
      disablePadding: false,
      label: 'Company Type',
      cell: (row: ILocatedCompanyAddress) => {
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(row.legal_form)}
          </Typography>
        );
      },
    },
    {
      id: 'status',
      disablePadding: false,
      label: 'Status',
      cell: (row: ILocatedCompanyAddress) => {
        if (!row.status) {
          return EmptyText;
        }
        return <Chip size="small" label={row.status} color={row.status === 'Active' ? 'success' : 'error'} />;
      },
    },
    {
      id: 'primary_industry',
      disablePadding: false,
      label: 'Primary Industry',
      cell: (row: ILocatedCompanyAddress) => {
        const industry = first(row.industries);
        return (
          <Typography variant="body2" color="text.secondary">
            {renderTextOrDefault(industry)}
          </Typography>
        );
      },
    },
    {
      id: 'sanctioned_entity ',
      disablePadding: false,
      label: 'Alerts',
      cell: (row: ILocatedCompanyAddress) => {
        if (row.is_sanctioned) {
          return <IconFeather icon={<Check />} muiProps={{ color: 'success', fontSize: 'small' }} />;
        }
        return <IconFeather icon={<X />} muiProps={{ color: 'error', fontSize: 'small' }} />;
      },
    },
  ];

  if (isFetching) {
    return (
      <StyledWhiteBox>
        <LoadingSkeleton count={5} height={50} />
      </StyledWhiteBox>
    );
  }
  return (
    <React.Fragment>
      <Stack spacing={3}>
        <RegisteredAddress address={data} placesService={placesService}/>
        <PrimaryAddress address={data} placesService={placesService}/>
        {data?.alternative?.address !== data?.primary?.address &&
          data?.alternative?.address !== data?.registered?.address && <AlternativeAddresses address={data} placesService={placesService}/>}

        {!isEmpty(data?.located_at_same_address) && (
          <StyledWhiteBox>
            <Stack spacing={1}>
              <Typography variant="h5" align="left">
                Located at Same Address
              </Typography>
              <Box mt={3} />
              <Table
                loading={isFetching}
                maxRow={10}
                isPagination={false}
                data={data?.located_at_same_address || []}
                configColumn={configColumn}
                dataKey="name"
              />
            </Stack>
          </StyledWhiteBox>
        )}
        {/* <CompanyCompetitor /> */}
      </Stack>
    </React.Fragment>
  );
};

export default Addresses;
