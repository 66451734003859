export class Jurisdictions implements IResponse<IJurisdictions[]> {
  status_code: number;
  data: IJurisdictions[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}

export class CompanyType implements IResponse<string[]> {
  status_code: number;
  data: string[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}

export class CompanyIndustry implements IResponse<ICompanyIndustry[]> {
  status_code: number;
  data: ICompanyIndustry[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}

export class CompanyProfiles implements IResponseList<ICompanyProfile> {
  message?: string;
  status_code: number;
  warning?: string | null | undefined;
  data: ICompanyProfile[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  constructor() {
    (this.status_code = 200), (this.data = []);
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
  total_records: number;
}

export class CompanyProfileQuickView implements IResponse<ICompanyProfileQuickView> {
  message?: string | undefined;
  status_code: number;
  data: ICompanyProfileQuickView;
  detail?: string | undefined;
  errors?: ExpectedAny[] | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
  total_pages?: number | undefined;
  constructor() {
    this.data = {};
    this.status_code = 200;
  }
}
export class CompanyDetail implements IResponse<ICompanyDetail> {
  message: string;
  status_code: number;
  data: ICompanyDetail;
  detail?: string;
  errors?: ExpectedAny[];
  constructor() {
    this.message = '';
    this.data = {
      id: '',
      name: '',
      logo_url: '',
      company_type: '',
      agent_name: '',
      jurisdiction: '',
      lei: '',
      date_incorporated: '',
      paid_up_capital: '',
      authorised_capital: '',
      full_address: '',
      country: '',
      company_code: '',
      status: '',
      description: '',
      date_struck_off: '',
      company_age: 0,
      number_of_employees: 0,
      registered_address: '',
      phone_numbers: null,
      fax_numbers: null,
      emails: null,
      websites: null,
      is_sanctioned: false,
      data_source: {},
      other_names: [
        {
          trade_document: [],
        },
        {
          company_known_as: [],
        },
      ],
      related_companies: [],
      ranking_as_a_buyer: {},
      ranking_as_a_seller: {},
      industries: [],
      created_at: null,
      updated_at: null,
    };
    this.status_code = 200;
  }
}

export class CompanyNames implements IResponseList<ICompanyName> {
  message?: string | undefined;
  status_code: number;
  warning?: string | null | undefined;
  data: ICompanyName[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  constructor() {
    this.data = [];
    this.status_code = 200;
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
  total_records: number;
}

export class CompanyWebDetail implements IResponse<ICompanyWebDetail[]> {
  message: string;
  status_code: number;
  data: Array<ICompanyWebDetail>;
  errors?: ExpectedAny[];
  constructor() {
    this.message = '';
    this.data = [];
    this.status_code = 200;
  }
}
export class CompanySocialReviews implements IResponseList<CompanySocialReview> {
  message?: string | undefined;
  status_code: number;
  warning?: string | null | undefined;
  data: CompanySocialReview[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  constructor() {
    this.data = [];
    this.page = 0;
    this.total_pages = 0;
    this.page_size = 0;
    this.status_code = 200;
    this.total_records = 0;
  }
  total_records: number;
}
export class CompanyReviewDetail implements IResponse<ICompanyReviewDetail> {
  message?: string | undefined;
  status_code: number;
  warning?: string | null | undefined;
  data?: ICompanyReviewDetail | undefined;
  detail?: string | undefined;
  errors?: ExpectedAny[] | undefined;
  public_link?: string | undefined;
  public_links?: { message: string; file_name: string; status_code: number }[] | undefined;
  request_id?: string | undefined;
  constructor() {
    this.status_code = 0;
    this.data = {};
  }
}
export class CompanyReviews implements IResponseList<ICompanyReview> {
  message?: string | undefined;
  status_code: number;
  warning?: string | null | undefined;
  data: ICompanyReview[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  constructor() {
    this.data = [];
    this.page = 0;
    this.total_pages = 0;
    this.page_size = 0;
    this.status_code = 200;
    this.total_records = 0;
  }
}

export class CompareCompanies implements IResponse<ICompareCompany[]> {
  status_code: number;
  data: ICompareCompany[];
  constructor() {
    this.status_code = 200;
    this.data = [];
  }
}

export class CompanyMarketPlaces implements IResponseList<ICompanyMarketPlace> {
  message?: string | undefined;
  status_code: number;
  warning?: string | null | undefined;
  data: ICompanyMarketPlace[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  constructor() {
    this.data = [];
    this.page = 0;
    this.total_pages = 0;
    this.page_size = 0;
    this.status_code = 200;
    this.total_records = 0;
  }
}

export class CompanyContacts implements IResponse<ICompanyContact> {
  status_code: number;
  data: ICompanyContact;
  constructor() {
    this.data = { other_contacts: [] };
    this.status_code = 0;
  }
}
export class CompanyAddresses implements IResponse<ICompanyAddress> {
  message?: string | undefined;
  status_code: number;
  data?: ICompanyAddress | undefined;
  constructor() {
    this.data = {};
    this.status_code = 0;
  }
}
export class CompareSocialMedia implements IResponse<IListSocialMedia> {
  status_code: number;
  data: IListSocialMedia;
  constructor() {
    this.status_code = 200;
    this.data = {
      social_medias: [],
      chats: [],
      directories: [],
      ecommerces: [],
      emails: [],
    };
  }
}
export class NegativeMediaList implements IResponseList<INegativeMedia> {
  message?: string | undefined;
  status_code: number;
  warning?: string | null | undefined;
  data: INegativeMedia[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  constructor() {
    this.data = [];
    this.page = 0;
    this.total_pages = 0;
    this.page_size = 0;
    this.status_code = 200;
    this.total_records = 0;
  }
}

export class CheckEmailValidation implements IResponse<string> {
  status_code: number;
  data: string;
  constructor() {
    this.status_code = 200;
    this.data = '';
  }
}

export class RelatedCompanies implements IResponseList<RelatedCompany> {
  message?: string;
  status_code: number;
  warning?: string | null | undefined;
  data: RelatedCompany[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_docs_count: number;
  total_records: number;
  constructor() {
    (this.status_code = 200), (this.data = []);
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_docs_count = 0;
    this.total_records = 0;
  }
}
export class SanctionCompanies implements IResponseList<IAlertCompany> {
  message?: string;
  status_code: number;
  warning?: string | null | undefined;
  data: IAlertCompany[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_docs_count: number;
  total_records: number;
  constructor() {
    (this.status_code = 200), (this.data = []);
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_docs_count = 0;
    this.total_records = 0;
  }
}
