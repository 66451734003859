import { SvgIcon, SvgIconProps } from '@mui/material';

const StarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="user">
          <path
            id="Vector"
            d="M20.0001 21.0001V19.0001C20.0001 17.9393 19.5787 16.9218 18.8285 16.1717C18.0784 15.4215 17.061 15.0001 16.0001 15.0001H8.00012C6.93926 15.0001 5.92184 15.4215 5.17169 16.1717C4.42155 16.9218 4.00012 17.9393 4.00012 19.0001V21.0001M15.9999 7.00012C15.9999 9.20926 14.209 11.0001 11.9999 11.0001C9.79074 11.0001 7.99988 9.20926 7.99988 7.00012C7.99988 4.79098 9.79074 3.00012 11.9999 3.00012C14.209 3.00012 15.9999 4.79098 15.9999 7.00012Z"
            stroke="currentColor"
            strokeWidth="2.00095"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default StarIcon;
