import { create } from 'zustand';

interface IAction {
  dispatch: (dto: TypeDTOSignup) => void;
  reset: () => void;
}

export const useSignupStore = create<TypeDTOSignup & IAction>((set) => ({
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  password_repeat: '',
  dispatch: (state: TypeDTOSignup) => set((prevState) => ({ ...prevState, ...state })),
  reset: () =>
    set({
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      password_repeat: '',
    }),
}));
