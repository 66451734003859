import { MenuItem, Stack, TextField, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { isArray, get } from 'lodash';
import { useMemo } from 'react';

// Components
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import { IPropsDataContribute, PEOPLE_RELATED_ROLE } from 'src/features/CompanyDetail/Common/config';

const RelatedCorporate = ({ indexData }: { indexData: number }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<IPropsDataContribute>();
  const errorsObject = useMemo(() => {
    if (isArray(errors.contribution_related_corporates)) {
      return {
        people_name: get(errors.contribution_related_corporates?.[indexData], 'people_name', ''),
        people_role: get(errors.contribution_related_corporates?.[indexData], 'people_role', ''),
      };
    }

    return {
      people_name: '',
      people_role: '',
    };
  }, [errors, touched]);

  return (
    <Stack spacing={2} flex={1}>
      <Stack spacing={0.5} flex={1}>
        <TextField
          required
          size="small"
          id={`contribution_related_corporates[${indexData}].people_name`}
          name={`contribution_related_corporates[${indexData}].people_name`}
          value={get(values.contribution_related_corporates, `[${indexData}].people_name`, '')}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          error={Boolean(
            touched.contribution_related_corporates?.[indexData]?.people_name && errorsObject?.people_name,
          )}
          label={
            <span>
              Person
              <TooltipFormLabel title=" Person" />
            </span>
          }
        />
        {touched.contribution_related_corporates?.[indexData]?.people_name && (
          <FormHelperText error>{errorsObject?.people_name}</FormHelperText>
        )}
      </Stack>
      <Stack spacing={0.5} flex={1}>
        <TextField
          type="select"
          size="small"
          id={`contribution_related_corporates[${indexData}].people_role`}
          name={`contribution_related_corporates[${indexData}].people_role`}
          value={get(values.contribution_related_corporates, `[${indexData}].people_role`, '')}
          select
          fullWidth
          required
          onChange={handleChange}
          onBlur={handleBlur}
          label={<span>Role </span>}
          error={Boolean(errorsObject?.people_role)}
        >
          {PEOPLE_RELATED_ROLE.map((role) => (
            <MenuItem value={role.value} key={role.value}>
              {role.label}
            </MenuItem>
          ))}
        </TextField>
        {touched.contribution_related_corporates?.[indexData]?.people_role && (
          <FormHelperText error>{errorsObject?.people_role}</FormHelperText>
        )}
      </Stack>
    </Stack>
  );
};

export default RelatedCorporate;
