import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import { get, includes, isArray, isString } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
import FormItemCompanyName from 'src/components/Form/FormItemCompanyName';
import FormItemCompanyType, { ALL_TYPE_VALUE } from 'src/components/Form/FormItemCompanyType';
// import FormItemIndustryClassification from 'src/components/Form/FormItemIndustryClassification';
import FormItemJurisdiction from 'src/components/Form/FormItemJurisdiction';
import ModalComponent from 'src/components/Modal/ModalComponent';

// Utilities
import { DateRange, DateRangePicker } from '@icttm-dev/x-date-pickers-pro';
import IconFeather from 'src/components/common/Icon';
import { FormSearchSortBy, SORT_BY_LIST } from 'src/features/CompanyDetail/Common/config';
import { useQueryParams } from 'src/hooks/useQueryParam';
import { FORMAT_DATE_TIME, LIMIT_GET_LIST } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { handleQueryParamFromObject } from 'src/utils/helpers/queryParam';
import { getFormItemError, renderFormItemError } from 'src/utils/helpers/renderFormItemError';

const LabelCheckbox = ({ children }: { children: ReactNode }) => {
  return (
    <Typography color={'text.secondary'} variant="caption2">
      {children}
    </Typography>
  );
};
interface IProps {
  open: boolean;
  handleClose: () => void;
}

const FormSearchCompany: React.FC<IProps> = ({ open, handleClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [visibleMoreFilter, setVisibleMoreFilter] = useState(false as boolean);

  const params = useQueryParams();

  const getDefaultValue = (key: string) => {
    return get(params, key, '') as string;
  };

  const getDefaultArray = (key: string) => {
    const value = params[key];
    if (isArray(value)) {
      return value as string[];
    }
    if (isString(value)) {
      return [value] as string[];
    }
    return [];
  };

  const formik = useFormik<TypeCompanySearch>({
    enableReinitialize: true,
    initialValues: {
      limit: LIMIT_GET_LIST,
      offset: 0,
      company_name: getDefaultValue('company_name'),
      company_code: getDefaultValue('company_code'),
      jurisdiction: getDefaultArray('jurisdiction'),
      city: getDefaultValue('city'),
      state: getDefaultValue('state'),
      industry: getDefaultValue('industry'),
      incorporation_date: getDefaultValue('incorporation_date'),
      to_date: getDefaultValue('to_date'),
      company_type: getDefaultValue('company_type'),
      sort_by: getDefaultValue('sort_by') || FormSearchSortBy.CompanyName,
      non_profit_status: getDefaultValue('non_profit_status'),
      company_branches: getDefaultValue('company_branches'),
      contact_person_available: getDefaultArray('contact_person_available'),
      include_anomalies: getDefaultArray('include_anomalies'),
      include_all_company_status: getDefaultValue('include_all_company_status') === 'true',
    },
    validationSchema: Yup.object().shape({
      jurisdiction: Yup.array(),
      company_type: Yup.string().max(255),
      company_name: Yup.string().max(255),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      handleClose();
      setSubmitting(false);
      values.jurisdiction = getListJurisdiction();
      if (values.company_type === ALL_TYPE_VALUE) {
        values.company_type = '';
      }
      const params = handleQueryParamFromObject(values);
      navigate({ pathname: AuthRouteKeys.CompanyProfiles, search: params });
    },
  });

  const getListJurisdiction = () => {
    return (formik.values?.jurisdiction || []).map((jurisdiction) =>
      typeof jurisdiction === 'string' ? jurisdiction : get(jurisdiction, 'jurisdiction', ''),
    );
  };

  useEffect(() => {
    if (!open) {
      setVisibleMoreFilter(false);
      formik.resetForm();
    }
    if (open && params['incorporation_date']) {
      setVisibleMoreFilter(true);
    }
  }, [open]);

  const handleClickMoreFilter = () => {
    setVisibleMoreFilter((prev) => {
      if (prev) {
        formik.setFieldValue('incorporation_date', '');
        formik.setFieldValue('to_date', '');
        formik.setFieldValue('sort_by', '');
        formik.setFieldValue('non_profit_status', '');
        formik.setFieldValue('company_branches', '');
        formik.setFieldValue('contact_person_available', []);
        formik.setFieldValue('include_anomalies', []);
      }
      return !prev;
    });
  };

  const renderButtonMoreFilter = () => {
    if (!visibleMoreFilter) {
      return (
        <Button onClick={handleClickMoreFilter} color="suggestion" size="small" variant="text">
          Advanced Search{' '}
          <IconFeather muiProps={{ color: 'inherit', fontSize: 'small' }} icon={<Icon.ChevronsDown size={24} />} />
        </Button>
      );
    } else {
      return (
        <Button onClick={handleClickMoreFilter} color="suggestion" size="small" variant="text">
          Basic Search{' '}
          <IconFeather muiProps={{ color: 'inherit', fontSize: 'small' }} icon={<Icon.ChevronsUp size={24} />} />
        </Button>
      );
    }
  };

  const handleChangeContactPerson = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const contact_person_available = formik.values.contact_person_available;
    if (value) {
      const state = update(contact_person_available, {
        $push: [e.target.name],
      });
      formik.setFieldValue('contact_person_available', state);
    } else {
      const index = contact_person_available.indexOf(e.target.name);
      const state = update(contact_person_available, {
        $splice: [[index, 1]],
      });
      formik.setFieldValue('contact_person_available', state);
    }
  };

  // const handleChangeIncludeAnomalies = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
  //   const include_anomalies = formik.values.include_anomalies;
  //   if (value) {
  //     const state = update(include_anomalies, {
  //       $push: [e.target.name],
  //     });
  //     formik.setFieldValue('include_anomalies', state);
  //   } else {
  //     const index = include_anomalies.indexOf(e.target.name);
  //     const state = update(include_anomalies, {
  //       $splice: [[index, 1]],
  //     });
  //     formik.setFieldValue('include_anomalies', state);
  //   }
  // };

  const defaultValueCheckbox = (type: string, key: string) => {
    return includes(get(formik.values, type), key);
  };

  const getDefaultValueRangeTime: () => [Dayjs | null, Dayjs | null] = () => {
    if (!formik.values.to_date || !formik.values.incorporation_date) return [null, null];

    const fromTime = dayjs(formik.values.incorporation_date, FORMAT_DATE_TIME.yyyy_mm_dd);
    const toTime = dayjs(formik.values.to_date, FORMAT_DATE_TIME.yyyy_mm_dd);
    return [fromTime, toTime];
  };

  const formMoreFilter = (
    <React.Fragment>
      <DateRangePicker
        defaultValue={getDefaultValueRangeTime()}
        format={FORMAT_DATE_TIME.FULL_ONLY_DATE}
        onChange={(value: DateRange<Dayjs>, context: PickerChangeHandlerContext<ExpectedAny>) => {
          let fromTime,
            toTime = '';
          if (value[0]) {
            fromTime = dayjs(value[0]).format(FORMAT_DATE_TIME.yyyy_mm_dd);
          }
          if (value[1]) {
            toTime = dayjs(value[1]).format(FORMAT_DATE_TIME.yyyy_mm_dd);
          }
          formik.setFieldValue('incorporation_date', fromTime);
          formik.setFieldValue('to_date', toTime);
        }}
        slotProps={{
          textField: { size: 'small' },
          actionBar: {
            actions: ['clear'],
          },
        }}
        localeText={{ start: 'Incorporation Date From', end: 'To' }}
      />

      <TextField
        value={formik.values['sort_by']}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        id="sort_by"
        name="sort_by"
        size="small"
        placeholder="Enter"
        select
        label={<span>Sort by</span>}
      >
        {SORT_BY_LIST.map((type) => (
          <MenuItem value={type.value} key={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </TextField>
      {/* <TextField
        value={formik.values['non_profit_status']}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        id="non_profit_status"
        name="non_profit_status"
        size="small"
        placeholder="Enter"
        select
        label={
          <span>
            Nonprofit status <TooltipFormLabel title="" />
          </span>
        }
      >
        <MenuItem value="Only nonprofits" key={'Only nonprofits'}>
          Only nonprofits
        </MenuItem>
        <MenuItem value="Excluding branches" key={'Excluding branches'}>
          Excluding branches
        </MenuItem>
        <MenuItem value="Including branches" key={'Including branches'}>
          Including branches
        </MenuItem>
      </TextField>
      <TextField
        value={formik.values['company_branches']}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        id="company_branches"
        name="company_branches"
        size="small"
        placeholder="Enter"
        select
        label={
          <span>
            Company branches <TooltipFormLabel title="" />
          </span>
        }
      >
        <MenuItem value="Only branches" key={'Only branches'}>
          Only branches
        </MenuItem>
        <MenuItem value="Excluding nonprofits" key={'Excluding nonprofits'}>
          Excluding nonprofits
        </MenuItem>
        <MenuItem value="Including nonprofit" key={'Including nonprofit'}>
          Including nonprofit
        </MenuItem>
      </TextField> */}

      <Box>
        <InputLabel shrink color="primary">
          Contact Person Available
        </InputLabel>
        <FormGroup id="contact_person_available" sx={{ marginTop: 0, paddingLeft: 1 }} row>
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'name')}
                onChange={handleChangeContactPerson}
                size="small"
                color="primary"
                id="name"
                name="name"
              />
            }
            label={<LabelCheckbox>Name</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'job_title')}
                onChange={handleChangeContactPerson}
                size="small"
                color="primary"
                id="job_title"
                name="job_title"
              />
            }
            label={<LabelCheckbox>Job Title</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'email')}
                onChange={handleChangeContactPerson}
                size="small"
                color="primary"
                id="email"
                name="email"
              />
            }
            label={<LabelCheckbox>Email</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'phone')}
                onChange={handleChangeContactPerson}
                size="small"
                color="primary"
                id="phone"
                name="phone"
              />
            }
            label={<LabelCheckbox>Phone</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('contact_person_available', 'website')}
                onChange={handleChangeContactPerson}
                size="small"
                color="primary"
                id="website"
                name="website"
              />
            }
            label={<LabelCheckbox>Website</LabelCheckbox>}
          />
        </FormGroup>
      </Box>
      <Box>
        <InputLabel shrink color="primary">
          Include all company status
        </InputLabel>
        <FormGroup id="contact_person_available" sx={{ marginTop: 0 }} row>
          <Switch
            checked={formik.values['include_all_company_status']}
            onChange={formik.handleChange}
            size="medium"
            color="primary"
            id="include_all_company_status"
            name="include_all_company_status"
          />
        </FormGroup>
      </Box>

      {/* <Box>
        <InputLabel shrink color="primary">
          Include Anomalies
        </InputLabel>
        <FormGroup row sx={{ marginTop: 0, paddingLeft: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('include_anomalies', 'counterfeits')}
                onChange={handleChangeIncludeAnomalies}
                size="small"
                color="primary"
                id="counterfeits"
                name="counterfeits"
              />
            }
            label={<LabelCheckbox>Counterfeits</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('include_anomalies', 'under_valued')}
                onChange={handleChangeIncludeAnomalies}
                size="small"
                color="primary"
                id="under_valued"
                name="under_valued"
              />
            }
            label={<LabelCheckbox>Under-Valued</LabelCheckbox>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultValueCheckbox('include_anomalies', 'data_miss_match')}
                onChange={handleChangeIncludeAnomalies}
                size="small"
                color="primary"
                id="data_miss_match"
                name="data_miss_match"
              />
            }
            label={<LabelCheckbox>Data Mis-Match</LabelCheckbox>}
          />
        </FormGroup>
      </Box> */}
    </React.Fragment>
  );

  return (
    <ModalComponent padding={4} handleClose={handleClose} open={open}>
      <FormikForm formik={formik}>
        <Box width={540}>
          <Typography textAlign={'center'} variant="h5">
            Search Company Profiles
          </Typography>

          <Box sx={{ mt: 1 }} />

          <Typography textAlign={'center'} color={theme.palette.text.secondary} variant="body1">
            Search across millions of company profiles and their trading activities.
          </Typography>

          <Box sx={{ mt: 4 }} />

          <Stack direction={'column'} spacing={2}>
            <Box>
              <FormItemJurisdiction error={Boolean(getFormItemError('jurisdiction', formik))} id="jurisdiction" />
              {renderFormItemError(getFormItemError('jurisdiction', formik))}
            </Box>

            <FormItemCompanyName
              id="company_name"
              freeSolo={true}
              jurisdiction={getArrayDefault(formik.values['jurisdiction'])}
            />

            <Box>
              <FormItemCompanyType
                error={Boolean(getFormItemError('company_type', formik))}
                value={formik.values['company_type']}
                id="company_type"
                jurisdiction={getArrayDefault(formik.values['jurisdiction'])}
              />
              {renderFormItemError(getFormItemError('company_type', formik))}
            </Box>

            <TextField
              value={formik.values.company_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="company_code"
              name="company_code"
              size="small"
              placeholder="Enter"
              label={<span>Company Registration Number</span>}
            />

            <Stack spacing={2} direction={'row'}>
              <TextField
                value={formik.values.city}
                onChange={formik.handleChange}
                id="city"
                name="city"
                placeholder="Enter"
                size="small"
                sx={{ flex: 1 }}
                label={<span>City</span>}
              />
              <TextField
                value={formik.values.state}
                onChange={formik.handleChange}
                id="state"
                name="state"
                placeholder="Enter"
                size="small"
                sx={{ flex: 1 }}
                label={<span>State</span>}
              />
            </Stack>

            {/* <FormItemIndustryClassification id="industry" countryCode={formik.values.jurisdiction} /> */}

            {visibleMoreFilter && formMoreFilter}

            {renderButtonMoreFilter()}

            <ButtonWithLoading sx={{ height: 53 }} size="large" variant="contained" isRounded type="submit">
              Search Now
            </ButtonWithLoading>
          </Stack>
        </Box>
      </FormikForm>
    </ModalComponent>
  );
};

export default FormSearchCompany;
