import { Route } from 'react-router-dom';

// Routes
import { AuthRouteKeys } from 'src/utils/constants/routes';

// Components
import { AuthProvider } from 'src/contexts/AuthContext';
import BillOfLandingDetailPage from 'src/pages/BillOfLandingDetail';
import BookmarkPage from 'src/pages/Bookmark';
import CompanyDetailPage from 'src/pages/CompanyDetail';
import CompanyProfilesPage from 'src/pages/CompanyProfiles';
import CompareCompanyPage from 'src/pages/CompareCompany';
import HomePage from 'src/pages/Home';
import OnboardingPage from 'src/pages/Onboarding';
import PeopleDetailPage from 'src/pages/PeopleDetail';
import PeopleProfilesPage from 'src/pages/PeopleProfiles';
import SettingPage from 'src/pages/Setting';
import TariffRulesPage from 'src/pages/TariffsAndRules';
import TradeDataPage from 'src/pages/TradeData';
import TradeDataDetailPage from 'src/pages/TradeDataDetail';

const AuthRoutes = [
  <Route
    key={AuthRouteKeys.Home}
    path={AuthRouteKeys.Home}
    element={
      <AuthProvider>
        <HomePage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.CompanyDetail}
    path={AuthRouteKeys.CompanyDetail}
    element={
      <AuthProvider>
        <CompanyDetailPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.CompanyProfiles}
    path={AuthRouteKeys.CompanyProfiles}
    element={
      <AuthProvider>
        <CompanyProfilesPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.Onboarding}
    path={AuthRouteKeys.Onboarding}
    element={
      <AuthProvider>
        <OnboardingPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.Setting}
    path={AuthRouteKeys.Setting}
    element={
      <AuthProvider>
        <SettingPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.PeopleProfiles}
    path={AuthRouteKeys.PeopleProfiles}
    element={
      <AuthProvider>
        <PeopleProfilesPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.PeopleDetail}
    path={AuthRouteKeys.PeopleDetail}
    element={
      <AuthProvider>
        <PeopleDetailPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.Bookmark}
    path={AuthRouteKeys.Bookmark}
    element={
      <AuthProvider>
        <BookmarkPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.CompareCompany}
    path={AuthRouteKeys.CompareCompany}
    element={
      <AuthProvider>
        <CompareCompanyPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.TariffsAndRules}
    path={AuthRouteKeys.TariffsAndRules}
    element={
      <AuthProvider>
        <TariffRulesPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.TradeDataDetail}
    path={AuthRouteKeys.TradeDataDetail}
    element={
      <AuthProvider>
        <TradeDataDetailPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.TradeData}
    path={AuthRouteKeys.TradeData}
    element={
      <AuthProvider>
        <TradeDataPage />
      </AuthProvider>
    }
  />,
  <Route
    key={AuthRouteKeys.BillOfLadingDetail}
    path={AuthRouteKeys.BillOfLadingDetail}
    element={
      <AuthProvider>
        <BillOfLandingDetailPage />
      </AuthProvider>
    }
  />,
];

export default AuthRoutes;
