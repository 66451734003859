import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Components
import { StyledWhiteBox } from 'src/features/CompanyDetail/config';
import SocialMediaTable from 'src/features/CompanyDetail/SocialMedia/SocialMediaTable';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import ContributeForNoData from 'src/features/CompanyDetail/Common/ContributeForNoData';

// Utilities
import { useQueryCompanySocialMedia } from 'src/services/queries';
import { useCompanyContribute } from 'src/zustand/useCompanyContribute';
import { TypeContribute } from 'src/features/CompanyDetail/Common/config';
import { useMemo } from 'react';
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';

const SocialMediaTab = () => {
  const { id } = useParams();
  const { handleOpenContribute } = useCompanyContribute((state) => state);
  const { data, isFetching } = useQueryCompanySocialMedia({
    id: id || '',
  });

  const socialMediasList = useMemo(() => {
    return getArrayDefault(data?.data?.social_medias).filter((item) => !isEmpty(item?.url));
  }, [data.data.social_medias]);

  const chatsList = useMemo(() => {
    return getArrayDefault(data?.data?.chats).filter((item) => !isEmpty(item?.url));
  }, [data.data.chats]);

  const directoriesList = useMemo(() => {
    return getArrayDefault(data?.data?.directories).filter((item) => !isEmpty(item?.url));
  }, [data.data.directories]);

  const ecommercesList = useMemo(() => {
    return getArrayDefault(data?.data?.ecommerces).filter((item) => !isEmpty(item?.url));
  }, [data.data.ecommerces]);

  if (isFetching) {
    return (
      <StyledWhiteBox>
        <LoadingSkeleton count={5} height={50} />
      </StyledWhiteBox>
    );
  }

  if ([socialMediasList, chatsList, directoriesList, ecommercesList].every((item) => isEmpty(item))) {
    return (
      <StyledWhiteBox display="flex" flexDirection="column">
        <ContributeForNoData action={() => handleOpenContribute(TypeContribute.SocialMedia)} />
      </StyledWhiteBox>
    );
  }

  return (
    <Stack gap={3} direction="column">
      {!isEmpty(socialMediasList) && (
        <StyledWhiteBox>
          <SocialMediaTable data={socialMediasList} title="Social Media" />
        </StyledWhiteBox>
      )}
      {!isEmpty(chatsList) && (
        <StyledWhiteBox>
          <SocialMediaTable data={chatsList} title="Chat" />
        </StyledWhiteBox>
      )}
      {!isEmpty(directoriesList) && (
        <StyledWhiteBox>
          <SocialMediaTable data={directoriesList} title="Directory" />
        </StyledWhiteBox>
      )}
      {!isEmpty(ecommercesList) && (
        <StyledWhiteBox>
          <SocialMediaTable data={ecommercesList} title="eCommerce" />
        </StyledWhiteBox>
      )}
    </Stack>
  );
};

export default SocialMediaTab;
