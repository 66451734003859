import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import update from 'immutability-helper';
import { get } from 'lodash';
import { useState } from 'react';
import { CheckCircle } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

//Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FormikForm from 'src/components/common/FormikForm';
// import ButtonBack from 'src/features/SignUp/Common/ButtonBack';
import ButtonBack from 'src/components/common/ButtonBack';
import IconFeather from 'src/components/common/Icon';

//Utilities
import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/hooks/useNotification';
import { useMutateForgotPassword } from 'src/services/mutate/useMutateAuth';
import { MIN_LENGTH_PASSWORD } from 'src/utils/constants/config';
import { NoAuthRouteKeys } from 'src/utils/constants/routes';
import { SUCCESS_CODE } from 'src/utils/constants/statusCode';
import { validatePassword } from 'src/utils/helpers/validate';
import { useSignupStore } from 'src/zustand/useSignupStore';

const FormResetPassword = () => {
  const theme = useTheme();

  const { isPending, mutateAsync } = useMutateForgotPassword();
  const { email } = useSignupStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { notification } = useNotification();
  const { t } = useTranslation();

  const [visibility, setVisibility] = useState({
    password: false,
    passwordRepeat: false,
  });

  const toggleVisiblePassword = (type: 'password' | 'passwordRepeat') => {
    const state = update(visibility, {
      [type]: {
        $set: !visibility[type],
      },
    });
    setVisibility(state);
  };

  const renderIconHidePassword = (type: 'password' | 'passwordRepeat') => {
    return visibility[type] ? (
      <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
    ) : (
      <VisibilityOffOutlinedIcon sx={{ fontSize: 16 }} />
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      password: '',
      confirm_password: '',
      redirect_new_password: true,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required('Password is required')
        .min(MIN_LENGTH_PASSWORD, `Your password must be at least ${MIN_LENGTH_PASSWORD} characters`)
        .test(
          'has-enough-rule',
          'Your password must be included an uppercase letter, a lowercase letter, and a number and a symbol ',
          (value: string) =>
            /(?=.*?[A-Z]).*/.test(value) &&
            /(?=.*?[a-z]).*/.test(value) &&
            /(?=.*?\d).*/.test(value) &&
            /(?=.*?\W).*/.test(value),
        ),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Password must match')
        .required()
        .required('Password is required'),
      redirect_new_password: Yup.bool(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const dto = {
        email: email,
        password: values.password,
        confirm_password: values.confirm_password,
        otp_code: location?.state?.otp || '',
      } as TypeDTOResetPassword;

      const res = await mutateAsync(dto);
      notification(
        res,
        {
          title: t('setting.password.notification.reset_password.success.title'),
          description: t('setting.password.notification.reset_password.success.description'),
        },
        {
          title: t('setting.password.notification.reset_password.error.title'),
          description: t('setting.password.notification.reset_password.error.description'),
        },
      );
      if (res?.status_code === SUCCESS_CODE.GET) {
        navigate(NoAuthRouteKeys.Login, {
          state: values.redirect_new_password ? { email: email, password: values.password } : {},
        });
      }
      setSubmitting(false);
    },
  });

  const renderError = (type: string) => {
    return (
      get(formik.touched, type) &&
      get(formik.errors, type) && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {get(formik.errors, type)}
        </FormHelperText>
      )
    );
  };

  const getError = (type: string) => {
    return get(formik.touched, type) && Boolean(get(formik.errors, type));
  };

  return (
    <FormikForm formik={formik}>
      <Box sx={{ width: 500 }}>
        <ButtonBack sx={{ position: 'absolute', top: 40 }} />
        <Typography variant="h3">Reset Your Password</Typography>

        <Box sx={{ mt: 1 }} />

        <Typography variant="body1" color={theme.palette.text.secondary}>
          Please fill in your new password
        </Typography>

        <Box sx={{ mt: 4 }} />

        <Box display={'flex'} gap={2} flexDirection={'column'}>
          <FormControl>
            <TextField
              size="small"
              id="password"
              error={getError('password')}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisiblePassword('password')} size="small">
                      {renderIconHidePassword('password')}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={visibility.password ? 'text' : 'password'}
              placeholder="********"
              required
              label={<span>Password</span>}
            />
            {renderError('password')}
          </FormControl>
          <FormControl>
            <TextField
              size="small"
              id="confirm_password"
              name="confirm_password"
              error={getError('confirm_password')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirm_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisiblePassword('passwordRepeat')} size="small">
                      {renderIconHidePassword('passwordRepeat')}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={visibility.passwordRepeat ? 'text' : 'password'}
              placeholder="********"
              required
              label={<span>Repeat Password</span>}
            />
            {renderError('confirm_password')}
          </FormControl>
        </Box>

        <Box sx={{ mt: 1 }} />

        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={(e, value) => {
                  formik.setFieldValue('redirect_new_password', value);
                }}
                checked={formik.values.redirect_new_password}
                color="primary"
              />
            }
            label={
              <Typography color={theme.palette.text.secondary} variant="body2">
                Redirect and log in with new password
              </Typography>
            }
          />
        </Stack>

        {formik.values.password ? (
          <Box mt={2} mb={2} display="flex" flexDirection="row" justifyContent="space-between">
            {validatePassword(formik.values.password).map((itemCheck, index) => {
              return (
                <Stack key={`register-strong-password-${index}`} direction="row" spacing={0.5}>
                  <IconFeather
                    muiProps={{ color: itemCheck.valid ? 'success' : 'disabled', fontSize: 'small' }}
                    icon={<CheckCircle />}
                  />
                  <Typography variant="caption2" color={itemCheck.valid ? 'success.main' : 'text.disabled'}>
                    {itemCheck.title}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        ) : (
          <Box mt={2} />
        )}

        <ButtonWithLoading
          sx={{ fontWeight: theme.typography.fontWeightMedium, height: 50 }}
          size="large"
          isRounded
          variant="contained"
          fullWidth
          type="submit"
          isLoading={isPending}
          disabled={isPending || !formik.isValid}
        >
          Reset My Password
        </ButtonWithLoading>
      </Box>
    </FormikForm>
  );
};

export default FormResetPassword;
