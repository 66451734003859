import MainLayout from 'src/layouts';
import Home from 'src/features/Home';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Home />
    </MainLayout>
  );
};

export default HomePage;
