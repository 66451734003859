import { Box, Typography, useTheme } from '@mui/material';
import Lottie from 'react-lottie';

import TechnySingle from 'src/asset/lottie/techny-single.json';

// Components

function SmallScreenNotice() {
  const theme = useTheme();

  return (
    <Box position="relative" height="100vh" width="100vw" bgcolor={theme.palette.background.paper1}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: TechnySingle,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height="100%"
          width="100%"
        />
        <Typography variant="h2" align="center" sx={{ color: '#3658C1' }}>
          Optimized for Larger Screens
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          Our website isn't quite ready for small screens yet. Please use a <b>Laptop</b> or <b>Desktop</b> for the best
          experience. Thank you for your understanding!
        </Typography>
      </Box>
    </Box>
  );
}

export default SmallScreenNotice;
