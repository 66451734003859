import { Stack, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

// Utilities
import SearchesIcon from 'src/asset/lottie/searches.json';

const ContributeForNoData = ({ action }: { action?: () => void }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" spacing={2}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: SearchesIcon,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={150}
        width={200}
      />
      <Typography variant="h4">{t('company_detail.common.contribute_for_no_data.title')}</Typography>
      {action && (
        <Typography variant="body2" color="text.secondary">
          {t('company_detail.common.contribute_for_no_data.sub_title')}
        </Typography>
      )}
      {action && (
        <Box>
          <Button size="medium" color="primary" variant="contained" onClick={() => action()}>
            {t('global.contribute_data')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};
export default ContributeForNoData;
