import { HelpOutline, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, SettingsOutlined } from '@mui/icons-material';
import { Box, ClickAwayListener, Divider, ListItemText, Toolbar, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// Components
import { Drawer, List, ListItem, ListItemButton, ListItemIcon } from 'src/components/Drawer/SidebarStyled';

// Utilities
import React from 'react';
import { usePathname } from 'src/hooks/usePathName';
import { HEADER_HEIGHT, LINK_SUPPORT } from 'src/utils/constants/config';
import { AuthRouteKeys } from 'src/utils/constants/routes';
import { LIST_MENU_SIDEBAR } from 'src/utils/constants/sidebar';
import { LOGO_HEADER_URL } from 'src/utils/constants/imagesUrl';

export default function SidebarMenu({
  open,
  handleDrawerOpen,
  handleDrawerClose,
}: {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}) {
  const pathname = usePathname();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const checkIsSelected = (path: string) => {
    return path === pathname;
  };

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Drawer variant="permanent" open={open}>
        <List>
          <Toolbar
            sx={{
              justifyContent: 'center',
              borderBottom: `1px solid ${theme.palette.divider}`,
              height: HEADER_HEIGHT,
            }}
          >
            <Link to={AuthRouteKeys.Home}>
              <Box width={open ? 64 : 49} src={LOGO_HEADER_URL} component="img" />
            </Link>
          </Toolbar>
          {LIST_MENU_SIDEBAR.map((menu) => {
            if (typeof menu === 'string') {
              return (
                <React.Fragment key={menu}>
                  {open ? (
                    <ListItem disablePadding={true}>
                      <Typography sx={{ pt: 1 }} variant="subtitle2">
                        {menu}
                      </Typography>
                    </ListItem>
                  ) : (
                    <Divider sx={{ mx: 2 }} />
                  )}
                </React.Fragment>
              );
            }
            const isSelected = checkIsSelected(menu.path);
            return (
              <ListItem key={menu.key} disablePadding={true}>
                <ListItemButton
                  selected={isSelected}
                  disabled={menu.disabled}
                  onClick={() => handleNavigate(menu.path)}
                >
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  {open && <ListItemText primary={<Typography variant="subtitle2">{menu.text}</Typography>} />}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <List>
          <ListItem disablePadding={true}>
            <ListItemButton
              selected={checkIsSelected(AuthRouteKeys.Setting)}
              onClick={() => handleNavigate(AuthRouteKeys.Setting)}
            >
              <ListItemIcon>
                <SettingsOutlined fontSize="small" />
              </ListItemIcon>
              {open && <ListItemText primary={<Typography variant="subtitle2">Settings</Typography>} />}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding={true}>
            <ListItemButton onClick={() => window.open(LINK_SUPPORT)}>
              <ListItemIcon>
                <HelpOutline fontSize="small" />
              </ListItemIcon>
              {open && <ListItemText primary={<Typography variant="subtitle2">Tutorial & Help</Typography>} />}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding={true}>
            <ListItemButton
              sx={{
                justifyContent: open ? 'flex-end' : 'center',
              }}
              onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
            >
              <ListItemIcon>{open ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}</ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </ClickAwayListener>
  );
}
