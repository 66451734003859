import { Box, Divider, IconButton, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { first, get, has, isEmpty } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { AlertOctagon, Bookmark, Calendar, Edit, MapPin, PhoneCall } from 'react-feather';
import AvatarUser from 'src/components/common/AvatarUser';

// Components
import ButtonWithLoading from 'src/components/common/ButtonWithLoading';
import FlagCountry from 'src/components/common/FlagCountry';
import IconFeather from 'src/components/common/Icon';
import LoadingSkeleton from 'src/components/common/LoadingSkeleton';
import TooltipFormLabel from 'src/components/common/TooltipFormLabel';
import { EmptyText, UnknownText } from 'src/utils/constants/config';
import { NATIONALITIES } from 'src/utils/constants/nationality';
// import { usePrinter } from 'src/hooks/usePrinter';

// Utilities
import { getArrayDefault } from 'src/utils/helpers/arrayHelpers';
import { convertToStartCase, getCountryByCountryCode } from 'src/utils/helpers/stringHelpers';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.25),
  background: 'linear-gradient(180deg, #7551FF 0%, #3311DB 100%)',
  borderRadius: theme.shape.borderRadius * 2,
  color: theme.palette.common.white,
  boxShadow: '0px 0px 50px 0px rgba(112, 114, 176, 0.08)',
}));

interface IProps {
  dataPeople: IPeopleDetail;
  isFetching: boolean;
}

const PeopleDetailUserInfoPanel = ({ dataPeople, isFetching }: IProps) => {
  const theme = useTheme();

  const country = useMemo(() => {
    if (dataPeople.country_code) return getCountryByCountryCode(dataPeople.country_code || '');

    const countryCode = get(NATIONALITIES, dataPeople?.nationality || '', '');
    return getCountryByCountryCode(countryCode);
  }, [dataPeople]);

  if (isFetching) {
    return (
      <StyledBox flexDirection="row">
        <LoadingSkeleton count={5} height={30} />
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack flex={1}>
          <Box display={'flex'} alignItems={'center'}>
            <AvatarUser
              alt={dataPeople?.full_name || UnknownText}
              variant="rounded"
              src={dataPeople?.image_url || ''}
              sizes="100"
              sx={{ width: 100, height: 100, mr: 2, border: '0.5px solid #A195FD', backgroundColor: '#F4F7FE' }}
            />
            <Typography variant="h5" color={'inherit'}>
              {convertToStartCase(dataPeople?.full_name || EmptyText)}
              {dataPeople?.description && (
                <Typography variant="subtitle2" color={'inherit'}>
                  {convertToStartCase(dataPeople?.description || EmptyText)}
                </Typography>
              )}
            </Typography>
          </Box>

          <Box mt={1} />

          {/* <Typography color={'inherit'}>{renderDescription()}</Typography> */}

          <Box mt={3} />

          <Stack direction={'row'} gap={2}>
            <Tooltip title="Coming Soon!">
              <ButtonWithLoading
                variant="outlined"
                color="inheritWhite"
                isRounded
                endIcon={<IconFeather icon={<Edit />} muiProps={{ width: '16' }} />}
                size="medium"
                onClick={() => null}
              >
                Contribute DATA
              </ButtonWithLoading>
            </Tooltip>
            {/* <Tooltip placement="top" title="Print">
              <IconButton
                size="large"
                sx={{
                  border: '1px solid white',
                }}
                color="inherit"
                onClick={() => handlePrint()}
              >
                <IconFeather icon={<Printer />} svgProps={{ fontSize: 'small' }} />
              </IconButton>
            </Tooltip> */}
            <Tooltip placement="top" title="Bookmarks Coming Soon!">
              <IconButton
                size="large"
                sx={{
                  border: '1px solid white',
                }}
                color="inherit"
              >
                <IconFeather icon={<Bookmark />} muiProps={{ fontSize: 'small' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider flexItem orientation="vertical" />
        <Stack gap={2} flexBasis={254}>
          {has(dataPeople?.addresses, '[0].full_address') && (
            <InforItem
              icon={<MapPin />}
              label={(get(first(dataPeople.addresses), 'full_address', EmptyText) || '').toUpperCase()}
              isFeatherIcon
            />
          )}
          {!isEmpty(dataPeople?.phone_numbers) && (
            <InforItem
              icon={<PhoneCall />}
              label={getArrayDefault(dataPeople?.phone_numbers).join(', ')}
              isFeatherIcon
            />
          )}
          {dataPeople?.birthday && (
            <InforItem
              icon={<Calendar />}
              label={dataPeople?.birthday || UnknownText}
              isFeatherIcon
              tooltip="Birth Year"
            />
          )}
          <InforItem
            icon={<FlagCountry width={12} code={country.code} colorDefaultFlag={theme.palette.common.white} />}
            label={convertToStartCase(country.label || UnknownText)}
          />
          {dataPeople?.is_sanctioned && (
            <InforItem
              icon={<AlertOctagon />}
              label={dataPeople?.is_sanctioned ? 'Sanctioned' : 'Not Found'}
              isFeatherIcon
              tooltip="Sanctioned Person"
            />
          )}
        </Stack>
      </Stack>
    </StyledBox>
  );
};

export default PeopleDetailUserInfoPanel;

const InforItem = ({
  icon,
  label,
  tooltip,
  isFeatherIcon,
}: {
  icon: ReactNode;
  tooltip?: string;
  label: ReactNode;
  isFeatherIcon?: boolean;
}) => {
  return (
    <Stack gap={1} direction={'row'} alignItems="center">
      {isFeatherIcon ? <IconFeather muiProps={{ sx: { fontSize: 12 } }} icon={icon} /> : icon}
      <Typography variant="caption" color={'inherit'}>
        {label} {tooltip && <TooltipFormLabel color="inherit" title={tooltip} />}
      </Typography>
    </Stack>
  );
};
